import uuid from 'uuid/v4';
import venueActions from './venueActions';
import distributorActions from './distributorActions';
import adminActions from './adminActions';
import commonGetTemplateAction from './commonGetTemplateAction';
import getters from './getters';
import mutations from './mutations';

let draft = null;

try {
  draft = JSON.parse(localStorage.getItem('productDraft') || '');
} catch (e) {
  // continue
}

const state = {
  draft: draft || {
    id: null,
    currentStep: 0,
    defaultTierPricing: {},
    specialTierPricings: [],
    name: null,
    categoryId: null,
    price: 0,
    tags: [],
  },
  draftSupplierProducts: {},
  specialTierDraft: {
    id: uuid(),
    name: '',
    discountType: 'price',
    tiers: [
      {
        id: uuid(),
        discountType: 'price',
        minQuantity: null,
        value: null,
      },
    ],
    venues: [],
    connectedVenues: [],
  },
  meta: {
    count: 0,
    totalCount: 0,
    nextId: null,
  },
  inventoryManagement: {
    warehouse: {},
    batchCode: '',
    expiryDate: null,
    items: [],
    params: {},
  },
  stocktake: {
    warehouse: {},
    selectedProducts: [],
    users: [],
    note: '',
  },
  venueStocktake: {
    location: {},
    selectedProducts: [],
    users: [],
    note: '',
  },
};

export default {
  state,
  namespaced: true,
  actions: {
    ...venueActions,
    ...distributorActions,
    ...adminActions,
    ...commonGetTemplateAction,
  },
  getters,
  mutations,
};
