import Vue from 'vue';
import dayjs from 'dayjs';
import {
  formatNumber,
  formatPercentage,
  formatPrice,
  pluralize,
  formatDate,
  formatDateRefactored,
  formatTime,
  formatDateTime,
} from '@/util/utils';

Vue.filter('firstLettersName', (value, numOfLetters = 2) => {
  const string = value
    .toUpperCase()
    .split(' ')
    .map(str => str[0])
    .join('')
    .slice(0, numOfLetters);

  return string;
});

Vue.filter('date', formatDate);

Vue.filter('dateRefactored', formatDateRefactored);

Vue.filter('time', formatTime);

Vue.filter('dateTime', formatDateTime);

Vue.filter('htmlDateTime', formatDateTime);

Vue.filter('price', formatPrice);

Vue.filter('percentage', formatPercentage);

Vue.filter('formatNumber', formatNumber);

Vue.filter('capitalize', value => {
  const status = (value || '').replace('_', ' ');

  return status.charAt(0).toLocaleUpperCase() + status.slice(1);
});

Vue.filter('categoryWithParent', category => {
  if (!category || !category.name) {
    return 'Uncategorized';
  }

  let categoryName = category.name;

  if (category.parent && category.parent.name) {
    categoryName = `${category.parent.name} - ${categoryName}`;
  }

  return categoryName;
});

Vue.filter('addMonths', (value, months) => dayjs(value).add(months, 'M'));

Vue.filter('pluralize', pluralize);

Vue.filter(
  'limitText',
  (text, numberOfChars, trail) =>
    `${text.slice(0, numberOfChars)}${text.length > numberOfChars ? trail || '...' : ''}`,
);

Vue.filter('uppercase', value => value.toUpperCase());

Vue.filter('lowercase', value => value.toLowerCase());
