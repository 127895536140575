/* eslint-disable no-unused-vars */
import axios from 'axios';
import qs from 'qs';
import store from '@/store';
import { parseErrors } from '@/util/response';
import flash from '@/components/ui/FlashMessage';
import platformRouter from '@/router';
import adminRouter from '@/router/admin';
import { isLocalStorageAccessSafeFlash } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';

let retryCallbackQueue = [];
let isRetryRequest = false;

const addRetryCallback = request => {
  retryCallbackQueue.push(request);
};

const onTokenRefresh = token => {
  retryCallbackQueue = retryCallbackQueue.filter(callback => callback(token));
};

const httpService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getRefreshToken = () => {
  const token = localStorage.getItem('refreshToken');
  const role = localStorage.getItem('role');
  const auth = role === 'admin' ? '/admin/oauth' : '/manager/oauth';

  return httpService
    .post(
      auth,
      qs.stringify({
        refresh_token: token,
        grant_type: 'refresh_token',
        client_id: 'web',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then(({ data: { data } }) => {
      const {
        accessToken,
        accessTokenExpiresAt,
        refreshToken,
        refreshTokenExpiresAt,
        scope,
        user,
      } = data;

      if (!isLocalStorageAccessSafeFlash()) {
        return Promise.reject(new Error('Internet Explorer access denied'));
      }
      if (user?.distributor?.defaultDeliveryDate)
        store.commit('entities/users/SET_DELIVERY_DATE', {
          defaultDeliveryDate: user.distributor.defaultDeliveryDate,
        });

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);
      localStorage.setItem('role', scope);

      return Promise.resolve(accessToken);
    })
    .catch(() => {
      if (!isLocalStorageAccessSafeFlash()) {
        return Promise.reject(new Error('Internet Explorer access denied'));
      }
      localStorage.clear();
      retryCallbackQueue = [];

      flash.error({
        title: 'Session expired!',
        message: 'Please sign in to continue.',
      });

      if (role === 'admin') {
        return adminRouter.replace({ name: 'admin-login' });
      }

      return platformRouter.replace({ name: 'platform-login' });
    });
};

httpService.interceptors.request.use(config => {
  const formKey = config.headers ? config.headers.formKey : null;
  const loadingKey = config.headers ? config.headers.loadingKey : null;
  const token = localStorage.getItem('accessToken');

  if (loadingKey) {
    store.commit('loading/SET_LOADING', { [loadingKey]: true });
  }

  if (formKey) {
    store.commit('errors/CLEAR_ERRORS', { key: formKey });
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpService.interceptors.response.use(
  response => {
    const { config } = response;
    const { headers } = config;
    const { loadingKey } = headers;
    store.commit('loading/CLEAR_LOADING', { key: loadingKey });

    if (loadingKey === LOADING_KEY.LOG_OFFLINE_ORDER) {
      flash.success({
        title: 'Invoice uploaded!',
        message: 'You have successfully uploaded a new invoice.',
      });
    }

    return response;
  },
  error => {
    const { response, config } = error;
    const { headers } = config;
    const { loadingKey } = headers;
    store.commit('loading/CLEAR_LOADING', { key: loadingKey });
    if (response?.status === 422 && headers.formKey) {
      store.commit('errors/SET_ERROR', { [headers.formKey]: parseErrors(response) });
    } else if (response?.status === 401) {
      if (!isRetryRequest) {
        isRetryRequest = true;
        // console.warn('Should retry');

        getRefreshToken().then(token => {
          isRetryRequest = false;

          onTokenRefresh(token);
        });
      }

      return new Promise(resolve => {
        addRetryCallback(token => {
          resolve(
            httpService.request({
              ...config,
              headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
              },
            }),
          );
        });
      });
    } else if (response?.status >= 400 && response?.status < 500) {
      let messageToShow;

      if (
        response.request.responseType === 'arraybuffer' &&
        response.data.toString() === '[object ArrayBuffer]'
      ) {
        /**
         * error response
         * @see {@link https://aleen42.gitbooks.io/wiki/content/qa/axios_download_within_same_request.html}
         *
         * */
        const res = JSON.parse(Buffer.from(response.data).toString('utf8'));
        const {
          error: { message: arrayMess },
        } = res;
        messageToShow = arrayMess;
      } else {
        const {
          data: {
            error: { message },
          },
        } = response;
        messageToShow = message;
      }

      flash.error({
        title: 'Something went wrong!',
        message: messageToShow || 'We are not able to process your request at the moment.',
      });
    } else if (response?.status >= 500) {
      flash.error({
        title: 'Unexpected internal server error.',
        message: `Please contact ${process.env.VUE_APP_HELP_EMAIL}`,
      });
    }

    return Promise.reject(error);
  },
);

export const userRoute = (route = '') => {
  const userRole = localStorage.getItem('role');

  if (!userRole) {
    return `${process.env.VUE_APP_API_URL}/${route.replace(/^\//, '')}`;
  }

  return `${process.env.VUE_APP_API_URL}/${userRole}/${route.replace(/^\//, '')}`;
};

export default httpService;
