import httpService from '@/api/http';
import { getContextId } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import { getPrice } from '@/util/utilsFinCalculator';
import i18n from '@/i18n';

const fetchTotalProductPrice = ({ rootState }, { id, quantity }) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService.get(`/venues/${venueContext}/products/${id}/price`, { params: { quantity } });
};

const fetchProductPriceForQuantity = (
  { commit, rootState },
  { id, quantity, distributorId, internalId, loadingKey = LOADING_KEY.ADD_TO_CART },
) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService
    .get(`/venues/${venueContext}/products/${id}/price`, {
      params: { quantity },
      headers: {
        loadingKey,
      },
    })
    .then(({ data: { data } }) => {
      commit('UPDATE_PRODUCT_COUNT', {
        internalId,
        distributorId,
        productId: id,
        price: data.price,
        marketPrice: data.marketPrice,
        pricePerUnit: data.pricePerUnit,
        quantity,
      });

      return Promise.resolve(data);
    });
};

const pushToCart = async (
  { commit, dispatch },
  { product, loadingKey = LOADING_KEY.ADD_TO_CART },
) => {
  commit('ADD_TO_CART', {
    product,
    quantity: Number.parseFloat(product.quantity),
    price: product.quantity * getPrice(product),
  });
  if (!product.isBonus) {
    await dispatch('fetchProductPriceForQuantity', {
      id: product.id,
      internalId: product.internalId,
      distributorId: product.distributor.id,
      quantity: Number.parseFloat(product.quantity),
      loadingKey,
    });
  }
};

/**
 *
 * Fetching distributors for venue
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchDistributorsForVenue(
  context,
  { loadingKey = LOADING_KEY.FETCH_DISTRIBUTORS_FOR_VENUE },
) {
  const venueId = getContextId();

  return httpService.get(`/venues/${venueId}/distributors`, {
    headers: {
      loadingKey,
    },
  });
}

export default {
  fetchDistributorsForVenue,
  pushToCart,
  fetchTotalProductPrice,
  fetchProductPriceForQuantity,
};
