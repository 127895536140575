import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * Transform the roles from the API to corespondent to component props.
 * @param request
 * @returns {Promise<unknown>}
 * @private
 */
// eslint-disable-next-line no-underscore-dangle
const _roleTransform = request => new Promise(async (resolve, reject) => {
  try {
    const { data } = await request;
    const roles = (data.data || []).map(role => ({
      id: role.key,
      name: role.name,
      text: role.description,
      optionalPermissions: role.optionalPermissions,
      rolePermissions: role.rolePermissions,
    }));
    resolve(roles);
  } catch (e) {
    reject(e);
  }
});

const getVenueRoles = () => _roleTransform(httpService.get('/venue-group/roles'));
const getDistributorRoles = ({ loadingKey = LOADING_KEY.GET_DISTRIBUTOR_ROLES }) => _roleTransform(
  httpService.get('/distributor/roles', {
    headers: {
      loadingKey,
    },
  }),
);
const getAdminDistributorRoles = () => _roleTransform(httpService.get('/admin/roles?scope=distributor'));
const getAdminVenueRoles = () => _roleTransform(httpService.get('/admin/roles?scope=venue'));

export default {
  getVenueRoles,
  getAdminVenueRoles,
  getDistributorRoles,
  getAdminDistributorRoles,
};
