import Venue from '@/models/Venue';
import Product from '@/models/Product';

import {
  PRODUCT_STEP_1,
  PRODUCT_STEP_2,
  PRODUCT_STEP_3,
} from '@/views/common/products/new-product/steps';

const getProduct = state => id => state.data[id];

const getDefaultTierForProduct = state => (id) => {
  const product = getProduct(state)(id);

  if (!product) {
    return null;
  }

  return product.defaultTierPricing;
};


const getAllSpecialTierForProduct = state => (id) => {
  const product = getProduct(state)(id);

  if (!product) {
    return [];
  }

  return (product.specialTierPricings || []).filter(s => s.id !== -1);
};

const getSpecialTierDraftIndex = state => (id) => {
  const product = getProduct(state)(id);

  if (!product) {
    return 0;
  }

  const ind = product.specialTierPricings.findIndex(s => s.id === -1);

  return ind > -1 ? ind : product.specialTierPricings.length;
};

const getAvailableVenuesForProduct = state => (skipDraft) => {
  const { specialTierDraft, draft } = state;

  let assignedVenues = [];

  draft.specialTierPricings
    .filter(t => skipDraft || t.id !== specialTierDraft.id)
    .forEach((t) => {
      assignedVenues = [
        ...assignedVenues,
        ...t.venues,
      ];
    });

  return Venue.query()
    .where(({ id, isConnected }) => isConnected && !assignedVenues.includes(id)).all();
};

const specialTierHasErrors = state => (productId, specialTierIndex) => {
  const product = getProduct(state)(productId);

  if (!product) {
    return false;
  }

  const { specialTierPricings } = product;

  if (!specialTierPricings || !specialTierPricings.length) {
    return false;
  }

  const specialTierPricing = specialTierPricings[specialTierIndex];

  if (!specialTierPricing) {
    return false;
  }

  if (!specialTierPricing.name) {
    return true;
  }

  if (!specialTierPricing.venues || !specialTierPricing.venues.length) {
    return true;
  }

  if (!specialTierPricing.tiers || !specialTierPricing.tiers.length) {
    return true;
  }

  return specialTierPricing.tiers.some(({ minQuantity, value }) => !minQuantity || !value);
};

const getLastValidStep = (state) => {
  const { draft } = state;

  const product = Product
    .query()
    .with('specialTierPricings')
    .find(draft.id);

  if (!product) {
    return PRODUCT_STEP_1;
  }

  if (!product.name && !product.price) {
    return PRODUCT_STEP_1;
  }

  if (!product.defaultTierPricing || !product.specialTierPricings) {
    return PRODUCT_STEP_2;
  }

  return PRODUCT_STEP_3;
};


function getNewDistributorProducts(state) {
  return Object.values(state.draftSupplierProducts);
}

function getNewDistributorProduct(state) {
  return id => state.draftSupplierProducts[id];
}

export default {
  getProduct,

  getDefaultTierForProduct,

  getAllSpecialTierForProduct,
  getSpecialTierDraftIndex,

  getAvailableVenuesForProduct,
  specialTierHasErrors,

  getLastValidStep,

  // Refactored

  getNewDistributorProducts,
  getNewDistributorProduct,
};
