<template>
  <transition name="fly-in">
    <div class="flash-message" :class="type" v-if="isVisible">
      <template v-if="isSuccess">
        <font-awesome-icon icon="check-circle" class="flash-message__icon"/>
      </template>
      <p class="flash-message__message" v-if="message">{{ message }}</p>
    </div>
  </transition>
</template>

<script>
import bus from './bus';

/**
 * Flash message component will sit at the root of the app and wait for messages
 * to be flashed. With a help of `flash` method, which interact with the internal event bus,
 * messages can be easily flashed.
 * @since 1.0.0
 */
export default {
  data() {
    return {
      isVisible: false,
      timeout: null,
      type: 'success',
      message: '',
      delay: 4000, // ms
      ctas: null,
    };
  },
  computed: {
    isSuccess() { return this.type === 'success'; },
  },
  methods: {
    show({
      message, type, delay,
    }) {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      this.isVisible = true;
      this.message = message;
      this.type = type;
      this.delay = delay === undefined ? this.delay : delay;
      if (delay !== -1) {
        this.timeout = setTimeout(() => {
          this.hide();
        }, this.delay);
      }
    },
    hide() {
      this.isVisible = false;
      this.timeout = null;
    },
  },
  mounted() {
    bus.$on('showToast', this.show);
    bus.$on('hideToast', this.hide);
  },
};
</script>

<style lang="scss" scoped>
$flash-message-text-font-size:        14px;
$flash-message-bottom-offset:         24px;
$flash-message-left-offset:           24px;
$flash-message-padding-y:             12px;
$flash-message-padding-x:             12px;
$flash-message-border-radius:         0.1875rem;

.flash-message {
  @include fixed(bottom $flash-message-bottom-offset left $flash-message-left-offset);
  display: flex;
  align-items: center;
  padding: $flash-message-padding-y $flash-message-padding-x;
  box-shadow: 0 4px 8px -4px rgba(0,0,0,0.12);
  background: #6C7995;
  @include z-index('toaster');

  border-radius: $flash-message-border-radius;
  color: #FFFFFF;
  @include font-size($flash-message-text-font-size);

  &__icon + &__message {
    margin: 0 0 0 12px;
  }
}

.fly-in-enter-active,
.fly-in-leave-active {
  transition: transform .3s ease-out;
}
.fly-in-enter-to, .fly-in-leave {
  transform: translate3d(0, 0, 0);
}
.fly-in-enter, .fly-in-leave-to {
  transform: translate3d(0, 130%, 0);
}
</style>
