const UPDATE_META = (state, { meta }) => {
  state.meta = {
    ...state.meta,
    ...meta,
  };
};

const UPDATE_FILTERS = (state, { filters }) => {
  state.filters = {
    ...state.filters,
    ...filters,
  };
};

const UPDATE_DRAFT = (state, draft) => {
  state.draft = {
    ...state.draft,
    ...draft,
  };
};

const CLEAR_DRAFT = (state) => {
  state.draft = {
    selectedOutlet: null,
    nonStockCredit: null,
    selectedProducts: [],
    returnToWarehouse: false,
    warehouse: {},
    note: '',
    fromOrder: false,
    order: null,
  };
};

const SET_DRAFT_VENUE = (state, venue) => {
  state.draft.selectedOutlet = venue;
};

const CLEAR_DRAFT_VENUE = (state) => {
  state.draft.selectedOutlet = null;
};

const SET_DRAFT_NON_STOCK_CREDIT_VALUE = (state, value) => {
  state.draft.nonStockCredit = value;
};

const CLEAR_DRAFT_NON_STOCK_CREDIT_VALUE = (state) => {
  state.draft.nonStockCredit = null;
};

const SET_DRAFT_PRODUCTS = (state, products) => {
  state.draft.selectedProducts = products;
};

const IS_FROM_ORDER = (state, value) => {
  state.draft.fromOrder = value;
};

const SET_CREDIT_NOTE_ORDER = (state, order) => {
  state.draft.order = order;
  SET_DRAFT_VENUE(state, order.venue);
  IS_FROM_ORDER(state, true);
};

const CLEAR_DRAFT_ORDER = (state) => {
  state.draft.order = null;
  state.draft.fromOrder = false;
};

export default {
  UPDATE_META,
  UPDATE_FILTERS,
  UPDATE_DRAFT,
  CLEAR_DRAFT,
  SET_DRAFT_VENUE,
  CLEAR_DRAFT_VENUE,
  SET_DRAFT_NON_STOCK_CREDIT_VALUE,
  CLEAR_DRAFT_NON_STOCK_CREDIT_VALUE,
  SET_DRAFT_PRODUCTS,
  SET_CREDIT_NOTE_ORDER,
  IS_FROM_ORDER,
  CLEAR_DRAFT_ORDER,
};
