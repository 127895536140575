import Vue from 'vue';
import Router from 'vue-router';
import flash from '@/components/ui/FlashMessage';
import VuePermission from '@/mixins/permissions';
import routes from '@/router/admin-routes';
import { routerErrorHandler } from '@/util/utils';

Vue.use(VuePermission);
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const appTitle = process.env.VUE_APP_ADMIN_TITLE;

  const isAuthenticated = !!localStorage.getItem('accessToken');

  document.title = (to.meta && to.meta.title) ? `${to.meta.title} - ${appTitle}` : appTitle;

  if (!isAuthenticated && to.matched.some(({ meta }) => meta.requireAuth)) {
    router
      .push({ path: 'login' })
      .catch(routerErrorHandler);

    return;
  }

  if (to.params.flash && flash[to.params.flash.type]) {
    flash[to.params.flash.type]({
      ...to.params.flash,
    });
  }

  if (isAuthenticated) {
    if (Object.prototype.hasOwnProperty.call(to.meta, 'requireAuth') && !to.meta.requireAuth) {
      router
        .push({ name: 'admin-home' })
        .catch(routerErrorHandler);
      return;
    }
  }
  if (VuePermission.routerCheck(to, from, next, router)) return;

  next();
});

export default router;
