// eslint-disable-next-line import/named
import { FILTER_AMOUNT } from '@/util/constants';
import { getThisMonthRange } from '@/util/utils-date';

import { getAccountOwnerIdForLoggedUser } from '@/util/utils';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const defaultRange = getThisMonthRange();
const defaultRangeName = 'This Month';

const state = {
  meta: {},
  filtersOutlets: {
    range: defaultRange,
    rangeName: defaultRangeName,
    product: null,
  },
  filtersProducts: {
    range: defaultRange,
    rangeName: defaultRangeName,
    venue: null,
    byType: FILTER_AMOUNT,
  },
  filtersActivity: {
    range: defaultRange,
    rangeName: defaultRangeName,
    accountOwnerId: null,
  },
  filtersToDos: {
    range: defaultRange,
    rangeName: defaultRangeName,
    accountOwnerId: getAccountOwnerIdForLoggedUser(),
    venue: null,
  },
  filtersCheckIn: {
    range: defaultRange,
    rangeName: defaultRangeName,
    userId: null,
    venue: null,
    types: null,
  },
  filtersExpense: {
    range: defaultRange,
    rangeName: defaultRangeName,
    userId: null,
    types: null,
  },
  metaTarget: {
    page: 1,
    lastPage: 1,
  },
  metaPending: {
    page: 1,
    lastPage: 1,
  },
  metaOpened: {
    page: 1,
    lastPage: 1,
  },
  metaNotOrdering: {
    page: 1,
    lastPage: 1,
  },
  metaOrdering: {
    page: 1,
    lastPage: 1,
  },
  metaOrders: {},
  metaProducts: {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
