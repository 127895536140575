import { Model } from '@vuex-orm/core';
import SpecialTier from '@/models/SpecialTier';

export default class SpecialTierItem extends Model {
  static entity = 'specialTierItems';

  static fields() {
    return {
      id: this.attr(null),
      specialTierId: this.attr(null),
      minQuantity: this.attr(null),
      value: this.attr(null),
      discountType: this.attr('price'),
      specialTier: this.belongsTo(SpecialTier, 'specialTierId'),
      marketPrice: this.attr(false),
    };
  }

  get price() {
    const tierItem = this.$query()
      .with('specialTier')
      .with('specialTier.product')
      .find(this.$id);

    const originalPrice = (tierItem.specialTier && tierItem.specialTier.product)
      ? tierItem.specialTier.product.price
      : 0;

    if (this.discountType === 'price') {
      return this.value;
    }

    return (1 - this.value / 100) * originalPrice;
  }
}
