import httpService from '@/api/http';
import router from '@/router';
import { LOADING_KEY } from '@/util/constants';
import { combineURLs } from '@/util/utils';

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a3ec2b13-a72f-499f-8afe-74bfbcd0b936}
 *
 * @param context
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteGetAuthorizationUrl(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_GET_AUTHORIZATION_URL } = {},
) {
  return httpService.get('/distributor/netsuite/authorization-url', {
    headers: { loadingKey },
  });
}

/**
 * @since 3.24.0
 *
 * @param dispatch
 * @param route
 * @param state
 */
async function distributorNetsuiteConnect({ dispatch }, { route, state }) {
  // Get URL from API
  const { data } = await dispatch('distributorNetsuiteGetAuthorizationUrl');

  // Resolve where to redirect the user (based on route name).
  const redirectRoute = router.resolve({ name: route });
  const redirectUrl = combineURLs(window.location.origin, redirectRoute.href);
  const params = [];

  // Add redirect URI
  params.push(`redirect_uri=${encodeURIComponent(redirectUrl)}`);

  // Add state if present
  if (state) params.push(`state=${encodeURIComponent(state)}`);

  // Redirect user to NetSuite with params
  window.location.href = `${data.data}&${params.join('&')}`;
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#df015ba4-66b9-4039-9c87-0b1c0ae2c335}
 *
 * @param context
 * @param params
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteAuthorize(
  context,
  { params, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_AUTHORIZE } = {},
) {
  return httpService.post(
    '/distributor/netsuite/authorize',
    { ...Object.fromEntries(new URLSearchParams(params)) },
    { headers: { loadingKey } },
  );
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b34e5d04-261e-4648-af77-625cdb576606}
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteDisconnect() {
  return httpService.delete('/distributor/netsuite');
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#71dcd1fa-84c0-441b-9cbd-5eb746006989}
 *
 * @param context
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchOutlets(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_OUTLETS } = {},
) {
  return httpService.get('/distributor/netsuite/venues', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6036f077-ed11-4bd9-ab34-15ca41928056}
 *
 * @param context
 * @param data
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */

function distributorNetsuiteUpdateOutlets(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_OUTLETS },
) {
  return httpService.put(
    '/distributor/netsuite/venues',
    { items: data },
    { headers: { loadingKey } },
  );
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#02dc853b-38e5-4805-943a-e746b4ab8653}
 *
 * @param context
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchWarehouses(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_WAREHOUSES } = {},
) {
  return httpService.get('/distributor/netsuite/warehouses', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6036f077-ed11-4bd9-ab34-15ca41928056}
 *
 * @param context
 * @param data
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteUpdateWarehouses(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_WAREHOUSES },
) {
  return httpService.put(
    '/distributor/netsuite/warehouses',
    { items: data },
    { headers: { loadingKey } },
  );
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f83eb1f7-ee93-4c84-9358-f8f0b7f57e53}
 *
 * @param context
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchConfig(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_CONFIG } = {},
) {
  return httpService.get('/distributor/netsuite', { headers: { loadingKey } });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f460d98a-6e4b-42ef-a9e2-2c392a6df3db}
 *
 * @param context
 * @param data
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteUpdateConfig(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_CONFIG } = {},
) {
  return httpService.put('/distributor/netsuite/config', data, { headers: { loadingKey } });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1609b762-08fd-47d4-9607-e058362ecd36}
 *
 * @param context
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchSubsidiaries(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_SUBSIDIARIES } = {},
) {
  return httpService.get('/distributor/netsuite/subsidiaries', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#32589a23-bbaf-4e28-a51a-3cc6f504a7cc}
 *
 * @param context
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchDeliveryItems(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_DELIVERY_ITEMS } = {},
) {
  return httpService.get('/distributor/netsuite/items', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1fdf33c1-5d18-4561-948f-c78aa98d467f}
 *
 * @param context
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchCustomForms(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_CUSTOM_FORMS } = {},
) {
  return httpService.get('/distributor/netsuite/custom-forms', {
    headers: { loadingKey },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9ede0781-e5d8-492f-9d85-b5cc5328eb53}
 *
 * @param context
 * @param query
 * @param loadignKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchPriceLevels(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_PRICE_LEVELS } = {},
) {
  return httpService.get('/distributor/netsuite/price-levels', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.24.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c3a7c6f6-7822-4d47-8088-9f2d7dd95c42}
 *
 * @param context
 * @param query
 * @param loadignKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorNetsuiteFetchEmployees(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_EMPLOYEES } = {},
) {
  return httpService.get('/distributor/netsuite/employees', {
    headers: { loadingKey },
    params: { ...query },
  });
}

export default {
  distributorNetsuiteGetAuthorizationUrl,
  distributorNetsuiteConnect,
  distributorNetsuiteAuthorize,
  distributorNetsuiteDisconnect,
  distributorNetsuiteFetchOutlets,
  distributorNetsuiteUpdateOutlets,
  distributorNetsuiteFetchWarehouses,
  distributorNetsuiteUpdateWarehouses,
  distributorNetsuiteFetchConfig,
  distributorNetsuiteUpdateConfig,
  distributorNetsuiteFetchSubsidiaries,
  distributorNetsuiteFetchDeliveryItems,
  distributorNetsuiteFetchCustomForms,
  distributorNetsuiteFetchPriceLevels,
  distributorNetsuiteFetchEmployees,
};
