const serializeState = (store) => {
  store.subscribe((mutation, state) => {
    const cartData = JSON.stringify(state.cart.info);
    const cartItems = JSON.stringify(state.cart.cart);
    const contextId = JSON.stringify(state.entities.users.contextId);
    const loggedUser = JSON.stringify(state.entities.users.loggedUser);
    const venueGroupDraft = JSON.stringify(state.entities.groups.draft);
    const distributorDraft = JSON.stringify(state.entities.distributors.draft);
    const productDraft = JSON.stringify(state.entities.products.draft);
    const eCommerceDraft = JSON.stringify(state.entities.distributors.eCommerceIntegration);
    const integrationVenueDraft = JSON.stringify(state.entities.venues.integrationVenueDraft);

    localStorage.setItem('contextId', contextId);
    localStorage.setItem('loggedUser', loggedUser);
    localStorage.setItem('cartData', cartData);
    localStorage.setItem('cartItems', cartItems);
    localStorage.setItem('venueGroupDraft', venueGroupDraft);
    localStorage.setItem('distributorDraft', distributorDraft);
    localStorage.setItem('productDraft', productDraft);
    localStorage.setItem('eCommerceDraft', eCommerceDraft);
    localStorage.setItem('integrationVenueDraft', integrationVenueDraft);
  });
};

export default [
  serializeState,
];
