import { Model } from '@vuex-orm/core';

/**
 * This will store connected Venues and Distributors.
 * It is made universal to be used by both venue and distributor.
 */
export default class ConnectedEntries extends Model {
  static entity = 'connectedEntries';

  static fields() {
    return {
      id: this.attr(null),
      logo: this.attr(''),
      name: this.attr(''),
      status: this.attr(''),
      registeredAt: this.attr(null),
      accountType: this.attr(''),
      editable: this.attr(false),
      accountOpenedAt: this.attr(null),
      provider: this.attr(null),
    };
  }
}
