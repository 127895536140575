/* eslint-disable no-underscore-dangle */
import {
  check,
  permMap,
  permKeys,
  remap,
  is,
  isVenue,
  isDistributor,
  adminPermKeys,
  adminPermMap,
} from '@/mixins/permissions/utils';
import install from './install';


export default class Permission {
  constructor(config) {
    this.config = config || {};
  }

  init() {
    this._pullPermissions();
  }

  getType() {
    if (isVenue(this.loggedUser.scope)) return this.loggedUser.group.accountType;
    if (isDistributor(this.loggedUser.scope)) return this.loggedUser.distributor.accountType;
    return 'admin';
  }

  _pullPermissions() {
    this.loggedUser = JSON.parse(localStorage.getItem('loggedUser')) || {};
    this._permissions = JSON.parse(localStorage.getItem('permissions'));
    this._scope = this.loggedUser.scope;
    this._type = this.getType();
    if (this._type === 'admin') this._mapAdminPermissions();
    else this._mapPermissions();
  }

  /**
   * Create a router rule used inside the `meta` field, later used for validation.
   * @param {string|array} permissions Name of the permission
   * @param {string} redirect Route *name* used for redirection if permission fails.
   * @returns {{permissionRedirect: *, permission: *}}
   */
  static routeRule(permissions, redirect) {
    return {
      permissions: Array.isArray(permissions) ? permissions : [permissions],
      permissionRedirect: redirect,
    };
  }

  /**
   * Router rule to validate permission.
   * Should be added to the `beforeEach` hook on the bottom.
   * If the user has permission to visit the route the router will let him.
   * If not he will redirect him to the provided redirect page.
   * @param {Object} to
   * @param {Object} from
   * @param {function} next
   * @param {Object} router
   * @returns {boolean}
   */
  static routerCheck(to, from, next, router) {
    // Get all permissions (from parent also).
    // debugger;
    const permissions = to.matched
      .map(({ meta }) => meta.permissions)
      .filter(Boolean)
      .flat();
    // Get all redirect (from parent also).
    const permissionRedirects = to.matched
      .map(({ meta }) => meta.permissionRedirect)
      .filter(Boolean); // Filter out falsy values
    // Check all permissions and check if there is a false value (not allowed).
    const permission = permissions
      .map(perm => router.app.$permission.has(perm))
      .includes(true);
    // Return a redirect name for the exact false value.
    const permissionRedirect = permissionRedirects[permissionRedirects.length - 1] || '';

    if (permissions && permissionRedirects) {
      if (permissions.length === 0 || permission) {
        next();
        return true;
      }
      next({ name: permissionRedirect, params: to.params, query: to.query });
      return true;
    }
    return false;
  }

  /**
   * Return an object of permissions.
   * @returns {{}}
   */
  get permissions() {
    return this.mappedPermissions;
  }

  get isGroup() {
    const groupRegex = /group/ig;
    return is(this._scope, 'string') ? groupRegex.test(this._scope) : false;
  }

  get isPremium() {
    // OrderEz Admin doesn't have permissions therefore it should always return true
    if (this.config.scope === 'admin') return true;
    this._pullPermissions();
    return this._type === 'premium';
  }

  get isLite() {
    if (this.config.scope === 'admin') return true;
    this._pullPermissions();
    return this._type === 'lite';
  }

  get isFree() {
    if (this.config.scope === 'admin') return false;
    this._pullPermissions();
    return this._type === 'free';
  }

  /**
   * Check if the current user has the requested permissions.
   *
   * @param {string|array} permissions Name of the permission
   * @return {boolean}
   */
  has(permissions) {
    permissions = Array.isArray(permissions) ? permissions : [permissions];
    if (permissions.includes('group')) return this.isGroup;
    /**
     * Pull the permissions on each check.
     * Maybe the store wasn't ready on the init.
     * Timing issue.
     */
    this._pullPermissions();

    let output = false;

    // Check if the permissions exist.
    permissions.forEach((permission) => {
      try {
        if (this._type === 'admin') {
          check(!adminPermKeys.includes(permission), `Provided permission '${permission}' doesn't exist`);
        } else {
          check(!permKeys.includes(permission), `Provided permission '${permission}' doesn't exist`);
        }
        output = output || this.mappedPermissions[permission];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
    return output;
  }

  /**
   * Check if a given user has specified permission(s).
   *
   * @param {object} user
   * @param {string|array<string>} permissions
   * @static
   */
  static checkUser(user, permissions) {
    permissions = Array.isArray(permissions) ? permissions : [permissions];
    const userPermissions = user.permissions;
    // User doesn't have any permissions
    if (!userPermissions) return false;
    let mappedPermissions;
    if (this._type === 'admin') {
      mappedPermissions = Permission._mapAdminUserPermissions(userPermissions);
    } else {
      mappedPermissions = Permission._mapUserPermissions(userPermissions);
    }
    let output = false;
    permissions.forEach((permission) => {
      try {
        if (this._type === 'admin') {
          check(!adminPermKeys.includes(permission), `User with id "${user.id}" doesn't have the requested permission "${permission}"`);
        } else {
          check(!permKeys.includes(permission), `User with id "${user.id}" doesn't have the requested permission "${permission}"`);
        }
        output = output || mappedPermissions[permission];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
    return output;
  }

  /**
   * Map API permissions to the enumerated values from `utils.js` for a given user.
   *
   * @param {array<string>} permissions
   * @returns {array<string>}
   * @static
   */
  // eslint-disable-next-line class-methods-use-this
  static _mapUserPermissions(permissions) {
    return {
      ...permKeys.reduce((o, key) => ({ ...o, [key]: false }), {}),
      ...remap(permissions, permMap),
    };
  }

  /**
   * Map API permissions to the enumerated values from `utils.js` for a given user.
   *
   * @param {array<string>} permissions
   * @returns {array<string>}
   * @static
   */
  // eslint-disable-next-line class-methods-use-this
  static _mapAdminUserPermissions(permissions) {
    return {
      ...adminPermKeys.reduce((o, key) => ({ ...o, [key]: false }), {}),
      ...permKeys.reduce((o, key) => ({ ...o, [key]: true }), {}),
      ...remap(permissions, adminPermMap),
    };
  }

  /**
   * Map API permissions to the enumerated values from `utils.js` for this user.
   * @private
   */

  _mapPermissions() {
    this.mappedPermissions = {
      ...Permission._mapUserPermissions(this._permissions),
      // Account types
      premium: this._type === 'premium',
      lite: this._type === 'lite',
      free: this._type === 'free',
    };
  }

  /**
   * Map API permissions to the enumerated values from `utils.js` for this user.
   * @private
   */

  _mapAdminPermissions() {
    this.mappedPermissions = {
      ...Permission._mapAdminUserPermissions(this._permissions),
      // Account types
      premium: this._type === 'premium',
      lite: this._type === 'lite',
      free: this._type === 'free',
    };
  }
}

Permission.install = install;
