<template>
  <div class="ez-button-group">
    <slot/>
  </div>
</template>

<script>
/**
 * Stateless component that is only used to space out a group of buttons.
 * @version 1.0.0
 * @since 2.0.0
 */
export default {};
</script>

<style lang="scss" scoped>
$spacing: px-to-rem(16px);

.ez-button-group {
  @extend %flex-center;
  > * + * { margin-left: $spacing}

  &__line {
    display: inline-block;
    width: 1px;
    height: 24px;
    background-color: #E9EBF2;
  }
}
</style>
