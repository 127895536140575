import VuePermission from '@/mixins/permissions';
import xeroRoutes from '@/router/distributor-xero-routes';
import quickBooksRoutes from '@/router/distributor-quickbooks-routes';
import provinoRoutes from '@/router/distributor-provino-routes';
import hubDocRoutes from '@/router/distributor-hub-doc-routes';
import ECommerceRoutes from '@/router/distributor-e-commerce-routes';
import NetSuiteRoutes from '@/router/distributor-netsuite-routes';
import { AccountDrawerType } from '@/util/constants';
import i18n from '@/i18n';

/* ------------------------ User Settings Components ------------------------ */

const UserSettings = () =>
  import(/* webpackChunkName: "distributor" */ '@/views/platform/user-settings/EzUserSettings.vue');

/* --------------------- Distributor Settings Components -------------------- */

const DistributorSettings = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/DistributorSettings.vue'
  );
const DistributorSettingsInfo = () =>
  import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/Info.vue');
const DistributorSettingsUsers = () =>
  import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/Users.vue');
const DistributorSettingsIntegrations = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/Integrations.vue'
  );
const DistributorSettingsInventory = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/IventorySettings.vue'
  );
const DistributorSettingsWarehouse = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/warehouses/index.vue'
  );
const DistributorSettingsOnboarding = () =>
  import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/Onboarding');
const DistributorSettingsPaymentInformation = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/settings/PaymentInformation'
  );

export default [
  ...xeroRoutes,
  ...quickBooksRoutes,
  ...provinoRoutes,
  ...hubDocRoutes,
  ...ECommerceRoutes,
  ...NetSuiteRoutes,
  // User Settings
  {
    path: '/supplier/user-settings',
    alias: '/distributor/user-settings',
    name: 'distributor-user-settings',
    component: UserSettings,
    meta: {
      title: 'User Settings',
    },
  },
  // Distributor Settings
  {
    path: '/supplier/settings',
    component: DistributorSettings,
    children: [
      {
        path: '/',
        name: 'distributor-settings',
        component: DistributorSettingsInfo,
        meta: {
          title: `${i18n.t('global.distributor')} Info`,
        },
      },
      {
        path: 'users',
        name: 'distributor-settings-users',
        component: DistributorSettingsUsers,
        meta: {
          title: `${i18n.t('global.distributor')} Users`,
        },
      },
      {
        path: 'integrations',
        name: 'distributor-integrations',
        component: DistributorSettingsIntegrations,
        meta: {
          title: `${i18n.t('global.distributor')} Integrations`,
        },
      },
      {
        path: 'inventory',
        name: 'distributor-settings-inventory',
        component: DistributorSettingsInventory,
        meta: {
          title: `${i18n.t('global.distributor')} Inventory Settings`,
        },
      },
      {
        path: 'warehouse',
        name: 'distributor-warehouse',
        component: DistributorSettingsWarehouse,
        meta: {
          title: `${i18n.t('global.distributor')} Warehouses`,
        },
      },
      {
        path: 'onboarding',
        name: 'distributor-venue-onboarding',
        component: DistributorSettingsOnboarding,
        meta: {
          title: `${i18n.t('global.distributor')} Onboarding`,
        },
      },
      {
        path: 'payment-information',
        name: 'distributor-payment-information',
        component: DistributorSettingsPaymentInformation,
        meta: {
          title: `${i18n.t('global.distributor')} Payment Information`,
        },
      },
    ],
  },
  {
    name: 'distributor-new-product',
    path: '/supplier/products/new',
    alias: '/distributor/products/new',
    props: ({ params }) => ({ distributorId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/common/products/new-product/NewProduct.vue'
      ),
    meta: {
      title: 'New Product',
    },
  },
  {
    name: 'distributor-add-inventory',
    path: '/supplier/products/add',
    alias: '/distributor/products/add',
    props: ({ params }) => ({ distributorId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/addition/index.vue'
      ),
    meta: {
      title: 'Inventory Addition',
    },
  },
  {
    name: 'distributor-transfer-inventory',
    path: '/supplier/products/transfer',
    alias: '/distributor/products/transfer',
    props: ({ params }) => ({
      distributorId: Number(params.id),
      fromWarehouseId: Number(params.fromWarehouseId),
      toWarehouseId: Number(params.toWarehouseId),
      returnPage: params.returnPage,
    }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/transfer/index.vue'
      ),
    meta: {
      title: 'Inventory Transfer',
    },
  },
  {
    name: 'distributor-remove-inventory',
    path: '/supplier/products/remove',
    alias: '/distributor/products/remove',
    props: ({ params }) => ({ distributorId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/removal/index.vue'
      ),
    meta: {
      title: 'Inventory Removal',
    },
  },
  {
    path: '/supplier/crm/team-activity/new',
    name: 'account-check-in-new',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/new-check-in/Index.vue'
      ),
    props: true,
    meta: {
      title: 'New Team Activity',
    },
  },
  {
    path: '/supplier/crm/expense-tracking/new',
    name: 'account-expense-new',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/new-expense/Index.vue'
      ),
    props: true,
    meta: {
      title: 'New Expense Tracking',
    },
  },
  {
    path: '/supplier/products/:id(\\d+)',
    alias: '/distributor/products/:id(\\d+)',
    props: ({ params }) => ({ productId: Number(params.id) }),
    component: () =>
      import(/* webpackChunkName: "distributor" */ '@/views/common/products/Show.vue'),
    children: [
      {
        path: '',
        name: 'distributor-product-single',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "distributor" */ '@/views/common/products/ProductInfo.vue'),
        meta: {
          title: 'Product Info',
        },
      },
      {
        path: 'pricing',
        name: 'distributor-product-single-tier',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "distributor" */ '@/views/common/products/TierPricing.vue'),
        meta: {
          title: 'Product Pricing',
        },
      },
      {
        path: 'availability',
        name: 'distributor-product-availability',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "distributor" */ '@/views/common/products/Availability.vue'),
        meta: {
          title: 'Product Availability',
        },
      },
      {
        path: 'inventory-history',
        name: 'distributor-product-inventory-history',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/common/products/InventoryHistory.vue'
          ),
        meta: {
          title: 'Product Inventory History',
        },
      },
      {
        path: 'inventory-overview',
        name: 'distributor-product-inventory-overview',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/common/products/InventoryOverview.vue'
          ),
        meta: {
          title: 'Product Inventory',
        },
      },
      {
        path: 'par-level',
        name: 'distributor-product-par-level',
        props: ({ params }) => ({ productId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/common/products/InventoryParLevel.vue'
          ),
        meta: {
          title: 'Product Inventory Par Level',
        },
      },
    ],
  },
  {
    path: '/supplier/products/stocktakes/:id(\\d+)',
    name: 'distributor-single-stocktake',
    props: ({ params }) => ({ stocktakeId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/Stocktake.vue'
      ),
    meta: {
      title: 'Stocktake',
    },
  },
  {
    path: '/supplier/products/conduct-stocktake',
    name: 'distributor-conduct-stocktake',
    props: ({ params }) => ({ distributorId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/common/products/conduct-stocktake/index.vue'
      ),
    meta: {
      title: 'Conduct Stocktake',
    },
  },
  {
    path: '/supplier/outlets/new',
    name: 'distributor-outlets-new',
    component: () =>
      import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/New.vue'),
    props: true,
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: `New ${i18n.t('global.venue')}`,
    },
  },
  {
    path: '/supplier/outlets/connect',
    name: 'distributor-outlets-connect',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/connect-to-outlet/Index'
      ),
    props: true,
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: `Connect to ${i18n.t('global.venue')}`,
    },
  },
  {
    path: '/supplier/outlets/send-onboarding-link',
    name: 'distributor-outlets-send-onboarding-link',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/send-onboarding-link/SendOnboardingLink'
      ),
    props: true,
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: `New ${i18n.t('global.venue')}`,
    },
  },
  {
    path: '/supplier/outlets/accept-and-send-onboarding-link',
    name: 'distributor-outlets-accept-send-onboarding-link',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/accept-send-onboarding-link/Index'
      ),
    props: true,
    meta: { ...VuePermission.routeRule('premium', 'distributor-home') },
  },
  {
    path: '/supplier/outlets/:id(\\d+)',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/VenueSingle.vue'
      ),
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: `${i18n.t('global.venue')}`,
    },
    children: [
      {
        path: '',
        name: 'distributor-outlets-single',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/VenueInfo.vue'
          ),
        meta: {
          title: `${i18n.t('global.venue')} Info`,
        },
      },
      {
        path: 'sales-settings',
        name: 'distributor-outlets-sales-settings',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/SalesSettings.vue'
          ),
        meta: {
          title: `${i18n.t('global.venue')} Settings`,
        },
      },
      {
        path: 'inventory-settings',
        name: 'distributor-outlets-inventory-settings',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/InventorySettings.vue'
          ),
        meta: {
          title: `${i18n.t('global.venue')} Inventory Settings`,
        },
      },
      {
        path: 'users',
        name: 'distributor-outlets-single-users',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/VenueUsers.vue'
          ),
        meta: {
          title: `${i18n.t('global.venue')} Users`,
        },
      },
      {
        path: 'products',
        name: 'distributor-outlets-single-products',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/VenueProducts'
          ),
        meta: {
          title: `${i18n.t('global.venue')} Products`,
        },
      },
    ],
  },
  {
    path: '/supplier/orders/new',
    name: 'distributor-orders-new',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/new/Index.vue'
      ),
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: 'New Order',
    },
  },
  {
    path: '/supplier/credit-notes/new',
    name: 'distributor-credit-notes-new',
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/credit-notes/new/Index.vue'
      ),
    meta: {
      ...VuePermission.routeRule('premium', 'distributor-home'),
      title: 'New Credit Note',
    },
  },
  {
    path: '/supplier/outlets/proposal/:id(\\d+)',
    name: 'distributor-outlets-requests-single',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/SingleRequest.vue'
      ),
    meta: { ...VuePermission.routeRule('premium', 'distributor-home') },
  },
  {
    path: '/supplier',
    alias: '/distributor',
    redirect: { name: 'distributor-home' },
    meta: {
      role: 'distributor',
      requireAuth: true,
      title: 'Home',
    },
    component: () =>
      import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/Home.vue'),
    children: [
      {
        path: '',
        name: 'distributor-home',
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/OrdersHome.vue'
          ),
        redirect: { name: 'distributor-orders-all' },
        children: [
          {
            path: 'orders/:id(\\d+)?',
            name: 'distributor-orders-all',
            props: ({ params }) => ({ orderId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/Orders.vue'
              ),
            meta: {
              title: 'Orders',
            },
          },
          {
            path: 'credit-notes/:id(\\d+)?',
            name: 'distributor-credit-notes',
            props: ({ params }) => ({ creditNoteId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/credit-notes/CreditNotes.vue'
              ),
            meta: {
              title: 'Credit Notes',
            },
          },
        ],
      },
      {
        path: 'products',
        name: 'distributor-products',
        props: ({ params }) => ({ orderId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/Products.vue'
          ),
        redirect: { name: 'distributor-products-all' },
        children: [
          {
            path: 'all',
            name: 'distributor-products-all',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/AllProducts.vue'
              ),
            meta: {
              title: 'Products',
            },
          },
          {
            path: 'stocktakes',
            name: 'distributor-products-stocktakes',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/Stocktakes.vue'
              ),
            meta: {
              title: 'Stocktakes',
              ...VuePermission.routeRule('conductStocktake', 'distributor-products'),
            },
          },
          {
            path: 'proposal/:id(\\d+)?',
            name: 'distributor-products-proposal',
            props: ({ params }) => ({ orderId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/ProposalsProducts.vue'
              ),
            meta: {
              title: 'Products',
            },
          },
          {
            path: 'trade-gecko',
            name: 'distributor-trade-gecko',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/products/Products.vue'
              ),
          },
        ],
        meta: {
          title: 'Products',
        },
      },
      {
        path: 'legal',
        name: 'distributor-legal',
        component: () =>
          import(/* webpackChunkName: "distributor" */ '@/views/platform/footer/Index.vue'),
        redirect: { name: 'distributor-terms-and-conditions' },
        meta: {
          title: 'Legal',
        },
        children: [
          {
            path: 'terms-and-conditions',
            name: 'distributor-terms-and-conditions',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/footer/TermsAndConditions.vue'
              ),
            meta: {
              title: 'Terms & Conditions',
            },
          },
          {
            path: 'privacy-policy',
            name: 'distributor-privacy-policy',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/footer/PrivacyPolicy.vue'
              ),
            meta: {
              title: 'Privacy Policy',
            },
          },
        ],
      },
      {
        path: 'outlets',
        name: 'distributor-outlets',
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/Index.vue'
          ),
        redirect: { name: 'distributor-outlets-all' },
        meta: {
          title: i18n.t('global.venues'),
          ...VuePermission.routeRule('premium', 'distributor-home'),
        },
        children: [
          {
            path: 'all',
            name: 'distributor-outlets-all',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/Venues.vue'
              ),
            meta: {
              title: i18n.t('global.venues'),
            },
          },
          {
            path: 'requests',
            name: 'distributor-outlets-requests',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/Requests.vue'
              ),
            meta: {
              title: 'Requests',
            },
          },
        ],
      },
      {
        name: 'distributor-reports',
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/reports/Index.vue'
          ),
        redirect: { name: 'distributor-reports-venues' },
        meta: {
          title: 'Reports',
          ...VuePermission.routeRule(['premium', 'seeReports'], 'distributor-home'),
        },
        children: [
          {
            path: 'outlets',
            name: 'distributor-reports-venues',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/reports/Venues.vue'
              ),
            meta: {
              title: 'Reports',
            },
          },
          {
            path: 'products',
            name: 'distributor-reports-products',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/reports/Products.vue'
              ),
            meta: {
              title: 'Reports',
            },
          },
        ],
      },
      {
        name: 'distributor-crm',
        path: 'crm',
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/Index.vue'
          ),
        redirect: { name: 'distributor-account-activity' },
        meta: {
          title: 'CRM',
          ...VuePermission.routeRule(['premium', 'seeCRM'], 'distributor-home'),
        },
        children: [
          {
            path: 'account-activity',
            name: 'distributor-account-activity',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: `account-activity/${AccountDrawerType.Target}/:id(\\d+)?`,
            name: `distributor-account-activity-${AccountDrawerType.Target}`,
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: `account-activity/${AccountDrawerType.Pending}/:id(\\d+)?`,
            name: `distributor-account-activity-${AccountDrawerType.Pending}`,
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: `account-activity/${AccountDrawerType.Opened}/:id(\\d+)?`,
            name: `distributor-account-activity-${AccountDrawerType.Opened}`,
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: `account-activity/${AccountDrawerType.NotOrdering}/:id(\\d+)?`,
            name: `distributor-account-activity-${AccountDrawerType.NotOrdering}`,
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: `account-activity/${AccountDrawerType.Ordering}/:id(\\d+)?`,
            name: `distributor-account-activity-${AccountDrawerType.Ordering}`,
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/AccountActivity.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'to-dos',
            name: 'distributor-to-dos',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/to-dos/To-dos'
              ),
            meta: {
              title: 'CRM',
              ...VuePermission.routeRule('createTodos', 'distributor-home'),
            },
          },
          {
            path: 'to-dos/single/:id(\\d+)?',
            name: 'distributor-to-dos-single',
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/to-dos/To-dos'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'team-activity',
            name: 'distributor-account-check-in',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/AccountCheckIn.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'team-activity/single/:id(\\d+)?',
            name: 'distributor-account-check-in-single',
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/AccountCheckIn.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'team-activity/single-todo/:id(\\d+)?',
            name: 'distributor-account-check-in-single-todo',
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/AccountCheckIn.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'expense-tracking',
            name: 'distributor-expense-tracking',
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/ExpenseTracking.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
          {
            path: 'expense-tracking/single/:id(\\d+)?',
            name: 'distributor-expense-tracking-single',
            props: ({ params }) => ({ singleId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/ExpenseTracking.vue'
              ),
            meta: {
              title: 'CRM',
            },
          },
        ],
      },
      {
        path: '*',
        component: () =>
          import(/* webpackChunkName: "pagenotfound" */ '@/views/common/PageNotFound.vue'),
        meta: {
          title: 'Page not found',
        },
      },
    ],
  },
];
