<template>
  <EzModal ref="modal" @close="onClose()">
    <div :class="`modal__inner ${innerClasses}`">
      <button class="modal__close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <!-- eslint-disable-next-line max-len -->
          <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
        </svg>
      </button>
      <div class="modal__title">
        <div v-if="modalIcon !== null" :class="`modal__icon modal__icon--${type}`">
          <component :is="modalIcon" />
        </div>
        <h2>
          <slot name="title"></slot>
        </h2>
      </div>
      <div class="modal__text">
        <slot name="content"></slot>
      </div>
      <div class="modal__footer">
        <slot name="footer">
          <EzButton type="link">Cancel</EzButton>
          <EzButton type="red">Delete</EzButton>
        </slot>
      </div>
    </div>
  </EzModal>
</template>

<script>
import { EzModal } from '.';
import EzButton from '../Button/EzButton.vue';
import Transfer from './Icons/Transfer.vue';
import Question from './Icons/Question.vue';
import Plus from './Icons/Plus.vue';
import Minus from './Icons/Minus.vue';
import Exclamation from './Icons/Exclamation.vue';

export default {
  props: {
    type: {
      type: String,
      default: 'blue',
    },
    innerClasses: {
      type: String,
    },
    icon: {
      type: String,
      default: 'exclamation',
    },
  },
  components: {
    EzButton,
    EzModal,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    modalIcon() {
      switch (this.icon) {
        case 'transfer': return Transfer;
        case 'question': return Question;
        case 'plus': return Plus;
        case 'minus': return Minus;
        case null: return null;
        default:
          break;
      }
      return Exclamation;
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
      this.$emit('close');
    },
    open() {
      this.$refs.modal.open();
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal {
    &__inner {
      position: relative;
      padding: px-to-rem(24px);
    }

    &__close {
      @extend %button-reset;
      position: absolute;
      top: 25px;
      right: px-to-rem(24px);
      @include font-size(18px);
      cursor: pointer;

      svg {
        fill: $secondary-color;
      }
    }

    &__icon,
    &__title {
      @extend %flex-center;
    }

    &__title {
      margin-bottom: 1.5em;

      h2 {
        margin: 0;
        @include font-size(20px);
        font-weight: 500;
      }
    }

    &__text {
      line-height: 20px;
      @include font-size(14px);
    }

    &__icon {
      margin-right: px-to-rem(12px);
      @include font-size(16px);
      color: $secondary-color;

      &--blue {
        color: $color-primary-blue;
      }

      &--red {
        color: $color-primary-dark-red;
      }
    }

    &__footer {
      @extend %flex-center;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }
</style>
<style lang="scss">
  .modal {
    .button-primary,
    .button-red {
      width: 100%;
      max-width: 13.3em;
    }

    .button-link {
      margin-right: 1em;
    }
  }
</style>
