import i18n from '@/i18n';

export const PRODUCT_STEP_1 = 0;
export const PRODUCT_STEP_2 = 1;
export const PRODUCT_STEP_3 = 2;
export const PRODUCT_STEP_4 = 3;

const steps = {
  [PRODUCT_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/new-product/Info.vue'),
    title: 'Add Product - Info',
    pageTitle: 'Please add product info',
    pageInfo: 'Add the necessary info such as product name and its price.<br/>SKU number, category and description are optional.',
    nextCta: 'Next Step',
  },
  [PRODUCT_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/new-product/Tiers.vue'),
    title: 'Add Product - Tier pricing',
    pageTitle: 'Now set tier pricing',
    pageInfo: `You can set general tier pricing based on quantity and set special tier pricing for certain ${i18n.t('global.venues').toLowerCase()}. <b>This step is optional.</b>`,
    nextCta: 'Next Step',
  },
  [PRODUCT_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/new-product/Warehouses.vue'),
    title: 'Add Product - Par Level',
    pageTitle: 'Select Par Level by Warehouse',
    pageInfo: 'When stock drops below specified par levels, select users will receive notifications and see warning indicators.',
    nextCta: 'Go To Overview',
  },
  [PRODUCT_STEP_4]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/new-product/Overview.vue'),
    title: 'Add Product - Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you complete adding new product, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
