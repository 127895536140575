import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let draft = null;
let eCommerceDraft = {};

try {
  draft = JSON.parse(localStorage.getItem('distributorDraft') || '');
  eCommerceDraft = JSON.parse(localStorage.getItem('eCommerceDraft') || '');
} catch (e) {
  // continue
}

const state = {
  distributors: [],
  draft: draft || {
    id: null,
    name: null,
    address: {},
    primaryContactName: null,
    primaryContactPhone: null,
    currentStep: 0,
  },
  draftSupplier: { id: -1 },
  draftSupplierAdmin: { id: -1 },
  me: {},
  selectedSupplier: {},
  distributorCount: 0,
  isOnboardingRequest: false,
  checkInInfo: {
    venue: {},
    datetime: null,
    note: null,
    moneySpent: 0,
    taxRate: null,
    receipts: [],
    isVenueAssigned: true,
    type: {
      id: 0,
      name: 'Select Activity Type',
      value: null,
    },
    todoId: null,
    disableVenueChange: false,
  },
  expense: {
    type: {
      id: 0,
      name: 'Select Expense Type',
      value: null,
    },
    datetime: null,
    note: null,
    moneySpent: null,
    taxRate: null,
    receipts: [],
    todoId: null,
  },
  eCommerceIntegration: eCommerceDraft || {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
