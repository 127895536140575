import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * Return the authorization URL for QuickBooks login.
 * Note that before the user is redirected to this URL a redirect query param should be added.
 * So, QuickBooks knows where to return the user.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#251d3835-f32a-4e71-9895-f882923d6aaa}
 * @return {Promise<AxiosResponse<any>>}
 */
function getAuthorizationUrl({ venueId } = {}) {
  return httpService.get(`/venues/${venueId}/quick-books/authorize-url`);
}

/**
 * Return the authorization URL for QuickBooks login.
 * Note that before the user is redirected to this URL a redirect query param should be added.
 * So, QuickBooks knows where to return the user.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function getAuthorizationUrlDistributor() {
  return httpService.get(`/distributor/quick-books/authorize-url`);
}

/**
 * Authorize venue based on the URL parameter recieved from QuickBooks.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ebcd1a21-1030-414f-814f-5de3ac68f454}
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAuthorize(id, params) {
  const url = `/venues/${id}/quick-books/authorize${params}`;
  return httpService.post(url);
}

/**
 * Authorize distributor based on the URL parameter recieved from QuickBooks.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorAuthorize(params) {
  const url = `/distributor/quick-books/authorize${params}`;
  return httpService.post(url);
}

/**
 * Get QuickBooks Info.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#43d6b1ae-e168-4541-9569-0be0d631b16a}
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetInfo(id) {
  return httpService.get(`/venues/${id}/quick-books`);
}

/**
 * Get QuickBooks Info.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetInfo() {
  return httpService.get(`/distributor/quick-books`);
}

/**
 * Update QuickBooks account settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#22ad1e23-c2e0-41f6-a670-7b71fbfc781d}
 *
 * @param {string|number} id - Venue ID
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueQuickBooksSetupAccount(id, data, formKey) {
  return httpService.put(`/venues/${id}/quick-books/config`, data, {
    headers: { formKey },
  });
}

/**
 * Update QuickBooks account settings.
 *
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorQuickBooksSetupAccount(data, formKey) {
  return httpService.put(`/distributor/quick-books/config`, data, {
    headers: { formKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2a367bb5-adc1-4b17-9b79-08b0e97ba163 }
 *
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetQuickBooksAccounts(id, query = {}) {
  return httpService.get(`/venues/${id}/quick-books/accounts`, {
    params: { ...query },
  });
}

/**
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetQuickBooksAccounts(query = {}) {
  return httpService.get(`/distributor/quick-books/accounts`, {
    params: { ...query },
  });
}

/**
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetQuickBooksClasses(query = {}) {
  return httpService.get(`/distributor/quick-books/classes`, {
    params: { ...query },
  });
}

/**
 * Return taxes for venue QuickBooks settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fca57ee6-7aaf-44ca-b11d-a1f2568880c0}
 *
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetQuickBooksTaxesSetup(id, query = {}) {
  return httpService.get(`/venues/${id}/quick-books/taxes`, {
    params: { ...query },
  });
}

/**
 * Return taxes for venue QuickBooks settings.
 *
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetQuickBooksTaxesSetup(query = {}) {
  return httpService.get(`/distributor/quick-books/taxes`, {
    params: { ...query },
  });
}

/**
 * Return taxes for venue QuickBooks settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e0b5e510-e5cb-49fd-ad02-f47c935c45b3}
 *
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetQuickBooksTrackingCategories(id, query = {}) {
  return httpService.get(`/venues/${id}/quick-books/tracking-categories`, {
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#49842316-b75e-4fce-a2cd-bf1b1222b2af}
 * @param {string|number} id - Venue ID
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {boolean} operative
 * @param {string} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetDistributors({
  id,
  limit,
  nextId,
  nextValue,
  term,
  operative,
  loadingKey = LOADING_KEY.VENUE_GET_QUICK_BOOKS_SUPPLIERS,
}) {
  return httpService.get(`/venues/${id}/quick-books/distributors`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
      ...(operative ? { operative: true } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {boolean} operative
 * @param {string} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetVenues({
  limit,
  nextId,
  nextValue,
  term,
  operative,
  loadingKey = LOADING_KEY.VENUE_GET_QUICK_BOOKS_SUPPLIERS,
}) {
  return httpService.get(`/distributor/quick-books/venues`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
      ...(operative ? { operative: true } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets outlet products.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4508deb4-deac-4897-9822-efa17f187bf0}
 *
 * @param {Number|String} id
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {boolean} operative
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetProducts({
  id,
  limit = 10,
  nextId,
  nextValue,
  term,
  operative,
  loadingKey = LOADING_KEY.VENUE_GET_QUICK_BOOKS_PRODUCTS,
}) {
  return httpService.get(`/venues/${id}/quick-books/products`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
      ...(operative ? { operative: true } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates venue products based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#344fdee1-740c-4109-9093-46f8ba6b7cb4}
 *
 * @param {Number|String} id
 * @param {items: Array, config: Object} body
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateQuickBooksProducts({
  id,
  body,
  term,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCTS,
}) {
  return httpService.put(`/venues/${id}/quick-books/products`, body, {
    params: {
      ...(term ? { term } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets outlets product groups.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a5d07555-79ab-4b50-a3da-6f66d4353702}
 *
 * @param {Number|String} id
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetProductGroups({
  id,
  limit = 10,
  nextId,
  nextValue,
  term,
  loadingKey = LOADING_KEY.VENUE_GET_PRODUCT_GROUPS,
}) {
  return httpService.get(`/venues/${id}/quick-books/product-groups`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets outlets product groups.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8b1f8bbf-3b2e-40a7-8af3-440b27579ee4}
 *
 * @param {Number|String} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetTaxRateConfig({ id, loadingKey = LOADING_KEY.VENUE_GET_PRODUCT_GROUPS }) {
  return httpService.get(`/venues/${id}/quick-books/tax-rate-config`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates product groups based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#64de5c4e-5c85-404f-927a-9c375e404168}
 *
 * @param {taxRateConfig: Array} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateTaxRateConfig({
  id,
  body,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCT_GROUPS,
}) {
  return httpService.put(`/venues/${id}/quick-books/tax-rate-config`, body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates product groups based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d9320a35-af69-441b-a6ec-0f994208833a}
 *
 * @param {items: Array, config: Object, state: String} body
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateQuickBooksProductGroups({
  id,
  body,
  term,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCT_GROUPS,
}) {
  return httpService.put(`/venues/${id}/quick-books/product-groups`, body, {
    params: {
      ...(term ? { term } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3716cb45-8659-4dc2-94ee-61e1916af8a1}
 * @param {string|number} id - Venue ID
 * @param {string} term
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetContacts(id, { term } = {}) {
  return httpService.get(`/venues/${id}/quick-books/external-distributors`, {
    params: {
      term,
    },
  });
}

/**
 * @param {string} term
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetContacts({ term } = {}) {
  return httpService.get(`/distributor/quick-books/external-venues`, {
    params: {
      term,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3ed520c4-8bbe-4991-aa8c-5b35ec9b5a4e}
 * @param {string|number} id - Venue ID
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueQuickBooksAddVendor(id, data) {
  return httpService.put(`/venues/${id}/quick-books/distributors`, data);
}

/**
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorQuickBooksAddVenue(data) {
  return httpService.put(`/distributor/quick-books/venues`, data);
}

/**
 * Update suppliers
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#968bed15-257c-469b-af9c-9a264cc7218a}
 *
 * @param {Number|String} id
 * @param {Object} data
 * @param {String} term
 * @param {String} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueQuickBooksConnections(
  id,
  data,
  term,
  operative,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_SUPPLIERS,
) {
  return httpService.put(`/venues/${id}/quick-books/distributors`, data, {
    params: {
      ...(term ? { term } : {}),
      ...(operative ? { operative: true } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Update customers
 *
 *
 * @param {Object} data
 * @param {String} term
 * @param {String} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorQuickBooksConnections(
  data,
  term,
  operative,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_SUPPLIERS,
) {
  return httpService.put(`/distributor/quick-books/venues`, data, {
    params: {
      ...(term ? { term } : {}),
      ...(operative ? { operative: true } : {}),
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Disconnect the venue from QuickBooks.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c0487e52-41df-4c84-a599-90a3e1bf57b1}
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDisconnectQuickBooks(id) {
  return httpService.delete(`/venues/${id}/quick-books`);
}

/**
 * Disconnect the distributor from QuickBooks.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDisconnectQuickBooks() {
  return httpService.delete(`/distributor/quick-books`);
}

export {
  getAuthorizationUrl,
  getAuthorizationUrlDistributor,
  venueAuthorize,
  distributorAuthorize,
  venueGetInfo,
  distributorGetInfo,
  venueQuickBooksSetupAccount,
  distributorQuickBooksSetupAccount,
  venueGetQuickBooksAccounts,
  distributorGetQuickBooksAccounts,
  distributorGetQuickBooksClasses,
  venueGetQuickBooksTaxesSetup,
  distributorGetQuickBooksTaxesSetup,
  venueGetQuickBooksTrackingCategories,
  venueGetDistributors,
  distributorGetVenues,
  venueGetProducts,
  venueUpdateQuickBooksProducts,
  venueGetProductGroups,
  venueUpdateQuickBooksProductGroups,
  venueGetContacts,
  distributorGetContacts,
  venueQuickBooksAddVendor,
  distributorQuickBooksAddVenue,
  venueQuickBooksConnections,
  distributorQuickBooksConnections,
  venueDisconnectQuickBooks,
  distributorDisconnectQuickBooks,
  venueGetTaxRateConfig,
  venueUpdateTaxRateConfig,
};
