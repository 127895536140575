import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';
import {
  deleteDefaultTierPricing,
  deleteSpecialTierPricing,
  fetchProduct,
  fetchProducts,
} from './commonActions';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#99ad2e81-779a-421a-9183-ea79d6aa307f}
 *
 * @param context
 * @param {object} query
 * @param {string} query.term
 * @param {number} query.limit
 * @param {string} query.sortBy
 * @param {number} query.nextId
 * @param {string} query.nextValue
 * @param {object} query.filters
 * @param {string} query.loadingKey
 * @param {boolean} query.withWarehouseInventory
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchDistributorProducts(
  context,
  {
    term = undefined,
    limit = 25,
    sortBy = undefined,
    nextId = undefined,
    nextValue = undefined,
    filters = {},
    loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS,
    withWarehouseInventory = false,
  } = {},
) {
  return httpService.get('/distributor/products', {
    headers: { loadingKey },
    params: {
      term,
      limit,
      sortBy,
      nextId,
      nextValue,
      ...filters,
      ...(withWarehouseInventory ? { withWarehouseInventory } : {}),
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9430164b-918c-4d77-a4bd-da8f72d57e68}
 *
 * @param context
 * @param {number} id
 * @param {number} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProduct(context, { id, loadingKey = LOADING_KEY.FETCH_PRODUCT }) {
  return httpService.get(`/distributor/products/${id}`, { headers: { loadingKey } });
}

const fetchDistributorProduct = (
  context,
  { id, distributorId, loadingKey = LOADING_KEY.FETCH_PRODUCT, ...payload },
) => {
  const isAdmin = localStorage.getItem('role') === 'admin';
  return fetchProduct(context, {
    payload,
    loadingKey,
    route: isAdmin ? `/admin/distributors/${id}/products/${id}` : `/distributor/products/${id}`,
  });
};

const removeDistributorProduct = (
  context,
  { id, loadingKey = LOADING_KEY.REMOVE_DISTRIBUTOR_PRODUCT },
) =>
  httpService.delete(`/distributor/products/${id}`, {
    headers: {
      loadingKey,
    },
  });

const removeDistributorProductImage = ({ commit }, { id }) =>
  httpService.delete(`/distributor/products/${id}/image`).then(() => {
    commit('REMOVE_PRODUCT_IMAGE', { id });
  });

// eslint-disable-next-line
const uploadDistributorCSVAdmin = ({ commit }, { id, data }) =>
  httpService.post(`/admin/distributors/${id}/import/products`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
// eslint-disable-next-line
const uploadDistributorCSV = ({ commit }, { data }) =>
  httpService.post(`/distributor/import/products`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const addTradeGeckoCode = (context, { code, redirectUrl }) =>
  httpService.post(`/distributor/trade-gecko/authorize?code=${code}&redirectUrl=${redirectUrl}`);

const removeTradeGeckoCode = () => httpService.delete('/distributor/trade-gecko');

const syncProducts = () => httpService.get('/distributor/trade-gecko/products');

const deleteDistributorDefaultTierPricing = (
  context,
  id,
  loadingKey = LOADING_KEY.DELETE_DISTRIBUTOR_DEFAULT_TIER_PRICING,
) => deleteDefaultTierPricing(context, `/distributor/products/${id}`, loadingKey);

const deleteDistributorSpecialTierPricing = (
  context,
  {
    productId,
    specialTierId,
    specialTierPricings,
    loadingKey = LOADING_KEY.DELETE_DISTRIBUTOR_SPECIAL_TIER_PRICING,
    ...payload
  },
) =>
  deleteSpecialTierPricing(context, {
    route: `/distributor/products/${productId}`,
    productId,
    specialTierId,
    specialTierPricings,
    payload,
    loadingKey,
  });

/**
 * New methods that do not use the ORM.
 * Part of a new refactor.
 */

/**
 * Return all proposed prices
 *
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63094112-8582-4df6-8cce-9b3d2d9512b9}
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorFetchProposedPricing(
  context,
  loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PROPOSED_PRICING,
) {
  return httpService.get('/distributor/proposed-prices', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Return single proposed pricing.
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c4d0bc5d-1228-4e6b-b7f1-6ab00993c97e}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorFetchSingleProposedPricing(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_PROPOSED_PRICING },
) {
  return httpService.get(`/distributor/proposed-prices/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c876c73a-2ff8-4464-ae5b-559afffeffdd}
 *
 * @param context
 * @param {number} id
 * @param {string} status
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorUpdateProposedPrice({ id, status, loadingKey }) {
  return httpService.patch(
    `/distributor/proposed-prices/${id}`,
    { status },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see distributorUpdateProposedPrice
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorApproveProposedPrice(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_APPROVE_PROPOSED_PRICE },
) {
  return distributorUpdateProposedPrice({
    id,
    status: 'approved',
    loadingKey,
  });
}

/**
 * @since 2.3.0
 * @see distributorUpdateProposedPrice
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorDeclineProposedPrice(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_DECLINE_PROPOSED_PRICE },
) {
  return distributorUpdateProposedPrice({
    id,
    status: 'declined',
    loadingKey,
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6ee3ab2e-775a-4fcf-bcd2-003c1576c228}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueRemoveNewDistributorProduct(context, { venueId, distributorId, productId }) {
  return httpService.delete(
    `/venues/${venueId}/distributors/${distributorId}/products/${productId}`,
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ea5abbee-2d0f-4924-b1f0-959ea4bc0ce5}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchNewDistributorProduct(context, { distributorId, productId }) {
  return httpService.get(`/venue/distributors/${distributorId}/products/${productId}`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#37c86f79-475f-401e-8e11-ad3453770fe5}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchNewDistributorProducts(
  context,
  { distributorId, query, loadingKey = LOADING_KEY.VENUE_SUPPLIER_PRODUCTS },
) {
  return httpService.get(`/venue/distributors/${distributorId}/products`, {
    headers: {
      loadingKey,
    },
    params: {
      ...query,
    },
  });
}

/**
 * Upload CSV for free distributor.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c1ecefa0-b719-4ba6-9ff7-fd7cd7178c0c}
 *
 * @param context
 * @param {number|string} venueId
 * @param {number|string} distributorId
 * @param {FormData} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUploadDistributorProducts(context, { distributorId, data }) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return httpService.post(`venue/distributors/${distributorId}/import/products`, data, config);
}

/**
 * Upload CSV for free distributor.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c1ecefa0-b719-4ba6-9ff7-fd7cd7178c0c}
 *
 * @param context
 * @param {number|string} venueId
 * @param {number|string} distributorId
 * @param {FormData} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUploadAllDistributorProducts(context, { data }) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return httpService.post(`venue/distributors/import/products`, data, config);
}

/**
 * When creating a new order from the distributor side, fetch price on quantity change.
 * @since 2.3.0
 *
 * @param context
 * @param productId
 * @param venueId
 * @param quantity
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorNewOrderFetchProductPrice(context, { productId, venueId, quantity }) {
  return httpService.get(
    `/distributor/products/${productId}/venues/${venueId}/price?quantity=${quantity}`,
    {
      headers: {
        loadingKey: LOADING_KEY.DISTRIBUTOR_NEW_ORDER_FETCH_PRODUCT_PRICE,
      },
    },
  );
}

/**
 * Download the products from the admin.
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9723c7c6-3dc8-4956-86ed-e4ebb735c0df}
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
function adminDownloadDistributorProductsCSV(context, { id }) {
  return httpService.get(`/admin/distributors/${id}/export/products`, {
    responseType: 'blob',
    headers: {
      loadingKey: LOADING_KEY.ADMIN_EXPORT_DISTRIBUTOR_PRODUCTS,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e602bb84-ba45-49e9-969f-b386b2b0e98c}
 *
 * @param context
 * @param {number} productId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchAllVenues(
  context,
  { productId, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ALL_VENUES },
) {
  return httpService.get(`/distributor/products/${productId}/venues`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.28.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d5fd438e-f9fe-4364-8a36-196486a3a579}
 *
 * @param context
 * @param {number} productId
 * @param {object} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchVenuesForProduct(
  context,
  { productId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ALL_VENUES },
) {
  return httpService.get(`/distributor/products/${productId}/venues/v2`, {
    params: {
      ...query,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8138469b-8116-4797-8507-e70771ee37b2}
 *
 * @param context
 * @param productId
 * @param venueId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorHideVenueForProduct(
  context,
  { productId, venueId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_VENUE_FOR_PRODUCT },
) {
  return httpService.put(
    `/distributor/products/${productId}/venues/${venueId}/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f00dbb25-ada3-4af1-8166-6672bf48e6d3}
 *
 * @param context
 * @param productId
 * @param venueId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUnhideVenueForProduct(
  context,
  { productId, venueId, loadingKey = LOADING_KEY.DISTRIBUTOR_UNHIDE_VENUE_FOR_PRODUCT },
) {
  return httpService.delete(`/distributor/products/${productId}/venues/${venueId}/hide`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7bb7dcc6-244d-454d-95dc-a0872da6e103}
 *
 * @param context
 * @param productId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorHideAllVenuesForProduct(
  context,
  { productId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_ALL_VENUES_FOR_PRODUCT },
) {
  return httpService.put(
    `/distributor/products/${productId}/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ac31151b-2de2-4bd7-9020-f236013eac11}
 *
 * @param context
 * @param productId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUnhideAllVenuesForProduct(
  context,
  { productId, loadingKey = LOADING_KEY.DISTRIBUTOR_UNHIDE_ALL_VENUES_FOR_PRODUCT },
) {
  return httpService.delete(`/distributor/products/${productId}/hide`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9aa85153-0b0b-41b1-a970-c54705ee2cef}
 *
 * @param context
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDownloadProductsCsv(
  context,
  query = {},
  loadingKey = LOADING_KEY.DISTRIBUTOR_DOWNLOAD_PRODUCTS_CSV,
) {
  return httpService.get('/distributor/export/products', {
    responseType: 'arraybuffer',
    params: { ...query },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f4324d61-c2f7-400c-9c47-27e7c07d8c30}
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchAuthUrl(context, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_AUTH_URL) {
  return httpService.get('/distributor/trade-gecko/authorize-url', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0bba9e1f-9ede-40b8-b52e-e0dc4ced5ef4}
 *
 * @param context
 * @param productId
 * @param query
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductHistoryLog(
  context,
  { productId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_HISTORY_LOG },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/distributor/products/${productId}/history${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9430164b-918c-4d77-a4bd-da8f72d57e68}
 *
 * @param context
 * @param {number} productId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchSingleProduct(
  context,
  { productId, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_PRODUCT },
) {
  return httpService.get(`/distributor/products/${productId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b6dd47f5-b6d7-416a-9772-5e62c93e8768}
 *
 * @param context
 * @param productId
 * @param stock
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateProductStock(
  context,
  { productId, stock, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT_STOCK },
) {
  const URL = `/distributor/products/${productId}`;
  return httpService.patch(
    URL,
    {
      ...stock,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ef7a5042-68a3-480c-9b60-1c0d28bedda4}
 *
 * @param context
 * @param {number} productId
 * @param {string} warehouse
 * @param {string} action - One of MANAGE_INVENTORY_ACTION-s
 * @param {number} quantity
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<any>>}
 */
function manageInventory(
  context,
  { productId, warehouse, action, quantity, loadingKey = LOADING_KEY.MANAGE_INVENTORY },
) {
  return httpService.put(
    `/distributor/products/${productId}/inventory`,
    {
      warehouse,
      action,
      quantity,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ef7a5042-68a3-480c-9b60-1c0d28bedda4}
 *
 * @param context
 * @param {number} productId
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<any>>}
 */
function trackingProductOff(context, { productId, loadingKey }) {
  return httpService.delete(`/distributor/products/${productId}/inventory`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.6.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a17b0307-92e1-43f6-b38e-c1c5802fed61}
 *
 * @param context
 * @param {number} venueId
 * @param {string} query?
 * @param {Object} params?
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductsForVenue(
  context,
  { venueId, params, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCTS_FOR_VENUE },
) {
  return httpService.get(`/distributor/venues/${venueId}/products`, {
    ...(params && { params }),
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#98cbada9-df9c-4f53-bb14-1ddbb7f4f82f}
 *
 * @param context
 * @param venueId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorHideVenueProduct(
  context,
  { venueId, productId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_VENUE_PRODUCT },
) {
  return httpService.put(
    `/distributor/venues/${venueId}/products/${productId}/hide`,
    {},
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#69f6815a-72bb-4f93-9bf9-0e3b52ef2eb4}
 *
 * @param context
 * @param venueId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUnhideVenueProduct(
  context,
  { venueId, productId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_VENUE_PRODUCT },
) {
  return httpService.delete(`/distributor/venues/${venueId}/products/${productId}/hide`, {
    headers: { loadingKey },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#916b6c7f-f64c-4a23-9193-c53ee4ad2860}
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorHideAllVenueProducts(
  context,
  { venueId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_PRODUCTS_FOR_VENUE },
) {
  return httpService.put(
    `/distributor/venues/${venueId}/products/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0d16fbf1-ac9c-4efe-8495-4df35cb121c6}
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUnhideAllVenueProducts(
  context,
  { venueId, loadingKey = LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_PRODUCTS_FOR_VENUE },
) {
  return httpService.delete(`/distributor/venues/${venueId}/products/hide`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3e5f17b5-aa36-4af0-88bc-fecf5e9406fc}
 *
 * @param context
 * @param {number|string} venueId
 * @param {FormData} data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUploadVenueProducts(context, { venueId, data }) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return httpService.post(`/distributor/venues/${venueId}/import/products`, data, config);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8a83c875-1826-44c8-93f6-256b6ff882cc}
 *
 * @param context
 * @param venueId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchVenueProduct(context, { venueId, productId }) {
  return httpService.get(`/distributor/venues/${venueId}/products/${productId}`);
}

/**
 * @since 3.14.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#971d9aee-d55d-40f3-b6e1-a1faa27716fd}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchUnits() {
  return httpService.get('/distributor/units');
}

/**
 * @since 3.14.0
 *
 * @param context
 * @param body
 * @param loadingKey
 * @param formKey
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#84161ab4-623c-4e67-8545-c67b46e5f540}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCreateUnit(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.post('/distributor/units', data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 3.14.0
 *
 * @param context
 * @param unitId
 * @param loadingKey
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#94412884-1e76-4ae9-b70b-12252253f5fa}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDeleteUnit(
  context,
  { unitId, loadingKey = LOADING_KEY.DISTRIBUTOR_DELETE_UNIT },
) {
  return httpService.delete(`/distributor/units/${unitId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.14.0
 *
 * @param context
 * @param unitId
 * @param data
 * @param loadingKey
 * @param formKey
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7547e53d-67b8-4169-95d1-655aa323b3b4}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateUnit(
  context,
  { unitId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.put(`/distributor/units/${unitId}`, data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#a17b0307-92e1-43f6-b38e-c1c5802fed61}
 *
 * @param context
 * @param venueId
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function searchForDistributorProducts(
  context,
  { venueId, query, loadingKey = LOADING_KEY.SEARCH_FOR_DISTRIBUTOR_PRODUCTS },
) {
  return httpService.get(`/distributor/venues/${venueId}/products`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#208bb860-dc13-4384-9735-ceddfdde3132}
 *
 * @param context
 * @param query product id
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductInventoryOverview(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW },
) {
  return httpService.get('/distributor/inventory-items', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#70eeec35-4c01-4eb5-8af6-202eca817c07}
 *
 * @param context
 * @param id inventory item ID
 * @param data inventory item data
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorUpdateInventoryItem(
  context,
  { id, data, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_INVENTORY_ITEM },
) {
  return httpService.patch(`/distributor/inventory-items/${id}`, data, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link}
 *
 * @param context
 * @param warehouseId
 * @param loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchInvetoryLocations(
  context,
  { warehouseId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_LOCATIONS },
) {
  return httpService.get(`/distributor/warehouses/${warehouseId}/location-items`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#38b33825-8200-4ab3-a297-b98b0c85a23a}
 *
 * @param context
 * @param data
 * @param loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorProductsAddInventory(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_PRODUCTS_ADD_INVENTORY } = {},
) {
  return httpService.post('/distributor/inventory', data, {
    responseType: 'arraybuffer',
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#437d7fd1-1fe3-4ac9-bc2c-e8f32180d6b8}
 *
 * @param context
 * @param data
 * @param loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorProductsTransferInventory(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_PRODUCTS_TRANSFER_INVENTORY } = {},
) {
  return httpService.put('/distributor/inventory', data, {
    responseType: 'arraybuffer',
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#437d7fd1-1fe3-4ac9-bc2c-e8f32180d6b8}
 *
 * @param context
 * @param data
 * @param loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorProductsRemoveInventory(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_PRODUCTS_REMOVE_INVENTORY } = {},
) {
  return httpService.delete('/distributor/inventory', {
    responseType: 'arraybuffer',
    headers: { loadingKey },
    data,
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5eb5bbdc-a15f-41bf-bab6-b9f50f00de5b}
 *
 * @param context
 * @param loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductsActions(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCTS_ACTIONS } = {},
) {
  return httpService.get('/distributor/products/actions', {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#b6dd47f5-b6d7-416a-9772-5e62c93e8768}
 *
 * @param context
 * @param productId
 * @param data
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorUpdateProduct(
  context,
  { productId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT } = {},
) {
  return httpService.patch(`/distributor/products/${productId}`, data, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8ad68e00-9dc0-4d22-838d-20f922d2ab78}
 *
 * @param context
 * @param productId
 * @param query
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductInventoryHistory(
  context,
  { productId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_HISTORY },
) {
  return httpService.get(`/distributor/products/${productId}/inventory-history`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#790a70f3-0efb-4c75-996f-c1c2e2e750ec}
 *
 * @param context
 * @param productId
 * @param query
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchInventoryHistoryWarehouses(
  context,
  {
    productId,
    query = {},
    loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_HISTORY_WAREHOUSES,
  },
) {
  return httpService.get(`/distributor/products/${productId}/inventory-history/warehouses`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#004e44c7-8e0f-458c-be60-52851a1f6e15}
 *
 * @param context
 * @param productId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchInventoryHistoryTypes(
  context,
  { productId, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_HISTORY_TYPES },
) {
  return httpService.get(`/distributor/products/${productId}/inventory-history/types`, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ba211e04-3ef8-4100-9541-eb518f77220e}
 *
 * @param context
 * @param productId
 * @param query
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorExportInventoryHistory(
  context,
  { productId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_INVENTORY_HISTORY },
) {
  return httpService.get(`/distributor/products/${productId}/inventory-history/export`, {
    responseType: 'arraybuffer',
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d399a3ab-c845-4c7a-b53f-eef718de5b84}
 *
 * @param context
 * @param productId
 * @param historyId
 * @param query
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorExportSingleInventoryHistory(
  context,
  {
    productId,
    historyId,
    query,
    loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_SINGLE_INVENTORY_HISTORY,
  },
) {
  return httpService.get(
    `/distributor/products/${productId}/inventory-history/${historyId}/export`,
    {
      responseType: 'arraybuffer',
      headers: { loadingKey },
      params: { ...query },
    },
  );
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c84849e2-8206-4c2b-9b9f-e4b7b16b111b}
 *
 * @param context
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchInventoryValuation(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_VALUATION },
) {
  return httpService.get('/distributor/inventory/valuation', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a8694b06-2d6f-437f-9614-ddbc76a71aa6}
 *
 * @param context
 * @param {string} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchProductGroups(
  context,
  { term, loadingKey = LOADING_KEY.FETCH_PRODUCT_GROUPS } = {},
) {
  return httpService.get('/distributor/product-groups', {
    params: { term },
    headers: { loadingKey },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b696f99e-e640-440b-b6cc-48ada689e1e0}
 *
 * @param context
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorCreateProductGroup(
  context,
  { name, loadingKey = LOADING_KEY.CREATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.post(
    '/distributor/product-groups',
    { name },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#128580ee-eaed-4da3-b43c-505dcb8e12da}
 *
 * @param context
 * @param {number} id
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorUpdateProductGroup(
  context,
  { id, name, loadingKey = LOADING_KEY.UPDATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.put(
    `/distributor/product-groups/${id}`,
    { name },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#97e9360a-71fc-4894-bb9a-5ea1195b5348}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorDeleteProductGroup(
  context,
  { id, loadingKey = LOADING_KEY.DELETE_PRODUCT_GROUP } = {},
) {
  return httpService.delete(`/distributor/product-groups/${id}`, { headers: { loadingKey } });
}

export default {
  manageInventory,
  fetchDistributorProducts,
  fetchDistributorProduct,
  removeDistributorProduct,
  removeDistributorProductImage,

  uploadDistributorCSV,
  uploadDistributorCSVAdmin,

  addTradeGeckoCode,
  removeTradeGeckoCode,
  syncProducts,
  deleteDistributorDefaultTierPricing,
  deleteDistributorSpecialTierPricing,

  fetchProducts,

  // Refactored Methods
  distributorFetchProposedPricing,
  distributorFetchSingleProposedPricing,
  distributorApproveProposedPrice,
  distributorDeclineProposedPrice,
  distributorNewOrderFetchProductPrice,

  venueRemoveNewDistributorProduct,
  venueFetchNewDistributorProducts,
  venueFetchNewDistributorProduct,
  venueUploadDistributorProducts,
  venueUploadAllDistributorProducts,

  adminDownloadDistributorProductsCSV,

  distributorFetchAllVenues,
  distributorFetchVenuesForProduct,

  distributorHideVenueForProduct,
  distributorUnhideVenueForProduct,
  distributorHideAllVenuesForProduct,
  distributorUnhideAllVenuesForProduct,
  distributorDownloadProductsCsv,
  distributorFetchAuthUrl,
  distributorFetchProductHistoryLog,
  distributorUpdateProductStock,
  distributorFetchSingleProduct,
  trackingProductOff,
  distributorFetchProductsForVenue,
  distributorHideVenueProduct,
  distributorUnhideVenueProduct,
  distributorHideAllVenueProducts,
  distributorUnhideAllVenueProducts,
  distributorUploadVenueProducts,
  distributorFetchVenueProduct,
  distributorFetchUnits,
  distributorCreateUnit,
  distributorDeleteUnit,
  distributorUpdateUnit,
  searchForDistributorProducts,

  distributorFetchProductInventoryOverview,
  distributorUpdateInventoryItem,
  distributorFetchInvetoryLocations,
  distributorUpdateProduct,

  distributorProductsAddInventory,
  distributorProductsTransferInventory,
  distributorProductsRemoveInventory,
  distributorFetchProductsActions,
  distributorFetchProductInventoryHistory,
  distributorFetchInventoryHistoryWarehouses,
  distributorFetchInventoryHistoryTypes,
  distributorExportInventoryHistory,
  distributorExportSingleInventoryHistory,
  distributorFetchInventoryValuation,
  distributorFetchProductGroups,
  distributorCreateProductGroup,
  distributorUpdateProductGroup,
  distributorDeleteProductGroup,

  // ORM Refactor
  distributorFetchProduct,
};
