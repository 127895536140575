import dayjs from 'dayjs';
import { CONNECTED_STATUSES, INTEGRATION_STATUSES } from '@/util/constants';

const getVenue = state => id => state.venues.find(d => d.id === id);

const areAllSelected = (state) => {
  const selected = state.offlineOrder.selectedProducts;
  const unselected = state.offlineOrder.products;

  return selected.length === unselected.length;
};


function getOfflineOrderMulti(state) {
  const formData = new FormData();
  const { offlineOrder } = state;
  const products = offlineOrder.selectedProducts.map(sP => ({
    id: sP.id,
    quantity: sP.quantity,
    price: !sP.marketPrice ? sP.price : null,
    marketPrice: sP.marketPrice,
    ...(sP.totalPrice && { totalPrice: sP.totalPrice }),
  }));

  formData.append('distributorId', offlineOrder.distributor.id);
  formData.append('message', '');
  formData.append('orderedAt', offlineOrder.date);

  formData.append('deliveryFee', offlineOrder.deliveryFee);
  formData.append('amountAdjustment', offlineOrder.amountAdjustment);
  formData.append('taxAdjustment', offlineOrder.taxAdjustment);

  products.forEach((pr, ind) => {
    const productName = `products[${ind}]`;

    formData.append(`${productName}[id]`, pr.id);
    formData.append(`${productName}[quantity]`, pr.quantity);
    if (pr.price >= 0) {
      if (pr.price === null && !pr.marketPrice) {
        formData.append(`${productName}[price]`, 0);
      } else {
        formData.append(`${productName}[price]`, pr.price);
      }
    }
    if (pr.marketPrice) {
      formData.append(`${productName}[marketPrice]`, pr.marketPrice);
    }
    if (pr.totalPrice >= 0) {
      formData.append(`${productName}[totalPrice]`, pr.totalPrice);
    }
  });

  offlineOrder.receipts.forEach(receipt => formData.append('invoices', receipt));
  return formData;
}

function getOfflinePendingUpdate(state) {
  const formData = new FormData();
  const { offlineOrderNewSupplier: order } = state;
  const products = order.products.map(sP => ({
    id: sP.id,
    quantity: sP.quantity,
    price: !sP.marketPrice ? sP.price : null,
    marketPrice: sP.marketPrice,
    ...(sP.totalPrice && { totalPrice: sP.totalPrice }),
  }));
  const orderAt = dayjs(order.date).valueOf();

  formData.append('deliveryFee', order.deliveryFee ?? 0);
  formData.append('taxAdjustment', order.taxAdjustment ?? 0);
  formData.append('amountAdjustment', order.amountAdjustment ?? 0);
  formData.append('distributorId', order.supplier.id);
  formData.append('message', '');
  formData.append('orderedAt', orderAt);
  formData.append('invoiceId', order.pendingUpdate.id);

  products.forEach((pr, ind) => {
    const productName = `products[${ind}]`;

    formData.append(`${productName}[id]`, pr.id);
    formData.append(`${productName}[quantity]`, pr.quantity);
    if (pr.price >= 0) {
      if (pr.price === null && !pr.marketPrice) {
        formData.append(`${productName}[price]`, 0);
      } else {
        formData.append(`${productName}[price]`, pr.price);
      }
    }
    if (pr.marketPrice) {
      formData.append(`${productName}[marketPrice]`, pr.marketPrice);
    }
    if (pr.totalPrice >= 0) {
      formData.append(`${productName}[totalPrice]`, pr.totalPrice);
    }
  });

  return formData;
}

function isSelectedIntegrationConnected(state) {
  return CONNECTED_STATUSES.includes(state.integrationVenueDraft.status);
}

function isSelectedIntegrationNotEnabled(state) {
  return state.integrationVenueDraft.status === INTEGRATION_STATUSES.notEnabled;
}

function isSelectedIntegrationNotConnected(state) {
  return state.integrationVenueDraft.status === INTEGRATION_STATUSES.notConnected;
}

function isSelectedIntegrationNeedToUpdate(state) {
  return state.integrationVenueDraft.status === INTEGRATION_STATUSES.updateNeeded;
}

function isSelectedIntegrationActionNeeded(state) {
  return state.integrationVenueDraft.status === INTEGRATION_STATUSES.actionNeeded;
}

export default {
  getVenue,
  areAllSelected,
  getOfflineOrderMulti,
  getOfflinePendingUpdate,
  isSelectedIntegrationConnected,
  isSelectedIntegrationNotEnabled,
  isSelectedIntegrationNotConnected,
  isSelectedIntegrationNeedToUpdate,
  isSelectedIntegrationActionNeeded,
};
