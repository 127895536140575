import { isString } from '@/util/utils';
import bus from './bus';

import ToastMessage from './EzToastMessage.vue';

function hide() {
  bus.$emit('hideToast');
}

function show({ message, type, delay }) {
  bus.$emit('showToast', {
    message, type, delay,
  });
}

function showSuccess(options) {
  const optionsObject = isString(options) ? { message: options } : options;
  show({ ...optionsObject, type: 'success' });
}

export { ToastMessage };
export default {
  show,
  hide,
  success: showSuccess,
};
