import VuePermission from '@/mixins/permissions';
import xeroRoutes from '@/router/venue-xero-routes';
import quickBooksRoutes from '@/router/venue-quickbooks-routes';
import trayRoutes from '@/router/venue-tray-integrations-routes';
import HubDocRoutes from '@/router/venue-hub-doc-routes';
import i18n from '@/i18n';

export default [
  ...xeroRoutes,
  ...quickBooksRoutes,
  ...trayRoutes,
  ...HubDocRoutes,
  {
    path: '/buyer/suppliers/new',
    name: 'buyer-suppliers-new',
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/new/Index.vue'),
    props: true,
    meta: {
      ...VuePermission.routeRule(['lite', 'premium'], 'venue-home'),
      title: `New ${i18n.t('global.distributor')}`,
    },
  },
  {
    path: '/buyer/orders/new-pending-update/:id(\\d+)',
    name: 'buyer-orders-new-pending-update',
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/orders/pending-updates/new-supplier/NewPendingInvoice'
      ),
    props: ({ params }) => ({
      invoiceId: Number(params.id),
    }),
  },
  {
    path: '/buyer/orders/merge-pending-update/:id(\\d+)',
    name: 'buyer-orders-merge-pending-update',
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/orders/pending-updates/new-supplier/PendingInvoiceMerge'
      ),
    props: ({ params }) => ({
      invoiceId: Number(params.id),
    }),
  },
  {
    path: '/buyer/orders/new-offline',
    name: 'buyer-orders-new-offline',
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/orders/new-offline/existing-supplier/Index'
      ),
    props: true,
    meta: {
      title: 'New Offline Order',
    },
  },
  {
    path: '/buyer/suppliers/connect/:id(\\d+)',
    name: 'buyer-suppliers-connect',
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connect/Index.vue'),
    props: ({ params }) => ({ supplierId: Number(params.id) }),
    meta: { ...VuePermission.routeRule(['lite', 'premium'], 'venue-home') },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connect/Contacts.vue'
          ),
        props: ({ params }) => ({ supplierId: Number(params.id) }),
        meta: {
          title: 'Connect',
        },
      },
    ],
  },
  {
    path: '/buyer/suppliers/connection-requests/:id(\\d+)',
    props: ({ params }) => ({ supplierId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connection-requests/Single.vue'
      ),
    // meta: { ...VuePermission.routeRule(['lite', 'premium'], 'venue-home') },
    children: [
      {
        path: '',
        name: 'buyer-invitation-single-info',
        props: ({ params }) => ({ supplierId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connection-requests/SingleInfo.vue'
          ),
        meta: {
          title: 'Connection Request',
        },
      },
    ],
  },
  {
    path: '/buyer/suppliers/connection-requests/:id(\\d+)/onboarding',
    name: 'buyer-invitation-onboarding',
    props: ({ params }) => ({ supplierId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding/Onboarding.vue'
      ),
    meta: {
      title: 'Onboarding',
    },
  },
  {
    path: '/buyer/suppliers/connection-requests/:id(\\d+)/onboarding-from-public',
    name: 'buyer-onboarding-from-public',
    props: ({ params }) => ({ supplierId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding-from-public/Onboarding.vue'
      ),
    meta: {
      title: 'Onboarding',
    },
  },
  {
    path: '/buyer/suppliers/all/:id(\\d+)',
    props: ({ params }) => ({ supplierId: Number(params.id) }),
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/Single.vue'),
    children: [
      {
        path: '',
        name: 'buyer-single-info',
        props: ({ params }) => ({ supplierId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/SingleInfo.vue'
          ),
        meta: {
          title: `${i18n.t('global.distributor')} Info`,
        },
      },
      {
        path: 'products',
        name: 'buyer-single-products',
        props: ({ params }) => ({ supplierId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/SingleProducts.vue'
          ),
        meta: {
          title: `${i18n.t('global.distributor')} Products`,
        },
      },
      /**
       * All actions moved to products tab
       * @deprecated 2.4.0
       */
      {
        path: 'products-visibility',
        name: 'buyer-single-products-visibility',
        redirect: { name: 'buyer-single-products' },
      },
      {
        path: 'users',
        name: 'buyer-single-users',
        props: ({ params }) => ({ supplierId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/SingleUsers.vue'
          ),
        meta: {
          title: `${i18n.t('global.distributor')} Users`,
        },
      },
    ],
  },
  {
    path: '/buyer/features-list',
    alias: '/venue/features-list',
    name: 'venue-features-list',
    meta: {
      title: 'Features List',
    },
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/FeaturesList.vue'),
  },
  {
    path: '/buyer/shopping-cart',
    alias: '/venue/shopping-cart',
    name: 'venue-shopping-cart',
    meta: {
      role: 'venue',
      requireAuth: true,
      title: 'Shopping Cart',
      ...VuePermission.routeRule('createOrder', 'venue-orders'),
    },
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/ShoppingCart.vue'),
  },
  {
    path: '/buyer/group-settings',
    alias: '/venue/group-settings',
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/GroupSettings.vue'),
    meta: {
      role: 'venue',
      requireAuth: true,
      title: 'Group Settings',
      ...VuePermission.routeRule('group', 'venue-orders'),
    },
    children: [
      {
        path: '/',
        name: 'venue-group-settings',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/Info.vue'),
        meta: {
          title: 'Group Settings',
        },
      },
      {
        path: '/settings',
        name: 'venue-group-settings-settings-group',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/Group.vue'),
        meta: {
          title: 'Group Settings',
        },
      },
      {
        path: 'buyers',
        name: 'venue-group-settings-venues',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/Venues.vue'),
        meta: {
          title: `${i18n.t('global.venues')}`,
        },
      },
      {
        path: 'users',
        name: 'venue-group-settings-users',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/Users.vue'),
        meta: {
          title: 'Users',
        },
      },
      {
        path: 'settings',
        name: 'venue-group-settings-settings',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/Settings.vue'),
        meta: {
          title: 'Settings',
        },
      },
    ],
  },
  {
    path: '/buyer/user-settings',
    alias: '/venue/user-settings',
    name: 'venue-user-settings',
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/user-settings/EzUserSettings.vue'),
    meta: {
      title: 'User Settings',
    },
  },
  {
    path: '/buyers/:id(\\d+)/settings',
    alias: '/venue/:id(\\d+)/settings',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "venue" */ '@/views/platform/venue/settings/venue/VenueSettings.vue'
      ),
    meta: {
      role: 'venue',
      requireAuth: true,
      title: `${i18n.t('global.venue')} Settings`,
    },
    children: [
      {
        path: '/',
        name: 'venue-settings',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/settings/venue/Info.vue'),
      },
      {
        path: 'integrations',
        name: 'venue-settings-integrations',
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/settings/venue/Integrations.vue'
          ),
      },
      {
        path: 'stocktake',
        name: 'venue-settings-stocktake',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/settings/venue/Stocktake.vue'
          ),
        meta: {
          ...VuePermission.routeRule('manageInventory', 'venue-settings'),
        },
      },
      {
        path: 'settings',
        name: 'venue-settings-settings',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/settings/venue/Settings.vue'
          ),
      },
    ],
  },
  {
    path: '/buyer/stock/conduct-stocktake/:id(\\d+)',
    name: 'venue-conduct-stocktake',
    props: ({ params }) => ({ stocktakeId: Number(params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/platform/venue/stock/conduct-stocktake/Index.vue'
      ),
    meta: {
      title: 'Conduct Count',
      ...VuePermission.routeRule('conductStocktake', 'venue-home'),
    },
  },
  {
    path: '/buyer/stock/stocktake/:id(\\d+)',
    name: 'venue-stocktake-single',
    props: ({ params }) => ({ stocktakeId: Number(params.id) }),
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/stock/Single.vue'),
    meta: {
      title: 'Stocktake',
      ...VuePermission.routeRule('conductStocktake', 'venue-home'),
    },
  },
  {
    path: '/buyer',
    alias: '/venue',
    redirect: { name: 'venue-home' },
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/Home.vue'),
    meta: {
      role: 'venue',
      requireAuth: true,
      title: 'Home',
    },
    children: [
      {
        path: 'overview',
        name: 'venue-home',
        redirect: { name: 'venue-overview-dashboard' },
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/Overview.vue'),
        meta: {
          ...VuePermission.routeRule('seeOverview', 'venue-orders'),
          title: 'Overview',
        },
        children: [
          {
            path: '/',
            name: 'venue-overview-dashboard',
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/overview/Dashboard.vue'
              ),
            meta: {
              title: 'Dashboard',
            },
          },
        ],
      },
      {
        path: 'all-freemium',
        name: 'venue-all-suppliers-freemium',
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/FreemiumSuppliers'
          ),
      },
      {
        path: 'suppliers',
        name: 'venue-suppliers',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/Index.vue'),
        redirect: { name: 'venue-all-suppliers-products' },
        meta: {
          title: 'Suppliers',
          ...VuePermission.routeRule(['seeSuppliers'], 'venue-home'),
        },
        children: [
          {
            path: 'all',
            name: 'venue-all-suppliers',
            props: ({ params }) => ({ orderingMethod: params.orderingMethod }),
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/Index.vue'
              ),
            meta: {
              title: `${i18n.t('global.distributors')}`,
            },
          },
          {
            path: 'products',
            name: 'venue-all-suppliers-products',
            props: ({ params }) => ({
              productGroupFilter: params.productGroupFilter,
              trackingFilter: params.trackingFilter,
              newFilter: params.newFilter,
            }),
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/all/Products.vue'
              ),
            meta: {
              title: 'All Products',
            },
          },
          {
            path: 'connection-requests',
            name: 'venue-connection-requests',
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connection-requests/Index.vue'
              ),
            meta: {
              title: `${i18n.t('global.distributors')}`,
            },
          },
        ],
      },
      {
        path: 'orders',
        name: 'venue-orders',
        props: ({ params }) => ({ productId: Number(params.id) }),
        redirect: { name: 'venue-orders-history' },
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/orders/Orders.vue'),
        meta: {
          title: 'Orders',
        },
        children: [
          {
            path: ':id(\\d+)',
            name: 'venue-orders-single',
            props: ({ params }) => ({ productId: Number(params.id) }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/orders/Orders.vue'),
            meta: {
              title: 'Orders',
            },
          },
          {
            path: 'products/:id(\\d+)?',
            name: 'venue-orders-products',
            props: ({ params }) => ({ productId: Number(params.id) }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/Products.vue'),
            meta: {
              ...VuePermission.routeRule('seeProducts', 'venue-orders-history'),
              title: 'Orders',
            },
          },
          {
            path: 'history/:id(\\d+)?',
            name: 'venue-orders-history',
            props: ({ params }) => ({ orderId: Number(params.id) }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/orders/History.vue'),
            meta: {
              title: 'Orders',
            },
          },
          {
            path: 'standing/:id(\\d+)?',
            name: 'venue-orders-standing',
            props: ({ params }) => ({ orderId: Number(params.id) }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/orders/Standing.vue'),
            meta: {
              ...VuePermission.routeRule('seeFutureOrders', 'venue-orders-history'),
              title: 'Orders',
            },
          },
          {
            path: 'scheduled/:id(\\d+)?',
            name: 'venue-orders-scheduled',
            props: ({ params }) => ({ orderId: Number(params.id) }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/orders/Scheduled.vue'),
            meta: {
              ...VuePermission.routeRule('seeFutureOrders', 'venue-orders-history'),
              title: 'Orders',
            },
          },
        ],
      },
      {
        path: 'pending-updates/:id(\\d+)?',
        name: 'venue-pending-updates',
        props: ({ params }) => ({ orderId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "venue" */ '@/views/platform/venue/orders/pending-updates/Index.vue'
          ),
        meta: {
          title: 'Invoices',
          ...VuePermission.routeRule(['lite', 'premium'], 'venue-orders-history'),
        },
      },
      {
        path: 'reports',
        name: 'venue-reports',
        props: ({ params }) => ({ reportId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/reports/Index.vue'),
        redirect: { name: 'venue-reports-invoices' },
        meta: {
          title: 'Reports',
          ...VuePermission.routeRule(['premium', 'lite', 'seeReports'], 'venue-home'),
        },
        children: [
          {
            path: 'all/:id(\\d+)?',
            name: 'venue-reports-invoices',
            props: ({ params }) => ({
              reportId: Number(params.id),
              onlyWithChange: params.onlyWithChange || false,
              from: params.from || null,
              to: params.to || null,
            }),
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/reports/Reports.vue'),
            meta: {
              title: 'Reports',
            },
          },
          {
            path: 'orders/:id(\\d+)?',
            name: 'venue-reports-orders',
            props: ({ params }) => ({
              reportId: Number(params.id),
              onlyWithChange: params.onlyWithChange || false,
              from: params.from || null,
              to: params.to || null,
            }),
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/reports/ReportsOrders.vue'
              ),
            meta: {
              title: 'Reports',
            },
          },
          {
            path: 'chartbrew-report',
            name: 'venue-reports-chartbrew',
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/venue/reports/ChartbrewReport.vue'
              ),
            meta: {
              title: 'Chartbrew Report',
            },
          },
        ],
      },
      {
        path: 'stock',
        name: 'venue-stock',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/venue/stock/Index.vue'),
        redirect: { name: 'venue-stock-all' },
        meta: {
          title: 'Stock',
        },
        children: [
          {
            path: 'all',
            name: 'venue-stock-all',
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/stock/AllStock.vue'),
            meta: {
              title: 'All Stock',
              ...VuePermission.routeRule('manageInventory', 'venue-stock-stocktake'),
            },
          },
          {
            path: 'stocktake',
            name: 'venue-stock-stocktake',
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/venue/stock/Stocktake.vue'),
            meta: {
              title: 'Stocktake',
              ...VuePermission.routeRule('conductStocktake', 'venue-home'),
            },
          },
        ],
      },
      {
        path: 'legal',
        name: 'venue-legal',
        component: () =>
          import(/* webpackChunkName: "venue" */ '@/views/platform/footer/Index.vue'),
        redirect: { name: 'venue-terms-and-conditions' },
        meta: {
          title: 'Legal',
        },
        children: [
          {
            path: 'terms-and-conditions',
            name: 'venue-terms-and-conditions',
            component: () =>
              import(
                /* webpackChunkName: "venue" */ '@/views/platform/footer/TermsAndConditions.vue'
              ),
            meta: {
              title: 'Terms & Conditions',
            },
          },
          {
            path: 'privacy-policy',
            name: 'venue-privacy-policy',
            component: () =>
              import(/* webpackChunkName: "venue" */ '@/views/platform/footer/PrivacyPolicy.vue'),
            meta: {
              title: 'Privacy Policy',
            },
          },
        ],
      },
      {
        path: '*',
        component: () =>
          import(/* webpackChunkName: "pagenotfound" */ '@/views/common/PageNotFound.vue'),
      },
    ],
  },
];
