<script>
/**
 *
 * @version 1.0.0
 * @since
 */
export default {};
</script>
<template>
  <font-awesome-layers>
    <font-awesome-icon icon="circle" />
    <font-awesome-icon icon="plus" transform="shrink-6" :style="{ color: 'white' }" />
  </font-awesome-layers>
</template>
<style lang="scss" scoped></style>
