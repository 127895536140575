const ECommerceSettings = () => import(/* webpackChunkName: "distributor-eCommerce-settings" */ '@/views/platform/distributor/e-commerce-integrations/Index.vue');
const ECommerceNotConnected = () => import(/* webpackChunkName: "distributor-eCommerce-settings" */ '@/views/platform/distributor/e-commerce-integrations/NotConnected.vue');
const ECommerceProducts = () => import(/* webpackChunkName: "distributor-eCommerce-settings" */ '@/views/platform/distributor/e-commerce-integrations/Products.vue');
const ECommerceWarehouses = () => import(/* webpackChunkName: "distributor-eCommerce-settings" */ '@/views/platform/distributor/e-commerce-integrations/Warehouses.vue');


export default [
  // E-commerce Settings Route
  {
    path: '/supplier/settings/integrations/e-commerce',
    component: ECommerceSettings,
    meta: {
      role: 'distributor',
    },
    children: [
      {
        path: 'products',
        name: 'distributor-e-commerce-products',
        component: ECommerceProducts,
        meta: {
          title: 'E-commerce Products',
        },
      },
      {
        path: 'warehouses',
        name: 'distributor-e-commerce-warehouses',
        component: ECommerceWarehouses,
        meta: {
          title: 'E-commerce Warehouses',
        },
      },
      {
        path: '/',
        name: 'distributor-e-commerce-not-connected',
        component: ECommerceNotConnected,
        meta: {
          title: 'E-commerce Not Connected',
          requireAuth: true,
        },
      },
    ],
  },
];
