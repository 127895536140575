const SET_LOADING = (state, payload) => {
  state.loading = {
    ...state.loading,
    ...payload,
  };
};

const CLEAR_LOADING = (state, { key }) => {
  state.loading = {
    ...state.loading,
    [key]: false,
  };
};

const CLEAR_ALL_LOADING = (state) => {
  state.loading = {};
};

export default {
  SET_LOADING,
  CLEAR_LOADING,
  CLEAR_ALL_LOADING,
};
