import { clone } from '@/util/utils';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const draftOrderInitial = {
  venue: {},
  products: [],
  message: '',
  deliveryFee: null,
};

const state = {
  meta: {
    actionsCount: 0,
    count: 0,
    firstOrderedAt: null,
    nextId: null,
    totalAmount: 0,
    totalCount: 0,
    upcomingScheduledCount: 0,
    upcomingStandingCount: 0,
    exportTypes: {},
  },
  draft: clone(draftOrderInitial),
  // Single order opened. Needed for editing mode.
  singleOrder: clone(draftOrderInitial),
  newOrder: {
    products: [],
    selectedProducts: [],
  },
  outletReferenceNumber: null,
  filters: {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  draftOrderInitial,
};
