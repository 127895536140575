import { get } from '@/util/utils';

const getItemCount = ({ cart }) => {
  let itemCount = 0;

  Object.entries(cart).forEach(([, products]) => {
    if (!products) {
      return;
    }

    Object.entries(products).forEach(([product]) => {
      if (product !== 'distributor') {
        itemCount += 1;
      }
    });
  });

  return itemCount;
};

const getProductCount =
  ({ cart }) =>
  ({ productId, distributorId }) =>
    get(cart, [distributorId, productId, 'quantity'], 0);

export default {
  getProductCount,
  getItemCount,
};
