import Vue from 'vue';
import Router from 'vue-router';
import flash from '@/components/ui/FlashMessage';
import VuePermission from '@/mixins/permissions';
import venueRoutes from '@/router/venue-routes';
import distributorRoutes from '@/router/distributor-routes';
import platformRoutes from '@/router/platform-routes';
import publicRoutes from '@/router/public-routes';
import productsPermissionFix from '@/util/productsPermissionFix';
import { routerErrorHandler } from '@/util/utils';
import { ACCOUNT_STATUS_TRIAL_EXPIRED } from '../util/constants';

Vue.use(VuePermission);
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...publicRoutes,
    ...platformRoutes,
    ...venueRoutes,
    ...distributorRoutes,
    {
      path: '*',
      component: () => import(/* webpackChunkName: "pagenotfound" */ '@/views/common/PageNotFound.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === 'platform-login' && to.query.token) {
    localStorage.clear();
  }

  const appTitle = process.env.VUE_APP_PLATFORM_TITLE;

  const role = localStorage.getItem('role');
  const subscriptionStatus = localStorage.getItem('subscriptionStatus');
  const isAuthenticated = !!localStorage.getItem('accessToken');

  document.title = (to.meta && to.meta.title) ? `${to.meta.title} - ${appTitle}` : appTitle;

  if (!isAuthenticated && to.matched.some(({ meta }) => meta.requireAuth)) {
    router
      .push({ path: 'login' })
      .catch(routerErrorHandler);

    return;
  }

  if (to.params.flash) {
    flash.show({
      ...to.params.flash,
    });
  }

  if (role === 'venue') {
    if (subscriptionStatus === ACCOUNT_STATUS_TRIAL_EXPIRED
      && to.name !== 'venue-overview-dashboard'
      && to.name !== 'platform-home'
      && to.name !== 'manage-accounts-premium'
      && to.name !== 'manage-accounts-freemium'
    ) {
      router
        .push({ name: 'platform-home' })
        .catch(routerErrorHandler);

      return;
    }
    if (to.matched.some(({ name }) => name === 'platform-home')) {
      let nextRoute = '';
      if (productsPermissionFix()) nextRoute = productsPermissionFix();
      else nextRoute = 'venue-home';
      router
        .push({ name: nextRoute })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ name }) => name === 'venue-overview-dashboard') && new VuePermission().isFree) {
      router
        .push({
          name: 'venue-orders',
          params: to.params,
        })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ name }) => name === 'venue-orders-public')) {
      router
        .push({
          name: 'venue-orders',
          params: to.params,
          query: to.query,
        })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ meta }) => meta && meta.role && meta.role !== 'venue')) {
      router
        .push({ name: 'venue-home' })
        .catch(routerErrorHandler);

      return;
    }
  }

  if (role === 'distributor') {
    if (subscriptionStatus === ACCOUNT_STATUS_TRIAL_EXPIRED
      && to.name !== 'distributor-orders-all'
      && to.name !== 'platform-home'
      && to.name !== 'manage-accounts-premium'
      && to.name !== 'manage-accounts-freemium'
    ) {
      router
        .push({ name: 'platform-home' })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ name }) => name === 'platform-home')) {
      router
        .push({ name: 'distributor-home' })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ name }) => name === 'distributor-orders-public')) {
      router
        .push({
          name: 'distributor-home',
          params: to.params,
          query: to.query,
        })
        .catch(routerErrorHandler);

      return;
    }

    if (to.matched.some(({ meta }) => meta && meta.role && meta.role !== 'distributor')) {
      router
        .push({ name: 'distributor-home' })
        .catch(routerErrorHandler);

      return;
    }
  }

  if (isAuthenticated) {
    if (Object.prototype.hasOwnProperty.call(to.meta, 'requireAuth') && !to.meta.requireAuth) {
      router
        .push({ name: 'platform-home' })
        .catch(routerErrorHandler);
      return;
    }
  }
  if (VuePermission.routerCheck(to, from, next, router)) return;

  next();
});

export default router;
