<template>
  <transition name="fly-in">
    <div class="flash-message" :class="type" v-if="isVisible">
      <div class="flash-message__icon">
        <span v-if="isSuccess">
          <font-awesome-icon icon="check-circle" />
        </span>
        <span v-if="isFail">
          <font-awesome-icon icon="minus-circle" />
        </span>
        <span v-if="isError">
          <font-awesome-icon icon="exclamation-circle" />
        </span>
      </div>
      <div class="flash-message__info">
        <h3 class="flash-message__title" v-if="title">{{ title }}</h3>
        <p class="flash-message__message" v-if="message">{{ message }}</p>
        <div class="flash-message__ctas" v-if="ctas" v-html="ctas"></div>
      </div>
      <div class="flash-message__buttons">
        <button class="flash-message__close" @click="hide">✕</button>
      </div>
    </div>
  </transition>
</template>

<script>
import bus from './bus';

/**
 * Flash message component will sit at the root of the app and wait for messages
 * to be flashed. With a help of `flash` method, which interact with the internal event bus,
 * messages can be easily flashed.
 * @since 1.0.0
 */
export default {
  data() {
    return {
      isVisible: false,
      timeout: null,
      type: '',
      title: '',
      message: '',
      delay: 4000, // ms
      ctas: null,
    };
  },
  computed: {
    isSuccess() {
      return this.type === 'success';
    },
    isFail() {
      return this.type === 'fail';
    },
    isError() {
      return this.type === 'error';
    },
  },
  methods: {
    show({ title, message, ctas, type, delay }) {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      this.isVisible = true;
      this.type = type;
      this.title = title;
      this.message = message;
      this.ctas = ctas;
      this.delay = delay === undefined ? this.delay : delay;
      if (delay !== -1) {
        this.timeout = setTimeout(() => {
          this.hide();
        }, this.delay);
      }
    },
    hide() {
      this.isVisible = false;
      this.timeout = null;
    },
  },
  mounted() {
    bus.$on('show', this.show);
    bus.$on('hide', this.hide);
  },
};
</script>

<style lang="scss" scoped>
$flash-message-title-font-size: 14px;
$flash-message-text-font-size: 14px;
$flash-message-top-offset: 20px;
$flash-message-padding-y: 12px;
$flash-message-padding-x: 16px;
$flash-message-border-radius: 0.1875rem;

.flash-message {
  @include fixed(top $flash-message-top-offset left 50%);
  display: flex;
  padding: $flash-message-padding-y $flash-message-padding-x;
  width: 560px;
  border: 1px solid #e9ebf2;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  @include z-index('flash');

  border-radius: $flash-message-border-radius;
  color: #ffffff;

  &.success {
    .flash-message__icon {
      color: #1ba975;
    }
  }
  &.fail,
  &.error {
    .flash-message__icon {
      color: #f73731;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(16px);
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 1em;
  }
  &__title {
    margin: 0;
    font-weight: 600;
    color: #252631;
    @include font-size($flash-message-title-font-size);
  }
  &__message {
    margin: 6px 0;
    color: #6c7995;
    @include font-size($flash-message-text-font-size);
    opacity: 0.6;
  }
  &__buttons {
    margin-left: auto;
  }
  &__ctas {
    margin-right: 1em;
  }
  &__ctas :deep() a {
    @include font-size($flash-message-text-font-size);
    color: #4d7cfe;
    text-decoration: none;
  }
  &__close {
    @extend %button-reset;
    color: #6c7995;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .flash-message {
    width: auto;
  }
}
.fly-in-enter-active,
.fly-in-leave-active {
  transition: transform 0.3s ease-out;
}
.fly-in-enter-to,
.fly-in-leave {
  transform: translate3d(-50%, 0, 0);
}
.fly-in-enter,
.fly-in-leave-to {
  transform: translate3d(-50%, -130%, 0);
}
</style>
