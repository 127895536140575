import { BILL_COMBINED } from '@/util/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  draft: {
    combinedExpenseBill: BILL_COMBINED.value,
    expenseBillCreateDay: 1,
    orderingMode: { id: 'manual', name: 'Manual' },
  },
  selectedProducts: {},
  bulkActionProducts: {},
  connections: {},
  products: {},
  venues: {},
  contacts: {},
  isAuthorized: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
