<template>
  <div id="app">
    <router-view />
    <flash-message />
    <toast-message />
    <VConfirmation />
  </div>
</template>

<script>
import { FlashMessage } from '@/components/ui/FlashMessage';
import { ToastMessage } from '@/components/ui/ToastMessage';
import VConfirmation from '@/components/v3/patterns/VConfirmation';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { falsy } from './util/utils';

export default {
  components: {
    FlashMessage,
    VConfirmation,
    ToastMessage,
  },
  data() {
    return {
      windowActive: true,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser', 'contextId']),
    ...mapGetters('defaultImages', ['getDefaultImages']),
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    isLoggedIn() {
      return Object.keys(this.loggedUser).length > 0;
    },
    venueGroup() {
      return this.loggedUser?.group;
    },
    distributor() {
      return this.loggedUser?.distributor;
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.intercomInit();
      } else {
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        });
      }
    },
    windowActive(val) {
      if (val) {
        const localContextId = +localStorage.getItem('contextId');
        if (+this.contextId === +localContextId) return;
        if (this.isVenue && localContextId) {
          const venue =
            this.loggedUser.venues.find(v => v.id === localContextId) || this.loggedUser.group;
          this.SWITCH_CONTEXT(venue);
          this.initVenue();
        }
      }
    },
  },
  methods: {
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('entities/users', ['initVenue']),
    ...mapMutations('entities/users', ['SWITCH_CONTEXT']),
    intercomInit() {
      const type = this.isDistributor ? 'distributor' : 'venueGroup';
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        email: this.loggedUser?.email,
        user_id: this.loggedUser?.id,
        name: this.loggedUser?.name,
        phone: this.loggedUser?.phone,
        avatar: {
          type: 'avatar',
          image_url: this[type]?.logo,
        },
        company: {
          company_id: this[type]?.id,
          name: this[type]?.name,
          plan: this[type]?.accountType,
        },
      });
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.intercomInit();
    } else {
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      });
    }
    window.addEventListener('focus', () => {
      this.windowActive = true;
    });
    window.addEventListener('blur', () => {
      this.windowActive = false;
    });
  },
  beforeMount() {
    if (this.isLoggedIn && falsy(this.getDefaultImages)) {
      this.fetchDefaultImages();
    }
  },
};
</script>
