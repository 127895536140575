import { Model } from '@vuex-orm/core';
import SpecialTier from '@/models/SpecialTier';
import Category from './Category';
import Distributor from './Distributor';

const defaultTierPricing = {
  discountType: 'price',
  tiers: [],
};

const applyDefaults = (model) => {
  model.defaultTierPricing = model.defaultTierPricing || defaultTierPricing;
  model.specialTierPricings = model.specialTierPricings || [];
  model.tiers = model.tiers || [];

  model.defaultTierPricing = {
    ...model.defaultTierPricing,
    tiers: model.defaultTierPricing.tiers.map(tier => ({
      ...tier,
      price: model.defaultTierPricing.discountType === 'price' ? tier.value : (1 - tier.value / 100) * model.price,
    })),
  };

  return model;
};

export default class Product extends Model {
  static entity = 'products';

  static fields() {
    return {
      id: this.attr(null),
      categoryId: this.attr(null),
      distributorId: this.attr(null),
      defaultTierPricing: this.attr(defaultTierPricing),
      description: this.attr(''),
      image: this.attr(''),
      importType: this.attr(''),
      isDefaultImage: this.attr(true),
      name: this.attr(null),
      price: this.attr(0),
      sku: this.attr(null),
      specialTierPricings: this.hasMany(SpecialTier, 'productId'),
      tiers: this.attr([]),
      distributor: this.hasOne(Distributor, 'id', 'distributorId'),
      category: this.hasOne(Category, 'id', 'categoryId'),
      quantity: this.attr(1),
      priceQuantity: this.attr(null),
      primaryWarehouseQuantity: this.attr(0),
      secondaryWarehouseQuantity: this.attr(0),
      available: this.attr(false),
      currency: this.attr(null),
      canBeDeleted: this.attr(false),
      isIncluded: this.attr(null),
      isPriceEdited: this.attr(false),
      xeroConfig: this.attr({}),
      xeroAccount: this.attr({}),
      marketPrice: this.attr(false),
      minimumQuantity: this.attr(null),
      orderingUnit: this.attr(null),
      priceUnit: this.attr(null),
      orderingUnitIncrement: this.attr(null),
      orderingUnitQuantity: this.attr(null),
      warehouse: this.attr(null),
      warehouses: this.attr([]),
      warehouseProducts: this.attr([]),
      locations: this.attr([]),
      parLevel: this.attr(0),
      parLevelReached: this.attr(false),
      expiresSoon: this.attr(false),
      inventory: this.attr(0),
      inventoryTracked: this.attr(false),
      quantityInvalid: this.attr(false),
      cogs: this.attr(null),
      customerInventory: this.attr({}),
      productGroup: this.attr({}),
      tags: this.attr([]),
      tax: this.attr(null),
      isTaxDefault: this.attr(false),
      deals: this.attr([]),
    };
  }

  static beforeCreate(model) {
    applyDefaults(model);
  }

  static beforeUpdate(model) {
    applyDefaults(model);
  }
}
