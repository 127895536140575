import i18n from '@/i18n';

const ProvinoSettings = () => import(/* webpackChunkName: "distributor-provino-settings" */ '@/views/platform/distributor/provino/Index.vue');
const ProvinoAccountInfo = () => import(/* webpackChunkName: "distributor-provino-settings" */ '@/views/platform/distributor/provino/AccountInfo.vue');


export default [
  // Settings Route
  {
    path: '/supplier/settings/integrations/provino',
    component: ProvinoSettings,
    children: [
      {
        path: 'account-info',
        name: 'distributor-provino-account',
        component: ProvinoAccountInfo,
        meta: {
          title: `${i18n.t('provino.name')} Account Info`,
        },
      },
    ],
  },
];
