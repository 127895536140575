var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'button',
    `button--${_vm.type}`,
    `button--${_vm.size}`,
    { 'button--block': _vm.isFullWidth },
    { 'button-align--center': _vm.isLoading },
    _vm.customClass,
  ],attrs:{"disabled":_vm.disabled || _vm.isLoading,"type":_vm.formType,"data-cy":_vm.dataCy},on:{"click":_vm.onClick}},[(_vm.isLoading)?[_c('ez-spinner',{staticClass:"position-absolute"})]:_vm._e(),_c('span',{class:{ 'opacity-0': _vm.isLoading }},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }