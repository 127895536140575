import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let integrationVenueDraft = {};

try {
  integrationVenueDraft = JSON.parse(localStorage.getItem('integrationVenueDraft') || '');
} catch (e) {
  // continue
}

export const initOfflineOrder = {
  distributor: {},
  date: null,
  products: [],
  selectedProducts: [],
  receipts: [],
  deliveryFee: null,
  amountAdjustment: null,
  taxAdjustment: null,
};

export const initOfflineOrderNewSupplier = {
  supplier: {},
  date: null,
  pendingUpdate: {},
  products: [],
  deliveryFee: 0,
  amountAdjustment: 0,
  taxAdjustment: 0,
};

const state = {
  me: {},
  venues: [],
  // Used inside the wizard
  draft: {
    id: -1,
    netsuite: {
      salesRepId: null,
      priceLevelId: null,
    },
    tags: [],
  },
  draftAdmin: {
    owner: {},
    finance: {},
    ordering: {},
  },
  settingsStep: {},
  /**
   * Single Venue view from Distributor side
   * @see views/platform/distributor/outlets/VenueSingle.vue
   */
  singleDistributorVenue: {},
  onboarding: {
    isNewFinance: false,
    isNewOrdering: false,
    finance: {},
    ordering: {},
  },
  publicOnboarding: {
    venue: {},
    owner: {},
    termsAndConditions: false,
    login: true,
  },
  publicToken: null,
  offlineOrder: { ...initOfflineOrder },
  offlineOrderNewSupplier: { ...initOfflineOrderNewSupplier },
  newVenue: {
    products: [],
  },
  sendOnboardingLinkData: {
    products: [],
    route: 'distributor-outlets',
  },
  newCustomerGroupLinkData: {
    route: 'distributor-outlets',
  },
  acceptAndSendOnboardingLink: {
    products: [],
  },
  integrationVenueDraft: integrationVenueDraft || {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
