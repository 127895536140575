import { LOADING_KEY } from '@/util/constants';
import httpService from '@/api/http';

/**
 * @since 3.14.0
 *
 * @param context
 * @param route
 * @param loadingKey
 *
 * This is a global action for downloading products CSV template
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function getTemplate(context, {
  route,
  loadingKey = LOADING_KEY.GET_CSV_TEMPLATE,
}) {
  return httpService.get(route, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
    },
  });
}
export default {
  getTemplate,
};
