import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9c521187-01ec-44ec-a32e-d8068e5331d1}
 * @param context
 * @param {{
 *  type: string;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
async function fetchTagsByType(context, {
  query,
  loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_TAGS,
} = {}) {
  const { data } = await httpService.get('/distributor/tags', {
    headers: { loadingKey },
    params: { ...query },
  });

  context.commit('SET_TAGS', data.data);

  return data;
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a57da4f3-f2a4-4260-9112-8b1abe2c7921}
 *
 * @param context
 * @param {string} name
 * @param {number} colorId
 * @param {string} type
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createTag(
  context,
  { name, colorId, type, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_TAG, formKey } = {},
) {
  return httpService.post(
    '/distributor/tags', { name, colorId, type },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f004f707-6529-4da0-8bb7-c0287098cc0a}
 *
 * @param context
 * @param {string} name
 * @param {number} colorId
 * @param {string} type
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateTag(
  context,
  { id, name, colorId, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_TAG, formKey } = {},
) {
  return httpService.put(
    `/distributor/tags/${id}`,
    { name, colorId },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2713620b-897d-49b2-90c9-8495c4bfee94}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteTag(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_TAG } = {},
) {
  return httpService.delete(
    `/distributor/tags/${id}`,
    { headers: { loadingKey } },
  );
}

/**
* @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f118fef7-275b-4bc2-902c-b0fde98f60d1}
* @param {string} loadingKey
*
* @return {Promise<AxiosResponse<any>>}
*/
async function fetchTagColors(context, { loadingKey = LOADING_KEY.FETCH_TAG_COLORS } = {}) {
  const { data } = await httpService.get('/utils/colors', {
    headers: { loadingKey },
  });

  context.commit('SET_COLORS', data.data);

  return data;
}

export default {
  fetchTagsByType,
  fetchTagColors,
  createTag,
  updateTag,
  deleteTag,
};
