import flash from '@/components/ui/FlashMessage';
import {
  ACCOUNT_TYPE_FREE,
  ACCOUNT_TYPE_LITE,
  ACCOUNT_TYPE_PREMIUM,
  CaseType,
  DATE_INPUT_FORMAT,
  isSafari,
  PRODUCT_VISIBILITY_HIDDEN,
  PRODUCT_VISIBILITY_VISIBLE,
  PRODUCT_TRACKED,
  PRODUCT_NOT_TRACKED,
  SCOPE,
  SUPPLIER_ROLE,
  TIME_INPUT_FORMAT,
  DEFAULT_FRACTION_DIGITS,
} from '@/util/constants';
import dayjs from 'dayjs';
import InfoRow from '@/components/ui/InfoPanelCustomData/InfoRow.model';
import { roundNumber } from '@/util/utilsFinCalculator';

const debounce = (fn, wait) => {
  let t;
  return function deb() {
    clearTimeout(t);
    // eslint-disable-next-line prefer-rest-params
    t = setTimeout(() => fn.apply(this, arguments), wait);
  };
};

function isPropertyAccessSafe(base, property) {
  let safe;

  try {
    safe = Boolean(base[property]);
  } catch (error) {
    safe = false;
  }

  return safe;
}

function isFunctionCallSafe(base, functionName, ...args) {
  let safe = true;

  try {
    base[functionName](...args);
  } catch (error) {
    safe = false;
  }

  return safe;
}

function isLocalStorageAccessSafe() {
  let safe;

  const TEST_KEY = 'isLocalStorageAccessSafe';
  const TEST_VALUE = 'true';

  safe = isPropertyAccessSafe(window, 'localStorage');
  if (!safe) return safe;

  safe = isFunctionCallSafe(window.localStorage, 'setItem', TEST_KEY, TEST_VALUE);

  if (safe) window.localStorage.removeItem(TEST_KEY);

  return safe;
}

function isLocalStorageAccessSafeFlash() {
  const safe = isLocalStorageAccessSafe();

  if (!safe) {
    flash.error({
      title: 'Internet Explorer Error',
      message: "It looks like you don't have access to local storage.",
    });
  }

  return safe;
}

function stringToPath(path) {
  // If the path isn't a string, return it
  if (typeof path !== 'string') return path;

  // Create new array
  const output = [];

  // Split to an array with dot notation
  path.split('.').forEach(item => {
    // Split to an array with bracket notation
    item.split(/\[([^}]+)\]/g).forEach(key => {
      // Push to the new array
      if (key.length > 0) {
        output.push(key);
      }
    });
  });

  return output;
}

/**
 * Return the object value for provided path.
 * @param object
 * @param {String|Array} path
 * @param fallback Default value if path not found
 * @returns {boolean|*}
 *
 * @example
 * const a = {
 *   b: {
 *     c: [
 *       {
 *         d: 'yey'
 *       }
 *     ]
 *   }
 * }
 * get(a, 'b.c[0].d)
 * //- 'yey'
 */
function get(object, path, fallback = false) {
  const propertyPath = stringToPath(path);

  let current = object;
  for (let i = 0; i < propertyPath.length; i++) {
    if (!current[propertyPath[i]]) return fallback;
    current = current[propertyPath[i]];
  }

  return current;
}

/**
 * Check if the `value` is an empty object, array, map or set
 * @param value
 * @return {boolean}
 * * @example
 *
 * falsy(null);
 * // => true
 *
 * falsy({});
 * // => true
 *
 * falsy(1);
 * // => true
 *
 * falsy([1, 2, 3]);
 * // => false
 *
 * falsy({ 'a': 1 });
 * // => false
 */
function falsy(value) {
  const mapTag = '[object Map]';
  const setTag = '[object Set]';

  if (value === null || value === undefined) return true;
  if (Array.isArray(value) || typeof value === 'string') return !value.length;
  const objType = value.toString();
  if (objType === mapTag || objType === setTag) return !value.size;

  // Check if object is empty
  // Object.key() is not used because it is O(n) time complexity.
  // eslint-disable-next-line no-restricted-syntax
  for (const key in value) {
    if (hasOwnProperty.call(value, key)) return false;
  }

  return true;
}

/**
 * Returns true if value is null or undefined
 *
 * @param {any} value
 *
 * @return {boolean}
 */
function isNullish(value) {
  return value === null || value === undefined;
}

function remapPath(path) {
  return path.replace('supplier', 'distributor').replace('buyer', 'venue');
}

/**
 * Given an object return its name by converting the construction to a string.
 * @param {Object} obj
 * @return {string}
 */
function className(obj) {
  const str = (obj.prototype ? obj.prototype.constructor : obj.constructor).toString();
  const cname = str.match(/function\s(\w*)/)?.[1] ?? obj?.constructor?.name ?? '';
  const output = ['', 'anonymous', 'Anonymous'].indexOf(cname) > -1 ? 'Function' : cname;
  return output.toLowerCase();
}

/**
 * Compare two dates by month. Remove the time and set the day to the first day of the month.
 * @param {Date} date1
 * @param {Date} date2
 * @return {boolean}
 */
function compareByMonth(date1, date2) {
  date1.setHours(0, 0, 0, 0);
  date1.setDate(1);
  date2.setHours(0, 0, 0, 0);
  date2.setDate(1);
  return date1.getTime() >= date2.getTime();
}

/**
 * Given a date, generate all months from this day till that date.
 * JavaScript uses milliseconds as the unit of measurement, whereas Unix Time is in seconds.
 * @param {number|Date} finalDate Timestamp needs to be provided in miliseconds.
 * @return {[]} Dates
 */
function generateMonthsToDate(finalDate) {
  let lastMonth = finalDate;
  if (finalDate instanceof Date) {
    lastMonth = finalDate.getTime();
  }

  const lastDate = new Date(lastMonth);
  const dates = [];
  const currentDate = new Date();
  let tmpDate;
  let tmpDateId;

  while (compareByMonth(currentDate, lastDate)) {
    tmpDate = new Date(currentDate);
    tmpDateId = tmpDate.toString();
    dates.push({
      id: tmpDateId,
      from: tmpDate.getTime(),
      to: new Date(tmpDate.getFullYear(), tmpDate.getMonth() + 1, 0).getTime(),
      name: `${tmpDate.toLocaleString('en-us', { month: 'long' })} ${tmpDate.getFullYear()}`,
    });
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return dates;
}

function formatErrorMessageFromRes(res) {
  const {
    data: {
      error: { fields },
    },
  } = res;
  let message = '';
  let errors = [];

  Object.keys(fields).forEach(key => {
    errors = fields[key];
    message += `${errors.join('\n')}\n`;
  });

  return message;
}

/**
 * Gets LoggedUser form localStorage
 *
 * @return {any}
 */
function getLoggedUser() {
  return JSON.parse(localStorage.getItem('loggedUser'));
}

/**
 * Gets contextId from localStorage
 *
 * @return {number}
 */
function getContextId() {
  return Number(localStorage.getItem('contextId'));
}

/**
 * Gets user{ distributor || venue || venue-group } from LoggedUser based on scope.
 * So it can come in handy when you need user in different contexts
 * because then it is different scope.
 *
 * @return {null|*}
 */
function getScopeUser() {
  const loggedUser = getLoggedUser();
  if (!loggedUser) return null;
  switch (loggedUser.scope) {
    case SCOPE.DISTRIBUTOR: {
      return loggedUser.distributor;
    }
    case SCOPE.VENUE: {
      const [venue] = loggedUser.venues;
      return venue;
    }
    case SCOPE.VENUE_GROUP: {
      const contextId = getContextId();
      const user = loggedUser.venues.find(v => v.id === contextId) ?? loggedUser.group;

      if (!user) {
        const contextIdErrorMsg = 'Venue contextId is not correctly set in local storage.';
        const venuesErrorMsg = 'Or venues in loggedUser.';
        throw new Error(`${contextIdErrorMsg}\n${venuesErrorMsg}`);
      }

      return user;
    }
    default: {
      return null;
    }
  }
}

/**
 * Returns currency for loggedUser.
 * Won't return currency for Admin, because Admin can't have one currency.
 *
 * @return {null}
 */
function getCurrency() {
  const { currency } = getScopeUser() || {};
  return currency || null;
}

/**
 * @returns {string|null} date format for loggedUser
 */
function getDateFormat() {
  const { dateFormat } = getScopeUser() || {};
  return dateFormat || null;
}

/**
 * @returns {string|null} time format for loggedUser
 */
function getTimeFormat() {
  const { timeFormat } = getScopeUser() || {};
  return timeFormat || null;
}

/**
 * @returns {string|null} date & time format for loggedUser
 */
function getDateTimeFormat() {
  const { dateTimeFormat } = getScopeUser() || {};
  return dateTimeFormat || null;
}

/**
 * Formats date.
 * @param {number} value
 * @param {string} format
 * @returns {string} dayjs formatted date
 */
function formatDate(value, format) {
  if (!value) return '';

  format = format || getDateFormat() || 'DD/MM/YYYY';

  const date = new Date(value);
  return dayjs(date).format(format);
}

/**
 * Formats date v2.
 * Remove casting to Date object as it is going to happen anyways in the dayjs constructor.
 * @param {number} value
 * @param {string} format
 * @returns {string} dayjs formatted date
 */
function formatDateRefactored(value, format) {
  if (!value) return '';

  format = format || getDateFormat() || 'DD/MM/YYYY';

  return dayjs(value).format(format);
}

/**
 * Formats time.
 * @param {number} value
 * @param {string} format
 * @returns {string} dayjs formatted date
 */
function formatTime(value, format) {
  if (!value) return '';

  format = format || getTimeFormat() || 'HH:mm';

  const date = new Date(value);
  return dayjs(date).format(format);
}

/**
 * Formats date & time.
 * @param {number} value
 * @param {string} format
 * @returns {string} dayjs formatted date
 */
function formatDateTime(value, format) {
  if (!value) return '';

  format = format || getDateTimeFormat() || 'DD/MM/YYYY HH:mm';

  const date = new Date(value);
  return dayjs(date).format(format);
}

/**
 * Formats value based on template in currency.
 * If there is no template it will just return value.
 * If a currency is not passed it will get currency for loggedUser,
 * but not for Admin, because Admin can't have one currency.
 *
 * @param {number} value
 * @param {Object} currency - main prop for this function in currency is template
 * @param {number} fractionDigits
 * @param {string} language
 *
 * @return {*|number}
 */
function formatPrice(
  value,
  currency = null,
  fractionDigits = DEFAULT_FRACTION_DIGITS,
  language = 'en-US',
) {
  let price = Number(value);
  price = Number.isFinite(price) ? price : 0;
  const prefix = price < 0 ? '-' : '';

  currency = currency || getCurrency();
  const { template, fractionDigits: fd } = currency || {};
  fractionDigits = fd ?? fractionDigits;

  language = language || navigator.language;
  const options = {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  };
  price = Math.abs(price).toLocaleString(language, options);

  const wildcard = '{value}';

  return `${prefix}${template ? template.replace(wildcard, price) : price}`;
}

function formatNumber(value) {
  return (Number.parseFloat(value) || 0).toLocaleString();
}

function formatPercentage(value, locale = 'en-US', options = {}) {
  const defaultOptions = {
    style: 'percent',
  };

  const price = (Number.parseFloat(value) || 0) / 100;

  return price.toLocaleString(locale, Object.assign(defaultOptions, options));
}

/**
 * Produces formatted string from address object.
 * @param {{ street: string; city: string; zipCode: string; country: string; }} obj address object
 * @param {Boolean} newFormat flag used to add new line after street address and swap places to ZIP code and city
 * @returns {string} formatted full address string
 */
function formatAddressFormObject(obj, newFormat = false) {
  if (!obj) {
    return '';
  }

  if (newFormat) {
    return [obj.street, `\n${obj.city}`, obj.zipCode, obj.state, obj.country]
      .filter(Boolean)
      .join(', ');
  }

  return [obj.street, obj.city, obj.zipCode, obj.state, obj.country].filter(Boolean).join(', ');
}

function formatContactInfoForVenue(venue) {
  if (!venue) {
    return [];
  }

  const placeholder = '';
  return [
    new InfoRow({
      infoList: [
        {
          title: 'Primary Contact Name',
          info: venue.primaryContactName || placeholder,
        },
        {
          title: 'Primary Contact Phone Number',
          info: venue.primaryContactPhone || placeholder,
        },
      ],
      hasDivider: true,
    }),
    new InfoRow({
      infoList: [
        {
          title: 'Delivery Address',
          info: formatAddressFormObject(venue.shippingAddress) || placeholder,
        },
      ],
    }),
    new InfoRow({
      infoList: [
        {
          title: 'Billing Address',
          info: formatAddressFormObject(venue.billingAddress) || placeholder,
        },
      ],
      hasDivider: true,
    }),
  ];
}

function formatContactInfoForOnboardingInfo(onboardingInfo) {
  if (!onboardingInfo) {
    return [];
  }

  const placeholder = '';
  const name = onboardingInfo.contactName || onboardingInfo.name;
  return [
    new InfoRow({
      infoList: [
        {
          title: 'Ordering Contact',
          info: name || placeholder,
        },
      ],
      hasDivider: true,
    }),
    new InfoRow({
      infoList: [
        {
          title: 'Email Address',
          info: onboardingInfo.email || placeholder,
        },
      ],
      hasDivider: true,
    }),
    new InfoRow({
      infoList: [
        {
          title: 'SMS',
          info: onboardingInfo.smsNumber || placeholder,
        },
        {
          title: 'WhatsApp',
          info: onboardingInfo.whatsAppNumber || placeholder,
        },
      ],
      hasDivider: true,
    }),
  ];
}

/**
 * Returns formatted time string based on Browser (is it Safari or not)
 *
 * @param {dayjs.Dayjs} dateObj
 *
 * @return {*|string}
 */
export function formatErrorTime(dateObj) {
  if (!dateObj) {
    return '';
  }

  const options = { hour: '2-digit', minute: '2-digit' };

  return isSafari
    ? dateObj.format(TIME_INPUT_FORMAT)
    : dateObj.toDate().toLocaleTimeString([], options);
}

/**
 * Returns formatted date string based on Browser (is it Safari or not).
 *
 * @param {dayjs.Dayjs} dateObj
 *
 * @return {*|string}
 */
export function formatErrorDate(dateObj) {
  if (!dateObj) {
    return '';
  }

  return isSafari ? dateObj.format(DATE_INPUT_FORMAT) : dateObj.toDate().toLocaleDateString();
}

export const capitalizeWord = word => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Capitalize every first letter of word in string.
 * (e.g.) check-in => Check-In
 * (e.g.) team activity => Team Activity
 *
 * @param {string} string
 * @param {string[]} separators -  list of characters that can be between words
 *
 * @return {string}
 */
export const capitalizeEveryWord = ({ string, separators = [' ', '-'] }) => {
  let capitalizedString = capitalizeWord(string);
  separators.forEach(separator => {
    if (string.includes(separator)) {
      capitalizedString = string.split(separator).map(capitalizeWord).join(separator);
    }
  });
  return capitalizedString;
};

/**
 * Format a number with thousands `k`.
 * @param value
 * @return {*}
 */
function formatK(value) {
  return Math.abs(value) >= 1000
    ? `${Math.sign(value) * (Math.abs(value) / 1000).toFixed(1)}k`
    : value;
}

function isPremium(accountType) {
  return accountType === ACCOUNT_TYPE_PREMIUM;
}

function isFree(accountType) {
  return accountType === ACCOUNT_TYPE_FREE;
}

function isLite(accountType) {
  return accountType === ACCOUNT_TYPE_LITE;
}

/**
 * Calculate the price when tax is removed.
 * @param price
 * @param tax
 * @return {number}
 */
function priceWithouTex(price, tax) {
  return (price / (100 + tax)) * 100;
}

/**
 * Add tax to price without tax.
 * @param price
 * @param tax
 * @return {number}
 */
function priceWithTax(price, tax) {
  return (price * (100 + tax)) / 100;
}

function clone(item) {
  return JSON.parse(JSON.stringify(item));
}

/**
 * Calculate end of the week based on when the week starts.
 * Dayjs doesn't let you get end of the week based on when it starts.
 * We can calculate this by subtracting 1 day getting end of the week and then adding 1 day.
 *
 * @param {Date|dayjs.Dayjs|number} date
 * @param {number} weekStartsOn
 * @return {dayjs.Dayjs}
 */
function endOfWeek(date, weekStartsOn) {
  if (weekStartsOn === 0) {
    return dayjs(date).endOf('w');
  }
  return dayjs(date).subtract(1, 'day').endOf('week').add(1, 'day');
}

function startOfWeek(date, weekStartsOn) {
  if (weekStartsOn === 0) {
    return dayjs(date).endOf('w');
  }
  return dayjs(date).subtract(1, 'day').startOf('week').add(1, 'day');
}

function isCorrectOrderEqual(start, date, end) {
  return (
    (dayjs(start).isBefore(date, 'd') || dayjs(start).isSame(date, 'd')) &&
    (dayjs(date).isBefore(end, 'd') || dayjs(date).isSame(end, 'd'))
  );
}

function isCorrectOrder(start, date, end) {
  return dayjs(start).isBefore(date, 'd') && dayjs(date).isBefore(end, 'd');
}

/**
 * Return a true typeof in JavaScript based on constructor name.
 * @param value
 * @return {string}
 */
function typeOf(value) {
  return Object.prototype.toString.call(value).split(' ')[1].slice(0, -1).toLowerCase();
}

function isArray(value) {
  return typeOf(value) === 'array';
}

function isString(value) {
  return typeOf(value) === 'string';
}

function isBoolean(value) {
  return typeof value === 'boolean';
}

function isObject(value) {
  return typeOf(value) === 'object';
}

function isArrayOf(array, type) {
  return isArray(array) ? array.every(a => typeOf(a) === type) : false;
}

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
function mergeDeep(target, source) {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

/**
 * Creates a new URL by combining the specified URLs
 *
 * @param {string} baseURL The base URL
 * @param {string} relativeURL The relative URL
 * @returns {string} The combined URL
 */
function combineURLs(baseURL, relativeURL) {
  return relativeURL
    ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}`
    : baseURL;
}

/**
 * List all available options for visibility change.
 * Based on given flag `isHidden` mark if the current option is selected.
 * Used for Status Dropdown component.
 *
 * @param {boolean} isHidden
 * @return {[]}
 */
function getVisibilityOptions(isHidden) {
  const options = [PRODUCT_VISIBILITY_VISIBLE, PRODUCT_VISIBILITY_HIDDEN];
  const currentVisibility = isHidden ? PRODUCT_VISIBILITY_HIDDEN : PRODUCT_VISIBILITY_VISIBLE;
  const data = [];
  options.forEach((option, i) => {
    data.push({
      id: i,
      name: option,
      selected: option === currentVisibility,
    });
  });
  return data;
}

/**
 * List all available options for product tracking.
 * Based on given flag `isTracked` mark if the current option is selected.
 * Used for Status Dropdown component.
 *
 * @param {boolean} isHidden
 * @return {[]}
 */
function getTrackingOptions(isTracked) {
  const options = [PRODUCT_NOT_TRACKED, PRODUCT_TRACKED];
  let currentVisibility = isTracked ? PRODUCT_TRACKED : PRODUCT_NOT_TRACKED;
  if (isTracked === null) currentVisibility = undefined;
  const data = [];
  options.forEach((option, i) => {
    data.push({
      id: i,
      name: option,
      selected: option === currentVisibility,
    });
  });
  return data;
}

async function findItemInPaginalList({ id, list, getNewListFn = () => [], page, filters }) {
  const account = list.find(a => a.id === id);

  if (!account) {
    try {
      list = await getNewListFn({ page, filters });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }

    if (list && !list.length) {
      return null;
    }

    page += 1;
    return findItemInPaginalList({
      id,
      list,
      getNewListFn,
      page,
      filters,
    });
  }

  return account;
}

/**
 * This function will separate different cases like (camelCase, kebab-case, snake_case) text
 * into Sentence Case, and can also capitalize words or leave them like they are
 *
 * @param {String} text
 * @param {Boolean} capitalize
 * @param {String} from
 *
 * @return {String}
 *
 */
function convertToSentenceCase(text = '', capitalize = true, from = CaseType.CAMEL_CASE) {
  let result = '';
  switch (from) {
    case CaseType.CAMEL_CASE:
      result = text.replace(/([A-Z])/g, ' $1');
      break;
    case CaseType.KEBAB_CASE:
      result = text.replace(/-/g, ' ');
      break;
    case CaseType.SNAKE_CASE:
      result = text.replace(/_/g, ' ');
      break;
    default:
      result = text.replace(/([A-Z])/g, ' $1');
  }
  if (capitalize) {
    return capitalizeEveryWord({ string: result });
  }
  return result;
}

/**
 * This function will make camelCase from Sentence Case
 *
 * @param {String} text
 *
 * @return {String}
 *
 */
function camalize(text) {
  return text.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

/**
 * Groups list by date dayjs format(month, year, day, month year, ...)
 * and returns dictionary that has objects with title & items.
 *
 * @param {Array} list
 * @param {String} dateKey
 * @param {String} dateFormat
 * @param {String} titleFormat
 *
 * @return {
 *   <key>: {
 *     title: {String},
 *     items: {Array}
 *   }
 * }
 *
 */
function groupListByDateFormat({ list, dateKey, dateFormat = 'MMMM YYYY', titleFormat }) {
  titleFormat = titleFormat || dateFormat;

  const groupedList = {};
  let date;
  let groupId;
  let title;

  list.forEach(item => {
    date = dayjs(new Date(item[dateKey]));
    title = item[dateKey] ? date.format(titleFormat) : 'No Date';
    groupId = date.format(dateFormat);

    if (groupedList[groupId]) {
      groupedList[groupId].items.push(item);
    } else {
      groupedList[groupId] = {
        title,
        items: [item],
      };
    }
  });

  return groupedList;
}

/**
 * Grouped list by passed segments, for example Array = [{
    key: 'pastDue', **(used for key in Object and for segment title)
    callback: item => item.isPastDue, **(used for filter condition)
  }],
 * and returns dictionary that has objects with title & items.
 *
 * @param {Array} list
 * @param {Array} segments
 * @param {Boolean} capitalized
 *
 * @return {
 *   <key>: {
 *     title: {String},
 *     items: {Array}
 *   }
 * }
 *
 */
function groupedListBySegments({ list, segments = [], capitalized = true }) {
  const groupedList = {};

  segments.forEach(seg => {
    groupedList[seg.key] = {
      items: list.filter(seg.callback),
      title: convertToSentenceCase(seg.key, capitalized),
    };
  });

  return groupedList;
}

function pluralize(word, amount) {
  return Number(amount) > 1 || Number(amount) === 0 ? `${word}s` : word;
}

/**
 * This function is used to set accountOwnerId in filter for Reports -> To-Dos tab
 *
 * @return {number|null}
 */
const getAccountOwnerIdForLoggedUser = () => {
  const strLoggedUser = localStorage.getItem('loggedUser');
  const loggedUser = JSON.parse(strLoggedUser);
  if (!loggedUser || !isObject(loggedUser)) {
    return null;
  }
  const { id, role } = loggedUser;
  return (role && role.key) === SUPPLIER_ROLE.MANAGER.key ? id : null;
};

/**
 * Handles errors for the router, so Sentry doesn't catch them.
 *
 * @param {Object} error
 */
function routerErrorHandler(error) {
  // eslint-disable-next-line no-console
  console.info(error);
}

/**
 * Returns true if product is declined.
 *
 * Product is Declined when user is in process of excluding products
 * and has not yet done some final action(eg. complete order, schedule order,...)
 *
 * @param {Object} product
 *
 * @return {boolean}
 */
function isDeclined(product) {
  return product.isDeclined;
}

/**
 * Returns true if product is excluded from order.
 *
 * Product becomes Excluded when is marked as Declined
 * and User dose some final action(eg. complete order, schedule order,...)
 *
 * @param {Object} product
 *
 * @return {boolean}
 */
function isExcluded(product) {
  /**
   * If isIncluded flag is null, product is Included but not Excluded.
   */
  return product.isIncluded === false;
}

/**
 * Returns true if product is included in order;
 *
 * @param {Object} product
 *
 *  @return {boolean}
 */
function isIncluded(product) {
  return product.isIncluded !== false;
}

/**
 * Return true if all products are marked as declined.
 *
 * @param products
 *
 * @return {boolean}
 */
function areAllProductsDeclined(products) {
  if (falsy(products)) {
    return false;
  }

  return products.every(isDeclined);
}

/**
 * Returns true if at least one product is marked as declined.
 *
 * @param products
 *
 * @return {boolean}
 */
function areSomeProductsDeclined(products) {
  if (falsy(products)) {
    return false;
  }

  return products.some(isDeclined);
}

/**
 * Returns true if there are some excluded products.
 *
 * @param { array } orderedProducts
 *
 * @return { boolean }
 */
function toShowPartiallyAcceptedTabs(orderedProducts) {
  const numberOfExcluded = orderedProducts?.filter(isExcluded).length;

  return numberOfExcluded > 0 && numberOfExcluded !== orderedProducts?.length;
}

/**
 * Venue can edit when he is Premium & Order is made by Freemium Supplier
 *
 * @param {Order} order
 * @param permission - this.$permission from component
 *
 * @return {boolean}
 */
function canVenueEditDeliveryFee({ order, permission }) {
  const {
    distributor: { accountType: distributorAccountType },
  } = order;
  return permission.isPremium && !isPremium(distributorAccountType);
}

/**
 * Return true if Delivery Fee should be updated.
 *
 * @param {number} amount
 * @param {number} oldAmount
 * @param {number} moa
 *
 */
function toUpdateDeliveryFee({ amount, oldAmount, moa }) {
  const isMOANullish = !moa; // not sure about this
  const didAmountWentOverMOA = oldAmount <= moa && amount >= moa;
  const didAmountWneUnderMOA = oldAmount > moa && amount < moa;

  if (isMOANullish) return false;
  return didAmountWentOverMOA || didAmountWneUnderMOA;
}

/**
 * Return true if provided value divided with provided increment
 * and rounded on provided decimals is Integer
 *
 * @param {number} val
 * @param {number} increment
 * @param {number} decimals
 *
 */
function isIntegerRoundedNumber(val, increment, decimals = 2) {
  return Number.isInteger(roundNumber({ number: val / increment, decimals }));
}

export {
  toUpdateDeliveryFee,
  canVenueEditDeliveryFee,
  toShowPartiallyAcceptedTabs,
  areAllProductsDeclined,
  areSomeProductsDeclined,
  isDeclined,
  isExcluded,
  isIncluded,
  routerErrorHandler,
  getLoggedUser,
  getContextId,
  getScopeUser,
  getCurrency,
  getDateFormat,
  getTimeFormat,
  getDateTimeFormat,
  formatDate,
  formatDateRefactored,
  formatTime,
  formatDateTime,
  getAccountOwnerIdForLoggedUser,
  groupListByDateFormat,
  findItemInPaginalList,
  formatErrorMessageFromRes,
  formatContactInfoForOnboardingInfo,
  formatContactInfoForVenue,
  formatAddressFormObject,
  get,
  clone,
  falsy,
  isNullish,
  typeOf,
  isArray,
  formatK,
  mergeDeep,
  isString,
  isArrayOf,
  isBoolean,
  isObject,
  isFree,
  isLite,
  debounce,
  remapPath,
  className,
  isPremium,
  endOfWeek,
  startOfWeek,
  combineURLs,
  isCorrectOrder,
  isCorrectOrderEqual,
  formatPrice,
  stringToPath,
  priceWithTax,
  priceWithouTex,
  formatPercentage,
  formatNumber,
  isFunctionCallSafe,
  isPropertyAccessSafe,
  generateMonthsToDate,
  isLocalStorageAccessSafe,
  isLocalStorageAccessSafeFlash,
  getVisibilityOptions,
  getTrackingOptions,
  pluralize,
  groupedListBySegments,
  convertToSentenceCase,
  camalize,
  isIntegerRoundedNumber,
};
