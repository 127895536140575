<script>
import { clone } from '@/util/utils';
import { EzImageModal, EzPdfModal } from '.';

/**
 * EzDrawerImageModal
 * @version 1.0.0
 * @since 3.17.0
 */

export default {
  name: 'EzDrawerImageModal',
  data() {
    return {
      invoice: '',
    };
  },
  components: {
    EzImageModal,
    EzPdfModal,
  },
  computed: {
    isPDF() {
      return this.invoice.type === 'pdf';
    },
    modal() {
      return this.isPDF ? EzPdfModal : EzImageModal;
    },
  },
  methods: {
    close() {
      this.$refs.drawerImageModal.close();
    },
    async open(invoice) {
      this.invoice = clone(invoice);
      await this.$nextTick();
      this.$refs.drawerImageModal.open(invoice.image);
    },
  },
};
</script>

<template>
  <component
    :is="modal"
    ref="drawerImageModal"
    class="drawer-image-modal"
    :image="isPDF ? invoice.image : ''"
  />
</template>

<style lang="scss" scoped>
.drawer-image-modal {
  @include z-index('drawer-image-modal');

  :deep() {
    right: 680px;

    .modal-backdrop {
      display: none;
    }

    .modal__image {
      max-width: 90%;

      img {
        max-width: 100%;
      }
    }
  }
}
</style>
