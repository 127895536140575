/* eslint-disable */
import { combineURLs } from '@/util/utils';
import { getAuthorizationUrl, getAuthorizationUrlDistributor } from '@/api/endpoints/quick-books';
import router from '@/router';

/**
 * Get QuickBooks authorization URL, append redirect URL and redirect user to QuickBooks.
 *
 * @param context
 * @param {string} routerName Name of the route where to redirect after authorization.
 * @param {string} state Venue ID
 * @return {Promise<void>}
 */
async function quickBooksAuthorize(context, { route, state }) {
  // Get URL from API
  const { data } = await getAuthorizationUrl({ venueId: state });
  // Redirect user to QuickBooks with params
  window.location.href = data.data;
}

/**
 * Get QuickBooks authorization URL, append redirect URL and redirect the user to QuickBooks.
 *
 * @param context
 * @param {string} routerName Name of the route where to redirect after authorization.
 * @return {Promise<void>}
 */
async function quickBooksAuthorizeDistributor(context, { route, state }) {
  // Get URL from API
  const { data } = await getAuthorizationUrlDistributor();
  // Redirect user to QuickBooks with params
  window.location.href = data.data;
}

export default {
  quickBooksAuthorize,
  quickBooksAuthorizeDistributor,
};
