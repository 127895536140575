import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';

import VTooltip from 'v-tooltip';

import categories from '@/store/modules/categories';
import distributors from '@/store/modules/distributors';
import errors from '@/store/modules/errors';
import user from '@/store/modules/user';
import venues from '@/store/modules/venues';
import products from '@/store/modules/products';
import cart from '@/store/modules/cart';
import orders from '@/store/modules/orders';
import creditNotes from '@/store/modules/creditNotes';
import groups from '@/store/modules/groups';
import roles from '@/store/modules/roles';
import purchasingLimits from '@/store/modules/purchasingLimits';
import defaultImages from '@/store/modules/defaultImages';
import connectedEntries from '@/store/modules/connectedEntries';
import reports from '@/store/modules/reports';
import xero from '@/store/modules/xero';
import loading from '@/store/modules/loading';
import tags from '@/store/modules/tags';

import Category from '@/models/Category';
import Distributor from '@/models/Distributor';
import Order from '@/models/Order';
import Product from '@/models/Product';
import SpecialTier from '@/models/SpecialTier';
import SpecialTierItem from '@/models/SpecialTierItem';
import Group from '@/models/Group';
import Venue from '@/models/Venue';
import User from '@/models/User';
import ConnectedEntries from '@/models/ConnectedEntries';
import netsuite from '@/store/modules/netsuite';


import plugins from './plugins';
import quickBooks from './modules/quickBooks';


Vue.use(Vuex);
Vue.use(VTooltip);

const database = new VuexORM.Database();

database.register(Category, categories);
database.register(Distributor, distributors);
database.register(Order, orders);
database.register(Product, products);
database.register(SpecialTier);
database.register(SpecialTierItem);
database.register(Group, groups);
database.register(Venue, venues);
database.register(User, user);
database.register(ConnectedEntries, connectedEntries);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    errors,
    cart,
    roles,
    purchasingLimits,
    defaultImages,
    reports,
    xero,
    quickBooks,
    netsuite,
    loading,
    creditNotes,
    tags,
  },

  plugins: [
    ...plugins,
    VuexORM.install(database),
  ],
});

export default store;

const initialState = {
  errors: {
    formErrors: {},
  },
  cart: {
    cart: {},
    info: {},
  },
  roles: {},
  purchasingLimits: {
    purchasingLimits: [],
  },
  defaultImages: {
    defaultImages: {},
  },
  connectedEntries: [],
  loading: {
    loading: {},
  },
};

const initialStateCopy = JSON.parse(JSON.stringify({ ...store.state, ...initialState }));

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
