import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * Return the authorization URL for Xero login.
 * Note that before the user is redirected to this URL a redirect query param should be added.
 * So, Xero knows where to return the user.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0e638d2e-9c24-465f-b925-a3dec8aeb861}
 * @return {Promise<AxiosResponse<any>>}
 */
function getAuthorizationUrl() {
  return httpService.get('/xero/authorize-url');
}

function distributorAuthorize(params) {
  const url = `/distributor/xero/authorize${params}`;
  const urlLocal = `/distributor/xero/authorize${params}&callback=http://localhost:8080/supplier/xero/setup`;
  const returnToLocal = window.location.hostname === 'localhost';
  return httpService.post(returnToLocal ? urlLocal : url);
}

function venueAuthorize(id, params) {
  const url = `/venues/${id}/xero/authorize${params}`;
  const urlLocal = `/venues/${id}/xero/authorize${params}&callback=http://localhost:8080/buyers/xero/setup`;
  const returnToLocal = window.location.hostname === 'localhost';
  return httpService.post(returnToLocal ? urlLocal : url);
}

/**
 * Disconnect the distributor from Xero.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#48b51d27-7e83-4787-beeb-01f56f323847}
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDisconnectXero() {
  return httpService.delete('/distributor/xero');
}

/**
 * Return list of products from Xero and OrderEZ to be merged.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b426d371-6712-4ca7-8ad9-d9e0aa1c55c4}
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetXeroProducts() {
  return httpService.get('/distributor/xero/items');
}

/**
 * Updates products based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4f2951fa-21a9-4b83-ac0e-340e8bb249e5}
 *
 * @param {items: Array, config: Object} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateXeroProducts({
  body,
  loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_XERO_PRODUCTS,
}) {
  return httpService.put('/distributor/xero/items', body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Return list of venues from Xero and OrderEZ to be merged.
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#72427fa4-f9d0-4cdf-892b-7af495d8086c}
 *
 * @param {Object} query search query params
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetXeroAccounts(query = {}) {
  return httpService.get('/distributor/xero/accounts', {
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9eb0c13e-c659-4623-8c4f-e73d92236a04}
 *
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorGetXeroAccountTypes(
  loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_XERO_ACCOUNT_TYPES,
) {
  return httpService.get('/distributor/xero/accounts/types', {
    headers: { loadingKey },
  });
}

/**
 * Return list of distributor tax rates from Xero and OrderEZ to be merged.
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dd85274e-92be-44e6-843e-df2baf4aeb42}
 *
 * @param {Object} query search query params
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetXeroTaxes(query = {}) {
  return httpService.get('/distributor/xero/taxes', {
    params: { ...query },
  });
}

/**
 * Return preselected account and tax rate of distributor Xero settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f202cc06-1393-4a32-baf9-c32ac355e57b}
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetXeroAccountSetup() {
  return httpService.get('/distributor/xero/setup');
}

/**
 * Fetch supplier's Xero connected venues.
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dd93152a-2a19-47e5-a641-3c2556f2b32c}
 *
 * @param {Object} query
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorGetConnectedVenues(
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_XERO_VENUES } = { query: {} },
) {
  return httpService.get('/distributor/xero/venues', {
    headers: { loadingKey },
    params: {
      ...query,
      ...(query.operative ? { operative: true } : {}),
    },
  });
}

/**
 * Fetch supplier's Xero contacts.
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6f9d156f-43eb-421f-bd36-0f17703af002}
 *
 * @param {string} term
 * @param {string|number} limit
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorGetContacts(
  { term, limit, loadingKey } = {
    limit: 10,
    loadingKey: LOADING_KEY.DISTRIBUTOR_FETCH_XERO_CONTACTS,
  },
) {
  return httpService.get('/distributor/xero/contacts', {
    headers: { loadingKey },
    params: { term, limit },
  });
}

/**
 * Create a Xero contact connection
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f10ad5a3-839e-49bd-a9ca-13e667d1eb84}
 * @param {string|number} data.contactId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorXeroConnectContact(data) {
  return httpService.post('/distributor/xero/contacts', data);
}

/**
 * Post account setup.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#07e2d2a7-e291-4880-b343-a484e95e4f68}
 *
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorPostAccountSetup(data) {
  return httpService.post('/distributor/xero/setup', data);
}

/**
 * Put Synced items.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e9dbe863-3c43-4a5c-a291-0b38d08425b2}
 *
 * @param {Array} data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorPutSyncItems(data) {
  return httpService.put('/distributor/xero/items/sync', { skipItems: data });
}

/**
 * Put Venue Connections.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#105907e9-190c-4c27-9f3b-ea92aedbbf98}
 *
 * @param {Array} data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorPutConnections(data, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_CONNECTIONS) {
  return httpService.put(
    '/distributor/xero/connections',
    { connections: data },
    { headers: { loadingKey } },
  );
}

/**
 * Distributor Get Synced Items
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#64f25659-1cd0-49c1-9a10-3e8c4e8a030b}
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetSyncedItems({ loadingKey = LOADING_KEY.DISTRIBUTOR_GET_SYNCED_ITEMS } = {}) {
  return httpService.get('/distributor/xero/items/synced', {
    headers: { loadingKey },
  });
}

/**
 * Gets suppliers products.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2c4531af-8280-40ea-9f88-30865e7228e7}
 *
 * @param {number} limit
 * @param {number} nextId
 * @param {string} sortBy
 * @param {string} term
 * @param {boolean} operative
 * @param {number} categoryId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetProducts({
  limit = 10,
  nextId,
  sortBy,
  term,
  operative,
  categoryId,
  loadingKey = LOADING_KEY.DISTRIBUTOR_GET_PRODUCTS,
}) {
  return httpService.get('/distributor/xero/products', {
    params: {
      limit,
      nextId,
      sortBy,
      term,
      ...(operative ? { operative: true } : {}),
      categoryId,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets suppliers product groups.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d089cf79-5413-4b86-aca6-bc1c0eaeb540}
 *
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetProductGroups({
  limit = 10,
  nextId,
  nextValue,
  term,
  loadingKey = LOADING_KEY.DISTRIBUTOR_GET_PRODUCT_GROUPS,
}) {
  return httpService.get('/distributor/xero/product-groups', {
    params: {
      limit,
      nextId,
      nextValue,
      term,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates product groups based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5a0f2dbd-24ef-48ce-bb41-0e2c305e239c}
 *
 * @param {items: Array, config: Object, state: String} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateXeroProductGroups({
  body,
  loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_XERO_PRODUCT_GROUPS,
}) {
  return httpService.put('/distributor/xero/product-groups', body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets supplier's customer categories.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1f50e1ab-96a1-4e7d-a644-be2e69cc4ed2}
 *
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetCustomerCategories({
  limit = 10,
  nextId,
  nextValue,
  term,
  loadingKey = LOADING_KEY.DISTRIBUTOR_GET_CUSTOMER_CATEGORIES,
}) {
  return httpService.get('/distributor/xero/customer-categories', {
    params: {
      limit,
      nextId,
      nextValue,
      term,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates customer categories based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4c0db136-86f5-4a07-ad3b-386a8b9a2a45}
 *
 * @param {items: Array, config: Object, state: String} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateXeroCustomerCategories({
  body,
  loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_XERO_CUSTOMER_CATEGORIES,
}) {
  return httpService.put('/distributor/xero/customer-categories', body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Distributor Create Invoice
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8a1b5ea1-7181-496e-90ae-b905ad50a091}
 * @param {string|number} id - Order ID
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCreateInvoice(id) {
  return httpService.put(`/distributor/xero/invoice/${id}`);
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#16c18a64-d96a-45be-a31a-6989a7a8e16b}
 * @param {string|number} id
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorCreateBill(id, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_XERO_BILL) {
  return httpService.put(
    `/distributor/xero/orders/${id}/sync`,
    {},
    {
      headers: { loadingKey },
    },
  );
}

/**
 * Create a Xero contact connection
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3058b606-b29e-4b06-b832-00fd494f0288}
 * @param {string|number} data.contactId
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueXeroConnectContact(id, data, loadingKey = LOADING_KEY.VENUE_ADD_SUPPLIER) {
  return httpService.post(`/venues/${id}/xero/contacts`, data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Disconnect the venue from Xero.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c3b2d0a0-b2b6-41b0-bfd6-989cd6c3c368}
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDisconnectXero(id) {
  return httpService.delete(`/venues/${id}/xero`);
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#45a54d39-a5b6-4678-8fe0-5fe2fbfe83d6 }
 * @param {string|number} id - Venue ID
 * @param {Boolean} withDefault
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetXeroAccounts(id, withDefault = false) {
  return httpService.get(`/venues/${id}/xero/accounts?withDefault=${withDefault}`);
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8ff811ba-e3c6-4d17-8b28-8201f7f8b549}
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetConnectedDistributors(id, term, operative) {
  return httpService.get(`/venues/${id}/xero/connected-distributors`, {
    params: {
      term,
      ...(operative ? { operative: true } : {}),
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cfecde5d-16a2-4108-9c1a-9a9552727cde}
 * @param {string|number} id - Venue ID
 * @param {string} term - Search term
 * @param {number=10} limit - Number of results
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetContacts(id, { term, limit } = { limit: 10 }) {
  return httpService.get(`/venues/${id}/xero/contacts`, {
    params: {
      term,
      limit,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#990c6205-3e80-44c9-8102-3bcbb15bdd1f}
 * @param {string|number} id - Venue ID
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueXeroSetupAccount(id, data) {
  return httpService.post(`venues/${id}/xero/setup`, data);
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#968bed15-257c-469b-af9c-9a264cc7218a}
 * @param {string|number} id - Venue ID
 * @param {Object} data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueXeroAddConnections(id, data) {
  return httpService.put(`/venues/${id}/xero/connections`, data);
}

/**
 * Create Bill manually
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0b747c63-d820-44b5-9a46-365a5cbf7129}
 * @param {string|number} id - Venue ID
 * @param {string|number} orderId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueXeroCreateBill(id, orderId) {
  return httpService.put(
    `/venues/${id}/xero/bill/${orderId}`,
    {},
    {
      headers: {
        loadingKey: LOADING_KEY.XERO_CREATE_BILL,
      },
    },
  );
}

/**
 * Return preselected tax rate of venue Xero settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#70e1010f-dc09-4708-8f45-e8e19ccc0679}
 * @param {string|number} id - Venue ID
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetXeroAccountSetup(id) {
  return httpService.get(`/venues/${id}/xero/setup`);
}

/**
 * Return taxes for venue Xero settings.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dd85274e-92be-44e6-843e-df2baf4aeb42}
 * @param {string|number} id - Venue ID
 * @param {Object} query - Query params
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetXeroTaxesSetup(id, query = {}) {
  return httpService.get(`/venues/${id}/xero/taxes`, {
    params: { ...query },
  });
}

/**
 * Return tracking categories.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1c946d5a-aeee-43e7-8f0b-7a7b4c417a58}
 * @return {Promise<AxiosResponse<any>>}
 */
function supplierFetchTrackingCategories() {
  return httpService.get('/distributor/xero/tracking-categories');
}

/**
 * Update venues
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#105907e9-190c-4c27-9f3b-ea92aedbbf98}
 * @param {Object} data
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorXeroConnections(data, loadingKey = LOADING_KEY.SUPPLIER_UPDATE_XERO_OUTLETS) {
  return httpService.put('/distributor/xero/connections', data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Return tracking categories.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2f9d4b37-ade4-411c-b087-dfd2b34bfbdd}
 *
 * @param {Number|String} id
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchTrackingCategories(id) {
  return httpService.get(`/venues/${id}/xero/tracking-categories`);
}

/**
 * Gets outlets product groups.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#58c05912-dcee-4afc-9b99-9459166e8031}
 *
 * @param {Number|String} id
 * @param {number} limit
 * @param {number} nextId
 * @param {string} nextValue
 * @param {string} term
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetProductGroups({
  id,
  limit = 10,
  nextId,
  nextValue,
  term,
  loadingKey = LOADING_KEY.VENUE_GET_PRODUCT_GROUPS,
}) {
  return httpService.get(`/venues/${id}/xero/product-groups`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates product groups based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9e8b05d0-1968-4a6c-9205-787941d70509}
 *
 * @param {items: Array, config: Object, state: String} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateXeroProductGroups({
  id,
  body,
  loadingKey = LOADING_KEY.VENUE_UPDATE_XERO_PRODUCT_GROUPS,
}) {
  return httpService.put(`/venues/${id}/xero/product-groups`, body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Update suppliers
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#968bed15-257c-469b-af9c-9a264cc7218a}
 *
 * @param {Number|String} id
 * @param {Object} data
 * @param {String} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueXeroConnections(id, data, loadingKey = LOADING_KEY.VENUE_UPDATE_XERO_SUPPLIERS) {
  return httpService.put(`venues/${id}/xero/connections`, data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets outlet products.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#964bd994-f06e-4ba6-92fb-e326c167eecb}
 *
 * @param {Number|String} id
 * @param {number} limit
 * @param {number} nextId
 * @param {string} sortBy
 * @param {string} term
 * @param {boolean} operative
 * @param {number} categoryId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetProducts({
  id,
  limit = 10,
  nextId,
  sortBy,
  term,
  operative,
  categoryId,
  loadingKey = LOADING_KEY.VENUE_GET_XERO_PRODUCTS,
}) {
  return httpService.get(`/venues/${id}/xero/products`, {
    params: {
      limit,
      nextId,
      sortBy,
      term,
      ...(operative ? { operative: true } : {}),
      categoryId,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates venue products based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d5e6beaa-ae44-44ca-8511-c20d8ca818e0}
 *
 * @param {Number|String} id
 * @param {items: Array, config: Object} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateXeroProducts({
  id,
  body,
  loadingKey = LOADING_KEY.VENUE_UPDATE_XERO_PRODUCTS,
}) {
  return httpService.put(`/venues/${id}/xero/items`, body, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets outlets product groups.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8b1f8bbf-3b2e-40a7-8af3-440b27579ee4}
 *
 * @param {Number|String} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueGetTaxRateConfig({ id, loadingKey = LOADING_KEY.VENUE_GET_PRODUCT_GROUPS }) {
  return httpService.get(`/venues/${id}/xero/tax-rate-config`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * Updates product groups based on config.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#da447cb1-3894-4d52-a0cf-456007c2cee8}
 *
 * @param {taxRateConfig: Array} body
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateTaxRateConfig({
  id,
  body,
  loadingKey = LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCT_GROUPS,
}) {
  return httpService.put(`/venues/${id}/xero/tax-rate-config`, body, {
    headers: {
      loadingKey,
    },
  });
}

export {
  getAuthorizationUrl,
  distributorAuthorize,
  distributorDisconnectXero,
  distributorGetXeroProducts,
  distributorUpdateXeroProducts,
  distributorGetXeroAccountSetup,
  distributorGetXeroAccounts,
  distributorGetXeroAccountTypes,
  distributorGetXeroTaxes,
  distributorGetConnectedVenues,
  distributorGetContacts,
  distributorPostAccountSetup,
  distributorPutSyncItems,
  distributorPutConnections,
  distributorGetSyncedItems,
  distributorXeroConnectContact,
  distributorCreateInvoice,
  distributorCreateBill,
  supplierFetchTrackingCategories,
  distributorGetProducts,
  distributorGetProductGroups,
  distributorUpdateXeroProductGroups,
  distributorGetCustomerCategories,
  distributorUpdateXeroCustomerCategories,
  distributorXeroConnections,
  // Venue
  venueAuthorize,
  venueGetXeroAccounts,
  venueGetConnectedDistributors,
  venueGetContacts,
  venueXeroSetupAccount,
  venueXeroAddConnections,
  venueXeroCreateBill,
  venueGetXeroAccountSetup,
  venueDisconnectXero,
  venueXeroConnectContact,
  venueGetXeroTaxesSetup,
  venueFetchTrackingCategories,
  venueXeroConnections,
  venueGetProducts,
  venueUpdateXeroProducts,
  venueGetProductGroups,
  venueUpdateXeroProductGroups,
  venueGetTaxRateConfig,
  venueUpdateTaxRateConfig,
};
