import { Model } from '@vuex-orm/core';
import Venue from '@/models/Venue';
import User from '@/models/User';

export default class Group extends Model {
  static entity = 'groups';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      invitedAt: this.attr(null),
      registeredAt: this.attr(null),
      logo: this.attr(null),
      isDefaultImage: this.attr(true),
      status: this.attr(''),
      users: this.hasMany(User, 'groupId'),
      venues: this.hasMany(Venue, 'groupId'),
      accountType: this.attr(''),
      currencyChangeEnabled: this.attr(false),
      priceChangeNotificationPercent: this.attr(null),
      shouldUpdateReferencePrice: this.attr(false),
      groupOverride: this.attr([]),
      currency: this.attr(null),
      shouldMirrorVenueToGroup: this.attr(false),
      dateFormat: this.attr(''),
      supportedDateFormats: this.attr([]),
    };
  }
}
