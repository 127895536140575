<template>
  <div class="ez-info-panel">
    <div v-if="hasPrimaryContact" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Primary Contact Name</small>
        <span :data-cy="dataCyAttr('TEXT__PRIMARY_CONTACT_NAME')">
          {{ data.primaryContactName }}
        </span>
      </div>
      <div class="ez-info-panel__item">
        <small>Primary Contact Phone Number</small>
        <span :data-cy="dataCyAttr('TEXT__PRIMARY_CONTACT_PHONE')">
          {{ data.primaryContactPhone }}
        </span>
      </div>
    </div>
    <div v-if="hasAddress" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Address</small>
        <span :data-cy="dataCyAttr('TEXT__ADDRESS')">
          {{ address }}
        </span>
      </div>
    </div>

    <div v-if="hasLegalName" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Legal Name</small>
        <span>
          {{ data.legalName }}
        </span>
      </div>
    </div>

    <div v-if="hasCompanyRegistrationNumber" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Company Registration Number / Tax ID</small>
        <span>
          {{ data.companyRegistrationNumber }}
        </span>
      </div>
    </div>

    <div v-if="hasCustomInfo" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Additional Info</small>
        <span :data-cy="dataCyAttr('TEXT__CUSTOM_INFO')">
          {{ data.customInfo }}
        </span>
      </div>
    </div>

    <div v-if="hasEmail" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Email</small>
        <span>
          <a :href="`mailto:${email}`" :data-cy="dataCyAttr('TEXT__EMAIL')">{{ email }}</a>
        </span>
      </div>
    </div>

    <div v-if="hasMinimumOrderAmount" class="ez-info-panel__contact">
      <div v-if="hasMinimumOrderAmount" class="ez-info-panel__item">
        <small>Minimum Order Amount</small>
        <span :data-cy="dataCyAttr('TEXT__MINIMUM_ORDER_AMOUNT')">
          {{ minimumOrderAmount | price }}
        </span>
      </div>
      <div v-if="hasMinimumOrderAmount" class="ez-info-panel__item">
        <small>Delivery Fee</small>
        <span :data-cy="dataCyAttr('TEXT__DELIVERY_FEE')">
          {{ deliveryFee | price }}
        </span>
      </div>
    </div>

    <div v-if="hasWorkingHours || data.deliveryPeriodCount" class="ez-info-panel__contact">
      <div v-if="hasWorkingHours" class="ez-info-panel__item">
        <small :data-cy="dataCyAttr('TEXT__WORKING_HOURS_LABEL')">
          {{ workingHoursLabel }}
        </small>
        <span :data-cy="dataCyAttr('TEXT__WORKING_HOURS')">
          {{ workingHours }}
        </span>
      </div>
      <div v-if="data.deliveryPeriodCount" class="ez-info-panel__item">
        <small>Will Be Delivered In</small>
        <span :data-cy="dataCyAttr('TEXT__DELIVERY_PERIOD')">
          {{ data.deliveryPeriodCount }} {{ deliveryPeriodCopy }}
        </span>
      </div>
    </div>

    <div v-if="hasShippingAddress" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Delivery Address</small>
        <span :data-cy="dataCyAttr('TEXT__SHIPPING_ADDRESS')">
          {{ shippingAddress }}
        </span>
      </div>
    </div>
    <div v-if="hasBillingAddress" class="ez-info-panel__contact">
      <div class="ez-info-panel__item">
        <small>Billing Address</small>
        <span :data-cy="dataCyAttr('TEXT__BILLING_ADDRESS')">
          {{ billingAddress }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Display info about the distributor or venue.
 * @version 1.0.0
 * @since
 */

import Venue from '@/models/Venue';
import { formatAddressFormObject, pluralize } from '@/util/utils';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    dataCy: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isVenue() {
      return this.$helpers.className(this.data) === this.$helpers.className(Venue);
    },
    isDistributor() {
      return !this.isVenue;
    },
    workingHoursLabel() {
      return this.isVenue ? 'Delivery time' : 'Orders Received By';
    },
    address() {
      const { address } = this.data;
      return formatAddressFormObject(address, true);
    },
    shippingAddress() {
      const { shippingAddress } = this.data;
      return formatAddressFormObject(shippingAddress, true);
    },
    billingAddress() {
      const { billingAddress } = this.data;
      return formatAddressFormObject(billingAddress, true);
    },
    email() {
      return this.data.email;
    },
    workingHours() {
      const { to } = this.data.workingHours || {};

      if (!to) return false;
      return to;
    },
    minimumOrderAmount() {
      return this.data.minimumOrderAmount;
    },
    deliveryFee() {
      return this.data.deliveryFee;
    },
    hasPrimaryContact() {
      return this.data.primaryContactName || this.data.primaryContactPhone;
    },
    hasSecondaryContact() {
      return this.data.secondaryContactName || this.data.secondaryContactPhone;
    },
    hasAddress() {
      return !!this.address;
    },
    hasCustomInfo() {
      return !!this.data.customInfo;
    },
    hasLegalName() {
      return !!this.data.legalName;
    },
    hasCompanyRegistrationNumber() {
      return !!this.data.companyRegistrationNumber;
    },
    hasShippingAddress() {
      return !!this.shippingAddress;
    },
    hasBillingAddress() {
      return !!this.billingAddress;
    },
    hasEmail() {
      return !!this.email;
    },
    hasWorkingHours() {
      return !!this.workingHours;
    },
    hasMinimumOrderAmount() {
      return !!this.minimumOrderAmount && this.deliveryFee > 0;
    },
    deliveryPeriodCopy() {
      return `Delivery ${pluralize('Day', this.data.deliveryPeriodCount)}`;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style lang="scss" scoped>
.ez-info-panel {
  &__title {
    @extend %flex-center;
    margin-bottom: 1.25rem;
    h3 {
      @include font-size(20px);
      font-weight: 500;
      margin: 0;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    small {
      @extend %gray-label;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    span {
      @include font-size(14px);
      white-space: pre-line;
    }
    a {
      color: #4d7cfe;
    }
  }
  &__contact {
    display: flex;
    margin-top: 1rem;

    &:not(:last-child) {
      border-bottom: 1px dashed #dee1e4;
      padding-bottom: 1rem;
    }
  }
  &__close-button {
    margin-left: auto;
    cursor: pointer;
    svg {
      fill: $color-gray-6C;
    }
  }
}
</style>
