import httpService from '@/api/http';
import { CHECK_IN_EXPENSE, CHECK_IN_TEAM_ACTIVITY, LOADING_KEY } from '@/util/constants';

/**
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#245ef17d-eb2d-4de8-bc22-097227a41eed}
 *
 * @param context
 * @param {string} from - Date string in YYYY-MM-DD format.
 * @param {string} to - Date string in YYYY-MM-DD format.
 * @param {number|string} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueReport(
  context,
  {
    from,
    to,
    id,
    loadingKey = LOADING_KEY.FETCH_VENUE_REPORT,
  },
) {
  return httpService.get(
    `reports/venues?from=${from}&to=${to}${id ? `&productId=${id}` : ''}`,
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 *
 * @see {https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e1f606a2-3cd4-4adb-a8a7-c26fa1f8fb6d}
 *
 * List all venues that are used in team activity (deleted and target accounts included)
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenuesFormAccountActivity() {
  return httpService.get('/distributor/expenses/venues');
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a357ade7-d348-4382-a9ee-0cb3386876ce}
 *
 * @param context
 * @param {number} id
 * @param {string} from
 * @param {string} to
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchSingleVenueReport(context, {
  id,
  from,
  to,
  loadingKey = LOADING_KEY.FETCH_SINGLE_VENUE_REPORT,
}) {
  return httpService.get(`reports/venues/${id}`, {
    params: {
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#86272346-c943-4da8-9b52-ed9ac0c1f85c}
 *
 * @param context
 * @param {number} venueId
 * @param {string} from
 * @param {string} to
 * @param {string} sort
 * @param {number} nextId
 * @param {number} nextValue
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchMostOrderedItems(context, {
  venueId,
  from,
  to,
  sort = 'DESC',
  nextId = null,
  nextValue = null,
  loadingKey = LOADING_KEY.FETCH_MOST_ORDERED_ITEMS,
}) {
  return httpService.get(`reports/venues/${venueId}/items`, {
    params: {
      from,
      to,
      sort,
      nextId,
      nextValue,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @formsee {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#bf42592c-ef80-42a9-af59-50a0a7ceb1f3}
 *
 * @param context
 * @param {string} from
 * @param {string} to
 * @param {number} venueId
 * @param {string} type
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductReport(context,
  {
    from,
    to,
    venueId,
    type,
    loadingKey = LOADING_KEY.FETCH_PRODUCT_REPORT,
  }) {
  return httpService.get('reports/products', {
    params: {
      from,
      to,
      venueId,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90d02ee3-b98b-4a76-839c-f2731a1a4e12}
 *
 * @param context
 * @param {number} id
 * @param {string} from
 * @param {string} to
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchSingleProductReport(context, {
  id,
  from,
  to,
  loadingKey = LOADING_KEY.FETCH_SINGLE_PRODUCT_REPORT,
}) {
  return httpService.get(`reports/products/${id}`, {
    params: {
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9aeb7c8c-4d45-4716-8a34-f3b4f2ed2ba8}
 *
 * @param context
 * @param {number} productId
 * @param {string} from
 * @param {string} to
 * @param {string} sortBy
 * @param {number} nextId
 * @param {number} nextValue
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchMostOrderedBy(context, {
  productId,
  from,
  to,
  sortBy,
  nextId,
  nextValue,
  loadingKey = LOADING_KEY.FETCH_MOST_ORDERED_BY,
}) {
  return httpService.get(`reports/products/${productId}/venues`, {
    params: {
      from,
      to,
      sortBy,
      nextId,
      nextValue,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a1085865-56eb-457b-a290-7c3ba2fd2dc5}

 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchReportProductsSearch(context, {
  loadingKey = LOADING_KEY.FETCH_REPORT_PRODUCTS_SEARCH } = {}) {
  return httpService.get('/distributor/search/products', {
    headers: {
      loadingKey,
    },
  });
}

// Account activity

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#772fa679-8674-48c4-afbf-6406ed556b6b}
 *
 * This action is used for:
 *   - Opened
 *   - Non ordering
 *   - Ordering
 * single view.
 *
 * @param context
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenue(context, {
  venueId,
  loadingKey = LOADING_KEY.FETCH_VENUE,
}) {
  return httpService.get(`/distributor/venues/${venueId}`, {
    headers: {
      loadingKey,
    },
  });
}

// Target accounts

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1f6d068a-116f-4f23-a71d-b08c3b23e81d}
 *
 * @param context
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 * @param {Object} filters
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchTargetAccountList(context, {
  filters,
  limit,
  page,
  loadingKey = LOADING_KEY.FETCH_TARGET_ACCOUNT_LIST,
}) {
  return httpService.get('/account-activity/target', {
    params: {
      ...filters,
      limit,
      page,
    },
    headers: {
      loadingKey,
    },
  });
}

// Pending accounts

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#749bee92-b7dd-48c5-b39a-8ffedbc87fab}
 *
 * @param {Object} filters
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPendingAccountList(context, {
  filters,
  limit,
  page,
  loadingKey = LOADING_KEY.FETCH_PENDING_ACCOUNT_LIST,
}) {
  return httpService.get('/account-activity/pending', {
    params: {
      ...filters,
      limit,
      page,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#21e8d474-ee34-4dbf-9d1c-0cc438f0cc03}
 *
 * @param context
 * @param {number} invitationId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPendingAccountSingle(context, {
  invitationId,
  loadingKey = LOADING_KEY.FETCH_PENDING_ACCOUNT_SINGLE,
}) {
  return httpService.get(`/distributor/invitations/${invitationId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dcd77c19-de7a-440e-8908-7d88dfab804c}
 *
 * @param context
 * @param {number} invitationId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deletePendingAccountSingle(context, {
  invitationId,
  loadingKey = LOADING_KEY.DELETE_PENDING_ACCOUNT_SINGLE,
}) {
  return httpService.delete(`/distributor/invitations/${invitationId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#314de2f4-fe40-4f89-9baa-6a1dfe7feaad}

 * @param {Number} invitationId
 * @param {Object} onboardingInfo: {
    "productVisibility": boolean,
    "email": string,
    "smsNumber": string,
    "whatsAppNumber": string,
    "name": string,
    "contactName": string
   }

 * @return {Promise<AxiosResponse<any>>}
 */
function resendConnectionInvitation(context, { invitationId, onboardingInfo }) {
  return httpService.put(`/distributor/invitations/${invitationId}/resend`, { ...onboardingInfo });
}

// Opened accounts, yet to buy

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d7346da3-1fbd-4e94-89c4-39140ffa5af4}
 *
 * @param {Object} filters,
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOpenedAccountList(context, {
  filters,
  limit,
  page,
  loadingKey = LOADING_KEY.FETCH_OPENED_ACCOUNT_LIST,
}) {
  return httpService.get('/account-activity/opened', {
    params: {
      ...filters,
      limit,
      page,
    },
    headers: {
      loadingKey,
    },
  });
}

// Non ordering accounts

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63138aa2-d6ba-41ea-9be8-1d531dda6a1e}
 *
 * @param {Object} filters,
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchNotOrderingAccountList(context, {
  limit,
  page,
  filters,
  loadingKey = LOADING_KEY.FETCH_NOT_ORDERING_ACCOUNT_LIST,
}) {
  return httpService.get('/account-activity/not-ordering', {
    params: {
      ...filters,
      limit,
      page,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63138aa2-d6ba-41ea-9be8-1d531dda6a1e}
 *
 * @param {number} venueId,
 * @param {number} nextId
 * @param {number} nextValue
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchMostOrderedItemsPerVenue(context,
  {
    venueId,
    nextId,
    nextValue,
    loadingKey = LOADING_KEY.FETCH_MOST_ORDERED_ITEMS_PER_VENUE,
  }) {
  return httpService.get(`/reports/venues/${venueId}/items`, {
    params: {
      nextId,
      nextValue,
    },
    headers: {
      loadingKey,
    },
  });
}

// Ordering accounts

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63138aa2-d6ba-41ea-9be8-1d531dda6a1e}
 *
 * @param {Object} filters,
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOrderingAccountList(context, {
  limit,
  page,
  filters,
  loadingKey = LOADING_KEY.FETCH_ORDERING_ACCOUNT_LIST,
}) {
  return httpService.get('/account-activity/ordering', {
    params: {
      ...filters,
      limit,
      page,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#00c0fa2d-3e37-468a-8f88-c2b3ff410bb4}
 *
 * @param {number} venueId,
 * @param {number} nextId
 * @param {number} nextValue
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOrderHistory(context,
  {
    venueId,
    from,
    to,
    nextId,
    nextValue,
    loadingKey = LOADING_KEY.FETCH_ORDER_HISTORY,
  }) {
  return httpService.get(`/account-history/${venueId}`, {
    params: {
      nextId,
      nextValue,
      limit: 10,
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#be19ecda-5076-4783-bd42-dcefc406aec1}
 *
 * @param {object} query,
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOrderHistoryTotal(context,
  { query, loadingKey = LOADING_KEY.FETCH_ORDER_HISTORY_TOTAL }) {
  return httpService.get('/distributor/orders/total-amount', {
    params: { ...query },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#66a541d0-1f4c-42b3-8dce-c7b9f3aaf795}
 *
 * This endpoint get active account owners.
 * Usually used to fetch account owners for options in filter.
 *
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchActiveAccountOwners({ loadingKey = LOADING_KEY.FETCH_ACTIVE_ACCOUNT_OWNERS } = {}) {
  return httpService.get('/distributor/account-owners', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6b61dcd4-4ba6-4814-8c28-31d639491016}
 *
 * @param {Number} accountOwnerId
 * @param {String} from
 * @param {String} to
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function exportAccountsOrderingData(context, { accountOwnerId, from, to }) {
  return httpService.get('/account-activity/ordering/export', {
    responseType: 'arraybuffer',
    params: {
      accountOwnerId,
      from,
      to,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b7c37e3b-4119-446e-b7b9-50477045332f}
 *
 * @param {Number} accountOwnerId
 * @param {String} from
 * @param {String} to
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function exportAccountsAllData(context, { accountOwnerId, from, to }) {
  return httpService.get('/account-activity/export', {
    responseType: 'arraybuffer',
    params: {
      accountOwnerId,
      from,
      to,
    },
  });
}


/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1ce9ddeb-d04b-4775-8e0f-56ccae22a66e}
 *
 * @param context
 * @param {{ from: string; to: string; venueId: string | number; }} query
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAccountActivityTotalAmount(
  context,
  { query = {}, loadingKey = LOADING_KEY.FETCH_ACCOUNT_ACTIVITY_TOTAL_AMOUNT } = {},
) {
  return httpService.get('/account-activity/total-amount', {
    params: { ...query },
    headers: { loadingKey },
  });
}


/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#66587d84-2ce3-40a5-95cb-465e6ed6f657}
 *
 *
 * @param context
 * @param {number} venueId
 * @param {number} dormantPeriod
 * @param {number} distributorAccountOwnerId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function updateConnectionOptions(context,
  {
    venueId,
    dormantPeriod,
    distributorAccountOwnerId,
    loadingKey = LOADING_KEY.UPDATE_CONNECTION_OPTIONS,
  }) {
  return httpService.put(`/distributor/venues/${venueId}/connection-options`,
    {
      dormantPeriod,
      distributorAccountOwnerId,
    },
    {
      headers: {
        loadingKey,
      },
    });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c35bb4c0-b7ba-4253-b877-a9444ca9a2d4}
 *
 * @param context
 * @param {ExpenseTypeCategory} category
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchExpenseTypesByCategory(context, {
  category = null,
  loadingKey = LOADING_KEY.FETCH_EXPENSE_TYPES_BY_CATEGORY,
} = {}) {
  return httpService.get('distributor/expenses/types', {
    params: {
      category,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 *
 * @param context
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchExpenseTypes() {
  return httpService.get('distributor/expenses/types', {
    params: {
      category: CHECK_IN_EXPENSE,
    },
    headers: {
      loadingKey: LOADING_KEY.FETCH_EXPENSE_TYPES,
    },
  });
}

/**
 *
 * @param context
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchActivityTypes() {
  return httpService.get('distributor/expenses/types', {
    params: {
      category: CHECK_IN_TEAM_ACTIVITY,
    },
    headers: {
      loadingKey: LOADING_KEY.FETCH_ACTIVITY_TYPES,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63fa8725-56a5-4f0f-9c70-62454e2c5574}
 *
 * @param context
 * @param {ExpenseTypeCategory} category
 * @param {number} venueId
 * @param {number} userId
 * @param {string} from
 * @param {string} to
 * @param {array} types
 * @param {number} nextId
 * @param {string} nextValue
 * @param {number} limit
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchExpenseList(context, {
  category,
  venueId,
  userId,
  from,
  to,
  types,
  nextId,
  nextValue,
  limit,
  loadingKey = LOADING_KEY.FETCH_EXPENSE_LIST,
}) {
  return httpService.get('/distributor/expenses', {
    params: {
      category,
      venueId,
      userId,
      from,
      to,
      types,
      nextId,
      nextValue,
      limit,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#737f42c2-5037-4d6c-a1a0-a9a0d71f9cdc}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchExpenseSingle(context, {
  id,
  loadingKey = LOADING_KEY.FETCH_EXPENSE_SINGLE,
}) {
  return httpService.get(`/distributor/expenses/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#92c9a933-f672-4828-9712-ae53d29dd1c5)
 *
 * Only expenses that have 'canDelete' property set to true, can be deleted.
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteSingleExpense(context, { id, loadingKey = LOADING_KEY.DELETE_EXPENSE }) {
  return httpService.delete(`/distributor/expenses/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8f226bee-fe0e-442d-889f-075dd7711550}
 *
 * This action gets users that have made one or more expenses.
 *
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchExpenseUsers(context, {
  loadingKey = LOADING_KEY.FETCH_EXPENSE_USERS,
} = {}) {
  return httpService.get('/distributor/expenses/users', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f3efb305-42a0-46b0-8582-cbc5bf4d526a}
 *
 * This action gets Money Spent for specific Venue.
 *
 * @param {number} userId
 * @param {number} venueId
 * @param {string[]} types
 * @param {string} from
 * @param {string} to
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchMoneySpentByAccountRep(context, {
  userId,
  venueId,
  types,
  from,
  to,
  loadingKey = LOADING_KEY.FETCH_MONEY_SPENT_BY_ACCOUNT_REP,
}) {
  return httpService.get('/distributor/expenses/total-amount', {
    params: {
      venueId,
      userId,
      types,
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/*
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#04548f41-8582-4f52-8a2c-9a3d760dba2e}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchReportVenuesSearch() {
  return httpService.get('/distributor/me/venues', {
    headers: {
      loadingKey: LOADING_KEY.FETCH_REPORT_VENUES_SEARCH,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#54b86072-7a15-400b-9fe1-4d1046b4e2b2)
 *
 * @param context
 * @param {number} userId
 * @param {string} from
 * @param {string} to
 * @param {string} category
 * @param {string[]} types
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function exportExpenses(context, {
  userId,
  from,
  to,
  category = 'expense',
  types,
  loadingKey,
}) {
  return httpService.get('/distributor/expenses/export', {
    params: {
      userId,
      from,
      to,
      category,
      types,
    },
    headers: {
      loadingKey,
    },
    responseType: 'arraybuffer',
  });
}

// To-Dos

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7445d4c7-0e1a-47b6-9dd2-d491bccfcaf2)
 *
 * Based on distributor_create_todos permission presence it will return either all todos or
 * only todos for the logged user (user without permission).
 *
 * @param context
 * @param {Object} filters
 * @param {number} nextId
 * @param {unknown} nextValue
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchToDoList(context, {
  filters,
  nextId,
  nextValue,
  loadingKey = LOADING_KEY.FETCH_TO_DO_LIST,
}) {
  return httpService.get('/distributor/todos', {
    params: {
      ...filters,
      nextId,
      nextValue,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a4055108-9c60-4a96-8f92-98000d99d410)
 *
 * List all venues that can be used to filter the todoList (that are assigned to some todo).
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchToDoVenues(context, { loadingKey = LOADING_KEY.FETCH_TO_DO_VENUES } = {}) {
  return httpService.get('/distributor/todos/venues', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0ca418e8-cf4a-4d9f-b206-5c2113ba238d)
 *
 * Returns list of account representatives used for filter
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchToDoAccountRepsOptions(context, {
  loadingKey = LOADING_KEY.FETCH_TO_DO_ACCOUNT_REPS_OPTIONS,
} = {}) {
  return httpService.get('/distributor/todos/account-owners', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#edad9216-0d29-4122-a642-aacb5c2e68ea)
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchToDoSingle(context, {
  id,
  loadingKey = LOADING_KEY.FETCH_TO_DO_SINGLE,
}) {
  return httpService.get(`/distributor/todos/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fd00a9d8-820e-40a2-931c-cac0cb4a8bf6)
 *
 * Users with distributor_create_todos permission can delete todos for all users.
 * Users without permission can delete only todos created by themselves.
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteToDo(context, { id, loadingKey = LOADING_KEY.DELETE_TO_DO }) {
  return httpService.delete(`/distributor/todos/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#19c0b716-14d5-46d5-ad73-8a845cee224d)
 *
 * If venue is previously selected via autocomplete, venueId field should be used.
 * Instead name field should be used to create target account.
 *
 * @param context
 * @param {number} venueId
 * @param {string} name
 * @param {number} accountOwnerId
 * @param {string} note
 * @param {string} dueDate
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function createToDo(context, {
  venueId,
  name,
  accountOwnerId,
  note,
  dueDate,
  loadingKey = LOADING_KEY.CREATE_TO_DO,
}) {
  return httpService.post('/distributor/todos',
    {
      venueId,
      name,
      accountOwnerId,
      note,
      dueDate,
    },
    {
      headers: {
        loadingKey,
      },
    });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c1e017eb-2e12-4c8c-8ca1-1c9b17991aad)
 *
 * @param context
 * @param {string} name
 * @param {number} distributorAccountOwnerId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function createTargetAccount(context, {
  name,
  distributorAccountOwnerId,
  loadingKey = LOADING_KEY.CREATE_TARGET_ACCOUNT,
}) {
  return httpService.post('/distributor/venues/target',
    {
      name,
      distributorAccountOwnerId,
    },
    {
      headers: {
        loadingKey,
      },
    });
}

/**
 * @see (@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d5370369-7738-43e1-912f-53335da299d9)
 *
 * List all account owners eligible to be assigned to the todo.
 *
 * @param context
 * @param {string} filter
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAccountRepsAssignable(context, {
  filter = 'assignable',
  loadingKey = '',
} = {}) {
  return httpService.get('/account-activity/account-owners', {
    params: {
      filter,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0d34ada5-919a-4e50-9f3c-831dd1224bd2}
 *
 * @param context
 * @param {object} query
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchAccountActivityVenues(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_VENUES } = {},
) {
  return httpService.get('/account-activity/search/venues', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6e3cf656-e158-4151-bf4b-a3eb664d4725}
 *
 * @param context
 * @param {string|number} venueId
 * @param {object} query
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchAccountActivityByVenue(
  context,
  { venueId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_BY_VENUE } = {},
) {
  return httpService.get(`/account-activity/venues/${venueId}`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

export default {
  fetchAccountRepsAssignable,
  fetchAccountActivityVenues,
  fetchAccountActivityByVenue,
  createTargetAccount,
  createToDo,
  deleteToDo,
  fetchToDoSingle,
  fetchToDoAccountRepsOptions,
  fetchToDoVenues,
  fetchToDoList,
  fetchTargetAccountList,
  exportExpenses,
  fetchMoneySpentByAccountRep,
  fetchExpenseTypesByCategory,
  fetchExpenseUsers,
  updateConnectionOptions,
  fetchExpenseList,
  fetchExpenseSingle,
  deleteSingleExpense,
  exportAccountsOrderingData,
  exportAccountsAllData,
  fetchAccountActivityTotalAmount,
  fetchOrderingAccountList,
  fetchNotOrderingAccountList,
  fetchOrderHistory,
  fetchOrderHistoryTotal,
  fetchMostOrderedItemsPerVenue,
  fetchVenue,
  fetchVenuesFormAccountActivity,
  fetchOpenedAccountList,
  resendConnectionInvitation,
  fetchPendingAccountSingle,
  deletePendingAccountSingle,
  fetchPendingAccountList,
  fetchVenueReport,
  fetchSingleVenueReport,
  fetchProductReport,
  fetchSingleProductReport,
  fetchReportProductsSearch,
  fetchReportVenuesSearch,
  fetchMostOrderedItems,
  fetchMostOrderedBy,
  fetchActiveAccountOwners,
  fetchExpenseTypes,
  fetchActivityTypes,
};
