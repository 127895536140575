/* eslint-disable */
import { combineURLs } from '@/util/utils';
import { getAuthorizationUrl } from '@/api/endpoints/xero';
import router from '@/router';

/**
 * Get Xero authorization URL, append redirect URL and redirect user to Xero.
 *
 * @param context
 * @param {string} routerName Name of the route where to redirect after authorization.
 * @param {string} state Will append to redirect URL. This will be returned to us from xero.
 * @return {Promise<void>}
 */
async function xeroAuthorize(context, { route, state }) {
  // Get URL from API
  const { data } = await getAuthorizationUrl();
  // Resolve where to redirect the user (based on route name).
  const redirectRoute = router.resolve({ name: route });
  const redirectUrl = combineURLs(window.location.origin, redirectRoute.href);
  const params = [];
  // Add redirect URI
  params.push(`redirect_uri=${encodeURIComponent(redirectUrl)}`);
  // Add state if present
  if (state) params.push(`state=${encodeURIComponent(state)}`);

  // Redirect user to Xero with params
  window.location.href = `${data.data}&${params.join('&')}`;
}


export default {
  xeroAuthorize,
};
