<template>
  <div class="ez-csv-error-list">
    <div>
      <div class="table-header">
        <span>Row #</span>
        <span>Product Name</span>
      </div>
      <div class="table-body">
        <div v-for="item in csvErrors" :key="item.row" class="table-body__item">
          <div class="table-body__item--first-row">
            <span>{{ item.row }}</span>
            <span>{{ item.name }}</span>
          </div>
          <div class="table-body__item--second-row" v-for="(error, i) in item.errors.name" :key="i">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showOverlay" class="ez-csv-error-list__overlay">
      <p>Check your email to see the complete list of rejected ${{ entityName | pluralize }}.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    csvErrors: {
      type: Array,
      required: true,
    },
    showOverlay: {
      type: Boolean,
      required: true,
    },
    entityName: {
      type: String,
      required: false,
      default: 'Product',
    },
  },
  data() {
    return {
      headers: {
        row: () => 'Row #',
        name: () => `${this.entityName} name`,
      },
    };
  },
};
</script>

<style scoped lang="scss">
$header-text-color: #f0f4f9;
.ez-csv-error-list {
  position: relative;
  width: 100%;
  padding-bottom: 3em;
  :deep() .table {
    min-width: auto;
    :deep() thead {
      th {
        background-color: $header-text-color;
      }
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }

  .table-header {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ebf2;
    font-size: 12px;
    font-weight: 500;
    color: $color-gray-6C;
  }
  .table-body {
    &__item {
      padding: 12px 0;
      color: $color-gray-6C;
      border-bottom: 1px solid #e9ebf2;
      &--first-row {
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #e9ebf2;
      }
      &--second-row {
        text-align: right;
        padding-top: 8px;
        color: $color-primary-red;
      }
    }
  }
}
</style>
