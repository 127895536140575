import { Model } from '@vuex-orm/core';
import Venue from '@/models/Venue';
import Distributor from '@/models/Distributor';

export default class User extends Model {
  static entity = 'users';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      initials: this.attr(''),
      email: this.attr(''),
      scope: this.attr(''),
      role: this.attr(''),
      status: this.attr(''),
      accountName: this.attr(''),
      permissions: this.attr({}),
      venueId: this.attr(null),
      groupId: this.attr(null),
      distributorId: this.attr(null),
      venue: this.belongsTo(Venue, 'venueId'),
      distributor: this.belongsTo(Distributor, 'distributorId'),
      purchaseLimit: this.attr(0),
      phone: this.attr(''),
      colors: this.attr([]),
      type: this.attr(''),
      primary: this.attr(false),
      primaryByDefault: this.attr(false),
      notificationSettings: this.attr({}),
      isPrimary: this.attr(false),
      venues: this.attr([]),
    };
  }
}
