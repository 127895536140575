<script>
/**
 *
 * @version 1.0.0
 * @since
 */
export default {};
</script>
<template>
  <font-awesome-icon icon="exclamation-circle"/>
</template>
<style lang="scss" scoped></style>
