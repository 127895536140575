import i18n from '@/i18n';

const HubDocSettings = () =>
  import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/hub-doc/Index.vue');
const HubDocAccountInfo = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/hub-doc/AccountInfo.vue'
  );

export default [
  // Settings Route
  {
    path: '/supplier/settings/integrations/email-forwarding',
    component: HubDocSettings,
    children: [
      {
        path: 'account-info',
        name: 'distributor-hub-doc-account',
        component: HubDocAccountInfo,
        meta: {
          title: `${i18n.t('hubDoc.name')} Account Info`,
        },
      },
    ],
  },
];
