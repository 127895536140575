let clickOpen;

export default {
  bind(el, binding, vnode) {
    const ref = binding.value;
    clickOpen = (e) => {
      e.preventDefault();
      vnode.context.$refs[ref].open();
    };

    el.addEventListener('click', clickOpen);
  },
  unbind(el) {
    el.removeEventListener('click', clickOpen);
  },
};
