import dayjs from 'dayjs';
import { endOfWeek, startOfWeek } from '@/util/utils';

function getAllTimeRange(today = new Date()) {
  return { start: dayjs('1970-01-02'), end: dayjs(today).endOf('d') };
}

function getAllTimeRangeInFuture(today = new Date()) {
  return { start: dayjs('1970-01-02'), end: dayjs(today).add(10, 'year') };
}

function getTodayRange(today = new Date()) {
  return { start: dayjs(today), end: dayjs(today) };
}

function getThisWeekRange(today = new Date(), weekStartsOn = 1) {
  return {
    start: startOfWeek(today, weekStartsOn),
    end: endOfWeek(today, weekStartsOn),
  };
}

function getThisMonthRange(today = new Date()) {
  return { start: dayjs(today).startOf('M'), end: dayjs(today).endOf('M') };
}

function getThisYearRange(today = new Date()) {
  return { start: dayjs(today).startOf('y'), end: dayjs(today).endOf('y') };
}

function getLastWeekRange(today = new Date(), weekStartsOn = 1) {
  return {
    start: dayjs(startOfWeek(today, weekStartsOn)).subtract(1, 'w'),
    end: dayjs(endOfWeek(today, weekStartsOn)).subtract(1, 'w'),
  };
}

function getLastMonthRange(today = new Date()) {
  const firstDayOfThisMonth = dayjs(today).startOf('M');
  const lastDayOfPreviousMonth = dayjs(firstDayOfThisMonth).subtract(1, 'd');
  const firstDayOfPreviousMonth = dayjs(lastDayOfPreviousMonth).startOf('M');
  return { start: firstDayOfPreviousMonth, end: lastDayOfPreviousMonth };
}

function getLastYearRange(today = new Date()) {
  const firstDayOfThisYear = dayjs(today).startOf('y');
  const lastDayOfPreviousYear = dayjs(firstDayOfThisYear)
    .subtract(1, 'd')
    .endOf('d');
  const firstDayOfPreviousYear = dayjs(lastDayOfPreviousYear).startOf('y');
  return { start: firstDayOfPreviousYear, end: lastDayOfPreviousYear };
}

export {
  getAllTimeRange,
  getTodayRange,
  getThisWeekRange,
  getThisMonthRange,
  getThisYearRange,
  getLastWeekRange,
  getLastMonthRange,
  getLastYearRange,
  getAllTimeRangeInFuture,
};
