import { isNullish } from '@/util/utils';
import Big from 'big.js';

/**
 * Rounds number to a certain number of decimal places.
 *
 * Rounding number manually because toFixed alone has some shady outputs in some situations.
 * And also to be consistent with BE. So if you are changing this, notify BE & QA team also.
 *
 * If you want to know more about shady outputs of toFixed
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#description}
 *
 * @param {number} number
 * @param {number} decimals
 *
 * @return {number}
 */
export function roundNumber({ number, decimals }) {
  let valueToRound;
  if (Number.isFinite(number)) {
    valueToRound = (+number).toFixed(8);
  } else {
    valueToRound = 0;
  }
  const value = new Big(valueToRound);
  return Number(value.toFixed(decimals));
}

/**
 * Returns sum for prop in list
 *
 * @param {Array} list
 * @param {string} prop - name of prop
 *
 * @return {number}
 */
export function getSum({ list, prop, roundToTwoDecimals = false }) {
  if (roundToTwoDecimals) {
    let sum = new Big(0);
    list.forEach(prod => {
      let valueToRound;
      if (Number.isFinite(prod[prop])) {
        valueToRound = (+prod[prop]).toFixed(8);
      } else {
        valueToRound = 0;
      }
      const val = new Big(valueToRound);
      sum = sum.plus(val.toFixed(2));
    });
    return sum.toNumber();
  }
  return list?.reduce((acc, cur) => acc + cur[prop], 0);
}

/**
 * Returns 0 if product has Market Price or Price Unit.
 *
 * Market Price - is price that can vary based on Market.
 * Price Unit - is price based on fractional units
 * checkPriceUnit - this flag we use when we want to skip check for priceUnit and MP
 * Since we don't know how much the price is we see it as 0 in the calculations.
 *
 * @param {Object} product
 * @param {Boolean} checkPriceUnit
 *
 * @return {Object}
 */
export function getPrice(product, checkPriceUnit = true) {
  return product.marketPrice || (checkPriceUnit && product.priceUnit) ? 0 : product.price;
}

/**
 * Returns tax value for price based on tax in %
 *
 * @param {number} price
 * @param {number} tax - tax in %
 * @param {number} decimals - by default 2
 *
 * @return {number} - tax for that price
 */
export function getTaxValue({ price, tax, decimals = 2, taxCalculation = 'exclusive' }) {
  const roundedPrice = roundNumber({ number: price, decimals });
  if (taxCalculation === 'exclusive') {
    const taxValue = (roundedPrice * tax) / 100;
    return roundNumber({ number: taxValue, decimals });
  }
  if (taxCalculation === 'inclusive') {
    const taxValue = (roundedPrice / (100 + tax)) * tax;
    return roundNumber({ number: taxValue, decimals });
  }
  return 0;
}

/**
 * Returns tax fee for price and delivery
 *
 * @param {Array} itemList
 * @param {string} itemProp - defines what prop to use in calculation (eg. price, amount, value,...)
 * @param {number} deliveryFee
 * @param {number} discount
 * @param {number} tax - in %
 * @param {number} decimals - by default 2
 *
 * @return {number}
 */
export function getTaxFee({
  itemList,
  itemProp = 'totalPrice',
  deliveryFee,
  discount,
  tax,
  decimals = 2,
  taxCalculation = 'exclusive',
  withDiscount = false,
  itemQuantity = 'priceQuantity',
}) {
  if (isNullish(itemList)) {
    // eslint-disable-next-line no-console
    console.error('getTaxFee: itemList is nullish');
    return null;
  }

  let price = 0;
  let errMsg = '';
  let taxTotal = 0;

  try {
    taxTotal = itemList
      .map(item => {
        price = item[itemProp] || 0;
        if (taxCalculation === 'inclusive' && withDiscount && item.discountAmount) {
          price += item.discountAmount * item[itemQuantity];
        }

        if (!Number.isFinite(price)) {
          errMsg = `getTaxFee: In itemList prop with name "${itemProp}" is not a number.`;
          throw Error(errMsg);
        }

        const taxValue = taxCalculation === 'exempt' ? 0 : (item.product?.tax || item.tax) ?? 0;
        return getTaxValue({
          price,
          tax: taxValue,
          decimals,
          taxCalculation,
        });
      })
      .reduce((acc, cur) => acc + cur, 0);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }

  const taxDF = deliveryFee ? getTaxValue({ price: deliveryFee, tax, decimals }) : 0;
  taxTotal += taxDF;

  const taxDiscount = discount
    ? getTaxValue({ price: -discount, tax, decimals, taxCalculation })
    : 0;
  taxTotal -= taxDiscount;

  return taxTotal;
}

/**
 * Returns total with tax.
 *
 * TaxDecimals = by default 2 or passed value
 * TotalTax = Tax is calculated for every item, then rounded to TaxDecimals and sum up
 * TotalTax += DeliveryFeeTax
 *
 * Subtotal = Is every value of item sum up.
 * Subtotal += DeliveryFee
 *
 * Total = TotalTax + Subtotal
 *
 * Logic based on xero developer guide:
 * @see {@link https://developer.xero.com/documentation/guides/how-to-guides/rounding-in-xero#line-item-totals}
 *
 * @param {Array} itemList
 * @param {string} itemProp - defines what prop to use in calculation (eg. price, amount, value,...)
 * @param {number} deliveryFee
 * @param {number} discount
 * @param {number} tax - in %
 * @param {number} taxDecimals - by default 2
 *
 * @return {number}
 */
export function getTotalWithTax({
  itemList,
  itemProp = 'totalPrice',
  deliveryFee,
  amountAdjustment = 0,
  taxAdjustment = 0,
  discount,
  tax,
  taxDecimals = 2,
  deliveryFeeTaxable = true,
  taxCalculation = 'exclusive',
}) {
  if (isNullish(itemList)) {
    // eslint-disable-next-line no-console
    console.error('getTotalWithTax: itemList is nullish');
    return null;
  }

  const taxTotal = getTaxFee({
    itemList,
    itemProp,
    ...(deliveryFeeTaxable ? { deliveryFee } : { deliveryFee: 0 }),
    discount,
    tax,
    decimals: taxDecimals,
    taxCalculation,
  });
  const subtotal =
    deliveryFee +
    getSum({ list: itemList, prop: itemProp, roundToTwoDecimals: true }) +
    (discount || 0) +
    (amountAdjustment || 0) +
    (taxAdjustment || 0);

  if (taxCalculation === 'inclusive') return subtotal + getTaxValue({ price: deliveryFee, tax });
  return taxTotal + subtotal;
}

// TODO: Use getTotalAmountPriceCustom [abandoned]
/**
 * Returns total amount for all products, or someone will call it subtotal
 *
 * @param {Array} products
 * @param {string} quantityProp
 *
 * @return {number}
 */
export function getTotalAmountQuantityPrice(products) {
  return products?.reduce((acc, cur) => acc + getPrice(cur) * cur.priceQuantity, 0);
}

/**
 * Returns total amount for all products, or someone will call it subtotal
 *
 * @param {Array} products
 * @param {string} multiplicand - name of item prop
 * @param {boolean} checkPriceUnits - skip condition to check priceUnit in getPrice function
 *
 * @return {number}
 */
export function getTotalAmountPriceCustom({
  products,
  multiplicand = 'quantity',
  checkPriceUnits = true,
}) {
  return products?.reduce(
    (acc, cur) => acc + getPrice(cur, checkPriceUnits) * cur[multiplicand],
    0,
  );
}

// TODO Use addTotalPriceCustom [abandoned]
/**
 * Adds totalPrice prop to every item in list and returns that list.
 * totalPrice = item[multiplier] * item[multiplicand]
 *
 * @param {Array} list
 * @param {string} multiplier - name of item prop
 * @param {string} multiplicand - name of item prop
 *
 * @return {Array}
 */
export function addTotalPriceProp({ list, multiplier = 'price', multiplicand = 'quantity' }) {
  return list.map(item => ({ ...item, totalPrice: item[multiplier] * item[multiplicand] }));
}

// TODO Use addTotalPriceCustom [abandoned]
/**
 * Adds totalPrice to item based on price & quantity
 * totalPrice = price * quantity
 *
 * @param item
 *
 * @return {*&{totalPrice: number}}
 */
export function addTotalPrice(item) {
  return { ...item, totalPrice: getPrice(item) * item.priceQuantity };
}

/**
 * Adds totalPrice to item based on price & quantity by default
 * totalPrice = price * quantity by default
 *
 * @param item
 * @param {string} multiplicand - name of item prop
 * @param {Boolean} checkPriceUnits - manage what value return getPrice function
 *
 * @return {*&{totalPrice: number}}
 */
export function addTotalPriceCustom({ item, multiplicand = 'quantity', checkPriceUnits = true }) {
  return { ...item, totalPrice: getPrice(item, checkPriceUnits) * item[multiplicand] };
}
