import i18n from '@/i18n';

export default [
  {
    path: '/supplier',
    alias: '/distributor',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/Home.vue'),
    props: { type: 'distributor' },
    redirect: { name: 'platform-login' },
    meta: {
      role: 'public',
      requireAuth: false,
      title: 'Home',
    },
    children: [
      {
        path: 'orders/:id(\\d+)',
        name: 'distributor-orders-public',
        component: () => import(/* webpackChunkName: "public" */ '@/views/public/Distributor.vue'),
        meta: {
          title: 'Orders',
        },
      },
    ],
  },
  {
    path: '/buyer',
    alias: '/venue',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/Home.vue'),
    redirect: { name: 'platform-login' },
    props: { type: 'venue' },
    meta: {
      role: 'public',
      requireAuth: false,
      title: i18n.t('global.venue'),
    },
    children: [
      {
        path: 'orders/:id(\\d+)',
        name: 'venue-orders-public',
        component: () => import(/* webpackChunkName: "public" */ '@/views/public/Venue.vue'),
        meta: {
          title: 'Orders',
        },
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'public-onboarding',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/index.vue'),
    props: route => ({ token: route.query.token }),
    meta: {
      role: 'public',
      requireAuth: false,
      title: 'Onboarding',
    },
  },
  {
    path: '/onboarding-not-found',
    name: 'invalid-link',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/InvalidLink.vue'),
    meta: {
      role: 'public',
      requireAuth: false,
      title: 'Invalid onboarding link',
    },
  },
  {
    path: '/free-trial',
    name: 'free-trial',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/FreeTrial.vue'),
    meta: {
      role: 'public',
      requireAuth: false,
      title: 'Free Trial',
    },
  },
];
