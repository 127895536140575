import httpService from '@/api/http';
import ConnectedEntries from '@/models/ConnectedEntries';
import { LOADING_KEY } from '@/util/constants';

/**
 * @param loadingKey
 *
 *  @returns {Promise<Collections>}
 */
const fetchConnectedVenues = ({ loadingKey = LOADING_KEY.FETCH_CONNECTED_VENUES }) => httpService
  .get('/distributor/connected-venues', {
    headers: {
      loadingKey,
    },
  })
  .then(({ data }) => ConnectedEntries.insertOrUpdate({ data: data.data }));

export default {
  fetchConnectedVenues,
};
