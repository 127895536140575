import httpService from '@/api/http';
import Distributor from '@/models/Distributor';
import Venue from '@/models/Venue';
import { LOADING_KEY } from '@/util/constants';

/**
 * ADMIN ACTIONS
 * */

const fetchDistributors = () =>
  httpService
    .get('/admin/distributors')
    .then(({ data }) => Distributor.insertOrUpdate({ data: data.data }));

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#521369b5-ffdc-4832-bb37-6cd86b73c16c}
 *
 * @param context
 * @param {{
 *  nextId?: string | number;
 *  nextValue?: string;
 *  limit?: number;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributors(
  context,
  { query = {}, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTORS } = {},
) {
  return httpService.get('/admin/distributors', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3ca02295-560a-42f6-bcef-fb7ee733f601}
 * @param context
 * @param {number} distributorId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributor(
  context,
  { distributorId, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d7585481-7675-417b-9dfd-3b8db5ba4242}
 * @param context
 * @param {number} distributorId
 * @param {{
 *  nextId?: string | number;
 *  nextValue?: string;
 *  limit?: number;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributorVenues(
  context,
  { distributorId, query = {}, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_VENUES } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}/venues`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

const fetchDistributor = (context, id) => {
  const isAdmin = localStorage.getItem('role') === 'admin';
  const isVenue = localStorage.getItem('role') === 'venue';

  if ((isAdmin || isVenue) && !id) {
    throw new Error('Distributor id is required');
  }

  const route =
    isAdmin || isVenue ? `${isAdmin ? '/admin' : ''}/distributors/${id}` : '/distributor/me';

  return httpService
    .get(route, {
      headers: {
        loadingKey: LOADING_KEY.FETCH_DISTRIBUTOR,
      },
    })
    .then(({ data }) => Distributor.insertOrUpdate({ data: data.data }));
};

const fetchTimeZones = ({ loadingKey = LOADING_KEY.FETCH_TIME_ZONES }) => {
  const route = '/utils/timezones';

  return httpService(route, {
    headers: {
      loadingKey,
    },
  }).then(({ data }) => data.data.map(zone => ({ name: zone, id: zone })));
};

const fetchPaymentTerms = ({ loadingKey = LOADING_KEY.FETCH_TIME_ZONES }) => {
  const route = '/utils/payment-terms';

  return httpService(route, {
    headers: {
      loadingKey,
    },
  });
};

const fetchSingleDistributor = (context, id) =>
  Promise.all([
    httpService.get(`/admin/distributors/${id}`),
    httpService.get(`/admin/distributors/${id}/venues`),
    httpService.get(`/admin/distributors/${id}/users`),
  ]).then(([distributorRes, venuesRes, usersRes]) => {
    Venue.insertOrUpdate({ data: venuesRes.data.data });
    Distributor.insertOrUpdate({
      data: [
        {
          ...distributorRes.data.data,
          users: usersRes.data.data.map(u => ({ ...u, distributorId: id })),
          venueIds: venuesRes.data.data.filter(v => v.isConnected).map(v => v.id),
        },
      ],
    });
  });

const removeDistributorLogo = (context, id) => {
  const isAdmin = localStorage.getItem('role') === 'admin';

  if (isAdmin && !id) {
    throw new Error('Distributor id is required');
  }

  const route = isAdmin ? `/admin/distributors/${id}/logo` : '/distributor/me/logo';

  return httpService
    .delete(route)
    .then(() => Distributor.update({ where: id, data: { logo: null, isDefaultImage: true } }));
};

function connectDistributorWithVenue(
  context,
  { distributorId, venueId, loadingKey = LOADING_KEY.ADMIN_CONNECT_DISTRIBUTOR_WITH_VENUE } = {},
) {
  return httpService.put(`/admin/distributors/${distributorId}/venues/${venueId}`, {
    headers: { loadingKey },
  });
}

function disconnectDistributorWithVenue(
  context,
  { distributorId, venueId, loadingKey = LOADING_KEY.ADMIN_DISCONNECT_DISTRIBUTOR_FROM_VENUE } = {},
) {
  return httpService.delete(`/admin/distributors/${distributorId}/venues/${venueId}`, {
    headers: { loadingKey },
  });
}

const sendDistributorInvitation = (context, id) =>
  httpService
    .put(`/admin/distributors/${id}/invite`)
    .then(({ data }) => Distributor.update({ data: data.data }));

const removeDistributor = (context, id) => httpService.delete(`/admin/distributors/${id}`);

const connectDistributorToVenue = (
  context,
  { id, loadingKey = LOADING_KEY.CONNECT_DISTRIBUTOR_TO_VENUE, data = {} },
) =>
  httpService.put(`/distributor/venues/${id}`, data, {
    headers: {
      loadingKey,
    },
  });
const disconnectDistributorToVenue = (context, id) =>
  httpService.delete(`/distributor/venues/${id}`);

/**
 * New methods that do not use the ORM.
 * Part of a new refactor.
 */

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#364bef9b-2955-46c2-9038-266142ee874b}
 *
 * @param context
 * @param {number} id
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchConnectedDistributors(
  context,
  { id, loadingKey = LOADING_KEY.FETCH_SUPPLIERS },
) {
  return httpService.get(`/venues/${id}/connected-distributors`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#26c86858-b8c7-4dad-86cd-542fbe003699}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 * @param {object} query
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchConnectedDistributorsNew(
  context,
  { loadingKey = LOADING_KEY.FETCH_SUPPLIERS, query } = {},
) {
  return httpService.get('/venue/connected-distributors', {
    params: {
      ...query,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#955d7074-1137-476e-b319-a6c4374d446b}
 *
 * @param context
 * @param {number} distributorId
 * @return {Promise<AxiosResponse<T>>}
 */
function venueAddSupplier(context, { distributorId, loadingKey = LOADING_KEY.VENUE_ADD_SUPPLIER }) {
  return httpService.put(
    `/venue/distributors/${distributorId}`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a3b10ebc-f1fd-4086-afc4-70a842081a09}
 *
 * @param {number} id
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchInvitations(
  context,
  { id, loadingKey = LOADING_KEY.VENUE_CONNECTION_REQUESTS },
) {
  return httpService.get(`/venues/${id}/invitations`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1f1db93a-2786-4591-a98b-3f9a0fbe8ac5}
 *
 * @param {number} venueId
 * @param {number} id
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchSupplierSingle(
  context,
  { id, loadingKey = LOADING_KEY.VENUE_FETCH_SUPPLIER_SINGLE },
) {
  return httpService.get(`/venue/distributors/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ee8d2ef4-024d-40dd-8d48-4edbce078bce}
 *
 * @param {number} id
 * @param {Array} locationIds
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<T>>}
 */
function venueUpdateSupplierAvailability(
  context,
  { id, locationIds, loadingKey = LOADING_KEY.VENUE_UPDATE_SUPPLIER_AVAILABILITY },
) {
  return httpService.put(
    `/venue/distributors/${id}/availability`,
    {
      connectedVenueIdsFromGroup: locationIds,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#83fd8a5b-b634-4499-ba65-5f25a7e3cf30}
 *
 * @param {number} distributorsId
 * @param {Array} locationIds
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<T>>}
 */
function venueUpdateSuppliersAvailability(
  context,
  { distributorIds, locationIds, loadingKey = LOADING_KEY.VENUE_UPDATE_SUPPLIER_AVAILABILITY },
) {
  return httpService.put(
    `/venue/distributors/availability`,
    {
      distributorIds,
      connectedVenueIdsFromGroup: locationIds,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cc18b436-b4b0-45d4-9116-0348998b7bb0}
 *
 * @param {number} venueId
 * @param {number} id
 * @param {string} status
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function venueApproveOrDeclineRequest(
  context,
  { venueId, id, status, loadingKey = LOADING_KEY.VENUE_APPROVE_OR_DECLINE_REQUEST },
) {
  return httpService.patch(
    `/venues/${venueId}/invitations/${id}`,
    { status },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a3e0ca3b-e5b3-48c2-ab7f-46d3299614b6}
 *
 * @param context
 * @param {number} venueId
 * @param {number} id
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchDistributorUsers(context, { id }) {
  return httpService.get(`/venue/distributors/${id}/users`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#757df0db-a4cb-4e6c-a535-7fe7f7a97089}
 *
 * @param context
 * @param {number} venueId
 * @param {number} id
 * @param {number} userId
 * @return {Promise<AxiosResponse<T>>}
 */
function venueRemoveDistributorUser(context, { id, userId }) {
  return httpService.delete(`/venue/distributors/${id}/users/${userId}`);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#aa169ff4-ba64-40ae-acd6-53e05f0dc6e6}
 *
 * @param context
 * @param {number} venueId
 * @param {number} id
 * @param {number} userId
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchDistributorUser(context, { id, userId }) {
  return httpService.get(`/venue/distributors/${id}/users/${userId}`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#612823ea-5923-41a9-8101-245198eb94c9}
 *
 * @param context
 * @param {number} distributorId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDisconnectDistributor(
  context,
  { distributorId, loadingKey = LOADING_KEY.VENUE_DISCONNECT_SUPPLIER },
) {
  return httpService.delete(`/venue/distributors/${distributorId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8bb5b424-1157-4a0b-85ae-6c11360556a8}
 *
 * @param context
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchTermsAndConditions(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_TERMS_AND_CONDITIONS } = {},
) {
  return httpService.get('/distributor/me/terms-and-conditions', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#31728497-79c2-4864-a149-e45ab65c7dd3}
 *
 * @param context
 * @param {string} terms
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateTermsAndConditions(
  context,
  { terms, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_TERMS_AND_CONDITIONS },
) {
  return httpService.put(
    '/distributor/me/terms-and-conditions',
    {
      terms,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2f63d200-c855-46c7-b248-70467f86b33b}
 *
 * @param context
 * @param venueId,
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchDistributorTos(context, { venueId, distributorId }) {
  return httpService.get(`/venues/${venueId}/distributors/${distributorId}/terms-and-conditions`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#18457db1-8406-45dd-b91c-9062c14c3ba2}
 *
 * @param context
 * @param venueId,
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchDistributorTosPdf(
  context,
  { venueId, distributorId, loadingKey = LOADING_KEY.DOWNLOAD_TOS },
) {
  return httpService.get(
    `/venues/${venueId}/distributors/${distributorId}/terms-and-conditions/pdf`,
    {
      responseType: 'arraybuffer',
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2f63d200-c855-46c7-b248-70467f86b33b}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchDistributorSingle() {
  return httpService.get('/distributor/me', {
    headers: {
      loadingKey: LOADING_KEY.FETCH_DISTRIBUTOR,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5038e073-6c70-48f6-a7ec-673efbc2f0fb}
 *
 * @param context
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateOnboardingSettings(context, { productVisibility }) {
  return httpService.put('/distributor/me/onboarding-settings', {
    productVisibility,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ffa68b6e-3aeb-4f8c-b5d4-a41fb8aeab91}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetSinglePendingUpdate(context, { pendingId }) {
  return httpService.get(`/venue/pending-invoices/${pendingId}`);
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#61635442-2182-465d-b069-22aad39f9eb5}
 *
 * @param context
 * @param venueId
 * @param invoiceId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetMergePendingUpdate(context, { invoiceId, page, limit = 10 }) {
  return httpService.get(`/venue/pending-invoices/${invoiceId}/merge-invoices`, {
    params: {
      limit,
      page,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#53bbdf17-ca19-42d4-9335-c7ac82b0a943}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminGetSinglePendingUpdate(context, { venueId, pendingId }) {
  return httpService.get(`/admin/venues/${venueId}/pending-invoices/${pendingId}`);
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#39d635b3-5c6f-4b49-9ddd-1992939ad14b}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAssignDistributorOnPendingInvoice(context, { venueId, pendingId, distributorId }) {
  return httpService.patch(`/venues/${venueId}/pending-invoices/${pendingId}`, {
    distributorId,
  });
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#13e109a9-4ddf-4162-9446-b3ea5befaa06}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUploadCsvProductsOfflineOrder(context, { venueId, pendingId, distributorId, data }) {
  return httpService.post(
    `venues/${venueId}/distributors/${distributorId}/import/products?pendingInvoiceId=${pendingId}`,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6ee3ab2e-775a-4fcf-bcd2-003c1576c228}
 *
 * @param context
 * @param venueId
 * @param productId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDeleteNewCreatedProduct(context, { venueId, productId, distributorId }) {
  return httpService.delete(
    `venues/${venueId}/distributors/${distributorId}/products/${productId}`,
  );
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d56d2fbc-2731-41fa-be5a-87cec6bf090f}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function adminUploadCsvProductsOfflineOrder(context, { venueId, pendingId, distributorId, data }) {
  return httpService.post(
    `/admin/distributors/${distributorId}/import/products?venueId=${venueId}&pendingInvoiceId=${pendingId}`,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4503940c-749e-4653-aa2a-56c3a921e4ff}
 *
 * @param context
 * @param productId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminDeleteNewCreatedProduct(context, { productId, distributorId }) {
  return httpService.delete(`/admin/distributors/${distributorId}/products/${productId}`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3ca02295-560a-42f6-bcef-fb7ee733f601}
 *
 * @param {number} distributorId
 * @return {Promise<AxiosResponse<T>>}
 */
function adminFetchSupplierSingle(context, { distributorId }) {
  return httpService.get(`/admin/distributors/${distributorId}`);
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1062c0e0-0bb1-4ff6-a6d7-9495a4ebcc87}
 *
 * @param context
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function createCheckIn(context, { data }) {
  return httpService.post('distributor/expenses', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7b7cc8d5-29ec-4de0-940c-04bcdc1950a1}
 *
 * @param context
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function uploadDistributorOutletCSV(context, { data }) {
  return httpService.post('/distributor/venues/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * @since 3.21.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6300ec54-5e4c-4e80-a0ad-4133c2e97367}
 *
 * @param context
 * @param {string} loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorDownloadOutletsCsv(
  context,
  loadingKey = LOADING_KEY.DISTRIBUTOR_DOWNLOAD_OUTLETS_CSV,
) {
  return httpService.get('/distributor/venues/export', {
    responseType: 'arraybuffer',
    headers: { loadingKey },
  });
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7b7cc8d5-29ec-4de0-940c-04bcdc1950a1}
 *
 * @param context
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorSendInvite(context, { data }) {
  return httpService.put('/distributor/venues/invite', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      loadingKey: LOADING_KEY.SUPPLIER_SEND_INVITE,
    },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c1e017eb-2e12-4c8c-8ca1-1c9b17991aad}
 * @param context
 * @param data
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCreateTargetVenue(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_TARGET_VENUE },
) {
  return httpService.post('/distributor/venues/target', data, {
    headers: { loadingKey },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0e981ba1-df93-4324-9c08-1ac7d7bbc9f6}
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDownloadVenueProductsCSV(
  context,
  { venueId, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_PRODUCTS_FOR_VENUE },
) {
  return httpService.get(`/distributor/venues/${venueId}/export/products`, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.7.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0e981ba1-df93-4324-9c08-1ac7d7bbc9f6}
 *
 * @param context
 * @param venueId
 * @param products
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorBulkUpdatePriceForTargetVenueProducts(
  context,
  { venueId, products, loadingKey = LOADING_KEY.DISTRIBUTOR_BULK_UPDATE_PRODUCTS_PRICES_FOR_VENUE },
) {
  return httpService.patch(
    `/distributor/venues/${venueId}/products/bulk`,
    { products },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 3.7.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a17b0307-92e1-43f6-b38e-c1c5802fed61}
 *
 * @param context
 * @param venueId
 * @param query
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchVenueVisibleProducts(
  context,
  { venueId, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS },
) {
  return httpService.get(`/distributor/venues/${venueId}/products`, {
    headers: {
      loadingKey,
    },
    params: {
      ...query,
    },
  });
}

/**
 * @since 3.7.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#02581e23-6c41-4bf0-aabc-88438043fc2e}
 *
 * @param context
 * @param products
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorSendOnboardingLink(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_SEND_ONBOARDING_LINK },
) {
  return httpService.post('/distributor/invitations/onboarding', data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.7.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#49c1ba08-f646-4bdc-8634-7c0502a030fc}
 *
 * @param context
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorGetPaymentTerms() {
  return httpService.get('/distributor/me/payment-terms');
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d13143ca-7580-4d22-838c-72a85fe84175}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProvinoSetup() {
  return httpService.get('/distributor/provino');
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#01fe511c-984a-4571-a9a0-66c970f966af}
 *
 * @param context
 * @param {string} method
 * @param declinedProducts
 * @param orderId
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProvinoSchedule(
  context,
  { method = 'delivery', declinedProducts, orderId },
) {
  return httpService.get(`/distributor/orders/${orderId}/schedule`, {
    params: {
      deliveryMethod: method,
      declinedProducts,
    },
  });
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a0622aaf-cd34-4255-aa69-aa815271876b}
 *
 * @param context
 * @param method
 * @param declinedProducts
 * @param orderId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProvinoFirstSchedule(
  context,
  { method = 'delivery', declinedProducts, orderId, loadingKey = LOADING_KEY.FETCH_PROVINO_DATA },
) {
  return httpService.get(`/distributor/orders/${orderId}/schedule/first-available`, {
    params: {
      deliveryMethod: method,
      declinedProducts,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#112e557f-6f3c-4162-943b-f7fc19ae466b}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchProvinoEnums() {
  return httpService.get('/distributor/provino/enums');
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1d9f03ff-ebd1-4d81-bbf9-6afebb6fa3fe}
 *
 * @param context
 * @param orderId
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCheckInventoryStatus(context, { orderId }) {
  return httpService.get(`/distributor/orders/${orderId}/inventory-status`);
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1d9f03ff-ebd1-4d81-bbf9-6afebb6fa3fe}
 *
 * @param context
 * @param orderId
 * @param data
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorProvinoCustomScheduleForDelivery(context, { orderId, data, loadingKey }) {
  return httpService.patch(`/distributor/orders/${orderId}/schedule`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      loadingKey,
    },
  });
}

/**
 * @since 3.11.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5fd62dc2-dd70-40cb-ae10-4d05d01a743a}
 *
 * @param context
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorProvinoDisconnect(context, { loadingKey }) {
  return httpService.delete('/distributor/provino', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.13.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4977c77a-145a-405f-b30d-14cea8c53267}
 *
 * * @param {String} orderedAt
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchDeliveryDate(context, { orderedAt }) {
  return httpService.get(`/distributor/orders/delivery-date?from=${orderedAt}`);
}

// Integrations

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#76d28a57-c31b-4ca7-b012-229f0daff95e}
 *
 * @param context
 * @param loadingKey
 * @param query
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchAvailableIntegrations(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INTEGRATIONS, query },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/distributor/tray/solutions${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1361174a-2301-4546-8e6b-ad4bc0194524}
 *
 * @param context
 * @param loadingKey
 * @param query
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchConnectedIntegrations(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INTEGRATIONS, query },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/distributor/tray/solution-instances${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#59a0db1d-7c8c-469d-9576-9a5b570e9aec}
 *
 * @param context
 * @param {String} solutionId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorEnableConnectedIntegrations(
  context,
  { solutionId, loadingKey = LOADING_KEY.DISTRIBUTOR_ENABLE_INTEGRATIONS },
) {
  return httpService.put(
    `/distributor/tray/solution-instances/${solutionId}/enable`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#faf5015a-3057-4e89-9221-39c960fdadd2}
 *
 * @param context
 * @param {String} solutionId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDisconnectIntegrations(
  context,
  { solutionId, loadingKey = LOADING_KEY.DISTRIBUTOR_DISCONNECT_INTEGRATIONS },
) {
  return httpService.delete(`/distributor/tray/solution-instances/${solutionId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.17.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e414d094-f8d0-4c82-ba1f-543594a21ea5}
 *
 * @param context
 * @param body
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCreateIntegration(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_INTEGRATIONS, body, formKey },
) {
  return httpService.post('/distributor/tray/solution-instances', body, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#64668e74-1b9f-493b-af1a-558d89e89781}
 *
 * @param context
 * @param {Boolean} withLocations
 * @param loadingKey
 */
function distributorFetchWarehouses(
  context,
  { withLocations = false, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES } = {},
) {
  return httpService.get('/distributor/warehouses', {
    headers: { loadingKey },
    params: { withLocations },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#bafa623e-5e80-442b-aca3-74dc4a4c0e1a}
 *
 * @param context
 * @param body warehouse data
 * @param loadingKey
 */
function distributorCreateWarehouse(
  context,
  { body, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_WAREHOUSE } = {},
) {
  return httpService.post('/distributor/warehouses', body, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#080dd1b1-4d72-42a3-869a-d705dfa0160a}
 *
 * @param context
 * @param id warehouse id
 * @param body warehouse data
 * @param loadingKey
 */
function distributorUpdateWarehouse(
  context,
  { id, body, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_WAREHOUSE } = {},
) {
  return httpService.put(`/distributor/warehouses/${id}`, body, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6d3c0cc8-84ba-4984-ae21-3a2a8051d658}
 *
 * @param context
 * @param id warehouse id
 * @param loadingKey
 */
function distributorDeleteWarehouse(
  context,
  { id, primaryWarehouseId = null, loadingKey = LOADING_KEY.DISTRIBUTOR_DELETE_WAREHOUSE } = {},
) {
  return httpService.delete(`/distributor/warehouses/${id}`, {
    headers: { loadingKey },
    ...(primaryWarehouseId ? { data: { primaryWarehouseId } } : {}),
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#202e6bcd-0147-404f-adc6-9ac3f5a98cb8}
 *
 * @param context
 * @param query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchStocktakes(
  context,
  { query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_STOCKTAKES } = {},
) {
  return httpService.get('/distributor/stocktakes', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fe2f43c4-381f-4e76-97ff-f203dbe2812e}
 *
 * @param context
 * @param data
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorConductStocktake(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_CONDUCT_STOCKTAKE } = {},
) {
  return httpService.post('/distributor/stocktakes', data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0973490d-4e0d-4572-a92e-20bd962f4219}
 *
 * @param context
 * @param {string} id Stocktake ID
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchSingleStocktake(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE } = {},
) {
  return httpService.get(`/distributor/stocktakes/${id}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#405d6f19-1e60-4c57-b69b-ae385b445aa0}
 *
 * @param context
 * @param {string} id Stocktake ID
 * @param {object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchSingleStocktakeItems(
  context,
  { id, query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE_ITEMS } = {},
) {
  return httpService.get(`/distributor/stocktakes/${id}/items`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#71372883-9107-4029-86d3-214a98938758}
 *
 * @param context
 * @param {string} id Stocktake ID
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorExportSingleStocktake(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_STOCKTAKE } = {},
) {
  return httpService.get(`/distributor/stocktakes/${id}/export`, {
    responseType: 'arraybuffer',
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cd1494e7-4152-4c9c-a99a-b0c5688d3404}
 *
 * @param context
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchConnectedVenues(
  context,
  { query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTED_VENUES },
) {
  return httpService.get('/distributor/connected-venues/v2', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b2cc915b-930a-4f98-910a-7db5b595cb63}
 *
 * @param context
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchCustomerCategories(
  context,
  { query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CUSTOMER_CATEGORIES } = {},
) {
  return httpService.get('/distributor/customer-categories', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e1a43266-8644-4b96-a390-04d10a86c123}
 *
 * @param context
 * @param {Object} body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorCreateCustomerCategory(
  context,
  { body, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_CUSTOMER_CATEGORY, formKey } = {},
) {
  return httpService.post('/distributor/customer-categories', body, {
    headers: { loadingKey, formKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#bf85c15f-9386-4dfb-87c6-623d50182af7}
 *
 * @param context
 * @param {number} categoryId
 * @param {Object} body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorUpdateCustomerCategory(
  context,
  { categoryId, body, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_CUSTOMER_CATEGORY, formKey } = {},
) {
  return httpService.put(`/distributor/customer-categories/${categoryId}`, body, {
    headers: { loadingKey, formKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90ebd755-83ac-4245-b5a2-982abffbdaee}
 *
 * @param context
 * @param {number} categoryId
 * @param {Object} body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorDeleteCustomerCategory(
  context,
  { categoryId, loadingKey = LOADING_KEY.DISTRIBUTOR_DELETE_CUSTOMER_CATEGORY } = {},
) {
  return httpService.delete(`/distributor/customer-categories/${categoryId}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9efa7a20-c4e9-4099-81fb-2c9a665e8b7b}
 *
 * @param context
 * @param {Object} query { for: 'orders' | 'credit_notes'; term: string; }
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchVenueFilterOptions(
  context,
  { query, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_FILTER_OPTIONS } = {},
) {
  return httpService.get('/distributor/filters/venues', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5f448593-a155-4f79-a3ed-d3b339a10382}
 *
 * @param context
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchDrivers(
  context,
  { loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_DRIVERS } = {},
) {
  return httpService.get('/distributor/drivers', { headers: { loadingKey } });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#84767fcd-f1ef-4a30-bc6c-163c76c4870f}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} limit
 * @param {number} venueId
 * @param {number} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchDistributorSuggestedMatches(
  context,
  { limit, venueId, term, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_SUGGESTED_MATCHES } = {},
) {
  return httpService.get(`/admin/suggested/distributors`, {
    headers: { loadingKey },
    params: {
      ...(limit ? { limit } : {}),
      ...(venueId ? { venueId } : {}),
      ...(term ? { term } : {}),
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#84767fcd-f1ef-4a30-bc6c-163c76c4870f}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} limit
 * @param {number} venueId
 * @param {number} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchVenueDistributorSuggestedMatches(
  context,
  { limit, venueId, term, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_SUGGESTED_MATCHES } = {},
) {
  return httpService.get(`/venues/${venueId}/suggested/distributors`, {
    headers: { loadingKey },
    params: {
      fromGroup: true,
      ...(limit ? { limit } : {}),
      ...(term ? { term } : {}),
    },
  });
}

export default {
  fetchSingleDistributor,
  fetchDistributors,
  fetchDistributor,
  fetchTimeZones,
  fetchPaymentTerms,
  removeDistributorLogo,
  connectDistributorWithVenue,
  disconnectDistributorWithVenue,

  connectDistributorToVenue,
  disconnectDistributorToVenue,

  sendDistributorInvitation,
  removeDistributor,

  // Refactored Methods
  venueFetchConnectedDistributors,
  venueFetchConnectedDistributorsNew,
  venueAddSupplier,
  venueFetchInvitations,
  venueFetchSupplierSingle,
  venueUpdateSupplierAvailability,
  venueUpdateSuppliersAvailability,
  venueApproveOrDeclineRequest,
  venueFetchDistributorUsers,
  venueFetchDistributorUser,
  venueRemoveDistributorUser,
  venueDisconnectDistributor,
  distributorFetchTermsAndConditions,
  distributorUpdateTermsAndConditions,
  venueFetchDistributorTos,
  venueFetchDistributorTosPdf,
  fetchDistributorSingle,
  distributorUpdateOnboardingSettings,
  distributorGetSinglePendingUpdate,
  distributorGetMergePendingUpdate,
  venueAssignDistributorOnPendingInvoice,
  venueUploadCsvProductsOfflineOrder,
  venueDeleteNewCreatedProduct,
  adminGetSinglePendingUpdate,
  adminUploadCsvProductsOfflineOrder,
  adminDeleteNewCreatedProduct,
  adminFetchSupplierSingle,
  createCheckIn,
  uploadDistributorOutletCSV,
  distributorDownloadOutletsCsv,
  distributorSendInvite,
  distributorCreateTargetVenue,
  distributorDownloadVenueProductsCSV,
  distributorBulkUpdatePriceForTargetVenueProducts,
  distributorFetchVenueVisibleProducts,
  distributorSendOnboardingLink,
  distributorGetPaymentTerms,
  distributorFetchDeliveryDate,
  distributorFetchConnectedVenues,
  distributorFetchVenueFilterOptions,
  distributorFetchDrivers,

  // Provino
  distributorFetchProvinoSetup,
  distributorFetchProvinoSchedule,
  distributorFetchProvinoFirstSchedule,
  distributorFetchProvinoEnums,
  distributorCheckInventoryStatus,
  distributorProvinoCustomScheduleForDelivery,
  distributorProvinoDisconnect,
  // Integrations
  distributorFetchAvailableIntegrations,
  distributorFetchConnectedIntegrations,
  distributorEnableConnectedIntegrations,
  distributorDisconnectIntegrations,
  distributorCreateIntegration,

  // Warehouses
  distributorFetchWarehouses,
  distributorCreateWarehouse,
  distributorUpdateWarehouse,
  distributorDeleteWarehouse,

  // Stocktake
  distributorFetchStocktakes,
  distributorConductStocktake,
  distributorFetchSingleStocktake,
  distributorFetchSingleStocktakeItems,
  distributorExportSingleStocktake,

  // Customer Categories
  distributorFetchCustomerCategories,
  distributorCreateCustomerCategory,
  distributorUpdateCustomerCategory,
  distributorDeleteCustomerCategory,

  // ORM Refactor
  fetchAdminDistributors,
  fetchAdminDistributor,
  fetchAdminDistributorVenues,
  fetchDistributorSuggestedMatches,
  fetchVenueDistributorSuggestedMatches,
};
