import i18n from '@/i18n';

const XeroSetup = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/setup/Index.vue');
const XeroSettings = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/Index.vue');
const XeroAccountInfo = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/Account.vue');
const XeroProducts = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/Products.vue');
const XeroVenues = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/Venues.vue');
const XeroProductGroups = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/ProductGroups.vue');
const XeroCustomerCategories = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/CustomerCategories.vue');
const XeroNotConnected = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/xero/settings/NotConnected.vue');


export default [
  // Setup Route
  {
    name: 'distributor-xero-setup',
    path: '/supplier/xero/setup',
    component: XeroSetup,
  },
  // Settings Route
  {
    path: '/supplier/settings/integrations/xero',
    component: XeroSettings,
    children: [
      {
        path: 'account-info',
        name: 'distributor-xero-account',
        component: XeroAccountInfo,
        meta: {
          title: `${i18n.t('xero.name')} Account Info`,
        },
      },
      {
        path: 'products',
        name: 'distributor-xero-products',
        component: XeroProducts,
        meta: {
          title: `${i18n.t('xero.name')} Products`,
        },
      },
      {
        path: 'outlets',
        name: 'distributor-xero-venues',
        component: XeroVenues,
        meta: {
          title: `${i18n.t('xero.name')} ${i18n.t('global.venues')}`,
        },
      },
      {
        path: 'product-groups',
        name: 'distributor-xero-product-groups',
        component: XeroProductGroups,
        meta: {
          title: `${i18n.t('xero.name')} ${i18n.t('global.productGroups')}`,
        },
      },
      {
        path: 'customer-categories',
        name: 'distributor-xero-customer-categories',
        component: XeroCustomerCategories,
        meta: {
          title: `${i18n.t('xero.name')} ${i18n.t('global.customerCategories')}`,
        },
      },
      {
        path: '/',
        name: 'distributor-xero-not-connected',
        component: XeroNotConnected,
        meta: {
          title: `${i18n.t('xero.name')} Not Connected`,
        },
      },
    ],
  },
];
