const SET_PURCHASING_LIMITS = (state, purchasingLimits) => {
  state.purchasingLimits = purchasingLimits;
};

const ADD_PURCHASING_LIMIT = (state, pLimit) => {
  state.purchasingLimits = [...state.purchasingLimits, pLimit];
};

const EDIT_PURCHASING_LIMIT = (state, pLimit) => {
  state.purchasingLimits = state.purchasingLimits
    .map(limit => (limit.id === pLimit.id ? pLimit : limit));
};

const DELETE_PURCHASING_LIMIT = (state, pLimitId) => {
  state.purchasingLimits = state.purchasingLimits.filter(pLimit => pLimit.id !== pLimitId);
};

export default {
  SET_PURCHASING_LIMITS,
  ADD_PURCHASING_LIMIT,
  EDIT_PURCHASING_LIMIT,
  DELETE_PURCHASING_LIMIT,

};
