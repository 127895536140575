import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let draft = null;

try {
  draft = JSON.parse(localStorage.getItem('venueGroupDraft') || '');
} catch (e) {
  // continue
}

const state = {
  draft: draft || {
    id: null,
    currentStep: 0,
    name: '',
    logo: null,
    isDefaultImage: true,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
