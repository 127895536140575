const UPDATE_BULK_ACTION_PRODUCTS = (state, payload) => {
  state.bulkActionProducts = {
    ...state.bulkActionProducts,
    ...payload,
  };
};

const CLEAR_BULK_ACTION_PRODUCTS = (state) => {
  state.bulkActionProducts = {};
};

export default {
  UPDATE_BULK_ACTION_PRODUCTS,
  CLEAR_BULK_ACTION_PRODUCTS,
};
