import httpService from '@/api/http';
import Product from '@/models/Product';
import SpecialTier from '@/models/SpecialTier';
import { LOADING_KEY } from '@/util/constants';

export const fetchProducts = (
  context,
  {
    payload: {
      term = undefined,
      nextId = undefined,
      sortBy = undefined,
      nextValue = undefined,
      filters = {},
      limit = 25,
      distributorId = null,
    } = {},
    loadingKey,
  },
) => {
  const isAdmin = localStorage.getItem('role') === 'admin';
  const isVenue = localStorage.getItem('role') === 'venue';
  const venueContext = context.rootState.entities.users.contextId;

  let route = '';

  if (isAdmin && !distributorId) {
    throw new Error('Distributor id is required');
  }

  if (isVenue && !venueContext) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  if (isAdmin) {
    route = `/admin/distributors/${distributorId}/products`;
  } else if (isVenue) {
    route = `/venues/${venueContext}/products`;
  } else {
    route = '/distributor/products';
  }

  return httpService
    .get(route, {
      params: {
        nextId,
        limit,
        term,
        sortBy,
        nextValue,
        ...filters,
      },
      headers: {
        loadingKey,
      },
    })
    .then(({ data: { data, meta } }) => {
      context.commit('UPDATE_META', { meta });

      return data;
    });
};

export const filterProducts = (context, { payload, loadingKey }) =>
  fetchProducts(context, { payload, loadingKey }).then(data => Product.create({ data }));

export const fetchMoreProducts = (context, payload) => {
  const {
    meta: { nextId },
  } = context.state;

  if (!nextId) {
    return null;
  }

  return fetchProducts(context, {
    payload: { ...payload, nextId },
    loadingKey: 'load-more-products',
  }).then(data => Product.insertOrUpdate({ data }));
};

export const fetchProduct = (context, { route, loadingKey = LOADING_KEY.FETCH_PRODUCT }) =>
  httpService
    .get(route, {
      headers: {
        loadingKey,
      },
    })
    .then(({ data }) => {
      const product = data.data;

      const specialTierPricings = (product.specialTierPricings || []).map(tierPricing => ({
        ...tierPricing,
        tiers: tierPricing.tiers.map(tier => ({
          ...tier,
          price:
            tierPricing.discountType === 'price'
              ? tier.value
              : (1 - tier.value / 100) * product.price,
        })),
      }));

      return Product.insertOrUpdate({
        data: {
          ...product,
          specialTierPricings,
        },
      });
    });

export const deleteDefaultTierPricing = (context, route, loadingKey) =>
  httpService
    .patch(
      route,
      {
        defaultTierPricing: null,
      },
      {
        headers: {
          loadingKey,
        },
      },
    )
    .then(({ data }) => Product.update({ data: data.data }));

export const deleteSpecialTierPricing = (
  context,
  { route, specialTierId, specialTierPricings, loadingKey },
) => {
  const requestConfig = {
    headers: {
      'content-type': 'multipart/form-data',
      loadingKey,
    },
  };
  const specialTiers = specialTierPricings.filter(item => item.id !== specialTierId);

  const formData = new FormData();

  if (specialTiers.length) {
    specialTiers.forEach((specialTier, ind) => {
      const specialTierName = `specialTierPricings[${ind}]`;

      formData.append(`${specialTierName}[id]`, specialTier.id);
      formData.append(`${specialTierName}[name]`, specialTier.name);
      formData.append(`${specialTierName}[discountType]`, specialTier.discountType);

      specialTier.tiers.forEach((tier, tierInd) => {
        const tierName = `${specialTierName}[tiers][${tierInd}]`;

        formData.append(`${tierName}[id]`, tier.id);
        formData.append(`${tierName}[value]`, tier.value);
        formData.append(`${tierName}[minQuantity]`, tier.minQuantity);
        formData.append(`${tierName}[marketPrice]`, tier.marketPrice);
      });
      specialTier.assignedVenues.forEach((venue, venueInd) => {
        const venueName = `${specialTierName}[venueIds][${venueInd}]`;

        formData.append(`${venueName}`, venue.id);
      });
    });
  } else {
    formData.append('specialTierPricings', '');
  }

  return httpService
    .patch(route, formData, requestConfig)
    .then(() => SpecialTier.delete(specialTierId));
};
