import { BILL_COMBINED, BILL_TYPE_OPTIONS } from '@/util/constants';

function combinedExpenseBillLabel(state) {
  const { combinedExpenseBill } = state.draft;
  const type = BILL_TYPE_OPTIONS.find(opt => opt.value === combinedExpenseBill);
  return type?.label;
}

function isBillCombined(state) {
  const { combinedExpenseBill } = state.draft;
  return combinedExpenseBill === BILL_COMBINED.value;
}

export default {
  combinedExpenseBillLabel,
  isBillCombined,
};
