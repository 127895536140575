<template>
  <div :data-cy="commonCy.GENERAL.PAGE_LOADER">
    <transition name="fade">
      <div class="loader" v-if="show">
        <ez-spinner/>
        <div class="loader__text" :data-cy="`${commonCy.GENERAL.PAGE_LOADER}-text`">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzSpinner from '@/components/ui/Spinner';

export default {
  components: {
    EzSpinner,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
};
</script>

<style scoped lang="scss">
.loader {
  @include absolute(0);
  @extend %flex-center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(#fff, .9);
  @include z-index('loading');

  &__text {
    margin-top: 1.6em;
    @include font-size(20px);
    font-weight: bold;
  }
}
</style>
