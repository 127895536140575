import httpService from '@/api/http';
import Category from '@/models/Category';
import User from '@/models/User';
import Venue from '@/models/Venue';
import Permission from '@/mixins/permissions';
import { isAdmin, LOADING_KEY } from '@/util/constants';

const adminLogout = () => httpService.delete('/admin/oauth');
const managerLogout = () => httpService.delete('/manager/oauth');
const fetchMe = (context, loadingKey = LOADING_KEY.ME) => httpService.get('/me', {
  headers: {
    loadingKey,
  },
});

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#214f5866-025b-460a-8ebc-0745d39a1e22}
 * @param {string} accountType
 * @param {string} term
 * @param {string} nextId
 * @param {Number} limit
 * @param {string} sort
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAccounts(
  context,
  { accountType, term, nextId, limit, sort, loadingKey = LOADING_KEY.FETCH_ACCOUNTS } = {},
) {
  return httpService.get('/accounts', {
    params: {
      accountType,
      term,
      nextId,
      limit,
      sort,
    },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a13810ce-9af5-43ca-866f-3428c57c82c3}
 * @param {string} accountId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function updateDefaultAccount(
  context,
  { accountId, loadingKey = LOADING_KEY.UPDATE_DEFAULT_ACCOUNT } = {},
) {
  return httpService.put(`/accounts/default/${accountId}`, {
    headers: { loadingKey },
  });
}

/**
 * Check if entity id belongs to the current account, or current user.
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0dda0252-577b-485f-97bb-2397ea5499a8}
 * @param {string} accountId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function ownerCheck(
  context,
  { data, loadingKey = LOADING_KEY.UPDATE_DEFAULT_ACCOUNT } = {},
) {
  return httpService.put('/accounts/owner-check', data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7ab73d59-f294-4a9f-8a69-e238b650a699}
 * @param {string} accountId
 * @param {string} newAdminId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function leaveAccount(
  context,
  { accountId, newAdminId, loadingKey = LOADING_KEY.LEAVE_ACCOUNT } = {},
) {
  return httpService.delete(`/accounts/${accountId}`, { data: { newAdminId } }, {
    headers: { loadingKey },
  });
}

const date = new Date().toISOString();

const initVenue = ({ commit, rootState }) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  return Promise.all([
    httpService.get(`/me?r=${date}`),
  ])
    .then(([meResults]) => {
      const loggedUser = meResults.data.data;
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
      localStorage.setItem('permissions', JSON.stringify(loggedUser.permissions));

      commit('SET_LOGGED_USER', loggedUser);
      const context = loggedUser.context === 'venue' ? loggedUser.venues : loggedUser.group;
      commit('SET_CONTEXT', {
        context: loggedUser.context, 
        venues: context,
      });
    });
};

const initVenueLoggedUser = ({ commit }, { venueId } = {}) => fetchMe()
  .then(({ data }) => {
    const loggedUser = data.data;

    localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
    localStorage.setItem('permissions', JSON.stringify(loggedUser.permissions));

    const currentVenue = loggedUser.context === 'venue' ? 
      loggedUser.venues.find(v => v.id === venueId) || loggedUser.venues[0] : 
      loggedUser.group;
    commit('SET_LOGGED_USER', loggedUser);
    commit('SWITCH_CONTEXT', currentVenue);
    localStorage.setItem('subscriptionStatus', currentVenue.subscriptionStatus);
  });


const initDistributorLoggedUser = ({ commit }) => fetchMe()
  .then(({ data }) => {
    const loggedUser = data.data;

    localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
    localStorage.setItem('permissions', JSON.stringify(loggedUser.permissions));
    localStorage.setItem('subscriptionStatus', loggedUser.distributor.subscriptionStatus);

    commit('SET_LOGGED_USER', loggedUser);
  });

const initDistributor = ({ commit }, { includeVenues = true } = {}) => new Promise((resolve, reject) => {
  const config = {
    headers: {
      loadingKey: LOADING_KEY.INIT_DISTRIBUTOR,
    },
  };
  Promise.all([
    httpService.get(`/me?r=${date}`, config),
    ...(includeVenues ? [httpService.get('/distributor/me/venues', config)] : [Promise.resolve({ data: { data: null } })]),
    httpService.get('/distributor/categories', config),
  ])
    .then(([meResults, venuesResponse, categoriesResponse]) => {
      const loggedUser = meResults.data.data;
      const { distributor } = meResults.data.data;
      const venues = venuesResponse.data.data;
      const categories = categoriesResponse.data.data;
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
      localStorage.setItem('permissions', JSON.stringify(loggedUser.permissions));

      commit('SET_LOGGED_USER', {
        ...loggedUser,
        distributor,
      });

      if (includeVenues) Venue.create({ data: venues });
      Category.create({ data: categories });
      resolve();
    })
    .catch(reject);
});

const fetchUsers = (context, { route, groupId, distributorId }) => httpService.get(route, {
  headers: {
    loadingKey: LOADING_KEY.GET_USERS,
  },
})
  .then(({ data }) => {
    const userData = Array.isArray(data.data)
      ? data.data.map(d => ({
        ...d,
        groupId,
        distributorId,
      }))
      : data.data;

    return User.insertOrUpdate({ data: userData });
  });

const fetchAdminVenueUsers = (context, { groupId, ...payload }) => fetchUsers(context, {
  ...payload,
  groupId,
  route: `/admin/venue-groups/${groupId}/users`,
});
const fetchAdminDistributorUsers = (context, { distributorId, ...payload }) => fetchUsers(context, {
  ...payload,
  distributorId,
  route: `/admin/distributors/${distributorId}/users`,
});

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8128f02d-ba20-4fbc-88e3-744f18ea48c0}
 * @param {string} context
 * @param {number} contextId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function switchContext(
  vuexContext,
  { context, contextId, loadingKey = LOADING_KEY.SWITCH_CONTEXT } = {},
) {
  return httpService.put('manager/oauth/context', { context, contextId }, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0edd6ea2-f4ed-481f-b2e7-fcee7910337d}
 * @param context
 * @param {number} distributorId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributorUsersNew(
  context,
  { distributorId, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_USERS } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}/users`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a9d16419-6791-42f5-bf5b-04b37f39cba3}
 * @param context
 * @param {number} distributorId
 * @param {number} userId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributorUserNew(
  context,
  { distributorId, userId, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_USER } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}/users/${userId}`, {
    headers: { loadingKey },
  });
}

const fetchVenueUsers = (context, payload) => fetchUsers(context, {
  ...payload,
  route: '/venue-group/users',
});

const fetchPurchasingLimitsUsers = async (context, venueId = null) => {
  const venueContext = context.rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  const vId = venueId || venueContext;

  const { data: { data } } = await httpService.get(`${isAdmin() ? 'admin/' : ''}venues/${vId}/purchasing-limits/users`);
  return User.create({ data });
};

const fetchVenueUser = (context, { groupId, id, ...payload }) => fetchUsers(context, {
  ...payload,
  groupId,
  route: `/venue-group/users/${id}`,
});

const fetchDistributorUsers = (context, payload) => fetchUsers(context, {
  ...payload,
  route: '/distributor/users',
});
const fetchDistributorUser = (context, { distributorId, id, ...payload }) => fetchUsers(context, {
  ...payload,
  distributorId,
  route: `/distributor/users/${id}`,
});

const fetchAccountOwners = async (context) => {
  const res = await fetchDistributorUsers(context);
  const { users } = res;
  return users.filter(user => Permission.checkUser(user, 'isAccountOwner'));
};

const fetchAdminVenueUser = (context, { groupId, id, ...payload }) => fetchUsers(context, {
  ...payload,
  groupId,
  route: `/admin/venue-groups/${groupId}/users/${id}`,
});

// eslint-disable-next-line max-len
const fetchAdminDistributorUser = (context, { distributorId, id, ...payload }) => fetchUsers(context, {
  ...payload,
  distributorId,
  route: `/admin/distributors/${distributorId}/users/${id}`,
});

const removeUser = (context,
  {
    route,
    id,
    replacementOwnerId,
    replacementPrimaryUserId,
    loadingKey = LOADING_KEY.REMOVE_USER,
  }) => httpService.delete(route, {
  data: {
    replacementOwnerId,
    replacementPrimaryUserId,
  },
  headers: {
    loadingKey,
  },
})
  .then(() => User.delete(id));

const removeAdminDistributorUser = (context, {
  id,
  distributorId,
  replacementOwnerId,
}) => removeUser(context, {
  route: `/admin/distributors/${distributorId}/users/${id}`,
  id,
  replacementOwnerId,
});

const removeAdminVenueUser = (context, {
  id,
  groupId,
}) => removeUser(context, {
  route: `/admin/venue-groups/${groupId}/users/${id}`,
  id,
});

const removeVenueUser = (context, { id, replacementPrimaryUserId }) => removeUser(context, {
  route: `/venue-group/users/${id}`,
  id,
  replacementPrimaryUserId,
});

const removeDistributorUser = (context, id) => removeUser(context, {
  route: `distributor/users/${id}`,
  id,
});

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a3e0ca3b-e5b3-48c2-ab7f-46d3299614b6}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchDistributorUsers(context, { distributorId }) {
  return httpService.get(`/venue/distributors/${distributorId}/users`);
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2ad6002c-e2e5-4ef2-a0b9-e1e55c923def}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} replacementOwnerId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteDistributorUser(context, {
  distributorId,
  replacementOwnerId,
  replacementPrimaryUserId,
  loadingKey = LOADING_KEY.DELETE_DISTRIBUTOR_USER,
}) {
  return httpService.delete(`/distributor/users/${distributorId}`, {
    data: {
      replacementOwnerId,
      replacementPrimaryUserId,
    },
    headers: {
      loadingKey,
    },
  });
}

async function updateVenueContext({ commit }) {
  const { data } = await fetchMe();
  commit('SET_LOGGED_USER', data.data);
  const context = data.data.context === 'venue' ? data.data.venues : data.data.group;
  commit('SET_CONTEXT', {
    context: data.data.context,
    venues: context,
  });
}

/**
 * This action fetches all available currencies for admin.
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchCurrencies({ loadingKey = LOADING_KEY.ADMIN_FETCH_CURRENCIES } = {}) {
  return httpService.get('/admin/currencies', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * This action fetches all available currencies for user.
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchCurrencies({ loadingKey = LOADING_KEY.FETCH_CURRENCIES } = {}) {
  return httpService.get('/currencies', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b06cd053-fccb-405a-933b-558efbe8cfa4}
 *
 * @param context
 * @param {number | string} groupId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchUsers(context, { groupId, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_GROUP_USERS }) {
  return httpService.get(`/admin/venue-groups/${groupId}/users`, { headers: { loadingKey } });
}

/**
 * @since 3.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#053168c2-6287-41da-9d15-7736b96d7f83}
 *
 * @param context
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function supplierFetchVenueUsers(context, { venueId }) {
  return httpService.get(`/distributor/venues/${venueId}/users`);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#46ca59f8-cf39-467d-b446-f787c4db16a4}
 *
 * @param context
 * @return {Promise<AxiosResponse<any>>}
 */
function supplierFetchVenueUsersTypes() {
  return httpService.get('/contacts/types?scope=venue');
}

/**
 * @since 3.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0bd080f4-df84-40f5-8704-688f3b120bac}
 *
 * @param context
 * @param venueId
 * @param userId
 * @return {Promise<AxiosResponse<any>>}
 */
function supplierFetchVenueUser(context, { venueId, userId }) {
  return httpService.get(`/distributor/venues/${venueId}/users/${userId}`);
}

/**
 * @since 3.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2ffe3528-9a3f-43fe-9766-12b65e724a8f}
 *
 * @param context
 * @param venueId
 * @param userId
 * @return {Promise<AxiosResponse<any>>}
 */
function supplierDeleteVenueUser(context, { venueId, userId }) {
  return httpService.delete(`/distributor/venues/${venueId}/users/${userId}`);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fe3c2e13-b99a-4199-8654-e778f130ede5}
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchSingleUser(context, { id }) {
  return httpService.get(`/distributor/users/${id}`);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2679d6ad-c1f5-465c-906d-053faad8f9e5}
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchSingleUser(context, { id }) {
  return httpService.get(`/venue-group/users/${id}`);
}

export default {
  fetchCurrencies,
  adminFetchCurrencies,
  fetchAccountOwners,

  adminLogout,
  managerLogout,
  fetchMe,
  fetchAccounts,
  updateDefaultAccount,
  leaveAccount,
  initVenueLoggedUser,
  initVenue,
  switchContext,
  ownerCheck,

  fetchUsers,
  removeUser,
  fetchAdminVenueUser,
  fetchAdminVenueUsers,
  fetchAdminDistributorUsers,
  fetchAdminDistributorUser,
  removeAdminDistributorUser,
  removeAdminVenueUser,

  fetchVenueUsers,
  fetchVenueUser,
  fetchPurchasingLimitsUsers,
  removeVenueUser,

  initDistributorLoggedUser,
  initDistributor,
  fetchDistributorUsers,
  fetchDistributorUser,
  removeDistributorUser,

  // Refactored
  venueFetchDistributorUsers,
  updateVenueContext,
  deleteDistributorUser,
  adminFetchUsers,
  supplierFetchVenueUsers,
  supplierFetchVenueUser,
  supplierDeleteVenueUser,
  supplierFetchVenueUsersTypes,
  distributorFetchSingleUser,
  venueFetchSingleUser,

  // ORM Refactor
  fetchAdminDistributorUsersNew,
  fetchAdminDistributorUserNew,
};
