<template>
  <ez-modal ref="uploadCSVModal" @close="resetCSV">
    <div class="modal__inner">
      <div class="upload-csv-modal">
        <button class="modal__close" @click="$refs.uploadCSVModal.close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"
            />
          </svg>
        </button>
        <transition name="fade">
          <div v-if="!uploadFinished">
            <h2>Upload CSV</h2>
            <div>
              You can
              <ez-button type="blue-link" @click="downloadCsvTemplate" class="download-template">
                download CSV template
              </ez-button>
              and use it when importing products.
            </div>
            <ez-csv-flow
              ref="csvPrev"
              :submit-function="uploadCSV"
              @onSuccess="uploadCSVFinished"
              @onFileAdded="enableSubmitButton"
              @onError="onError"
              @onUpload="uploadProgress"
            />
            <span class="upload-csv-modal__tips">Tips for Import</span>
            <ul v-if="venueStocktake" class="checklist">
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Products from this location are already included in the file, please update only the
                counts.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                If you already counted some of the products, the counts will be included in the
                file.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                When you are finished, save Products sheet as Text CSV (.csv) file and upload it.
              </li>
            </ul>
            <ul v-else-if="venueAllStock" class="checklist">
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Input counting order in location columns in a comma delimited format (example:
                1,3,5).
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Value 0 in location columns means that product is assigned to the location but
                counting order is not defined.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Empty values in the location columns mean that product is not assigned to that
                location.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                When you are finished, save Products sheet as Text CSV (.csv) file and upload it.
              </li>
            </ul>
            <ul class="checklist" v-else>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Product name and price for every product are mandatory.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                Category and discount type already have populated options, so please use one of
                these.
              </li>
              <li>
                <font-awesome-icon icon="check-circle" class="checklist__icon" />
                When you are finished, save Products sheet as Text CSV (.csv) file and upload it.
              </li>
            </ul>
            <div class="upload-csv-modal__actions">
              <ez-button :disabled="!addedCSV" @click="submitCsv">Upload CSV</ez-button>
            </div>
          </div>
          <div v-else-if="!csvStats.inProgress">
            <h2>Completed! Here are the results:</h2>
            <ul class="csv-stats">
              <li>
                <strong>CSV file</strong>
                <span>{{ csvStats.filename }}</span>
              </li>
              <li>
                <strong>Products processed</strong>
                <span>{{ csvStats.total }}</span>
              </li>
              <li>
                <strong>Products successfully uploaded</strong>
                <span>{{ csvStats.inserted }}</span>
              </li>
              <li>
                <strong>Products updated</strong>
                <span>{{ csvStats.updated }}</span>
              </li>
              <li
                :class="{
                  rejected: true,
                  'rejected--has-rejected': csvStats.rejected,
                }"
                @click="toggleErrorVisibility"
              >
                <div class="rejected__trigger">
                  <strong>
                    Products rejected due to errors
                    <span>
                      <font-awesome-icon v-if="errorsExpanded" icon="angle-up" />
                      <font-awesome-icon v-else icon="angle-down" />
                    </span>
                  </strong>
                  <span>{{ csvStats.rejected }}</span>
                </div>

                <ez-csv-error-list
                  v-if="errorsExpanded"
                  :csvErrors="csvStats.rejectedRows"
                  :showOverlay="hasMoreRejected"
                ></ez-csv-error-list>
              </li>
            </ul>
            <ez-button :isFullWidth="true" @click="closeUploadCSV">Go to Products</ez-button>
          </div>
          <div v-else>
            <h2>Your CSV upload is still in progress!</h2>
            <div class="mb-48">Once it is processed, you will receive an email update.</div>
            <ez-button :isFullWidth="true" @click="closeUploadCSV">Go back to Products</ez-button>
          </div>
        </transition>
      </div>
    </div>
  </ez-modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EzCsvFlow from '@/components/ui/CSVFlow';
import { EzModal } from '@/components/ui/Modal';
import EzCsvErrorList from '@/components/ui/Modal/EzCsvErrorList.vue';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import downloadAttachment from '@/util/downloadAttachment';
import httpService from '@/api/http';

export default {
  components: {
    EzCsvFlow,
    EzButton,
    EzModal,
    EzCsvErrorList,
  },
  props: {
    uploadCSV: {
      required: true,
      type: Function,
    },
    onUploadFinished: {
      required: false,
      type: Function,
      default: () => null,
    },
    forVenue: {
      type: Boolean,
      required: false,
      default: false,
    },
    forAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    forDistributor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPendingInvoice: {
      type: Boolean,
      required: false,
      default: false,
    },
    distributorId: {
      type: Number,
      required: false,
      default: -1,
    },
    venueId: {
      type: Number,
      required: false,
      default: -1,
    },
    venueStocktake: {
      type: Boolean,
      required: false,
      default: false,
    },
    venueAllStock: {
      type: Boolean,
      required: false,
      default: false,
    },
    stocktakeTemplateUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      uploadFinished: false,
      addedCSV: false,
      csvStats: {
        filename: '',
        total: 0,
        inserted: 0,
        updated: 0,
        rejected: 0,
        rejectedRows: [],
      },
      errorsExpanded: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    csvTemplateUrl() {
      const queryParams = [
        ...(this.forVenue ? ['for=venue'] : []),
        ...(this.forDistributor ? ['for=distributor'] : []),
        ...(this.forAll ? ['for=all'] : []),
        ...(this.isPendingInvoice ? ['for=pendingInvoice'] : []),
      ];
      if (this.isVenue) {
        return `/venue/products/import-template?${queryParams}`;
      }
      if (this.isDistributor) {
        return `/distributor/products/import-template?${queryParams}`;
      }
      const adminQuery = [
        ...(this.venueId ? [`venueId=${this.venueId}`] : []),
        ...(this.distributorId ? [`distributorId=${this.distributorId}`] : []),
        ...(this.isPendingInvoice ? ['for=pendingInvoice'] : []),
      ].join('&');
      return `admin/products/import-template?${adminQuery}`;
    },
    hasMoreRejected() {
      const { rejected, rejectedRows } = this.csvStats;

      if (typeof rejected === 'undefined') {
        return false;
      }

      return Number(rejected) > rejectedRows.length;
    },
  },
  methods: {
    ...mapActions('entities/products', ['uploadDistributorCSV', 'getTemplate']),
    open() {
      this.$refs.uploadCSVModal.open();
    },
    resetCSV() {
      // Restart everything
      this.addedCSV = false;
      this.csvStats = false;
      this.uploadFinished = false;
    },
    uploadProgress() {
      this.$refs.uploadCSVModal.togglePreventClose();
    },
    enableSubmitButton() {
      this.addedCSV = true;
    },
    onError(errorResponse) {
      this.disableSubmitButton();

      const {
        response: {
          data: { error },
        },
      } = errorResponse;

      const message = error && error.message ? error.message : '';

      flash.error({
        title: 'Something went wrong!',
        message: message || 'We are not able to process your request at the moment.',
      });
    },
    disableSubmitButton() {
      this.addedCSV = false;
      this.$refs.uploadCSVModal.togglePreventClose();
    },
    closeUploadCSV() {
      this.$refs.uploadCSVModal.close();
      this.resetCSV();
    },
    uploadCSVFinished(stats) {
      this.$refs.uploadCSVModal.togglePreventClose();
      this.csvStats = { ...this.csvStats, ...stats };
      this.uploadFinished = true;
      this.onUploadFinished(stats);
    },
    submitCsv() {
      this.$refs.csvPrev.submit();
    },
    toggleErrorVisibility() {
      this.errorsExpanded = !this.errorsExpanded;
    },
    async downloadCsvTemplate() {
      try {
        if (this.venueStocktake || this.venueAllStock) {
          const { data, headers } = await httpService.get(this.stocktakeTemplateUrl);
          const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
          const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
          downloadAttachment(data, name);
        } else {
          const { data, headers } = await this.getTemplate({ route: this.csvTemplateUrl });
          const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
          const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
          downloadAttachment(data, name);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.csv-stats {
  @extend %ul-reset;
  margin-bottom: 3.4em;

  li {
    @extend %flex-center;
    justify-content: space-between;
    padding: 1.7em 0;
    border-bottom: 1px solid #dee1e4;
    &.rejected {
      flex-direction: column;
      align-items: stretch;
      padding-bottom: 0;
      .rejected__trigger {
        padding-bottom: 1.7em;
        display: flex;
        justify-content: space-between;
      }
      &--has-rejected {
        cursor: pointer;
      }
    }
    transition: display 0.5s ease-in-out;
  }

  strong {
    color: #8790a3;
    flex-shrink: 0;
  }

  span {
    flex: 1 1 auto;
    margin-left: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
}

.modal__close {
  @include absolute(top 1em right 1em);
  @include font-size(22px);
  color: #858fa1;
  svg {
    fill: #858fa1;
  }
}

.upload-csv-modal {
  position: relative;
  padding: 48px;
  a {
    color: #507efe;
  }
  &__tips {
    @include font-size(12px, 14px);
    color: $color-gray-6C;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}

.checklist {
  margin-top: 1em;
  margin-bottom: 2.4em;
  @extend %ul-reset;

  li {
    @extend %flex-center;
    @include font-size(14px, 20px);
    margin-bottom: 0.8em;
    align-items: flex-start;
  }

  &__icon {
    margin-right: 0.5em;
    @include font-size(16px);
    height: px-to-rem(20px);
    color: #34b384;
  }
}
.download-template {
  text-decoration: underline;
  padding: 0;
}
</style>
