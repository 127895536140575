const INSERT_ME = (state, { me }) => {
  state.me = me;
};

const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const UPDATE_DRAFT_SUPPLIER = (state, payload) => {
  state.draftSupplier = payload;
};

const UPDATE_DRAFT_ADMIN = (state, payload) => {
  state.draftSupplierAdmin = payload;
};

const UPDATE_SELECTED_SUPPLIER = (state, payload) => {
  state.selectedSupplier = {
    ...state.selectedSupplier,
    ...payload,
  };
};

const REMOVE_SELECTED_SUPPLIER = (state) => {
  state.selectedSupplier = {};
};

const CLEAR_DRAFT = (state) => {
  state.draft = {};
  state.draftSupplier = { id: -1 };
  state.draftSupplierAdmin = { id: -1 };
};

function UPDATE_DISTRIBUTOR_COUNT(state, count) {
  state.distributorCount = count;
}

function UPDATE_ONBOARDING_FLAG(state, payload) {
  state.isOnboardingRequest = payload;
}

const UPDATE_CHECK_IN_INFO = (state, payload) => {
  state.checkInInfo = { ...state.checkInInfo, ...payload };
};

const SET_CHECK_IN_INFO_VENUE = (state, payload) => {
  state.checkInInfo.venue = payload;
};

const SET_CHECK_IN_INFO_DATETIME = (state, payload) => {
  state.checkInInfo.datetime = payload;
};

const SET_CHECK_IN_INFO_SPENT = (state, payload) => {
  state.checkInInfo.moneySpent = payload;
};

const SET_CHECK_IN_INFO_TAX = (state, payload) => {
  state.checkInInfo.taxRate = payload;
};

const SET_CHECK_IN_INFO_NOTE = (state, payload) => {
  state.checkInInfo.note = payload;
};

const SET_CHECK_IN_INFO_TYPE = (state, payload) => {
  state.checkInInfo.type = payload;
};

const SET_CHECK_IN_INFO_RECEIPTS = (state, payload) => {
  state.checkInInfo = {
    ...state.checkInInfo,
    receipts: payload,
  };
};

const CLEAR_CHECK_IN_INFO_SPENT = (state) => {
  state.checkInInfo.moneySpent = 0;
};

const CLEAR_CHECK_IN_INFO_TAX = (state) => {
  state.checkInInfo.taxRate = null;
};

const CLEAR_CHECK_IN_INFO_RECEIPTS = (state) => {
  state.checkInInfo = {
    ...state.checkInInfo,
    receipts: [],
  };
};

const CLEAR_CHECK_IN_INFO_VENUE = (state) => {
  state.checkInInfo.venue = {};
};

const CLEAR_CHECK_IN_INFO_DATETIME = (state) => {
  state.checkInInfo.datetime = null;
};

const CLEAR_CHECK_IN_INFO_NOTE = (state) => {
  state.checkInInfo.note = null;
};

const CLEAR_CHECK_IN_INFO = (state) => {
  state.checkInInfo.moneySpent = 0;
  state.checkInInfo.taxRate = null;
  state.checkInInfo = {
    ...state.checkInInfo,
    receipts: [],
  };
  state.checkInInfo.venue = {};
  state.checkInInfo.datetime = null;
  state.checkInInfo.isVenueAssigned = true;
  state.checkInInfo.note = null;
  state.checkInInfo.type = {
    id: 0,
    name: 'Select Activity Type',
    value: null,
  };
  state.checkInInfo.todoId = null;
  state.checkInInfo.disableVenueChange = false;
};

const SET_EXPENSE_TYPE = (state, payload) => {
  state.expense.type = payload;
};

const SET_EXPENSE_DATETIME = (state, payload) => {
  state.expense.datetime = payload;
};

const SET_EXPENSE_SPENT = (state, payload) => {
  state.expense.moneySpent = payload;
};

const SET_EXPENSE_TAX = (state, payload) => {
  state.expense.taxRate = payload;
};

const SET_EXPENSE_NOTE = (state, payload) => {
  state.expense.note = payload;
};

const SET_EXPENSE_RECEIPTS = (state, payload) => {
  state.expense = {
    ...state.expense,
    receipts: payload,
  };
};

const UPDATE_EXPENSE_INFO = (state, payload) => {
  state.expense = { ...state.expense, ...payload };
};

const CLEAR_EXPENSE_SPENT = (state) => {
  state.expense.moneySpent = 0;
};

const CLEAR_EXPENSE_TAX = (state) => {
  state.expense.taxRate = null;
};

const CLEAR_EXPENSE_RECEIPTS = (state) => {
  state.expense = {
    ...state.expense,
    receipts: [],
  };
};

const CLEAR_EXPENSE_DATETIME = (state) => {
  state.expense.datetime = null;
};

const CLEAR_EXPENSE_NOTE = (state) => {
  state.expense.note = null;
};

const CLEAR_EXPENSE_TYPE = (state) => {
  state.expense.type = {
    id: 0,
    name: 'Select Expense Type',
    value: null,
  };
};

const CLEAR_EXPENSE = (state) => {
  state.expense.moneySpent = 0;
  state.expense.taxRate = null;
  state.expense = {
    ...state.expense,
    receipts: [],
  };
  state.expense.type = {
    id: 0,
    name: 'Select Expense Type',
    value: null,
  };
  state.expense.datetime = null;
  state.expense.note = null;
  state.expense.todoId = null;
};

const SET_SELECTED_INTEGRATION = (state, payload) => {
  state.eCommerceIntegration = payload;
};

const UPDATE_SELECTED_INTEGRATION = (state, payload) => {
  state.eCommerceIntegration = {
    ...state.eCommerceIntegration,
    ...payload,
  };
};

const CLEAR_SELECTED_INTEGRATION = (state) => {
  state.eCommerceIntegration = {};
};

export default {
  UPDATE_CHECK_IN_INFO,
  UPDATE_DRAFT,
  CLEAR_DRAFT,
  INSERT_ME,
  UPDATE_DRAFT_SUPPLIER,
  UPDATE_DRAFT_ADMIN,
  UPDATE_SELECTED_SUPPLIER,
  REMOVE_SELECTED_SUPPLIER,
  UPDATE_DISTRIBUTOR_COUNT,
  UPDATE_ONBOARDING_FLAG,
  SET_CHECK_IN_INFO_VENUE,
  SET_CHECK_IN_INFO_DATETIME,
  SET_CHECK_IN_INFO_NOTE,
  SET_CHECK_IN_INFO_TYPE,
  SET_CHECK_IN_INFO_SPENT,
  SET_CHECK_IN_INFO_TAX,
  SET_CHECK_IN_INFO_RECEIPTS,
  CLEAR_CHECK_IN_INFO_RECEIPTS,
  CLEAR_CHECK_IN_INFO_TAX,
  CLEAR_CHECK_IN_INFO_SPENT,
  CLEAR_CHECK_IN_INFO_VENUE,
  CLEAR_CHECK_IN_INFO_DATETIME,
  CLEAR_CHECK_IN_INFO_NOTE,
  CLEAR_CHECK_IN_INFO,
  UPDATE_EXPENSE_INFO,
  SET_EXPENSE_DATETIME,
  SET_EXPENSE_TYPE,
  SET_EXPENSE_NOTE,
  SET_EXPENSE_TAX,
  SET_EXPENSE_RECEIPTS,
  SET_EXPENSE_SPENT,
  CLEAR_EXPENSE_DATETIME,
  CLEAR_EXPENSE_TYPE,
  CLEAR_EXPENSE_NOTE,
  CLEAR_EXPENSE_TAX,
  CLEAR_EXPENSE_RECEIPTS,
  CLEAR_EXPENSE_SPENT,
  CLEAR_EXPENSE,
  SET_SELECTED_INTEGRATION,
  CLEAR_SELECTED_INTEGRATION,
  UPDATE_SELECTED_INTEGRATION,
};
