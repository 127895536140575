<template>
  <transition name="modal-fade" @after-enter="opened" @after-leave="closed">
    <div class="modal-wrapper" v-show="show">
      <div class="modal-backdrop" @click.stop="close"></div>
      <div class="modal" role="dialog" @click.self="close" @keyup.esc="close">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      preventClose: false,
    };
  },
  methods: {
    opened() { this.$emit('open'); },
    closed() { this.$emit('close'); },
    close() {
      if (this.preventClose === false) {
        document.body.classList.remove('modal-open');
        this.show = false;
      }
    },
    open() {
      document.body.classList.add('modal-open');
      this.show = true;
    },
    handleEscapeKey(event) {
      if (this.show && event.which === 27 && this.preventClose === false) {
        this.close();
      }
    },
    togglePreventClose() {
      this.preventClose = !this.preventClose;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.handleEscapeKey);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleEscapeKey);
  },
};
</script>
<style lang="scss">
.modal-backdrop {
  @include fixed(0);
  background-color: rgba(#121E3D, 0.6);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  @include z-index('modal');
}
.modal {
  flex: 1 0 100%;
  min-height: 100%;
  padding: 3em 24px;
  position: relative;
  overflow-x: auto;
  @include z-index('modal', 10);

  &__inner {
    margin: 0 auto;
    width: 100%;
    border-radius: 4px;
    max-width: 480px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,20,0.1);
  }
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;

  .modal {
    pointer-events: none;
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
</style>
