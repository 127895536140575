<script>
/**
 * EzSpinner
 * @version 1.0.0
 * @since
 */
export default {
  name: 'EzSpinner',
};
</script>

<template>
  <span class="spinner"></span>
</template>

<style lang="scss" scoped>
.spinner {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 4px solid #447CFC;
  border-right-color: transparent;
  border-radius: 50%;

  animation: spin 1.1s infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
