import httpService from '@/api/http';
import { isAdmin } from '@/util/constants';

const fetchPurchasingLimits = async ({ commit }, venueId) => {
  const { data: { data } } = await httpService.get(`${isAdmin() ? 'admin' : ''}/venues/${venueId}/purchasing-limits`);
  commit('SET_PURCHASING_LIMITS', data);
};

/**
 * Gets current venue id
 * @param rootState Vue context rootState
 * @returns {Number} Current Venue Id
 * @private
 */
// eslint-disable-next-line no-underscore-dangle
const _getVenueContextId = (rootState) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }
  return venueContext;
};

/**
 * Helper function that prepares purchasing limit data for API (POST, PUT) request
 * @example
 * // returns {
 *   userId: 2,
 *   items: [{
 *     amount: 100,
 *     categoryId: 16,
 *   }]
 * }
 * @param {Object} purchasingLimit
 * @returns {Object} Return the restructured purchasing limit.
 * @private
 */
// eslint-disable-next-line no-underscore-dangle
const _createUpdateHelper = (purchasingLimit) => {
  const pLimit = JSON.parse(JSON.stringify(purchasingLimit));
  pLimit.userId = pLimit.user.id;
  delete pLimit.user;
  pLimit.items = pLimit.items.map(item => ({
    amount: +item.amount,
    categoryId: item.category.id,
  }));
  return pLimit;
};

// eslint-disable-next-line max-len
const createPurchasingLimit = async ({ commit, rootState }, { purchasingLimit, venueId = null }) => {
  const pLimit = _createUpdateHelper(purchasingLimit);
  const vId = venueId || _getVenueContextId(rootState);
  const { data: { data } } = await httpService.post(`${isAdmin() ? 'admin' : ''}/venues/${vId}/purchasing-limits`, pLimit);
  commit('ADD_PURCHASING_LIMIT', data);
};

// eslint-disable-next-line max-len
const updatePurchasingLimit = async ({ commit, rootState }, { purchasingLimit, venueId = null }) => {
  const pLimit = _createUpdateHelper(purchasingLimit);
  const vId = venueId || _getVenueContextId(rootState);
  const { data: { data } } = await httpService.put(`${isAdmin() ? 'admin' : ''}/venues/${vId}/purchasing-limits/${purchasingLimit.id}`, pLimit);
  commit('EDIT_PURCHASING_LIMIT', data);
};

// eslint-disable-next-line max-len
const deletePurchasingLimit = async ({ commit, rootState }, { purchasingLimitId, venueId = null }) => {
  const vId = venueId || _getVenueContextId(rootState);
  await httpService.delete(`${isAdmin() ? 'admin' : ''}/venues/${vId}/purchasing-limits/${purchasingLimitId}`);
  commit('DELETE_PURCHASING_LIMIT', purchasingLimitId);
};

export default {
  fetchPurchasingLimits,
  createPurchasingLimit,
  updatePurchasingLimit,
  deletePurchasingLimit,
};
