import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { isAdmin, isDistributor, isVenue } from '@/util/constants';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  formatter: {
    interpolate(message, values, path) {
      let msg = message;
      const variants = msg.split('|');

      /**
       * Since we're using different terms for each context + admin, we're checking which one
       * the user is currently logged into and returning the appropriate term.
       *
       * Supplier/distributor context -> Customer
       * Outlet/venue context + admin -> Outlet
       */
      if (path.includes('global.venue')) {
        /**
         * If 'role' isn't set in localStorage, we can safely assume it's a public view.
         */
        const isPublic = !isDistributor() && !isVenue() && !isAdmin();

        const { pathname } = window.location;
        const isDistributorRoute = pathname.includes('supplier') || pathname.includes('distributor');
        const isVenueRoute = pathname.includes('buyer') || pathname.includes('venue');
        const isOnboarding = pathname.includes('onboarding');
        // Manage Accounts Page should always have 'Outlet'
        const isMAP = pathname.includes('accounts');

        if (isDistributor() || (isPublic && isDistributorRoute)) [msg] = variants;
        if (isVenue() || isAdmin() || (isPublic && isVenueRoute)) [, msg] = variants;
        if (isPublic && isOnboarding) [, msg] = variants;
        if (isMAP) [, msg] = variants;
        return msg.trim();
      }

      /**
       * If there are no parameters passed to the function we can just return the initial translation.
       */
      if (!values) return msg;

      /**
       * For $tc instances we're checking the value after the path string.
       */
      const count = values.count ?? values.n;

      /**
       * If there's more than 2 variants of translation, we simply return the appropriate variant
       */
      if (variants.length > 2) msg = variants[count];

      /**
       * If there's only 2 variants, we get a bit more specific.
       */
      if (variants.length === 2) {
        /**
         * If the argument after the path string is 1, we simply return the first variant.
         */
        if (count === 1) [msg] = variants;
        /**
         * If the argument is 0 or 2, we return the second variant. This is the default behavior
         * of the `interpolate` method which is overriden here, and had to be mimicked.
         */
        if (!count || count > 1) [, msg] = variants;
      }

      /**
       * Lastly, we replace the template strings in the translations and return the message i.e. translation.
       */
      Object.entries(values).forEach(([key, val]) => {
        const temp = `{${key}}`;
        if (!msg.includes(temp)) return;
        msg = msg.replaceAll(temp, val);
      });

      return msg.trim();
    },
  },
});
