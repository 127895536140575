import dayjs from 'dayjs';
import { clone } from '@/util/utils';
import uuid from 'uuid/v4';

const ADD_TO_CART = (state, { product, quantity, price }) => {
  const { distributor } = product;
  const existingProducts = state.cart[distributor.id];

  if (!existingProducts) {
    state.cart = {
      ...state.cart,
      [distributor.id]: {
        distributor,
        [product.internalId]: {
          product,
          quantity,
          price,
        },
      },
    };

    return;
  }

  const existingProduct = existingProducts[product.internalId];

  if (!existingProduct) {
    state.cart = {
      ...state.cart,
      [distributor.id]: {
        ...existingProducts,
        [product.internalId]: {
          product,
          quantity,
          price,
        },
      },
    };
  }
};

const UPDATE_PRODUCT_COUNT = (
  state,
  { distributorId, quantity, price, marketPrice, pricePerUnit, internalId },
) => {
  const existingProducts = state.cart[distributorId];

  if (!existingProducts) {
    return;
  }

  const existingProduct = existingProducts[internalId];

  if (!existingProduct) {
    return;
  }

  state.cart = {
    ...state.cart,
    [distributorId]: {
      ...existingProducts,
      [internalId]: {
        ...existingProduct,
        quantity,
        price,
        product: {
          ...existingProduct.product,
          marketPrice,
          price: pricePerUnit,
        },
      },
    },
  };

  if (existingProduct.product?.deals?.length) {
    const deals = existingProduct.product?.deals[0];
    const { buy } = deals;
    const { get } = deals;
    const { price: bonusPrice } = deals;
    const bonusMultiplier = Math.floor((quantity ?? 0) / buy);
    const addedQuantity = bonusMultiplier * get;
    const existingBonusProduct = existingProducts[existingProduct.bonusId];
    if (addedQuantity && !existingBonusProduct) {
      const bonusProductId = uuid();
      state.cart = {
        ...state.cart,
        [distributorId]: {
          ...existingProducts,
          [internalId]: {
            ...existingProduct,
            quantity,
            price,
            product: {
              ...existingProduct.product,
              marketPrice,
              price: pricePerUnit,
            },
            bonusId: bonusProductId,
          },
          [bonusProductId]: {
            ...existingProduct,
            product: {
              ...existingProduct.product,
              price: bonusPrice,
              internalId: bonusProductId,
            },
            quantity: addedQuantity,
            price: addedQuantity * bonusPrice,
            isBonus: true,
            bonusId: null,
            marketPrice: false,
          },
        },
      };
    } else if (existingBonusProduct) {
      state.cart = {
        ...state.cart,
        [distributorId]: {
          ...existingProducts,
          [internalId]: {
            ...existingProduct,
            quantity,
            price,
            product: {
              ...existingProduct.product,
              marketPrice,
              price: pricePerUnit,
            },
          },
          [existingProduct.bonusId]: {
            ...existingBonusProduct,
            quantity: addedQuantity,
            price: addedQuantity * bonusPrice,
            isBonus: true,
            bonusId: null,
            marketPrice: false,
          },
        },
      };
    }
  }
};

const REMOVE_FROM_CART = (state, { distributorId, productId, bonusId }) => {
  const existingProducts = state.cart[distributorId];

  if (!existingProducts) {
    return;
  }

  const remainingProducts = {};
  /**
   * Counter for the number of keys that are not products
   * Cart stores items based on the distributor.
   * Each distributor has product, distributor and message.
   * If there are no products, the distributor should be removed from the cart.
   * When there are no products, the keys left are either only the distributor key
   * or the distributor and the message key.
   * Therefore, we count keys, and in the case of the count being 1 or 2
   * the distributor will be removed.
   * @type {number}
   */
  let keyCount = 0;

  Object.keys(existingProducts).forEach(key => {
    if (['distributor', 'message'].includes(key)) {
      keyCount += 1;
      remainingProducts[key] = existingProducts[key];
    }

    const id = key;

    if (id !== productId && id !== bonusId) {
      remainingProducts[key] = existingProducts[key];
    }
  });

  if (Object.keys(remainingProducts).length <= keyCount) {
    state.cart = {
      ...state.cart,
      [distributorId]: null,
    };
    return;
  }

  state.cart[distributorId] = {
    ...remainingProducts,
  };
};

const UPDATE_DISTRIBUTOR_MESSAGE = (state, { distributorId, message }) => {
  state.cart[distributorId] = {
    ...state.cart[distributorId],
    message,
  };
};

const UPDATE_DISTRIBUTORS = (state, { distributors }) => {
  const { cart } = state;
  const distributorIds = Object.keys(cart);

  distributorIds.forEach(id => {
    const newDist = distributors.find(d => d.id === Number(id));
    if (cart[id]) {
      const oldDist = cart[id].distributor;
      cart[id].distributor = {
        ...oldDist,
        ...newDist,
      };
    }
  });

  state.cart = clone(cart);
};

const UPDATE_CART_TYPE = (state, type) => {
  state.info.type = type;
};
const UPDATE_CART_STANDING = (state, { interval, date, time }) => {
  delete state.info.requestedDeliveryAt;
  const dateTime = `${date} ${dayjs(time).format('HH:mm')}`;
  const dateTimeTimeZone = dayjs(dateTime).format();
  state.info.scheduledAt = `${dateTimeTimeZone || dayjs(new Date()).format()}`;
  state.info.interval = interval;
};
const UPDATE_CART_DATETIME = (state, { date, time }) => {
  delete state.info.interval;
  delete state.info.scheduledAt;
  const dateTime = `${date} ${dayjs(time).format('HH:mm')}`;
  const dateTimeTimeZone = dayjs(dateTime).format();
  state.info.requestedDeliveryAt = `${dateTimeTimeZone || dayjs(new Date()).format()}`;
};

const EMPTY_CART = state => {
  state.cart = {};
  state.info = {};
};

export default {
  UPDATE_DISTRIBUTORS,
  ADD_TO_CART,
  UPDATE_PRODUCT_COUNT,
  REMOVE_FROM_CART,
  UPDATE_DISTRIBUTOR_MESSAGE,
  EMPTY_CART,
  UPDATE_CART_TYPE,
  UPDATE_CART_STANDING,
  UPDATE_CART_DATETIME,
};
