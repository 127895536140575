import { Model } from '@vuex-orm/core';
import SpecialTierItem from '@/models/SpecialTierItem';
import Product from '@/models/Product';
import Venue from './Venue';

export default class SpecialTier extends Model {
  static entity = 'specialTiers';

  static fields() {
    return {
      id: this.attr(null),
      productId: this.attr(null),
      discountType: this.attr('price'),
      name: this.attr(''),
      venues: this.attr([]),
      product: this.belongsTo(Product, 'productId'),
      assignedVenues: this.hasManyBy(Venue, 'venues'),
      tiers: this.hasMany(SpecialTierItem, 'specialTierId'),
    };
  }
}
