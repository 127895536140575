<template>
  <ez-modal ref="modal" v-on="$listeners">
    <div class="modal__image">
      <a :href="image" target="_blank" title="Open in new tab">
        <v-pdf-viewer
          :src="image"
        />
      </a>
    </div>
  </ez-modal>
</template>

<script>
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
import { EzModal } from '.';

/**
 * EzPdfModal
 * @version 1.0.0
 * @since 3.4.0
 */
export default {
  name: 'EzPdfModal',
  components: {
    EzModal,
    VPdfViewer,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingTask: null,
      numPages: undefined,
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal {
  @extend %flex-center;
  justify-content: center;
  padding: 0;

  &__image {
    width: 720px;
  }
}
</style>
