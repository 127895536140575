import httpService from '@/api/http';
import Product from '@/models/Product';
import { LOADING_KEY } from '@/util/constants';
import { filterProducts, fetchMoreProducts } from './commonActions';

const fetchVenueProducts = (context, { payload, loadingKey = LOADING_KEY.FETCH_PRODUCTS }) =>
  filterProducts(context, { payload, loadingKey });

const fetchMoreVenueProducts = (context, payload) => fetchMoreProducts(context, payload);

const fetchVenueProduct = (context, { id, loadingKey = LOADING_KEY.FETCH_PRODUCT }) => {
  const venueContext = context.rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  return httpService
    .get(`/venues/${venueContext}/products/${id}`, {
      headers: {
        loadingKey,
      },
    })
    .then(({ data }) => {
      const product = data.data;

      product.tiers.sort((t1, t2) => t1.minQuantity - t2.minQuantity);

      return Product.insertOrUpdate({ data: product });
    });
};

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d8176875-c178-48e0-b89c-d9b06e5379d4}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueHideDistributorProduct(
  context,
  {
    venueId,
    distributorId,
    productId,
    loadingKey = LOADING_KEY.VENUE_HIDE_UNHIDE_DISTRIBUTOR_PRODUCT,
  },
) {
  return httpService.put(
    `/venues/${venueId}/distributors/${distributorId}/products/${productId}/hide`,
    {},
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#147163d4-2a56-4c48-9724-1697ccc0017b}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param productId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUnhideDistributorProduct(
  context,
  {
    venueId,
    distributorId,
    productId,
    loadingKey = LOADING_KEY.VENUE_HIDE_UNHIDE_DISTRIBUTOR_PRODUCT,
  },
) {
  return httpService.delete(
    `/venues/${venueId}/distributors/${distributorId}/products/${productId}/hide`,
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3fde7835-201c-4ea5-8d04-bc32d82ba1e2}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueHideAllDistributorProduct(
  context,
  { venueId, distributorId, loadingKey = LOADING_KEY.VENUE_HIDE_UNHIDE_PRODUCTS },
) {
  return httpService.put(
    `/venues/${venueId}/distributors/${distributorId}/products/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3b2a2a8d-a5fe-43b4-aaf0-05634daf3981}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUnhideAllDistributorProduct(
  context,
  { venueId, distributorId, loadingKey = LOADING_KEY.VENUE_HIDE_UNHIDE_PRODUCTS },
) {
  return httpService.delete(`/venues/${venueId}/distributors/${distributorId}/products/hide`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.14.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ef2911b7-e003-4cf0-97f5-b9c60283999a}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchUnits() {
  return httpService.get('/venue/units');
}

/**
 * @since 3.14.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a170df31-805b-4680-9efc-af8be979757c}
 *
 * @param context
 * @param data
 * @param loadingKey
 * @param formKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueCreateUnit(
  context,
  { data, loadingKey = LOADING_KEY.VENUE_CREATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.post('/venue/units', data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 3.14.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5d6fdd40-1250-42b4-b339-2d4e5504a736}
 *
 * @param context
 * @param venueId
 * @param unitId
 * @param data
 * @param loadingKey
 * @param formKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueUpdateUnit(
  context,
  { venueId, unitId, data, loadingKey = LOADING_KEY.VENUE_UPDATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.put(`/venues/${venueId}/units/${unitId}`, data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 3.14.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a38437e9-ba6b-4e9b-8acf-8d2b3c50ce01}
 *
 * @param context
 * @param venueId
 * @param unitId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDeleteUnit(context, { venueId, unitId, loadingKey = LOADING_KEY.VENUE_UPDATE_UNIT }) {
  return httpService.delete(`/venues/${venueId}/units/${unitId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#0ee7362c-dd85-4e7c-b0d0-9dcd9c95fdc7}
 *
 * @param context
 * @param venueId
 * @param query
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function searchForVenueProducts(
  context,
  { venueId, query, loadingKey = LOADING_KEY.SEARCH_FOR_VENUE_PRODUCTS },
) {
  return httpService.get(`/venues/${venueId}/products`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.18
 * @see {@link }
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @param term
 * @param nextId
 * @param nextValue
 * @param sortBy
 * @param filters
 * @param limit
 * @param distributorId
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchProducts(
  context,
  {
    venueId,
    loadingKey = LOADING_KEY.FETCH_PRODUCTS,
    term = undefined,
    nextId = undefined,
    sortBy = undefined,
    nextValue = undefined,
    filters = {},
    limit = 25,
    distributorId = null,
  },
) {
  return httpService.get(`/venues/${venueId}/products`, {
    headers: {
      loadingKey,
    },
    params: {
      term,
      nextValue,
      nextId,
      sortBy,
      limit,
      distributorId,
      ...filters,
    },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#0ee7362c-dd85-4e7c-b0d0-9dcd9c95fdc7}
 *
 * @param context
 * @param venueId
 * @param id
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchProduct(context, { venueId, id, loadingKey = LOADING_KEY.FETCH_PRODUCT }) {
  return httpService.get(`/venues/${venueId}/products/${id}`, {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90444119-2d3f-4228-a412-23ea654053b3}
 *
 * @param context
 * @param limit
 * @param nextId
 * @param nextValue
 * @param term
 * @param newFilter
 * @param categoryId
 * @param productGroupId
 * @param distributorId
 * @param sortBy
 * @param visibility
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchAllSuppliersProducts(
  context,
  {
    limit,
    nextId,
    nextValue,
    term,
    newFilter,
    categoryId,
    productGroupId,
    distributorId,
    orderId,
    sortBy,
    visibility,
    venueInventoryTracked,
    loadingKey = LOADING_KEY.VENUE_FETCH_ALL_SUPPLIERS_PRODUCTS,
  },
) {
  return httpService.get('/venue/distributors/products', {
    params: {
      limit,
      nextId,
      nextValue,
      term,
      ...(newFilter ? { new: newFilter } : {}),
      categoryId,
      productGroupId,
      distributorId,
      orderId,
      sortBy,
      visibility,
      venueInventoryTracked,
    },
    headers: { loadingKey },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90444119-2d3f-4228-a412-23ea654053b3}
 *
 * @param context
 * @param config
 * @param term
 * @param categoryId
 * @param productGroupId
 * @param distributorId
 * @param visibility
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueBulkUpdateSuppliersProducts(
  context,
  {
    config,
    term,
    categoryId,
    productGroupId,
    distributorId,
    visibility,
    loadingKey = LOADING_KEY.VENUE_BULK_UPDATE_SUPPLIERS_PRODUCTS,
  },
) {
  return httpService.patch('/venue/distributors/products', config, {
    params: {
      term,
      categoryId,
      productGroupId,
      distributorId,
      visibility,
    },
    headers: { loadingKey },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90444119-2d3f-4228-a412-23ea654053b3}
 *
 * @param context
 * @param config
 * @param distributorId,
 * @param term
 * @param categoryId
 * @param productGroupId
 * @param distributorId
 * @param visibility
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueBulkUpdateSupplierProducts(
  context,
  {
    config,
    term,
    distributorId,
    categoryId,
    productGroupId,
    visibility,
    loadingKey = LOADING_KEY.VENUE_BULK_UPDATE_SUPPLIER_PRODUCTS,
  },
) {
  return httpService.patch(`/venue/distributors/${distributorId}/products`, config, {
    params: {
      term,
      categoryId,
      productGroupId,
      visibility,
    },
    headers: { loadingKey },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5e1faf7c-11f2-4124-b680-f7cf3c7a45ed}
 *
 * @param context
 * @param productId
 * @param productGroupId
 * @param venueInventoryTracked
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueUpdateSuppliersProduct(
  context,
  {
    productId,
    productGroupId,
    venueInventoryTracked,
    loadingKey = LOADING_KEY.VENUE_UPDATE_SUPPLIER_PRODUCT,
  },
) {
  return httpService.patch(
    `/venue/distributors/products/${productId}`,
    { productGroupId, venueInventoryTracked },
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b91f7abb-ab50-4dbe-b430-c2581243e02e}
 *
 * @param context
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchAllProductsStats(
  context,
  { loadingKey = LOADING_KEY.VENUE_FETCH_ALL_PRODUCTS_STATS } = {},
) {
  return httpService.get('/venue/distributors/products/stats', {
    headers: { loadingKey },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b91f7abb-ab50-4dbe-b430-c2581243e02e}
 *
 * @param context
 * @param distributorId,
 * @param limit,
 * @param nextId,
 * @param nextValue,
 * @param categoryId,
 * @param sortBy,
 * @param term,
 * @param loadingKey
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchSuppliersProducts(
  context,
  {
    limit,
    nextId,
    nextValue,
    term,
    categoryId,
    sortBy,
    distributorId,
    loadingKey = LOADING_KEY.VENUE_FETCH_SUPPLIER_PRODUCTS,
  } = {},
) {
  return httpService.get(`/venue/distributors/${distributorId}/products`, {
    params: {
      limit,
      nextId,
      nextValue,
      term,
      categoryId,
      sortBy,
    },
    headers: { loadingKey },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f1055d58-5ca3-4d62-bec1-d5114bf94cd1}
 *
 * @param context
 * @param {number} venueId
 * @param {string} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchProductGroups(
  context,
  { term, loadingKey = LOADING_KEY.FETCH_PRODUCT_GROUPS } = {},
) {
  return httpService.get(`/venue/product-groups`, {
    params: { term },
    headers: { loadingKey },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#732d19ad-aa3f-45a7-96f3-9f3a7fde1276}
 *
 * @param context
 * @param {number} venueId
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueCreateProductGroup(
  context,
  { name, loadingKey = LOADING_KEY.CREATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.post(`/venue/product-groups`, { name }, { headers: { loadingKey, formKey } });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1b4435dd-72b1-4a7d-ac7d-e3848c70ddcc}
 *
 * @param context
 * @param {number} venueId
 * @param {number} id
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueUpdateProductGroup(
  context,
  { id, name, loadingKey = LOADING_KEY.UPDATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.put(
    `/venue/product-groups/${id}`,
    { name },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#16c714aa-d5d1-4cda-a2f6-c874485726ab}
 *
 * @param context
 * @param {number} venueId
 * @param {number} id
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueDeleteProductGroup(
  context,
  { id, loadingKey = LOADING_KEY.DELETE_PRODUCT_GROUP } = {},
) {
  return httpService.delete(`/venue/product-groups/${id}`, { headers: { loadingKey } });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#62172d62-248f-45f9-a04d-2c382c4c09df}
 *
 * @param {number} id
 * @param {Array} locationIds
 * @param {string} loadingKey

 * @return {Promise<AxiosResponse<T>>}
 */
function venueUpdateProductAvailability(
  context,
  { id, locationIds, loadingKey = LOADING_KEY.VENUE_UPDATE_PRODUCT_AVAILABILITY },
) {
  return httpService.put(
    `/venue/distributors/products/${id}`,
    {
      visibleVenueIdsFromGroup: locationIds,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1dbb2c3a-ae69-4579-b906-558281bb5d36}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} limit
 * @param {number} venueId
 * @param {number} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchVenueProductsSuggestedMatches(
  context,
  {
    distributorId,
    limit,
    venueId,
    term,
    productId,
    loadingKey = LOADING_KEY.FETCH_PRODUCT_SUGGESTED_MATCHES,
  } = {},
) {
  return httpService.get(`/venues/${venueId}/distributors/${distributorId}/suggested/products`, {
    headers: { loadingKey },
    params: {
      ...(limit ? { limit } : {}),
      ...(term ? { term } : {}),
      ...(productId ? { productId } : {}),
    },
  });
}

/**
 * @since 3.18
 * @see {@link }
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @param term
 * @param nextId
 * @param nextValue
 * @param filters
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchInventoryProducts(
  context,
  {
    venueId,
    loadingKey = LOADING_KEY.FETCH_PRODUCTS,
    term = undefined,
    nextId = undefined,
    nextValue = undefined,
    filters = {},
    limit = 10,
  },
) {
  return httpService.get(`/venues/${venueId}/inventory-products`, {
    headers: {
      loadingKey,
    },
    params: {
      term,
      nextValue,
      nextId,
      ...filters,
      limit,
    },
  });
}

/**
 * @since 3.18
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f90cc94d-c328-47db-a4da-e4b626bc4cb5}
 *
 * @param context
 * @param venueId
 * @param loadingKey
 * @param term
 * @param filters
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchInventoryProductsTotal(
  context,
  { venueId, loadingKey = LOADING_KEY.FETCH_PRODUCTS, term = undefined, filters = {} },
) {
  return httpService.get(`/venues/${venueId}/inventory-products/valuation`, {
    headers: {
      loadingKey,
    },
    params: {
      term,
      ...filters,
    },
  });
}

/**
 * @since 3.18
 * @see {@link }
 *
 * @param context
 * @param venueId
 * @param productId
 * @param loadingKey
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueUpdateInventoryProduct(
  context,
  { venueId, loadingKey = LOADING_KEY.VENUE_UPDATE_INVENTORY_PRODUCTS, productId, body },
) {
  return httpService.patch(`/venues/${venueId}/inventory-products/${productId}`, body, {
    headers: {
      loadingKey,
    },
  });
}

export default {
  fetchVenueProducts,
  fetchVenueProduct,
  fetchMoreVenueProducts,
  fetchVenueProductsSuggestedMatches,

  // Refactor
  venueHideDistributorProduct,
  venueUnhideDistributorProduct,
  venueHideAllDistributorProduct,
  venueUnhideAllDistributorProduct,
  venueFetchUnits,
  venueCreateUnit,
  venueUpdateUnit,
  venueDeleteUnit,
  searchForVenueProducts,

  venueFetchProducts,
  venueFetchProduct,
  venueFetchAllSuppliersProducts,
  venueBulkUpdateSuppliersProducts,
  venueBulkUpdateSupplierProducts,
  venueFetchSuppliersProducts,
  venueUpdateSuppliersProduct,
  venueFetchAllProductsStats,
  venueFetchProductGroups,
  venueCreateProductGroup,
  venueUpdateProductGroup,
  venueDeleteProductGroup,

  venueUpdateProductAvailability,

  venueFetchInventoryProducts,
  venueUpdateInventoryProduct,
  venueFetchInventoryProductsTotal,
};
