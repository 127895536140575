let clickClose;

export default {
  bind(el, binding, vnode) {
    const ref = binding.value;
    clickClose = (e) => {
      e.preventDefault();
      vnode.context.$refs[ref].close();
    };

    el.addEventListener('click', clickClose);
  },
  unbind(el) {
    el.removeEventListener('click', clickClose);
  },
};
