import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e48dcab5-1bdc-4be6-8814-02753f6c651b}
 *
 * @param context
 * @param {string} status
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchCreditNotes(
  context,
  { status = '', query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTES } = {},
) {
  return httpService.get(`/distributor/credit-notes?${status}`, {
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c643719c-e9e5-4cec-a1ec-13c0487d97f7}
 *
 * @param context
 * @param {string} status
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchCreditNotesTotal(
  context,
  { status = '', query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTES_TOTAL } = {},
) {
  return httpService.get(`/distributor/credit-notes/total-amount?${status}`, {
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#14bee093-23b6-4b46-8762-1b0f02f6d119}
 *
 * @param context
 * @param {string} status
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorExportCreditNotesPDF(
  context,
  { status = '', query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_PDF } = {},
) {
  return httpService.get(`/distributor/credit-notes/pdf?${status}`, {
    responseType: 'arraybuffer',
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f5a766cd-9174-4723-933a-d3200ac89cea}
 *
 * @param context
 * @param {string} status
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorExportCreditNotesCSV(
  context,
  { status = '', query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_CSV } = {},
) {
  return httpService.get(`/distributor/credit-notes/xlsx?${status}`, {
    responseType: 'arraybuffer',
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90c2d997-9ac0-4ff5-b62a-6837d221b6b5}
 *
 * @param context
 * @param {Object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchCreditNoteProducts(
  context,
  { query = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_PRODUCTS } = {},
) {
  return httpService.get('/distributor/credit-notes/products', {
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#67eabfe8-8062-410e-a336-9c2a3b32fbef}
 *
 * @param context
 * @param {Object} data
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorCreateCreditNote(
  context,
  { data = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_CREDIT_NOTE } = {},
) {
  return httpService.post('/distributor/credit-notes', data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ab19b587-9e46-4895-85de-af1308808907}
 *
 * @param context
 * @param {number} id credit note ID
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchSingleCreditNote(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_CREDIT_NOTE } = {},
) {
  return httpService.get(`/distributor/credit-notes/${id}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ddfa959b-728d-4ad9-838b-5b38ee3525f3}
 *
 * @param context
 * @param {number} id credit note ID
 * @param {Object} data credit note body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorUpdateCreditNote(
  context,
  { id, data = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_CREDIT_NOTE } = {},
) {
  return httpService.patch(`/distributor/credit-notes/${id}`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7763346e-f5df-4033-b6ec-f1b99f072a9d}
 *
 * @param context
 * @param {number} id credit note ID
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorDeleteCreditNote(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_DELETE_CREDIT_NOTE } = {},
) {
  return httpService.delete(`/distributor/credit-notes/${id}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#be264d2b-e0a2-4a17-93a2-492bf89a3e37}
 *
 * @param context
 * @param {number} id credit note ID
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorExportCreditNotePDF(
  context,
  { id, loadingKey = LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTE } = {},
) {
  return httpService.get(`/distributor/credit-notes/${id}/pdf`, {
    responseType: 'arraybuffer',
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90d1352f-331e-4b9d-ba21-c9b04d6bf0cf}
 *
 * @param context
 * @param {Object} data credit note body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function distributorFetchCreditNoteAmounts(
  context,
  { data = {}, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_AMOUNTS } = {},
) {
  return httpService.put('/distributor/credit-notes/amounts', data, {
    headers: { loadingKey },
  });
}

export default {
  distributorFetchCreditNotes,
  distributorFetchCreditNotesTotal,
  distributorExportCreditNotesPDF,
  distributorExportCreditNotesCSV,
  distributorFetchCreditNoteProducts,
  distributorCreateCreditNote,
  distributorFetchSingleCreditNote,
  distributorUpdateCreditNote,
  distributorDeleteCreditNote,
  distributorExportCreditNotePDF,
  distributorFetchCreditNoteAmounts,
};
