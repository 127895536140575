import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';
import {
  filterProducts,
  fetchMoreProducts,
  deleteDefaultTierPricing,
  fetchProduct,
  deleteSpecialTierPricing,
} from './commonActions';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#226d2490-574e-40ec-bb33-d313c91b5a4e}
 * @param context
 * @param {string} distributorId
 * @param {object} query
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchAdminDistributorProducts(
  context,
  { distributorId, query = {}, loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_PRODUCTS } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}/products`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

const fetchProductsForDistributor = (
  context,
  { payload, loadingKey = LOADING_KEY.FETCH_PRODUCTS },
) => filterProducts(context, { payload, loadingKey });
const fetchMoreProductsForDistributor = (context, payload) => fetchMoreProducts(context, payload);
// eslint-disable-next-line max-len
const fetchProductForDistributor = (context, { id, productId, loadingKey, ...payload }) =>
  fetchProduct(context, {
    route: `/admin/distributors/${id}/products/${productId}`,
    payload,
    loadingKey,
  });
const removeProductForDistributor = (context, { distributorId, productId }) =>
  httpService.delete(`/admin/distributors/${distributorId}/products/${productId}`);
const removeProductImageForDistributor = ({ commit }, { distributorId, productId }) =>
  httpService
    .delete(`/admin/distributors/${distributorId}/products/${productId}/image`)
    .then(() => {
      commit('REMOVE_PRODUCT_IMAGE', { id: productId });
    });

const deleteAdminDefaultTierPricing = (context, { productId, distributorId }) =>
  deleteDefaultTierPricing(context, `/admin/distributors/${distributorId}/products/${productId}`);

// eslint-disable-next-line max-len
const deleteAdminSpecialTierPricing = (
  context,
  { distributorId, productId, specialTierId, specialTierPricings, ...payload },
) =>
  deleteSpecialTierPricing(context, {
    route: `/admin/distributors/${distributorId}/products/${productId}`,
    productId,
    specialTierId,
    specialTierPricings,
    payload,
  });

/**
 *  * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#226d2490-574e-40ec-bb33-d313c91b5a4e}
 *
 * @param {number} distributorId
 * @param {number} venueId
 * @param {string} term
 * @param {number} nextId
 * @param {number} limit
 *
 * @returns {Promise<AxiosResponse<any>>}
 */

function fetchDistributorProductsForVenueAdmin(
  context,
  { distributorId, venueId, term, nextId, limit },
) {
  const URL = `/admin/distributors/${distributorId}/products`;
  return httpService.get(URL, {
    params: {
      venueId,
      term,
      nextId,
      limit,
    },
  });
}

/**
 * New methods that do not use the ORM.
 * Part of a new refactor.
 */

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#25da27bd-4807-4005-9dfa-2bac69e8ccf0}
 *
 * @param context
 * @param productId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchAllVenues(context, { distributorId, productId }) {
  return httpService.get(`/admin/distributors/${distributorId}/products/${productId}/venues`);
}

/**
 * @since 3.28.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#53a7e269-c0b9-430d-83cc-c64fd6270af1}
 *
 * @param context
 * @param productId
 * @param distributorId
 * @param query
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchAllVenuesForProducts(
  context,
  { distributorId, productId, query, loadingKey = LOADING_KEY.ADMIN_FETCH_VENUES_FOR_PRODUCT },
) {
  return httpService.get(`/admin/distributors/${distributorId}/products/${productId}/venues/v2`, {
    params: {
      ...query,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * Gets Units from Admin context for specific Distributor or Venue
 *
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#767d897a-325f-437e-bba8-f04b79ff313a}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} venueId
 * @param {string} term
 * @param {string} group
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchUnits(
  context,
  { distributorId, venueId, term, group, loadingKey = LOADING_KEY.ADMIN_FETCH_UNITS } = {},
) {
  return httpService.get('/admin/units', {
    params: {
      distributorId,
      venueId,
      term,
      group,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c12fb112-0920-4890-9bee-955c6d9b81ed}
 *
 * @param context
 * @param {Object} data
 * @param {string} loadingKey
 * @param {string} formKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminCreateUnit(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_CREATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.post('/admin/units', data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5aaeb4d4-cbe5-428c-aae7-19500bed8901}
 *
 * @param context
 * @param {number | string} unitId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminDeleteUnit(context, { unitId, loadingKey = LOADING_KEY.DISTRIBUTOR_DELETE_UNIT }) {
  return httpService.delete(`/admin/units/${unitId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4eec3c3c-8838-404e-9fe7-3bb58c93036f}
 *
 * @param context
 * @param {number | string} unitId
 * @param {Object} data
 * @param {string} loadingKey
 * @param {string} formKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminUpdateUnit(
  context,
  { unitId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_UPDATE_UNIT, formKey = 'add-new-unit' },
) {
  return httpService.put(`/admin/units/${unitId}`, data, {
    headers: {
      loadingKey,
      formKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#76190b59-f985-4d22-9818-6938d8b87c52}
 *
 * @param context
 * @param productId
 * @param venueId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function adminHideVenueForProduct(
  context,
  { distributorId, productId, venueId, loadingKey = LOADING_KEY.ADMIN_HIDE_VENUE_FOR_PRODUCT },
) {
  return httpService.put(
    `/admin/distributors/${distributorId}/products/${productId}/venues/${venueId}/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#8a29e741-623e-4516-91e3-ca40fd40e008}
 *
 * @param context
 * @param productId
 * @param venueId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function adminUnhideVenueForProduct(
  context,
  { distributorId, productId, venueId, loadingKey = LOADING_KEY.ADMIN_UNHIDE_VENUE_FOR_PRODUCT },
) {
  return httpService.delete(
    `/admin/distributors/${distributorId}/products/${productId}/venues/${venueId}/hide`,
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7bb7dcc6-244d-454d-95dc-a0872da6e103}
 *
 * @param context
 * @param productId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function adminHideAllVenuesForProduct(
  context,
  { distributorId, productId, loadingKey = LOADING_KEY.ADMIN_HIDE_ALL_VENUES_FOR_PRODUCT },
) {
  return httpService.put(
    `/admin/distributors/${distributorId}/products/${productId}/hide`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b9df020e-5d95-4213-9d3f-144cd575acf8}
 *
 * @param context
 * @param productId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function adminUnhideAllVenuesForProduct(
  context,
  { distributorId, productId, loadingKey = LOADING_KEY.ADMIN_UNHIDE_ALL_VENUES_FOR_PRODUCT },
) {
  return httpService.delete(`/admin/distributors/${distributorId}/products/${productId}/hide`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#63e2fb15-327c-489b-b998-bc1e0a5b0f1b}
 *
 * @param context
 * @param {string} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminFetchProductGroups(
  context,
  { term, loadingKey = LOADING_KEY.FETCH_PRODUCT_GROUPS } = {},
) {
  return httpService.get('/admin/product-groups', {
    params: { term },
    headers: { loadingKey },
  });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ab0f23d0-42dc-4862-9573-34a960621618}
 *
 * @param context
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminCreateProductGroup(
  context,
  { name, loadingKey = LOADING_KEY.CREATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.post('/admin/product-groups', { name }, { headers: { loadingKey, formKey } });
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fdea8d2c-9670-4c90-af98-a4854e909542}
 *
 * @param context
 * @param {number} id
 * @param {string} name
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminUpdateProductGroup(
  context,
  { id, name, loadingKey = LOADING_KEY.UPDATE_PRODUCT_GROUP, formKey } = {},
) {
  return httpService.put(
    `/admin/product-groups/${id}`,
    { name },
    { headers: { loadingKey, formKey } },
  );
}

/**
 * @since 3.29.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7dcbf89e-60f3-40a4-9e3b-fbba2f23f1a6}
 *
 * @param context
 * @param {number} id
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminDeleteProductGroup(
  context,
  { id, loadingKey = LOADING_KEY.DELETE_PRODUCT_GROUP } = {},
) {
  return httpService.delete(`/admin/product-groups/${id}`, { headers: { loadingKey } });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#84767fcd-f1ef-4a30-bc6c-163c76c4870f}
 *
 * @param context
 * @param {number} distributorId
 * @param {number} limit
 * @param {number} venueId
 * @param {number} term
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function fetchProductsSuggestedMatches(
  context,
  {
    distributorId,
    limit,
    venueId,
    term,
    productId,
    loadingKey = LOADING_KEY.FETCH_PRODUCT_SUGGESTED_MATCHES,
  } = {},
) {
  return httpService.get(`/admin/distributors/${distributorId}/suggested/products`, {
    headers: { loadingKey },
    params: {
      ...(limit ? { limit } : {}),
      ...(venueId ? { venueId } : {}),
      ...(term ? { term } : {}),
      ...(productId ? { productId } : {}),
    },
  });
}

export default {
  fetchProductsForDistributor,
  fetchMoreProductsForDistributor,
  fetchProductForDistributor,
  removeProductForDistributor,
  removeProductImageForDistributor,
  deleteAdminDefaultTierPricing,
  deleteAdminSpecialTierPricing,

  fetchDistributorProductsForVenueAdmin,
  adminFetchUnits,
  adminUpdateUnit,
  adminDeleteUnit,
  adminCreateUnit,

  // Refactored Methods

  adminFetchAllVenues,

  adminFetchAllVenuesForProducts,
  adminHideVenueForProduct,
  adminUnhideVenueForProduct,
  adminHideAllVenuesForProduct,
  adminUnhideAllVenuesForProduct,
  adminFetchProductGroups,
  adminCreateProductGroup,
  adminUpdateProductGroup,
  adminDeleteProductGroup,

  // ORM Refactor
  fetchAdminDistributorProducts,
  fetchProductsSuggestedMatches,
};
