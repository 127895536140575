function getError(state) {
  return (formKey, field) => {
    const error = state.formErrors[formKey] ? state.formErrors[formKey][field] : null;

    if (Array.isArray(error)) {
      return { message: error.reduce((acc, val) => `${acc} ${val}`) };
    }

    if (typeof error === 'string') {
      return { message: error };
    }

    return error;
  };
}

const getFormErrors = state => formKey => state.formErrors[formKey] || {};

export default {
  getError,
  getFormErrors,
};
