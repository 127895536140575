import Order from '@/models/Order';
import { clone, isDeclined, isExcluded, toUpdateDeliveryFee } from '@/util/utils';
import { getTotalAmountPriceCustom } from '@/util/utilsFinCalculator';
import { calculateDeliveryFee } from '@/util/deliveryFee';
import uuid from 'uuid/v4';
import orders from './index';

const UPDATE_META = (state, { meta }) => {
  state.meta = {
    ...state.meta,
    ...meta,
    count: state.meta.count + meta.count,
  };
};

const SET_TOTAL_AMOUNT = (state, payload) => {
  state.meta = {
    ...state.meta,
    ...payload,
  };
};

const UPDATE_FILTERS = (state, { filters }) => {
  state.filters = {
    ...state.filters,
    ...filters,
  };
};

const TOGGLE_PRODUCT = (state, { productId }) => {
  const product = state.singleOrder.orderedProducts.find(pr => pr.id === productId);

  product.isDeclined = !product.isDeclined;

  state.singleOrder.orderedProducts = [...state.singleOrder.orderedProducts];
};

const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const SET_DRAFT_VENUE = (state, venue) => {
  state.draft.venue = venue;
};

const SET_DRAFT_DELIVERY = (state, deliveryFee) => {
  state.draft.deliveryFee = deliveryFee;
};

const CLEAR_DRAFT_VENUE = state => {
  state.draft.venue = {};
};

const SET_DRAFT_PRODUCTS = (state, products) => {
  state.draft.products = products;
};

const REMOVE_DRAFT_PRODUCT = (state, productId) => {
  state.draft.products = state.draft.products.filter(p => p.internalId !== productId);
};

const UPDATE_DRAFT_PRODUCT = (state, product) => {
  const idx = state.draft.products.findIndex(p => p.internalId === product.internalId);
  if (idx < 0) return;
  state.draft.products.splice(idx, 1, product);
};

const CLEAR_DRAFT = state => {
  state.draft = clone(orders.draftOrderInitial);
};

const SET_DRAFT_MESSAGE = (state, message) => {
  state.draft.message = message;
};

const MARK_ORDER_MESSAGE = (state, { id }) =>
  Order.update({
    where: id,
    data: {
      isMessageRead: true,
    },
  });

const SET_PRODUCTS = (state, products) => {
  const resultingProducts = [];
  const selectedProds = state.newOrder.selectedProducts;
  products.forEach(prod => {
    const sP = selectedProds.filter(sProd => sProd.id === prod.id);
    // eslint-disable-next-line no-unused-expressions
    if (sP.length && !resultingProducts.filter(item => item.id === prod.id).length) {
      resultingProducts.push(...sP);
    } else if (!sP.length && !resultingProducts.filter(item => item.id === prod.id).length) {
      resultingProducts.push({
        ...prod,
        checked: false,
        quantity: 0,
        internalId: uuid(),
      });
    }
  });

  state.newOrder = {
    ...state.newOrder,
    products: [...resultingProducts],
  };
};

const CLEAR_PRODUCTS = state => {
  state.newOrder = clone({
    ...state.newOrder,
    products: [],
  });
};

const ADD_SELECTED_PRODUCT = (state, product) => {
  product = {
    ...product,
    checked: true,
  };
  let addToSelected = true;
  const { products, selectedProducts } = state.newOrder;

  const index = products.findIndex(prod => prod.internalId === product.internalId);
  if (index >= 0) {
    products[index] = product;
    const bonusIndexCheck = products.findIndex(prod => prod.internalId === product.bonusId);
    if (bonusIndexCheck === -1) product.bonusId = null;
    if (product.deals?.length) {
      const { buy } = product.deals[0];
      const { get } = product.deals[0];
      const { price } = product.deals[0];
      const multiplierChange = Math.floor(product.quantity / buy);
      const addedQuantity = multiplierChange * get;
      if (product.bonusId) {
        const bonusIndex = products.findIndex(prod => prod.internalId === product.bonusId);
        products[bonusIndex] = {
          ...products[bonusIndex],
          quantity: addedQuantity,
          price,
        };

        const selectedBonusIndex = selectedProducts.findIndex(
          prod => prod.internalId === product.bonusId,
        );
        if (selectedBonusIndex !== -1 && addedQuantity) {
          selectedProducts[selectedBonusIndex] = {
            ...selectedProducts[selectedBonusIndex],
            quantity: addedQuantity,
            price,
            isBonus: true,
          };
        } else if (selectedBonusIndex !== -1 && !addedQuantity) {
          selectedProducts.splice(selectedBonusIndex, 1);
        } else if (selectedBonusIndex === -1 && addedQuantity) {
          const selectedOriginalIndex = selectedProducts.findIndex(
            prod => prod.internalId === product.internalId,
          );
          selectedProducts.splice(selectedOriginalIndex + 1, 0, {
            ...product,
            quantity: addedQuantity,
            price,
            isBonus: true,
          });
          addToSelected = false;
        }
      } else {
        const bonusProductId = uuid();
        const bonusProduct = {
          ...product,
          internalId: bonusProductId,
          quantity: addedQuantity,
          price,
          isBonus: true,
        };
        if (addedQuantity) {
          products[index] = {
            ...products[index],
            bonusId: bonusProductId,
          };
          products.splice(index + 1, 0, bonusProduct);
          const selectedOriginalIndex = selectedProducts.findIndex(
            prod => prod.internalId === product.internalId,
          );
          selectedProducts.splice(selectedOriginalIndex + 1, 0, bonusProduct);
          addToSelected = false;
        }
      }
    }
  } else {
    product = {
      ...product,
      ...(product.internalId ? {} : { internalId: uuid() }),
    };
    const indexes = products
      .map((item, i) => (item.id === product.id ? i : null))
      .filter(i => i !== null);
    products.splice(indexes.at(-1) + 1, 0, product);
    const selectedIndexes = selectedProducts
      .map((item, i) => (item.id === product.id ? i : null))
      .filter(i => i !== null);
    if (selectedIndexes.length) selectedProducts.splice(selectedIndexes.at(-1) + 1, 0, product);
    else selectedProducts.push(product);
    addToSelected = false;
  }

  state.newOrder = {
    ...state.newOrder,
    selectedProducts: [...selectedProducts, ...(addToSelected ? [product] : [])],
    products: [...products],
  };
};

const REMOVE_SELECTED_PRODUCT = (state, product) => {
  product = {
    ...product,
    checked: false,
  };

  const { products } = state.newOrder;
  const index = products.findIndex(prod => prod.internalId === product.internalId);

  products[index] = product;

  state.newOrder = {
    ...state.newOrder,
    selectedProducts: state.newOrder.selectedProducts.filter(
      p => p.internalId !== product.internalId,
    ),
    products: [...products],
  };
};

const CLEAR_SELECTED_PRODUCTS = state => {
  state.newOrder = clone({
    ...state.newOrder,
    selectedProducts: [],
  });
};

const UPDATE_PRODUCT = (state, product) => {
  const i = state.newOrder.products.findIndex(p => p.internalId === product.internalId);
  const j = state.newOrder.selectedProducts.findIndex(p => p.internalId === product.internalId);
  if (j > -1) {
    state.newOrder.selectedProducts[j] = { ...product };
  }
  const bonusIndexCheck = state.newOrder.products.findIndex(
    prod => prod.internalId === product.bonusId,
  );
  if (bonusIndexCheck === -1) product.bonusId = null;
  let isProductUpdated = false;
  if (product.deals?.length) {
    const { buy } = product.deals[0];
    const { get } = product.deals[0];
    const { price } = product.deals[0];
    const multiplierChange = Math.floor(product.quantity / buy);
    const addedQuantity = multiplierChange * get;
    if (product.bonusId) {
      const bonusIndex = state.newOrder.products.findIndex(
        prod => prod.internalId === product.bonusId,
      );
      state.newOrder.products[bonusIndex] = {
        ...state.newOrder.products[bonusIndex],
        quantity: addedQuantity,
        price,
      };

      const selectedBonusIndex = state.newOrder.selectedProducts.findIndex(
        prod => prod.internalId === product.bonusId,
      );
      if (selectedBonusIndex !== -1 && addedQuantity) {
        state.newOrder.selectedProducts[selectedBonusIndex] = {
          ...state.newOrder.selectedProducts[selectedBonusIndex],
          quantity: addedQuantity,
          price,
          isBonus: true,
        };
        state.newOrder.products[bonusIndex] = {
          ...state.newOrder.products[bonusIndex],
          checked: true,
        };
      } else if (selectedBonusIndex !== -1 && !addedQuantity) {
        state.newOrder.selectedProducts.splice(selectedBonusIndex, 1);
        state.newOrder.products[bonusIndex] = {
          ...state.newOrder.products[bonusIndex],
          checked: false,
        };
      } else if (!addedQuantity) {
        state.newOrder.products[bonusIndex] = {
          ...state.newOrder.products[bonusIndex],
          checked: false,
        };
      } else if (selectedBonusIndex === -1 && addedQuantity) {
        const selectedOriginalIndex = state.newOrder.selectedProducts.findIndex(
          prod => prod.internalId === product.internalId,
        );
        state.newOrder.products[bonusIndex] = {
          ...state.newOrder.products[bonusIndex],
          checked: true,
        };
        state.newOrder.selectedProducts.splice(selectedOriginalIndex + 1, 0, {
          ...state.newOrder.products[bonusIndex],
          discountValue: 0,
          discountAmount: 0,
          discountType: 'amount',
          discount: 0,
          quantity: addedQuantity,
          defaultMarketPrice: false,
          marketPRice: false,
          defaultPrice: price,
          price,
          isBonus: true,
        });
      }
    } else {
      const bonusProductId = uuid();
      const bonusProduct = {
        ...product,
        internalId: bonusProductId,
        bonusId: null,
        quantity: addedQuantity,
        price,
        isBonus: true,
        discountValue: 0,
        discountAmount: 0,
        discountType: 'amount',
        discount: 0,
        defaultMarketPrice: false,
        marketPrice: false,
        defaultPrice: price,
      };
      if (addedQuantity) {
        state.newOrder.products[i] = {
          ...product,
          bonusId: bonusProductId,
        };
        state.newOrder.selectedProducts[j] = {
          ...state.newOrder.selectedProducts[j],
          bonusId: bonusProductId,
        };
        isProductUpdated = true;
        state.newOrder.products.splice(i + 1, 0, bonusProduct);
        const selectedOriginalIndex = state.newOrder.selectedProducts.findIndex(
          prod => prod.internalId === product.internalId,
        );
        state.newOrder.selectedProducts.splice(selectedOriginalIndex + 1, 0, bonusProduct);
      }
    }
  }
  if (!isProductUpdated) state.newOrder.products[i] = { ...product };
  state.newOrder = clone(state.newOrder);
};

const CALCULATE_NEW_ORDER_AMOUNT = state => {
  const products = state.newOrder?.selectedProducts || [];
  state.newOrder.amount = getTotalAmountPriceCustom({ products });
};

const CALCULATE_NEW_DRAFT_ORDER_AMOUNT = state => {
  const products = state.draft?.products || [];
  state.draft.amount = getTotalAmountPriceCustom({ products });
};

const UPDATE_DELIVERY_FEE_TO_NEW_ORDER = (state, { distributor, taxCalculation = 'exclusive' }) => {
  // TODO: Refactor state so there is SSOT for create order flow.

  const { oldAmount } = state.draft;
  let { amount } = state.newOrder;
  const moa = distributor?.minimumOrderAmount ?? null;
  const tax = distributor?.tax ?? 0;

  const calculateDF = !oldAmount ? true : toUpdateDeliveryFee({ amount, oldAmount, moa });
  if (!calculateDF) return;

  if (taxCalculation === 'inclusive') amount = (amount / (100 + tax)) * 100;

  state.draft.deliveryFee = calculateDeliveryFee(
    distributor,
    amount - (state.draft.orderDiscountAmount ?? 0),
    false,
  );
};

function SET_SELECTED_SINGLE_ORDER(state, order) {
  state.singleOrder = clone(order);
}

function UPDATE_SELECTED_SINGLE_ORDER(state, payload) {
  state.singleOrder = { ...state.singleOrder, ...payload };
}

function ADD_PRODUCTS_TO_SELECTED_SINGLE_ORDER(state, products) {
  products.forEach(product => {
    const productId = uuid();
    // eslint-disable-next-line no-unused-expressions
    state?.singleOrder?.orderedProducts?.push({
      ...product,
      id: productId,
    });
    const productIndex = state?.singleOrder?.orderedProducts?.findIndex(
      prod => prod.id === productId,
    );
    if (product?.deals?.length) {
      const { buy } = product.deals[0];
      const { get } = product.deals[0];
      const { price } = product.deals[0];
      const bonusMultiplier = Math.floor((product?.quantity ?? 0) / buy);
      const addedQuantity = bonusMultiplier * get;
      const bonusProductId = uuid();
      const bonusProduct = {
        ...product,
        id: bonusProductId,
        quantity: addedQuantity,
        priceQuantity: addedQuantity,
        price,
        isBonus: true,
        discountValue: 0,
        discountAmount: 0,
        discountType: 'amount',
        discount: 0,
        defaultMarketPrice: false,
        marketPrice: false,
        defaultPrice: price,
        bonusId: null,
      };
      if (addedQuantity) {
        state.singleOrder.orderedProducts[productIndex] = {
          ...state.singleOrder.orderedProducts[productIndex],
          bonusId: bonusProductId,
        };
        state.singleOrder.orderedProducts.splice(productIndex + 1, 0, bonusProduct);
      }
    }
  });
}

function SET_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER(state, deliveryFee) {
  state.singleOrder.deliveryFee = deliveryFee;
}

function SET_DISCOUNT_TO_SELECTED_SINGLE_ORDER(state, discount) {
  state.singleOrder.orderDiscountAmount = discount;
}

function SET_PRICE_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER(state, amountAdjustment) {
  state.singleOrder.amountAdjustment = amountAdjustment;
}

function SET_TAX_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER(state, taxAdjustment) {
  state.singleOrder.taxAdjustment = taxAdjustment;
}

function UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER(state) {
  const { distributor, amount, oldAmount } = state.singleOrder;
  const moa = distributor?.minimumOrderAmount ?? null;

  const calculateDF = toUpdateDeliveryFee({ amount, oldAmount, moa });
  if (!calculateDF) return;

  state.singleOrder.deliveryFee = calculateDeliveryFee(distributor, amount, false);
}

function UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER_PUBLIC(state) {
  const { distributor, amount, oldAmount } = state.singleOrder;
  const moa = distributor?.minimumOrderAmount ?? null;

  const calculateDF = toUpdateDeliveryFee({ amount, oldAmount, moa });
  if (!calculateDF) return;

  state.singleOrder.deliveryFee = calculateDeliveryFee(distributor, amount);
}

function REMOVE_PRODUCT_FROM_SELECTED_SINGLE_ORDER(state, product) {
  const productIndex = state?.singleOrder?.orderedProducts.findIndex(p => p.id === product.id);

  if (productIndex === -1) return;

  // eslint-disable-next-line no-unused-expressions
  state?.singleOrder?.orderedProducts?.splice(productIndex, 1);
  if (product.bonusId) {
    const bonusProductIndex = state?.singleOrder?.orderedProducts.findIndex(
      p => p.id === product.bonusId,
    );
    if (bonusProductIndex >= 0) {
      // eslint-disable-next-line no-unused-expressions
      state?.singleOrder?.orderedProducts?.splice(bonusProductIndex, 1);
    }
  }
}

function CALCULATE_SELECT_SINGLE_ORDER_AMOUNT(state) {
  let { orderedProducts: products } = state?.singleOrder || {};
  products = products?.filter(prod => !isExcluded(prod) && !isDeclined(prod)) || [];

  // checkPriceUnits: false - skip condition to check does priceUnit exist,
  // when we have MP and/or TBD products
  state.singleOrder.amount = getTotalAmountPriceCustom({
    products,
    multiplicand: 'priceQuantity',
    checkPriceUnits: false,
  });
}

function UPDATE_PRODUCT_FROM_SELECTED_SINGLE_ORDER(state, product) {
  const productIndex = state?.singleOrder?.orderedProducts.findIndex(p => p.id === product.id);
  if (productIndex === -1) return;
  const bonusIndexCheck = state.singleOrder?.orderedProducts.findIndex(
    prod => prod.id === product.bonusId,
  );
  if (bonusIndexCheck === -1) product.bonusId = null;
  let isProductUpdated = false;

  if (product?.deals?.length) {
    const { buy } = product.deals[0];
    const { get } = product.deals[0];
    const { price } = product.deals[0];
    const bonusMultiplier = Math.floor((product?.quantity ?? 0) / buy);
    const addedQuantity = bonusMultiplier * get;
    if (product.bonusId) {
      const bonusIndex = state.singleOrder?.orderedProducts.findIndex(
        prod => prod.id === product.bonusId,
      );
      state.singleOrder.orderedProducts[bonusIndex] = {
        ...state.singleOrder.orderedProducts[bonusIndex],
        quantity: addedQuantity,
        price,
        priceQuantity: addedQuantity,
      };
    } else {
      const bonusProductId = uuid();
      const originalProduct = state.singleOrder?.orderedProducts.findIndex(
        prod => prod.productId === product.productId,
      );
      const bonusProduct = {
        ...state.singleOrder?.orderedProducts[originalProduct],
        id: bonusProductId,
        quantity: addedQuantity,
        priceQuantity: addedQuantity,
        price,
        isBonus: true,
        discountValue: 0,
        discountAmount: 0,
        discountType: 'amount',
        discount: 0,
        defaultMarketPrice: false,
        marketPrice: false,
        defaultPrice: price,
        bonusId: null,
      };
      if (addedQuantity) {
        state.singleOrder.orderedProducts[productIndex] = {
          ...state.singleOrder.orderedProducts[productIndex],
          ...product,
          bonusId: bonusProductId,
        };
        isProductUpdated = true;
        state.singleOrder.orderedProducts.splice(productIndex + 1, 0, bonusProduct);
      }
    }
  }
  if (!isProductUpdated) {
    state.singleOrder.orderedProducts[productIndex] = {
      ...state.singleOrder.orderedProducts[productIndex],
      ...product,
    };
  }

  state.singleOrder = clone(state.singleOrder);
}

function SET_OUTLET_REFERENCE_NUMBER(state, payload) {
  state.outletReferenceNumber = payload;
}

function CLEAR_OUTLET_REFERENCE_NUMBER(state) {
  state.outletReferenceNumber = null;
}

function CLEAR_NEW_ORDER_AMOUNT(state) {
  state.newOrder = {
    ...state.newOrder,
    amount: null,
  };
}

export default {
  UPDATE_DRAFT,
  SET_DRAFT_VENUE,
  CLEAR_DRAFT_VENUE,
  SET_DRAFT_PRODUCTS,
  REMOVE_DRAFT_PRODUCT,
  UPDATE_DRAFT_PRODUCT,
  CLEAR_DRAFT,
  TOGGLE_PRODUCT,
  UPDATE_META,
  SET_TOTAL_AMOUNT,
  UPDATE_FILTERS,
  MARK_ORDER_MESSAGE,
  SET_DRAFT_MESSAGE,
  SET_PRODUCTS,
  CALCULATE_NEW_ORDER_AMOUNT,
  CALCULATE_NEW_DRAFT_ORDER_AMOUNT,
  UPDATE_DELIVERY_FEE_TO_NEW_ORDER,
  CLEAR_PRODUCTS,
  ADD_SELECTED_PRODUCT,
  REMOVE_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCTS,
  UPDATE_PRODUCT,
  SET_SELECTED_SINGLE_ORDER,
  ADD_PRODUCTS_TO_SELECTED_SINGLE_ORDER,
  SET_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER,
  SET_DISCOUNT_TO_SELECTED_SINGLE_ORDER,
  SET_PRICE_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER,
  SET_TAX_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER,
  REMOVE_PRODUCT_FROM_SELECTED_SINGLE_ORDER,
  CALCULATE_SELECT_SINGLE_ORDER_AMOUNT,
  UPDATE_PRODUCT_FROM_SELECTED_SINGLE_ORDER,
  SET_DRAFT_DELIVERY,
  UPDATE_SELECTED_SINGLE_ORDER,
  UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER,
  SET_OUTLET_REFERENCE_NUMBER,
  CLEAR_OUTLET_REFERENCE_NUMBER,
  UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER_PUBLIC,
  CLEAR_NEW_ORDER_AMOUNT,
};
