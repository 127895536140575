function getLoading(state) {
  return (loadingKey) => {
    const val = state.loading[loadingKey] ? state.loading[loadingKey] : false;
    return val;
  };
}

const isSomeLoading = state => (loadingKeys) => {
  let isLoading = false;
  for (let i = 0; i < loadingKeys.length && !isLoading; i++) {
    isLoading = !!state.loading[loadingKeys[i]];
  }
  return isLoading;
};

export default {
  getLoading,
  isSomeLoading,
};
