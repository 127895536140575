import actions from './actions';
import mutations from './mutations';

const state = {
  unselectedTags: [],
  selectedTags: [],
  selectedTagsIds: [],
  tagColors: [],
  allTags: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
