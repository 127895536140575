function compareByPriority(obj1, obj2) {
  return obj1.priority - obj2.priority;
}

const SET_TAGS = (state, payload) => {
  state.unselectedTags = [];
  state.selectedTags = [];
  state.selectedTagsIds = [];
  state.allTags = payload;
};

const SET_SELECTED_TAGS = (state, payload) => {
  state.selectedTagsIds = payload;
  state.selectedTags = state.allTags.filter(item => state.selectedTagsIds.indexOf(item.id) >= 0);
  state.unselectedTags = state.allTags.filter(item => state.selectedTagsIds.indexOf(item.id) === -1);
};

const SET_COLORS = (state, payload) => {
  state.tagColors = payload;
};

const CLEAR_ALL_TAGS = (state) => {
  state.unselectedTags = [];
  state.selectedTags = [];
  state.selectedTagsIds = [];
  state.allTags = [];
};

const UPDATE_TAG = (state, payload) => {
  const oldTag = state.allTags.filter(item => item.id === payload.id)[0];
  const oldTagPriority = oldTag.priority;
  state.unselectedTags.forEach((item) => {
    if (item.priority > oldTagPriority) item.priority -= 1;
  });
  state.selectedTags.forEach((item) => {
    if (item.priority > oldTagPriority) item.priority -= 1;
  });
  state.unselectedTags.forEach((item) => {
    if (item.priority >= payload.priority) item.priority += 1;
  });
  state.selectedTags.forEach((item) => {
    if (item.priority >= payload.priority) item.priority += 1;
  });
  if (state.selectedTagsIds.indexOf(payload.id) === -1) {
    state.unselectedTags = state.unselectedTags.filter(item => item.id !== payload.id);
    state.unselectedTags = [payload, ...state.unselectedTags];
    state.unselectedTags.sort(compareByPriority);
    state.allTags = [...state.unselectedTags, ...state.selectedTags];
    state.allTags.sort(compareByPriority);
  } else {
    state.selectedTags = state.selectedTags.filter(item => item.id !== payload.id);
    state.selectedTags = [payload, ...state.selectedTags];
    state.selectedTags.sort(compareByPriority);
    state.allTags = [...state.selectedTags, ...state.unselectedTags];
    state.allTags.sort(compareByPriority);
  }
};

const REMOVE_TAG = (state, payload) => {
  if (state.selectedTagsIds.indexOf(payload) === -1) {
    state.unselectedTags = state.unselectedTags.filter(item => item.id !== payload);
  } else {
    state.selectedTags = state.selectedTags.filter(item => item.id !== payload);
    state.selectedTagsIds = state.selectedTagsIds.filter(item => item !== payload);
  }
  state.allTags = state.allTags.filter(item => item.id !== payload);
};

const ADD_SELECTED_TAG = (state, payload) => {
  if (payload.isNew) {
    state.selectedTags.forEach((item) => {
      if (item.priority >= payload.priority) item.priority += 1;
    });
  }
  state.selectedTags = [payload.tag, ...state.selectedTags];
  state.selectedTagsIds = [payload.tag.id, ...state.selectedTagsIds];
  if (payload.isNew) {
    state.unselectedTags.forEach((item) => {
      if (item.priority >= payload.priority) item.priority += 1;
    });
  }
  state.unselectedTags = state.unselectedTags.filter(tag => tag.id !== payload.tag.id);
  state.allTags = [...state.selectedTags, ...state.unselectedTags];
  state.selectedTags.sort(compareByPriority);
  state.allTags.sort(compareByPriority);
};

const REMOVE_SELECTED_TAG = (state, payload) => {
  state.selectedTags = state.selectedTags.filter(item => item.id !== payload.id);
  state.selectedTagsIds = state.selectedTagsIds.filter(item => item !== payload.id);
  state.unselectedTags = [payload, ...state.unselectedTags];
  state.allTags = [...state.selectedTags, ...state.unselectedTags];
  state.unselectedTags.sort(compareByPriority);
  state.allTags.sort(compareByPriority);
};

const CLEAR_SELECTED = (state) => {
  state.unselectedTags = [...state.selectedTags, ...state.unselectedTags];
  state.unselectedTags.sort(compareByPriority);
  state.selectedTags = [];
  state.selectedTagsIds = [];
};

export default {
  SET_TAGS,
  SET_COLORS,
  CLEAR_ALL_TAGS,
  ADD_SELECTED_TAG,
  REMOVE_SELECTED_TAG,
  UPDATE_TAG,
  REMOVE_TAG,
  CLEAR_SELECTED,
  SET_SELECTED_TAGS,
};
