import './common';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import { VMoney } from 'v-money';
import * as Sentry from '@sentry/vue';
import VeeValidate from 'vee-validate';
import dictionary from '@/validation/dictionary';
import VuePermission from '@/mixins/permissions';
import Utils from '@/mixins/helpers';
import { close, open } from '@/directives';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

if (process.env.VUE_APP_MOCK_SERVER_ENABLED && Number(process.env.VUE_APP_MOCK_SERVER_ENABLED) && process.env.NODE_ENV === 'development') {
  import('./api/mock').then((createServer) => {
    createServer.default(process.env.NODE_ENV);
  });
}

Vue.directive('money', VMoney);
Vue.directive('close', close);
Vue.directive('open', open);

Vue.use(Utils);

if (!Number(process.env.VUE_APP_IS_LOCAL_ENV)) {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
  });
}

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  router,
  ignoreRoutes: ['/'],
  debug: {
    sendHitTask: process.env.NODE_ENV !== 'development',
  },
});

Vue.use(VeeValidate, {
  dictionary,
  useConstraintAttrs: false,
});
const permission = new VuePermission();

new Vue({
  permission,
  router,
  store,
  i18n,
  render: h => h(App),
  watch: {
    $route: () => {
      window.Intercom('update');
    },
  },
}).$mount('#app');
