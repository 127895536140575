<script>
import EzButton from '@/components/ui/Button/EzButton.vue';
import uuid from 'uuid/v4';
import { debounce } from '@/util/utils';

let pdfjs;

(async function initializePDFJS() {
  pdfjs = await import('pdfjs-dist/build/pdf');
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
})();

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VPdfViewer',
  components: {
    EzButton,
  },
  props: {
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    src: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    showAllPages: {
      type: Boolean,
      required: false,
      default: true,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    controls: {
      type: Boolean,
      required: false,
      default: false,
    },
    rotate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      pdfFile: null,
      pdfCanvas: null,
      pdfScale: 1,
      rotateDegrees: 0,
      translate: [0, 0, 0],
      zoomedIn: false,
      pageRendering: false,
      left: 0,
      top: 0,
      uuid: '',
      pdfViewerWidth: null,
    };
  },
  methods: {
    getPdf() {
      pdfjs.getDocument(this.src).promise.then(pdf => {
        if (this.pdfFile) {
          this.pdfFile.destroy();
        }
        this.pdfFile = pdf;
        const viewer = this.$refs.pdfViewer;

        if (this.showAllPages) {
          for (let page = 1; page <= pdf.numPages; page++) {
            const canvas = document.createElement('canvas');
            canvas.width = viewer.clientWidth;
            canvas.className = `pdf-page-canvas pdf-page-canvas-${this.id}-${page}`;
            viewer.appendChild(canvas);
            this.renderPage(page, canvas);
          }
        } else {
          this.pdfCanvas = this.pdfCanvas ?? document.createElement('canvas');
          this.pdfCanvas.className = `pdf-page-canvas pdf-page-canvas-${this.id}-${this.pageNumber}`;
          this.pdfCanvas.width = viewer.clientWidth;
          viewer.appendChild(this.pdfCanvas);
          this.renderPage(this.pageNumber, this.pdfCanvas);
        }
      });
    },
    async renderPage(pageNumber, canvas, rescale = true) {
      if (this.pageRendering) return;

      this.pageRendering = true;
      await this.$nextTick();

      this.pdfFile.getPage(pageNumber).then(page => {
        if (rescale) this.pdfScale = canvas.width / page.getViewport({ scale: 1 }).width;
        const viewport = page.getViewport({ scale: this.pdfScale || 0.25 });

        // Prepare canvas using PDF page dimensions
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport,
        };
        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          this.pageRendering = false;
        });
      });
    },
    zoomIn($event, delta = 0.25) {
      this.pdfScale += delta;
      if (this.showAllPages) {
        for (let page = 1; page <= this.pdfFile.numPages; page++) {
          const canvas = document.querySelector(`.pdf-page-canvas-${this.id}-${page}`);
          this.renderPage(page, canvas, false);
        }
      } else {
        this.renderPage(this.pageNumber, this.pdfCanvas, false);
      }
    },
    zoomOut($event, delta = 0.25) {
      if (this.pdfScale > 0.25) this.pdfScale -= delta;
      if (this.showAllPages) {
        for (let page = 1; page <= this.pdfFile.numPages; page++) {
          const canvas = document.querySelector(`.pdf-page-canvas-${this.id}-${page}`);
          this.renderPage(page, canvas, false);
        }
      } else {
        this.renderPage(this.pageNumber, this.pdfCanvas, false);
      }
    },
    onDoubleClick() {
      if (this.controls) {
        if (!this.zoomedIn) this.zoomIn(null, 0.5);
        else if (this.pdfScale > 0.25) this.zoomOut(null, 0.5);
        this.zoomedIn = !this.zoomedIn;
      }
    },
    zoomOnScroll(event) {
      if (this.controls) {
        if (event.deltaY < 0) this.zoomIn(null, 0.05);
        else if (event.deltaY > 0) this.zoomOut(null, 0.05);
      }
    },
    rotateBy(deg) {
      this.rotateDegrees += deg;
      if (this.rotateDegrees > 360) this.rotateDegrees -= 360;
      if (this.rotateDegrees < -360) this.rotateDegrees += 360;
      if (this.rotateDegrees === 90 || this.rotateDegrees === -270) {
        this.translate[0] = 0;
        this.translate[1] = '-100%';
      }
      if (this.rotateDegrees === 180 || this.rotateDegrees === -180) {
        this.translate[0] = '-100%';
        this.translate[1] = '-100%';
      }
      if (this.rotateDegrees === 270 || this.rotateDegrees === -90) {
        this.translate[0] = '-100%';
        this.translate[1] = 0;
      }
      if (this.rotateDegrees === 360 || this.rotateDegrees === 0 || this.rotateDegrees === -360) {
        this.translate[0] = 0;
        this.translate[1] = 0;
      }
      this.pdfCanvas.style.transform = `rotate(${
        this.rotateDegrees
      }deg) translate3d(${this.translate.join(',')})`;
      this.pdfCanvas.style.transformOrigin = 'top left';
    },
    mouseMoveWhilstDown() {
      const target = document.querySelector(`#ID${this.uuid}`);
      if (target) {
        const endMove = () => {
          target.removeEventListener('mousemove', this.moveDocument);
          target.removeEventListener('mouseup', endMove);
        };
        target.addEventListener('mousedown', event => {
          event.stopPropagation();
          event.preventDefault();
          target.addEventListener('mousemove', this.moveDocument);
          target.addEventListener('mouseup', endMove);
        });
      }
    },
    moveDocument(event) {
      event.preventDefault();
      event.stopPropagation();
      const scrollable =
        document.querySelector(`#ID${this.uuid}`).closest('.pdf-placeholder') ||
        document.querySelector(`#ID${this.uuid}`).closest('.selected-file__container');
      if (scrollable) {
        scrollable.scrollBy({
          top: -event.movementY,
          left: -event.movementX,
        });
      }
    },
  },
  created() {
    if (this.pdfFile === null) this.getPdf();
    this.uuid = uuid();
  },
  mounted() {
    if (this.controls) this.mouseMoveWhilstDown();
    const resizeObserver = new ResizeObserver(
      debounce(elems => {
        const [elem] = elems;
        const newWidth = elem.contentBoxSize[0].inlineSize;
        if (this.showAllPages) {
          for (let page = 1; page <= this.pdfFile.numPages; page++) {
            const canvas = document.querySelector(`.pdf-page-canvas-${this.id}-${page}`);
            if (canvas && Math.abs(this.pdfViewerWidth - newWidth) > 100) {
              canvas.width = newWidth;
              this.renderPage(page, canvas);
            }
          }
          this.pdfViewerWidth = newWidth;
        } else if (this.pdfCanvas && Math.abs(this.pdfViewerWidth - newWidth) > 100) {
          this.pdfCanvas.width = newWidth;
          this.renderPage(this.pageNumber, this.pdfCanvas);
          this.pdfViewerWidth = newWidth;
        }
      }, 300),
    );
    this.pdfViewerWidth = 0;
    resizeObserver.observe(this.$refs.pdfViewer);
  },
  watch: {
    src() {
      this.getPdf();
    },
  },
};
</script>

<template>
  <div
    :class="{ 'pdf-page-wrapper': true, 'pdf-page-wrapper--controls': controls }"
    :id="`ID${uuid}`"
    @dblclick="onDoubleClick"
    @wheel.prevent="zoomOnScroll"
  >
    <a @click="$emit('click')" :data-cy="dataCy" ref="pdfViewer" class="pdf-container"> </a>
    <div class="pdf-controls" v-if="controls">
      <ez-button type="secondary" @click.prevent="zoomIn">+</ez-button>
      <ez-button type="secondary" @click.prevent="zoomOut" :disabled="pdfScale <= 0.25"
        >-</ez-button
      >
      <ez-button v-if="rotate" type="secondary" @click.prevent="rotateBy(-90)">
        <font-awesome-icon icon="undo"></font-awesome-icon>
      </ez-button>
      <ez-button v-if="rotate" type="secondary" @click.prevent="rotateBy(+90)">
        <font-awesome-icon icon="undo"></font-awesome-icon>
      </ez-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pdf-page-wrapper--controls {
  padding-top: 44px;
}
.pdf-container {
  display: inline-block;
  @include size(100%);

  border: 1px solid $color-gray-E1;
  border-radius: 3px;
  background-color: $color-gray-F5;

  :deep() canvas {
    cursor: move;
  }
}

.pdf-controls {
  display: block;
  position: absolute;
  top: 4px;
  padding-left: 4px;

  button svg {
    margin-right: 0;
  }
  button:nth-child(4) {
    transform: rotateY(180deg);
  }

  :deep() button.button {
    border-radius: 0;
    &:first-child,
    &:last-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}
</style>
