import { Model } from '@vuex-orm/core';
import Distributor from '@/models/Distributor';
import Venue from '@/models/Venue';

export default class Order extends Model {
  static entity = 'orders';

  static fields() {
    return {
      id: this.attr(null),
      acceptedAt: this.attr(null),
      createdAt: this.attr(null),
      expectedAt: this.attr(null),
      completedAt: this.attr(null),
      requestedDeliveryAt: this.attr(null),
      amount: this.attr(0),
      tax: this.attr(0),
      taxAmount: this.attr(0),
      amountWithTax: this.attr(0),
      deliveryFee: this.attr(0),
      deliveryInstructions: this.attr(null),
      declinedAt: this.attr(null),
      afterWorkingHours: this.attr(false),
      distributorMessage: this.attr(''),
      isMessageRead: this.attr(false),
      offline: this.attr(false),
      orderNumber: this.attr(''),
      distributorOrderNumber: this.attr(''),
      orderedAt: this.attr(null),
      orderedProducts: this.attr([]),
      status: this.attr(''),
      venueMessage: this.attr(''),
      distributorId: this.attr(null),
      distributor: this.hasOne(Distributor, 'id', 'distributorId'),
      venueId: this.attr(null),
      notes: this.attr(null),
      venue: this.hasOne(Venue, 'id', 'venueId'),
      scheduledAt: this.attr(null),
      type: this.attr(null),
      upcoming: this.attr(null),
      interval: this.attr(null),
      historyLog: this.attr(null),
      invoice: this.attr(null),
      invoices: this.attr(null),
      invoiceNumber: this.attr(null),
      invoiceProcessingStatus: this.attr(null),
      xeroInvoiceId: this.attr(null),
      xeroBillId: this.attr(null),
      primaryWarehouseName: this.attr(''),
      secondaryWarehouseName: this.attr(''),
      actions: this.attr([]),
      canCreateXeroBill: this.attr(null),
      canCreateXeroInvoice: this.attr(null),
      canCreateXeroInventoryBill: this.attr(null),
      integrationWarnings: this.attr(null),
      currency: this.attr(null),
      exportTypes: this.attr([]),
      provinoReference: this.attr(null),
      venueOrderNumber: this.attr(null),
      deliveryPeriod: this.attr(null),
      deliveryPeriodCount: this.attr(null),
      restrictDeliveryDates: this.attr(null),
      editConfig: this.attr(null),
      tbd: this.attr(false),
      integrationLinks: this.attr([]),
      customerInfo: this.attr(null),
      notMatching: this.attr(false),
      fulfillmentWarehouse: this.attr({}),
      remainingCreditAmount: this.attr(null),
      creditNoteAmount: this.attr(0),
      distributorAccountOwner: this.attr({}),
    };
  }
}
