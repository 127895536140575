/* eslint-disable */
let Vue;

/**
 * Initialize Permission plugin for parent and child components.
 */
function permissionInit() {
  const { permission, parent } = this.$options;

  let instance = null;
  if (permission) {
    instance = typeof permission === 'function' ? new permission() : permission;
    instance.init();
  } else if (parent && parent._$permissionInstance) {
    instance = parent._$permissionInstance;
    instance.init();
  }

  if (instance) {
    this._$permissionInstance = instance;
    this.$permission = instance;
  }
}

export default function install(_Vue) {
  if (Vue && _Vue === Vue) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error('[permission] already installed.');
    }
    return;
  }
  Vue = _Vue;
  Vue.mixin({ beforeCreate: permissionInit });
}
