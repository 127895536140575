import { CONNECTED_STATUSES, INTEGRATION_STATUSES } from '@/util/constants';

const connectedDistributors = ({ distributors }) => distributors.filter(d => d.isConnected);

function getCheckIn(state) {
  const data = new FormData();
  data.append('type', state.checkInInfo.type.value);
  data.append('amount', state.checkInInfo.moneySpent);
  data.append('note', state.checkInInfo.note);
  data.append('createdAt', state.checkInInfo.datetime.toISOString());
  data.append('taxIncluded', state.checkInInfo.taxRate?.value);
  if (state.checkInInfo.venue?.id) {
    data.append('venueId', state.checkInInfo.venue.id);
  }
  if (state.checkInInfo.todoId) {
    data.append('todoId', state.checkInInfo.todoId);
  }
  state.checkInInfo.receipts.forEach(receipt => data.append('invoices', receipt));

  return data;
}

function getExpense(state) {
  const data = new FormData();
  data.append('type', state.expense.type.value);
  data.append('amount', state.expense.moneySpent);
  data.append('note', state.expense.note);
  data.append('createdAt', state.expense.datetime.toISOString());
  data.append('taxIncluded', state.expense.taxRate.value);
  if (state.expense.todoId) {
    data.append('todoId', state.expense.todoId);
  }
  state.expense.receipts.forEach(receipt => data.append('invoices', receipt));

  return data;
}

function isSelectedEcommerceConnected(state) {
  return CONNECTED_STATUSES.includes(state.eCommerceIntegration.status);
}

function isSelectedEcommerceNotEnabled(state) {
  return state.eCommerceIntegration.status === INTEGRATION_STATUSES.notEnabled;
}

function isSelectedEcommerceNotConnected(state) {
  return state.eCommerceIntegration.status === INTEGRATION_STATUSES.notConnected;
}

function isSelectedEcommerceNeedToUpdate(state) {
  return state.eCommerceIntegration.status === INTEGRATION_STATUSES.updateNeeded;
}

function isSelectedEcommerceActionNeeded(state) {
  return state.eCommerceIntegration.status === INTEGRATION_STATUSES.actionNeeded;
}

export default {
  connectedDistributors,
  getCheckIn,
  getExpense,
  isSelectedEcommerceConnected,
  isSelectedEcommerceNotEnabled,
  isSelectedEcommerceNotConnected,
  isSelectedEcommerceNeedToUpdate,
  isSelectedEcommerceActionNeeded,
};
