import httpService from '@/api/http';
import Order from '@/models/Order';
import { getContextId } from '@/util/utils';
import { FILE_TYPE_PDF, FILE_TYPE_XLSX, LOADING_KEY } from '@/util/constants';
import i18n from '@/i18n';

async function fetchVenueOrders(
  context,
  {
    limit = 25,
    sortBy = undefined,
    nextId = undefined,
    nextValue = undefined,
    filters = {},
    futureOrder = false,
    orderType = 'standing',
    loadingKey = LOADING_KEY.FETCH_ORDERS,
  } = {},
) {
  const venueContext = context.rootState.entities.users.contextId;
  const route = futureOrder
    ? `/venues/${venueContext}/future-orders/${orderType}`
    : `/venues/${venueContext}/orders`;
  const { data } = await httpService.get(route, {
    headers: { loadingKey },
    params: {
      limit,
      sortBy,
      nextId,
      nextValue,
      ...filters,
    },
  });

  context.commit('UPDATE_META', { meta: { ...data.meta, page: 1, lastPage: 1 } });

  return data;
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#119278f6-e260-48bb-b858-c2f4a3750340}
 *
 * @param {object} query,
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueOrdersHistoryTotal(
  context,
  { query, loadingKey = LOADING_KEY.FETCH_ORDER_HISTORY_TOTAL },
) {
  const venueContext = context.rootState.entities.users.contextId;
  return httpService.get(`/venues/${venueContext}/orders/total-amount`, {
    params: { ...query },
    headers: {
      loadingKey,
    },
  });
}

async function fetchVenueInvoices(
  context,
  { limit = 25, page, filters = {}, loadingKey = LOADING_KEY.FETCH_ORDERS } = {},
) {
  const route = `/venue/pending-invoices`;
  const { data } = await httpService.get(route, {
    headers: { loadingKey },
    params: {
      limit,
      page,
      ...filters,
    },
  });

  context.commit('UPDATE_META', { meta: { ...data.meta, nextId: null, nextValue: null } });

  return data;
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#52435d69-94f9-4413-9b89-8d16fc242981}
 *
 * @param {object} query,
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueInvoicesHistoryTotal(
  context,
  { query, loadingKey = LOADING_KEY.FETCH_ORDER_HISTORY_TOTAL },
) {
  return httpService.get(`/venue/pending-invoices/total-amount`, {
    params: { ...query },
    headers: {
      loadingKey,
    },
  });
}

function fetchVenueOrder(
  context,
  { id, futureOrder = false, loadingKey = LOADING_KEY.FETCH_ORDER },
) {
  const venueContext = context.rootState.entities.users.contextId;
  const route = futureOrder ? `/venues/${venueContext}/future-orders/${id}` : `/venue/orders/${id}`;

  return httpService.get(route, { headers: { loadingKey } });
}

const fetchPublicOrder = (context, { id, config, type }) =>
  httpService.get(`/${type}/public/orders/${id}`, config);

const createOrder = ({ rootState }, { orders, info }) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService.post(`/venues/${venueContext}/orders`, { orders, ...info });
};

const distributorCreateOrder = async ({ getters }) => {
  const res = await httpService.post('/distributor/orders', getters.getDraftOrder, {
    headers: {
      loadingKey: LOADING_KEY.DISTRIBUTOR_CREATE_ORDER,
    },
  });

  return res;
};

const distributorAddInvoiceToOrder = (
  context,
  { orderId, invoices, loadingKey = LOADING_KEY.DISTRIBUTOR_ADD_INVOICE_TO_ORDER },
) => {
  const formData = new FormData();
  invoices.forEach(invoice => formData.append('invoice', invoice));

  return httpService.put(`/distributor/orders/${orderId}/invoice`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      loadingKey,
    },
  });
};

const patchOrderDateTime = (
  { rootState },
  { orderId, dateTime, loadingKey = LOADING_KEY.UPDATE_INTERVAL },
) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService.patch(
    `/venues/${venueContext}/future-orders/${orderId}`,
    { scheduledAt: dateTime },
    {
      headers: {
        loadingKey,
      },
    },
  );
};

async function fetchDistributorOrders(
  context,
  {
    limit = 25,
    sortBy = undefined,
    nextId = undefined,
    nextValue = undefined,
    filters = {},
    updateMetaTotal = true,
    loadingKey = LOADING_KEY.FETCH_ORDERS,
  } = {},
) {
  const { data } = await httpService.get('/distributor/orders', {
    headers: { loadingKey },
    params: {
      limit,
      sortBy,
      nextId,
      nextValue,
      ...filters,
    },
  });

  let meta = { totalAmount: 0, ...data.meta };
  if (!updateMetaTotal) {
    const { totalAmount, ...rest } = meta;
    meta = { ...rest };
  }
  context.commit('UPDATE_META', { meta });

  return data;
}

/**
 * Note: Properties `distributor` and `distributorId` are added for backwards compatibility
 * with previous implementations that leverage ORM that we're trying to erradicate.
 */
async function fetchDistributorOrder(context, { id, loadingKey = LOADING_KEY.FETCH_ORDER } = {}) {
  const { distributor } = context.rootState.entities.users.loggedUser;
  const { data } = await httpService.get(`/distributor/orders/${id}`, { headers: { loadingKey } });
  return { data: { data: { ...data.data, distributor, distributorId: distributor.id } } };
}

const acceptOrder = (
  context,
  {
    id,
    declinedProducts,
    message = '',
    warehouseId,
    inventoryItems,
    loadingKey = LOADING_KEY.ACCEPT_ORDER,
  },
) =>
  httpService.patch(
    `distributor/orders/${id}/accept`,
    {
      declinedProducts,
      message,
      ...(warehouseId ? { warehouseId } : {}),
      ...(inventoryItems ? { inventoryItems } : {}),
    },
    { headers: { loadingKey } },
  );

const declineOrder = (context, { id, message = '', loadingKey = LOADING_KEY.DECLINE_ORDER }) =>
  httpService.delete(`distributor/orders/${id}`, {
    data: { message },
    headers: {
      loadingKey,
    },
  });

const markDistributorMessage = ({ commit }, { id }) =>
  httpService.put(`distributor/orders/${id}/message`).then(() => {
    commit('MARK_ORDER_MESSAGE', { id });
  });

const markVenueMessage = ({ commit, rootState }, { id }) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService.put(`/venues/${venueContext}/orders/${id}/message`).then(() => {
    commit('MARK_ORDER_MESSAGE', { id });
  });
};

const downloadOrder = (
  { rootState },
  {
    id,
    fileType,
    publicOrder,
    config,
    type,
    loadingKey = LOADING_KEY.EXPORT_ORDER,
    exportType = '',
    venueId = null,
  },
) => {
  let downloadUrl = '';
  let query = [];

  if (publicOrder && type) {
    downloadUrl = [];
    downloadUrl.push(`/${type}`);
    downloadUrl.push('public', 'orders');
    downloadUrl.push(id);
    if (fileType === FILE_TYPE_PDF) downloadUrl.push('pdf');
    if (fileType === FILE_TYPE_XLSX) downloadUrl.push('xlsx');
    downloadUrl = downloadUrl.join('/');
    query = [...(exportType ? [`type=${exportType}`] : [])];
  } else {
    downloadUrl = [];
    const venue = rootState.entities.users.contextId;
    const role = localStorage.getItem('role');

    switch (role) {
      case 'venue':
      case 'venue_group':
        downloadUrl.push(`/venues/${venueId ?? venue}`);
        break;
      case 'distributor':
        downloadUrl.push('/distributor');
        break;
      case 'admin':
        downloadUrl.push('/admin');
        break;
      default:
        downloadUrl = [];
    }

    downloadUrl.push(`orders/${id}`);
    if (fileType === FILE_TYPE_PDF) downloadUrl.push('pdf');
    if (fileType === FILE_TYPE_XLSX) downloadUrl.push('xlsx');
    downloadUrl = downloadUrl.join('/');
    query = [...(exportType ? [`type=${exportType}`] : [])];
  }
  return httpService.get(`${downloadUrl}?${query}`, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
      ...config,
    },
  });
};

const cancelFutureOrder = ({ rootState }, { id, loadingKey = LOADING_KEY.CANCEL_ORDER }) => {
  const isVenue = localStorage.getItem('role') === 'venue';

  const venueContext = rootState.entities.users.contextId;

  if (isVenue && !venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }
  return httpService.delete(`venues/${venueContext}/future-orders/${id}`, {
    headers: {
      loadingKey,
    },
  });
};

const venueOrderAction = (
  { rootState },
  { id, action, message, declinedProducts, loadingKey = LOADING_KEY.DECLINE_ORDER },
) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService
    .patch(
      `/venues/${venueContext}/orders/${id}/${action}`,
      {
        message,
        declinedProducts,
      },
      {
        headers: {
          loadingKey,
        },
      },
    )
    .then(({ data }) => {
      Order.update(data);
    });
};

const resolveDispute = (
  { rootState },
  { id, resolveData, loadingKey = LOADING_KEY.RESOLVE_DISPUTE },
) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService
    .delete(`/venues/${venueContext}/orders/${id}/dispute`, {
      data: resolveData,
      headers: {
        loadingKey,
      },
    })
    .then(({ data }) => {
      Order.update(data);
    });
};

const declineEntireOrder = ({ rootState }, { id, loadingKey = LOADING_KEY.DECLINE_ORDER }) => {
  const venueContext = rootState.entities.users.contextId;

  if (!venueContext) {
    throw new Error(`${i18n.t('global.venue')} context is required`);
  }

  return httpService
    .delete(`/venues/${venueContext}/orders/${id}`, {
      headers: {
        loadingKey,
      },
    })
    .then(() => {
      Order.delete(id);
    });
};

const publicOrderActions = (context, { id, type, action, data = {}, config = {} }) =>
  httpService.patch(`/${type}/public/orders/${id}/${action}`, data, config);

const publicOrderAbandonAction = (context, { id, type, data = {}, config = {} }) =>
  httpService.patch(`/${type}/public/orders/${id}/abandon`, data, config);

const publicOrderDecline = (context, { id, type, config = {} }) =>
  httpService.delete(`/${type}/public/orders/${id}/decline`, config);

function publicVenueResolveDispute(context, { id, config }) {
  return httpService.delete(`/venue/public/orders/${id}/dispute`, {
    ...config,
  });
}

/**
 * @since 2.4.0
 *
 * This is a generalized function for downloading binary data like PDFs and CSVs
 *
 * @param context
 * @param route
 * @return {Promise<AxiosResponse<any>>}
 */
function exportOrders(context, route) {
  return httpService.get(route, {
    responseType: 'arraybuffer',
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9072676b-34ad-49f6-be2f-b286ffa3e2d4}
 *
 * @param context
 * @param orderId
 * @param cancelNote
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorAbandonOrder(
  context,
  { orderId, cancelNote, loadingKey = LOADING_KEY.ABANDON_ORDER },
) {
  return httpService.patch(
    `/distributor/orders/${orderId}/abandon`,
    {
      message: cancelNote,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e63384ba-3030-4134-a88d-4798209f0663}
 *
 * @param context
 * @param venueId
 * @param orderId
 * @param cancelNote
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAbandonOrder(
  context,
  { venueId, orderId, cancelNote, loadingKey = LOADING_KEY.ABANDON_ORDER },
) {
  return httpService.patch(
    `/venues/${venueId}/orders/${orderId}/abandon`,
    {
      message: cancelNote,
    },
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#17a21003-636a-4672-9f74-d6ace88f1a36}
 *
 * @param context
 * @param {number} orderId
 * @param {Array} declinedProducts
 * @param {string} message
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorResolveDispute(
  context,
  { orderId, declinedProducts, message, loadingKey = LOADING_KEY.RESOLVE_DISPUTE },
) {
  return httpService.delete(`/distributor/orders/${orderId}/dispute`, {
    data: {
      declinedProducts,
      message,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.3.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a1085865-56eb-457b-a290-7c3ba2fd2dc5}
 *
 * @param context
 * @param {number|string} venueId
 * @param {number|string} limit
 * @param {string} term
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchDistributorProductsForVenue(context, { venueId, limit, term }) {
  return httpService.get('/distributor/search/products', {
    params: {
      limit,
      term,
      venueId,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a17b0307-92e1-43f6-b38e-c1c5802fed61}
 *
 * @param context
 * @param {number} venueId
 * @param {string} term
 * @param {number} limit
 * @param {number} nextId
 * @param {boolean} withWarehouseInventory
 * @param {boolean} inventoryByUnit
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductsForVenue(
  context,
  {
    venueId,
    filters,
    limit,
    nextId,
    nextValue,
    withWarehouseInventory,
    inventoryByUnit,
    loadingKey = LOADING_KEY.FETCH_PRODUCTS_FOR_VENUE,
  },
) {
  return httpService.get(`/distributor/venues/${venueId}/products`, {
    params: {
      ...filters,
      limit,
      nextId,
      nextValue,
      ...(withWarehouseInventory ? { withWarehouseInventory } : {}),
      ...(inventoryByUnit ? { inventoryByUnit } : {}),
    },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cda670ed-6bae-4a4d-81e7-de2d544b4435}
 *
 * @param context
 * @param {number} id
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchSingleOrder(context, { id, venueId, loadingKey = LOADING_KEY.FETCH_ORDER }) {
  return httpService.get(`/venues/${venueId}/orders/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#eacb3d15-7e88-4165-9101-6aebd979b992}
 *
 * @param context
 * @param {number} id
 * @param {number} products
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorAddProductsToSingleOrder(
  context,
  { id, products, config, loadingKey = LOADING_KEY.DISTRIBUTOR_ADD_PRODUCTS_TO_SINGLE_ORDER },
) {
  return httpService.patch(
    `/distributor/orders/${id}/products`,
    { products },
    {
      headers: {
        loadingKey,
        ...config,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6e97f0ba-a2a5-4da5-a983-82270bb67586}
 *
 * @param context
 * @param {number} orderId
 * @param {number} productId
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorDeleteProductsFromSingleOrder(
  context,
  { orderId, productId, config, loadingKey = LOADING_KEY.DISTRIBUTOR_ADD_PRODUCTS_TO_SINGLE_ORDER },
) {
  return httpService.delete(`/distributor/orders/${orderId}/products/${productId}`, {
    headers: {
      loadingKey,
      ...config,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#514c0ece-d8c4-481b-b66c-9054b02410ab}
 *
 * @param context
 * @param {number} venueId
 * @param {number} orderId
 * @param {number} products
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAddProductsToSingleOrder(
  context,
  {
    venueId,
    orderId,
    products,
    config,
    loadingKey = LOADING_KEY.VENUE_ADD_PRODUCTS_TO_SINGLE_ORDER,
  },
) {
  return httpService.patch(
    `/venues/${venueId}/orders/${orderId}/products`,
    { products },
    {
      headers: {
        loadingKey,
        ...config,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3b27cd26-6d3b-48ba-9535-d0800f5cef6c}
 *
 * @param context
 * @param {number} venueId
 * @param {number} orderId
 * @param {number} productId
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDeleteProductsFromSingleOrder(
  context,
  {
    venueId,
    orderId,
    productId,
    config,
    loadingKey = LOADING_KEY.VENUE_DELETE_PRODUCTS_FROM_SINGLE_ORDER,
  },
) {
  return httpService.delete(`/venues/${venueId}/orders/${orderId}/products/${productId}`, {
    headers: {
      loadingKey,
      ...config,
    },
  });
}

async function distributorSaveOrderEdit(context, order) {
  const {
    id,
    deliveryFee,
    amountAdjustment,
    taxAdjustment,
    expectedAt,
    isExpectedAtEdited,
    isDueDateEdited,
    isInvoiceNumberEdited,
    dueDate,
    venueOrderNumber,
    driver,
    orderDiscountAmount,
    isOrderDateEdited,
    orderedAt,
    orderedProducts,
    message,
    warehouseId,
    inventoryItems,
  } = order;

  const body = {
    message,
    deliveryFee,
    amountAdjustment,
    taxAdjustment,
    ...(isExpectedAtEdited && { requestedDeliveryAt: expectedAt }),
    ...(isDueDateEdited && { dueDate }),
    ...(isInvoiceNumberEdited && { venueOrderNumber }),
    ...(isOrderDateEdited && { orderedAt }),
    driverId: driver?.id ?? null,
    orderDiscountAmount,
    products: orderedProducts.map(p => ({
      id: p.productId,
      ...(typeof p.id === 'number' && { orderedProductId: p.id }),
      internalId: p.id,
      isBonus: p.isBonus || null,
      bonusId: p.bonusId || null,
      quantity: p.quantity,
      priceQuantity: p.priceQuantity,
      ...(!p.marketPrice && { price: p.price }),
      marketPrice: p.marketPrice,
      ...(p.note && { message: p.note }),
      ...(p.discountAmount && { discountAmount: p.discountAmount }),
      ...(p.discountValue && { discountValue: p.discountValue }),
      ...(p.discountType && { discountType: p.discountType }),
    })),
    ...(warehouseId ? { warehouseId } : {}),
    ...(inventoryItems ? { inventoryItems } : {}),
  };

  const { data } = await httpService.patch(`/distributor/orders/${id}`, body);

  const { distributor } = context.rootState.entities.users.loggedUser;
  return { data: { data: { ...data.data, distributor, distributorId: distributor.id } } };
}

async function venueSaveOrderEdit(context, { order, venueId }) {
  const {
    id,
    deliveryFee,
    amountAdjustment,
    taxAdjustment,
    orderDiscountAmount,
    expectedAt,
    invoiceNumber,
    invoiceDate,
    isExpectedAtEdited,
    isInvoiceNumberEdited,
    isInvoiceDateEdited,
    orderedProducts,
    message,
  } = order;

  const body = {
    message,
    deliveryFee,
    amountAdjustment,
    taxAdjustment,
    ...(isExpectedAtEdited && { requestedDeliveryAt: expectedAt }),
    ...(isInvoiceNumberEdited && { invoiceNumber }),
    ...(isInvoiceDateEdited && { invoiceDate }),
    orderDiscountAmount,
    products: orderedProducts.map(p => ({
      id: p.productId,
      ...(typeof p.id === 'number' && { orderedProductId: p.id }),
      internalId: p.id,
      isBonus: p.isBonus || null,
      bonusId: p.bonusId || null,
      quantity: p.quantity,
      ...(!p.marketPrice && { price: p.price }),
      priceQuantity: p.priceQuantity,
      marketPrice: p.marketPrice,
      ...(p.note && { message: p.note }),
    })),
  };

  const { data } = await httpService.patch(`/venues/${venueId}/orders/${id}`, body);

  return Order.insertOrUpdate({ data: data.data });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3cc83680-2fcb-43c0-a6c1-2c095aef6f40}
 *
 * @param context
 * @param {Object} order
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueEditPublicOrder(
  context,
  { order, config, loadingKey = LOADING_KEY.VENUE_EDIT_PUBLIC_SINGLE_ORDER },
) {
  const { id, isExpectedAtEdited, isInvoiceNumberEdited, invoiceNumber, expectedAt } = order;
  const body = {
    ...(isExpectedAtEdited && { requestedDeliveryAt: expectedAt }),
    ...(isInvoiceNumberEdited && { invoiceNumber }),
  };

  body.products = order.orderedProducts.map(p => ({
    id: p.productId,
    ...(typeof p.id === 'number' && { orderedProductId: p.id }),
    internalId: p.id,
    isBonus: p.isBonus || null,
    bonusId: p.bonusId || null,
    quantity: p.quantity,
    marketPrice: p.marketPrice,
    ...(!p.marketPrice && { price: p.price }),
    ...(p.priceQuantity && { priceQuantity: p.priceQuantity }),
    ...(p.note && { message: p.note }),
  }));
  return httpService.patch(`/venue/public/orders/${id}`, body, {
    headers: {
      loadingKey,
      ...config,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2e21147e-9ae9-4abd-b49d-03109362e927}
 *
 * @param context
 * @param {Object} order
 * @param {Object} config
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorEditPublicOrder(
  context,
  { order, config, loadingKey = LOADING_KEY.DISTRIBUTOR_EDIT_PUBLIC_SINGLE_ORDER },
) {
  const { id, isExpectedAtEdited, isInvoiceNumberEdited, expectedAt, invoiceNumber } = order;
  const body = {
    ...(isExpectedAtEdited && { requestedDeliveryAt: expectedAt }),
    ...(isInvoiceNumberEdited && { invoiceNumber }),
  };
  body.products = order.orderedProducts.map(p => ({
    id: p.productId,
    ...(typeof p.id === 'number' && { orderedProductId: p.id }),
    internalId: p.id,
    isBonus: p.isBonus || null,
    bonusId: p.bonusId || null,
    quantity: p.quantity,
    marketPrice: p.marketPrice,
    ...(!p.marketPrice && { price: p.price }),
    ...(p.note && { message: p.note }),
    ...(p.priceQuantity && { priceQuantity: p.priceQuantity }),
  }));
  body.deliveryFee = order.deliveryFee;
  return httpService.patch(`/distributor/public/orders/${id}`, body, {
    headers: {
      loadingKey,
      ...config,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#05fb17f5-fd0c-4335-804e-5470950ec4f4 }
 *
 * @param context
 * @param { number } productId - product.productId
 * @param { number } quantity
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductPriceVenue(context, { productId, quantity, loadingKey }) {
  const venueId = getContextId();

  return httpService.get(`/venues/${venueId}/products/${productId}/price`, {
    params: { quantity },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#74c3efe8-f5aa-40b9-a0c2-240c2e14cc1a }
 *
 * @param context
 * @param { number } venueId
 * @param { number } productId - product.productId
 * @param { number } quantity
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductPriceSupplier(context, { venueId, productId, quantity, loadingKey }) {
  return httpService.get(`/distributor/products/${productId}/venues/${venueId}/price`, {
    params: { quantity },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6d4407b5-4e83-4984-b104-35a29537d97f }
 *
 * @param context
 * @param { number } orderId
 * @param { number } productId - product.id
 * @param { number } quantity
 * @param { string } string
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductPriceVenuePublic(
  context,
  { orderId, productId, quantity, token, loadingKey },
) {
  return httpService.get(`/venue/public/orders/${orderId}/products/${productId}/price`, {
    params: { quantity },
    headers: { loadingKey, 'X-Public-Token': token },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#95498af9-2574-46ac-985f-ca354f158ea5 }
 *
 * @param context
 * @param { number } orderId
 * @param { number } productId - product.id
 * @param { number } quantity
 * @param { string } token
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchProductPriceSupplierPublic(
  context,
  { orderId, productId, quantity, token, loadingKey },
) {
  return httpService.get(`/distributor/public/orders/${orderId}/products/${productId}/price`, {
    params: { quantity },
    headers: { loadingKey, 'X-Public-Token': token },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#28c445af-dace-48bc-a9cf-3fc696b3bb1c }
 *
 * @param context
 * @param { number } orderId
 * @param { string } token
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchPublicOrderDeliveryDate(context, { orderId, token, loadingKey }) {
  return httpService.get(`/venue/public/orders/${orderId}/delivery-date`, {
    headers: { loadingKey, 'X-Public-Token': token },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ac335209-ffdc-43ba-8c47-13b6d2cfeca0}
 *
 * @param context
 * @param { Object } data
 * @param { number } orderId
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchOrderFulfillmentItems(
  context,
  { orderId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ORDER_FULFILLMENT_ITEMS } = {},
) {
  return httpService.post(`/distributor/orders/${orderId}/fulfillment-items`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7fcc6258-610e-4b18-b3e7-d81403e1fa21}
 *
 * @param context
 * @param { Object } data
 * @param { number } orderId
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchOrderInventoryItems(
  context,
  { orderId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ORDER_INVENTORY_ITEMS } = {},
) {
  return httpService.post(`/distributor/orders/${orderId}/inventory-items`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0a98f8ee-b8fe-485f-aa72-918cbd025217}
 *
 * @param context
 * @param { number } orderId
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchOrderInventoryStock(
  context,
  { orderId, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_STOCK } = {},
) {
  return httpService.get(`/distributor/orders/${orderId}/inventory`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#31753f39-e8e1-4609-bc79-67ab34ec528e}
 *
 * @param context
 * @param {number} orderId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueRequestMoreInvoices(
  context,
  { venueId, loadingKey = LOADING_KEY.VENUE_REQUEST_MORE_INVOICES } = {},
) {
  return httpService.post(
    `/venues/${venueId}/pending-invoices/requests`,
    {},
    { headers: { loadingKey } },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a7f8a998-574c-4360-a63b-211629c08f40}
 *
 * @param context
 * @param { number } limit
 * @param { number } nextId
 * @param { string } nextValue
 * @param { number } distributorId
 * @param { number } venueId
 * @param { string } status
 * @param { number } from
 * @param { number } to
 * @param { string } sortBy
 * @param { number } orderNumber
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchOrderHistory(
  context,
  {
    limit = 25,
    nextId,
    nextValue,
    distributorId,
    venueId,
    status,
    from,
    to,
    sortBy = 'orderedAt',
    orderNumber,
    loadingKey = LOADING_KEY.FETCH_ADMIN_ORDER_HISTORY,
  } = {},
) {
  return httpService.get('/admin/orders', {
    params: {
      limit,
      nextId,
      nextValue,
      distributorId,
      venueId,
      status,
      from,
      to,
      sortBy,
      orderNumber,
    },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a7f8a998-574c-4360-a63b-211629c08f40}
 *
 * @param context
 * @param { number } orderId
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchSingleOrder(context, { orderId, loadingKey = LOADING_KEY.FETCH_ORDER } = {}) {
  return httpService.get(`/admin/orders/${orderId}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#896c4d6e-6347-46c1-92c6-bd017d68dfb7}
 *
 * @param context
 * @param { number } orderId
 * @param { object } data
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorConvertQuoteToOrder(
  context,
  { orderId, data, loadingKey = LOADING_KEY.DISTRIBUTOR_CONVERT_QUOTE } = {},
) {
  return httpService.patch(`/distributor/orders/${orderId}/convert`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f3c08851-fed4-4e9e-96f6-adb7e07088a2}
 *
 * @param context
 * @param { number } orderId
 * @param { object } data
 * @param { string } loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorCreateFromDraft(
  context,
  { orderId, data, loadingKey = LOADING_KEY.CREATE_FROM_DRAFT } = {},
) {
  return httpService.patch(`/distributor/orders/${orderId}/create-from-draft`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9fa18362-a880-4ed2-b5de-13a856355f63}
 *
 * @param context
 * @param {number} limit
 * @param {number} page
 * @param {string} term
 * @param {number} from
 * @param {number} to
 * @param {number} groupId
 * @param {string} processingStatus
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminFetchPendingInvoices(
  context,
  {
    limit = 25,
    page,
    term,
    groupId,
    from,
    to,
    processingStatus,
    loadingKey = LOADING_KEY.FETCH_ADMIN_PENDING_INVOICES,
  } = {},
) {
  return httpService.get('/admin/pending-invoices', {
    params: {
      limit,
      page,
      from,
      to,
      term,
      groupId,
      processingStatus,
    },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fcbf1854-fb5e-4d34-8912-73db72071624}
 *
 * @param context
 * @param {number} invoiceId
 * @param {number} venueId
 * @param {string} processingStatus
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminFetchPendingInvoice(
  context,
  { invoiceId, loadingKey = LOADING_KEY.FETCH_ADMIN_PENDING_INVOICE, processingStatus } = {},
) {
  return httpService.get(`/admin/pending-invoices/${invoiceId}`, {
    headers: { loadingKey },
    params: {
      ...(processingStatus ? { processingStatus } : {}),
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c409cd52-b514-4f89-ba87-982d512be602}
 *
 * @param context
 * @param {number} invoiceId
 * @param {number} limit
 * @param {number} page
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminFetchPendingInvoiceMerge(
  context,
  { invoiceId, page, limit = 10, loadingKey = LOADING_KEY.FETCH_ADMIN_PENDING_INVOICE_MERGE } = {},
) {
  return httpService.get(`/admin/pending-invoices/${invoiceId}/merge-invoices`, {
    params: {
      limit,
      page,
    },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#4d8150d2-c78f-4680-a147-ac7b382b4107}
 *
 * @param context
 * @param {number} invoiceId
 * @param {string} loadingKey
 * @param {object} body
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminPendingInvoiceMerge(
  context,
  { invoiceId, body, loadingKey = LOADING_KEY.ADMIN_PENDING_INVOICE_MERGE } = {},
) {
  return httpService.put(`/admin/pending-invoices/${invoiceId}/merge`, body, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#892fdb22-3bf3-41fd-a186-179af76c86ba}
 *
 * @param context
 * @param {number} invoiceId
 * @param {string} clearingNote
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminUpdateClearingNote(
  context,
  { invoiceId, clearingNote, loadingKey = LOADING_KEY.ADMIN_UPDATE_CLEARING_NOTE } = {},
) {
  return httpService.put(
    `/admin/pending-invoices/${invoiceId}/clearing-note`,
    { text: clearingNote },
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c3fba3b1-522e-4fb5-af65-792daf493f66}
 *
 * @param context
 * @param {number} invoiceId
 * @param {string} clearingNote
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminCompletePendingInvoice(
  context,
  { body, invoiceId, formKey, loadingKey = LOADING_KEY.ADMIN_COMPLETE_PENDING_INVOICE } = {},
) {
  return httpService.put(`/admin/pending-invoices/${invoiceId}/complete`, body, {
    headers: { loadingKey, formKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#fbfb98cc-0865-4ab5-aa04-bae5239fe39e}
 *
 * @param context
 * @param {number} invoiceId
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueCompletePendingInvoice(
  context,
  {
    body,
    invoiceId,
    venueId,
    formKey,
    loadingKey = LOADING_KEY.ADMIN_COMPLETE_PENDING_INVOICE,
  } = {},
) {
  return httpService.put(`/venues/${venueId}/pending-invoices/${invoiceId}/complete`, body, {
    headers: { loadingKey, formKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#013cfef6-5328-46b6-92db-b01ad7f2c73d}
 *
 * @param context
 * @param {number} invoiceId
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueInvoiceSendToClearing(
  context,
  { invoiceId, venueId, loadingKey = LOADING_KEY.ADMIN_COMPLETE_PENDING_INVOICE } = {},
) {
  return httpService.put(`/venues/${venueId}/pending-invoices/${invoiceId}/send-to-clearing`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2ff4484d-70ea-4f37-9965-ba7de4f0c02c}
 *
 * @param context
 * @param {number} invoiceId
 * @param {number} venueId
 * @param {string} loadingKey
 * @param {object} body
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venuePendingInvoiceMerge(
  context,
  { invoiceId, venueId, body, loadingKey = LOADING_KEY.ADMIN_PENDING_INVOICE_MERGE } = {},
) {
  return httpService.put(`/venues/${venueId}/pending-invoices/${invoiceId}/merge`, body, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#80fad2fd-db53-45f0-90db-a3ee29f7c2d0}
 *
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminFetchNextPendingInvoice(
  context,
  {
    invoiceId,
    status = null,
    groupId,
    from,
    to,
    loadingKey = LOADING_KEY.ADMIN_FETCH_NEXT_PENDING_INVOICE,
  } = {},
) {
  return httpService.get('/admin/pending-invoices/next', {
    headers: { loadingKey },
    params: {
      ...(invoiceId ? { id: invoiceId } : {}),
      ...(status ? { processingStatus: status } : {}),
      ...(groupId ? { groupId } : {}),
      ...(from ? { from } : {}),
      ...(to ? { to } : {}),
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#22eb8ac7-cb54-4a1b-8730-85bab4c44f33}
 *
 * @param context
 * @param {number} invoiceId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function adminReleasePendingInvoice(
  context,
  { invoiceId, loadingKey = LOADING_KEY.RELEASE_ADMIN_PENDING_INVOICE } = {},
) {
  return httpService.delete(`/admin/pending-invoices/${invoiceId}/user`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#871ddb75-92a5-4b3e-a2d3-341766154e08}
 *
 * @param context
 * @param {number} venueId
 * @param {number} orderId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function exportToAccountingIntegration(
  context,
  { venueId, orderId, loadingKey = LOADING_KEY.EXPORT_ORDER } = {},
) {
  return httpService.put(`/venues/${venueId}/orders/${orderId}/accounting`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#96b5eb8d-62e5-4afe-94b4-f307b26c7e29}
 *
 * @param context
 * @param {number} venueId
 * @param {number} orderId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateExportStatus(
  context,
  { venueId, orderId, exportStatus, loadingKey = LOADING_KEY.EXPORT_ORDER } = {},
) {
  return httpService.put(
    `/venues/${venueId}/orders/${orderId}/export-status`,
    {
      exportStatus,
    },
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6b6bfb06-ba77-44d9-ab3f-c75455fbf7f9}
 *
 * @param context
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function bulkExportToAccountingIntegration(
  context,
  { venueId, loadingKey = LOADING_KEY.BULK_EXPORT_ORDERS } = {},
) {
  return httpService.put(`/venues/${venueId}/orders/accounting`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d9fa81b5-877c-465f-afe1-25c4fa5b4157}
 *
 * @param context
 * @param {number} venueId
 * @param {string} loadingKey
 * @param {Object} query
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function bulkExportInvoicesToAccountingIntegration(
  context,
  { query, loadingKey = LOADING_KEY.BULK_EXPORT_ORDERS } = {},
) {
  return httpService.put(`/venue/pending-invoices/accounting?${query}`, {
    headers: { loadingKey },
  });
}

export default {
  fetchProductPriceVenue,
  fetchProductPriceSupplier,
  fetchProductPriceVenuePublic,
  fetchProductPriceSupplierPublic,

  fetchProductsForVenue,
  fetchPublicOrder,

  fetchVenueOrders,
  fetchVenueOrdersHistoryTotal,
  fetchVenueInvoices,
  fetchVenueInvoicesHistoryTotal,
  fetchVenueOrder,
  createOrder,
  distributorCreateOrder,
  distributorAddInvoiceToOrder,
  patchOrderDateTime,

  fetchDistributorOrders,
  fetchDistributorOrder,
  acceptOrder,
  declineOrder,
  cancelFutureOrder,

  markDistributorMessage,
  markVenueMessage,

  venueOrderAction,
  resolveDispute,
  declineEntireOrder,
  publicOrderActions,
  publicOrderAbandonAction,
  publicOrderDecline,

  downloadOrder,
  publicVenueResolveDispute,

  // Refactored Methods
  exportOrders,
  distributorAbandonOrder,
  venueAbandonOrder,
  distributorResolveDispute,
  fetchDistributorProductsForVenue,
  venueFetchSingleOrder,
  distributorAddProductsToSingleOrder,
  distributorDeleteProductsFromSingleOrder,
  venueAddProductsToSingleOrder,
  venueDeleteProductsFromSingleOrder,
  distributorSaveOrderEdit,
  venueSaveOrderEdit,
  venueEditPublicOrder,
  distributorEditPublicOrder,
  venueFetchPublicOrderDeliveryDate,
  venueRequestMoreInvoices,
  venueCompletePendingInvoice,
  venuePendingInvoiceMerge,
  venueInvoiceSendToClearing,

  exportToAccountingIntegration,
  bulkExportToAccountingIntegration,
  bulkExportInvoicesToAccountingIntegration,
  updateExportStatus,

  distributorFetchOrderFulfillmentItems,
  distributorFetchOrderInventoryItems,
  distributorFetchOrderInventoryStock,
  distributorConvertQuoteToOrder,
  distributorCreateFromDraft,

  adminFetchOrderHistory,
  adminFetchSingleOrder,
  adminFetchPendingInvoices,
  adminFetchPendingInvoice,
  adminUpdateClearingNote,
  adminCompletePendingInvoice,
  adminFetchPendingInvoiceMerge,
  adminPendingInvoiceMerge,
  adminFetchNextPendingInvoice,
  adminReleasePendingInvoice,
};
