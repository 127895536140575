import i18n from '@/i18n';

const XeroSetup = () =>
  import(/* webpackChunkName: "venue-xero" */ '@/views/platform/venue/xero/setup/Index.vue');
const XeroSettings = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/Index.vue'
  );
const XeroAccountInfo = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/Account.vue'
  );
const XeroSuppliers = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/Suppliers.vue'
  );
const XeroProducts = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/Products.vue'
  );
const XeroProductGroups = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/ProductGroups.vue'
  );
const XeroTaxMapping = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/TaxMapping.vue'
  );
const XeroNotConnected = () =>
  import(
    /* webpackChunkName: "venue-xero-settings" */ '@/views/platform/venue/xero/settings/NotConnected.vue'
  );

export default [
  // Setup Route
  {
    name: 'venue-xero-setup',
    path: '/buyers/xero/setup',
    component: XeroSetup,
  },
  // Settings Routesamsun
  {
    path: '/buyers/:id(\\d+)/settings/integrations/xero',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: XeroSettings,
    children: [
      {
        path: 'account-info',
        name: 'venue-xero-account',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: XeroAccountInfo,
        meta: {
          title: `${i18n.t('xero.name')} Account Info`,
        },
      },
      {
        path: 'suppliers',
        name: 'venue-xero-venues',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: XeroSuppliers,
        meta: {
          title: `${i18n.t('xero.name')} ${i18n.t('global.distributors')}`,
        },
      },
      {
        path: 'products',
        name: 'venue-xero-products',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: XeroProducts,
        meta: {
          title: `${i18n.t('xero.name')} Products`,
        },
      },
      {
        path: 'product-groups',
        name: 'venue-xero-product-groups',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: XeroProductGroups,
        meta: {
          title: `${i18n.t('xero.name')} ${i18n.t('global.productGroups')}`,
        },
      },
      {
        path: 'tax',
        name: 'venue-xero-tax',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: XeroTaxMapping,
        meta: {
          title: `${i18n.t('xero.name')} Tax`,
        },
      },
      {
        path: '/',
        name: 'venue-xero-not-connected',
        component: XeroNotConnected,
        meta: {
          title: `${i18n.t('xero.name')} Not Connected`,
        },
      },
    ],
  },
];
