function SET_META_DATA(state, meta) {
  state.meta = { ...state.meta, ...meta };
}

function RESET_META(state) {
  state.metaTarget = {
    page: 1,
    lastPage: 1,
  };
  state.metaPending = {
    page: 1,
    lastPage: 1,
  };
  state.metaOpened = {
    page: 1,
    lastPage: 1,
  };
  state.metaNotOrdering = {
    page: 1,
    lastPage: 1,
  };
  state.metaOrdering = {
    page: 1,
    lastPage: 1,
  };
  state.metaOrders = {};
  state.metaProducts = {};
}

function SET_META_DATA_TARGET(state, meta) {
  state.metaTarget = { ...state.metaTarget, ...meta };
}

function SET_META_DATA_PENDING(state, meta) {
  state.metaPending = { ...state.metaPending, ...meta };
}

function SET_META_DATA_OPENED(state, meta) {
  state.metaOpened = { ...state.metaOpened, ...meta };
}

function SET_META_DATA_NOT_ORDERING(state, meta) {
  state.metaNotOrdering = { ...state.metaNotOrdering, ...meta };
}

function SET_META_DATA_ORDERING(state, meta) {
  state.metaOrdering = { ...state.metaOrdering, ...meta };
}

function SET_META_DATA_ORDERS(state, meta) {
  state.metaOrders = { ...state.metaOrders, ...meta };
}

function SET_META_DATA_PRODUCTS(state, meta) {
  state.metaProducts = { ...state.metaProducts, ...meta };
}

function CLEAR_META_DATA_ORDERS(state) {
  state.metaOrders = {};
}

function CLEAR_META_DATA_PRODUCTS(state) {
  state.metaProducts = {};
}

function UPDATE_FILTERS_OUTLETS(state, filters) {
  state.filtersOutlets = {
    ...state.filtersOutlets,
    ...filters,
  };
}

function UPDATE_FILTERS_PRODUCTS(state, filters) {
  state.filtersProducts = {
    ...state.filtersProducts,
    ...filters,
  };
}

function UPDATE_FILTERS_ACTIVITY(state, filters) {
  state.filtersActivity = {
    ...state.filtersActivity,
    ...filters,
  };
}

function UPDATE_FILTERS_TO_DOS(state, filters) {
  state.filtersToDos = {
    ...state.filtersToDos,
    ...filters,
  };
}

function UPDATE_FILTERS_CHECK_IN(state, filters) {
  state.filtersCheckIn = {
    ...state.filtersCheckIn,
    ...filters,
  };
}

function UPDATE_FILTERS_EXPENSE(state, filters) {
  state.filtersExpense = {
    ...state.filtersExpense,
    ...filters,
  };
}

export default {
  UPDATE_FILTERS_OUTLETS,
  UPDATE_FILTERS_PRODUCTS,
  UPDATE_FILTERS_TO_DOS,
  UPDATE_FILTERS_ACTIVITY,
  UPDATE_FILTERS_EXPENSE,
  UPDATE_FILTERS_CHECK_IN,
  CLEAR_META_DATA_ORDERS,
  CLEAR_META_DATA_PRODUCTS,
  SET_META_DATA_ORDERS,
  SET_META_DATA_PRODUCTS,
  SET_META_DATA_ORDERING,
  SET_META_DATA_NOT_ORDERING,
  SET_META_DATA_OPENED,
  SET_META_DATA_PENDING,
  SET_META_DATA_TARGET,
  SET_META_DATA,
  RESET_META,
};
