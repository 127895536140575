import i18n from '@/i18n';

const QuickBooksSetup = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/quick-books/setup/Index.vue'
  );
const QuickBooksSettings = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/quick-books/settings/Index.vue'
  );
const QuickBooksAccountInfo = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/quick-books/settings/Account.vue'
  );
const QuickBooksNotConnected = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/quick-books/settings/NotConnected.vue'
  );
const QuickBooksCustomers = () =>
  import(
    /* webpackChunkName: "distributor" */ '@/views/platform/distributor/quick-books/settings/Venues.vue'
  );

export default [
  // Setup Route
  {
    name: 'distributor-quick-books-setup',
    path: '/supplier/quick-books/setup',
    component: QuickBooksSetup,
  },
  // Settings Routesamsun
  {
    path: '/supplier/settings/integrations/quick-books',
    component: QuickBooksSettings,
    children: [
      {
        path: 'account-info',
        name: 'distributor-quick-books-account',
        component: QuickBooksAccountInfo,
        meta: {
          title: `${i18n.t('quickBooks.name')} Account Info`,
        },
      },
      {
        path: 'outlets',
        name: 'distributor-quick-books-outlets',
        component: QuickBooksCustomers,
        meta: {
          title: `${i18n.t('quickBooks.name')} ${i18n.t('global.venues')}`,
        },
      },
      {
        path: '/',
        name: 'distributor-quick-books-not-connected',
        component: QuickBooksNotConnected,
        meta: {
          title: `${i18n.t('quickBooks.name')} Not Connected`,
        },
      },
    ],
  },
];
