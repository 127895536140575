<template>
  <EzModal ref="modal" v-on="$listeners">
    <div :class="`modal__inner modal__inner--${size}`">
      <div class="modal__header">
        <h2><slot name="title"></slot></h2>
        <button class="modal__close" @click="close()" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
          </svg>
        </button>
      </div>
      <div class="modal__text">
        <slot name="content"></slot>
      </div>
      <div class="modal__footer">
        <slot name="footer">
          <EzButton type="link">Cancel</EzButton>
          <EzButton type="red">Yes, Delete</EzButton>
        </slot>
      </div>
    </div>
  </EzModal>
</template>
<script>
import { EzModal } from '.';
import EzButton from '../Button/EzButton.vue';

export default {
  components: {
    EzButton,
    EzModal,
  },
  props: {
    size: {
      type: String,
      default: 'md',
      validator: value => ['xs', 'md', 'xl'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal {
  &__inner{
    &--md { max-width: 480px; }
    &--xl { max-width: 640px; }
  }
  &__header {
    @extend %flex-center;
    justify-content: space-between;
    background-color: $color-white;
    padding: 1.25rem 1.5rem;
    border-radius: 3px;

    h2 {
      margin: 0;
      @include font-size(20px, 24px);
      font-weight: 500;
    }
  }
  &__close {
    @extend %button-reset;
    color: #DEE1E4;
    @include font-size(14px);
    cursor: pointer;
    svg {
      fill: $color-gray-6C;
    }
  }
  &__text {
    padding: 0 1.5em 1rem 1.5rem;
  }
  &__footer {
    padding: .875rem 1.5rem;
    text-align: right;
  }
}
</style>
