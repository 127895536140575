export const parseErrors = (response) => {
  const parsedErrors = {};
  if (response && response.data) {
    const { data: { error } } = response;

    if (!error.fields) {
      return parsedErrors;
    }

    Object.entries(error.fields).forEach(([key, value]) => {
      const parsedKey = key.split('.').map((k, ind) => (ind > 0 ? `[${k}]` : k)).join('');
      parsedErrors[parsedKey] = value;
    });
  }

  return parsedErrors;
};

export default {
  parseErrors,
};
