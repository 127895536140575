const SET_ERROR = (state, payload) => {
  state.formErrors = {
    ...state.formErrors,
    ...payload,
  };
};

const CLEAR_ERRORS = (state, { key }) => {
  state.formErrors = {
    ...state.formErrors,
    [key]: null,
  };
};

const CLEAR_ERROR = (state, { formKey, field }) => {
  const errors = state.formErrors[formKey];

  if (!errors) {
    return;
  }

  delete errors[field];

  state.formErrors = {
    ...state.formErrors,
    [formKey]: {
      ...errors,
    },
  };
};

export default {
  SET_ERROR,
  CLEAR_ERROR,
  CLEAR_ERRORS,
};
