import { clone } from '@/util/utils';
import { initOfflineOrder, initOfflineOrderNewSupplier } from '@/store/modules/venues/index';

const SET_OFFLINE_PRODUCTS = (state, products) => {
  const selectedProds = state.offlineOrder.selectedProducts;
  products = products.map((prod) => {
    const sP = selectedProds.find(sProd => sProd.id === prod.id);
    return sP
      ? { ...sP }
      : {
        ...prod,
        checked: false,
        quantity: 0,
      };
  });

  state.offlineOrder = {
    ...state.offlineOrder,
    products: [...products],
  };
};

const UPDATE_OFFLINE = (state, payload) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    ...payload,
  };
};

const CLEAR_OFFLINE = (state) => {
  state.offlineOrder = { ...initOfflineOrder };
};

const CLEAR_OFFLINE_PRODUCTS = (state) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    products: [],
  };
};

const ADD_OFFLINE_SELECTED_PRODUCT = (state, product) => {
  product = {
    ...product,
    checked: true,
  };

  const { products } = state.offlineOrder;
  const index = products.findIndex(prod => prod.id === product.id);
  products[index] = product;

  state.offlineOrder = {
    ...state.offlineOrder,
    selectedProducts: [product, ...state.offlineOrder.selectedProducts],
    products: [...products],
  };
};

const REMOVE_OFFLINE_SELECTED_PRODUCT = (state, product) => {
  product = {
    ...product,
    checked: false,
  };

  const { products } = state.offlineOrder;
  const index = products.findIndex(prod => prod.id === product.id);
  products[index] = product;

  state.offlineOrder = {
    ...state.offlineOrder,
    selectedProducts: state.offlineOrder.selectedProducts.filter(p => p.id !== product.id),
    products: [...products],
  };
};

const CLEAR_OFFLINE_SELECTED_PRODUCTS = (state) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    selectedProducts: [],
  };
};

const UPDATE_OFFLINE_PRODUCT = (state, product) => {
  const i = state.offlineOrder.products.findIndex(p => p.id === product.id);
  const j = state.offlineOrder.selectedProducts.findIndex(p => p.id === product.id);

  if (j > -1) {
    state.offlineOrder.selectedProducts[j] = { ...product };
  }
  state.offlineOrder.products[i] = { ...product };

  state.offlineOrder = clone(state.offlineOrder);
};

const INSERT_ME = (state, { me }) => {
  state.me = me;
};

const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const UPDATE_NEW_ADDED_PRODUCTS = (state, product) => {
  state.newVenue = {
    ...state.newVenue,
    products: [
      ...state.newVenue.products,
      ...product,
    ],
  };
};

const CLEAR_DRAFT = (state) => {
  state.draft = {
    id: -1,
    tags: [],
  };
  state.draftAdmin = {
    owner: {},
    finance: {},
    ordering: {},
  };
  state.newVenue = {
    products: [],
  };
};

const UPDATE_DRAFT_ADMIN = (state, payload) => {
  state.draftAdmin = {
    ...state.draftAdmin,
    ...payload,
  };
};

const INSERT_DISTRIBUTOR_VENUE_SINGLE = (state, payload) => {
  state.singleDistributorVenue = payload;
};

const CLEAR_DISTRIBUTOR_VENUE_SINGLE = (state) => {
  state.singleDistributorVenue = {};
};

const UPDATE_ONBOARDING_CONTACTS = (state, payload) => {
  state.onboarding = payload;
};

const CLEAR_ONBOARDING_CONTACTS = (state) => {
  state.onboarding = {};
};

const ADD_ACCEPTED_TOS = (state, payload) => {
  state.onboarding = {
    ...state.onboarding,
    ...payload,
  };
};

const UPDATE_PUBLIC_ONBOARDING = (state, payload) => {
  state.publicOnboarding = {
    ...state.publicOnboarding,
    ...payload,
  };
};

const CLEAR_PUBLIC_ONBOARDING = (state) => {
  state.publicOnboarding = {
    venue: {},
    owner: {},
    termsAndConditions: false,
  };
};

const SET_LOGIN_PUBLIC_TOKEN = (state, payload) => {
  state.publicToken = payload;
};

const CLEAR_LOGIN_PUBLIC_TOKEN = (state) => {
  state.publicToken = null;
};

const SET_OFFLINE_ORDER_DISTRIBUTOR = (state, payload) => {
  state.offlineOrder.distributor = payload;
};

const SET_OFFLINE_ORDER_RECEIPTS = (state, payload) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    receipts: [...payload.values()],
  };
};

const SET_OFFLINE_ORDER_DELIVERY_FEE = (state, payload) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    deliveryFee: payload,
  };
};

const SET_OFFLINE_ORDER_AMOUNT_ADJUSTMENT = (state, payload) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    amountAdjustment: payload,
  };
};

const SET_OFFLINE_ORDER_TAX_ADJUSTMENT = (state, payload) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    taxAdjustment: payload,
  };
};

const CLEAR_OFFLINE_ORDER_DELIVERY_FEE = (state) => {
  state.offlineOrder.deliveryFee = null;
};

const CLEAR_OFFLINE_ORDER_AMOUNT_ADJUSTMENT = (state) => {
  state.offlineOrder.amountAdjustment = null;
};

const CLEAR_OFFLINE_ORDER_TAX_ADJUSTMENT = (state) => {
  state.offlineOrder.taxAdjustment = null;
};

const CLEAR_OFFLINE_ORDER_RECEIPTS = (state) => {
  state.offlineOrder = {
    ...state.offlineOrder,
    receipts: [],
  };
};

const SET_OFFLINE_ORDER_DATE = (state, payload) => {
  state.offlineOrder.date = payload;
};

const SET_OFFLINE_ORDER_NEW_SUPPLIER = (state, payload) => {
  state.offlineOrderNewSupplier = {
    ...state.offlineOrderNewSupplier,
    ...payload,
  };
};

const CLEAR_OFFLINE_ORDER_NEW_SUPPLIER = (state) => {
  state.offlineOrderNewSupplier = { ...initOfflineOrderNewSupplier };
};

const CLEAR_SELECTED_SUPPLIER = (state) => {
  state.offlineOrderNewSupplier = {
    supplier: {},
    date: null,
    products: [],
    pendingUpdate: {},
  };
};

const UPDATE_OFFLINE_PRODUCTS_NEW_SUPPLIER = (state, payload) => {
  state.offlineOrderNewSupplier.products = payload;
};

const SET_PENDING_UPDATE = (state, payload) => {
  state.offlineOrderNewSupplier = {
    ...state.offlineOrderNewSupplier,
    ...payload,
  };
};

const SET_SINGLE_DISTRIBUTOR_VENUE_ACCOUNT_OWNER = (state, accountOwner) => {
  state.singleDistributorVenue = {
    ...state.singleDistributorVenue,
    accountOwner: { ...accountOwner },
  };
};

const SET_ONBOARDING_LINK_DATA = (state, payload) => {
  state.sendOnboardingLinkData = {
    ...state.sendOnboardingLinkData,
    ...payload,
  };
};

const UPDATE_PRICE_CHANGED_PRODUCTS = (state, product) => {
  const i = state.sendOnboardingLinkData.products.findIndex(p => p.productId === product.productId);
  if (i === -1) {
    state.sendOnboardingLinkData.products.push({ ...product });
    return;
  }
  state.sendOnboardingLinkData.products[i] = { ...product };
};

const CLEAR_SEND_ONBOARDING_LINK_DATA = (state) => {
  state.sendOnboardingLinkData = {
    products: [],
    route: 'distributor-outlets',
  };
};

const SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA = (state, payload) => {
  state.acceptAndSendOnboardingLink = {
    ...state.acceptAndSendOnboardingLink,
    ...payload,
  };
};

const CLEAR_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA = (state) => {
  state.acceptAndSendOnboardingLink = {
    products: [],
  };
};

const UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT = (state, product) => {
  const i = state.acceptAndSendOnboardingLink.products
    .findIndex(p => p.productId === product.productId);
  if (i === -1) {
    state.acceptAndSendOnboardingLink.products.push({ ...product });
    return;
  }
  state.acceptAndSendOnboardingLink.products[i] = { ...product };
};

const SET_SELECTED_INTEGRATION = (state, payload) => {
  state.integrationVenueDraft = payload;
};

const UPDATE_SELECTED_INTEGRATION = (state, payload) => {
  state.integrationVenueDraft = {
    ...state.integrationVenueDraft,
    ...payload,
  };
};

const CLEAR_SELECTED_INTEGRATION = (state) => {
  state.integrationVenueDraft = {};
};

const UPDATE_SETTINGS_STEP = (state, payload) => {
  state.settingsStep = {
    ...state.settingsStep,
    ...payload,
  };
};

const UPDATE_CUSTOMER_TAGS = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const CLEAR_SETTINGS_STEP = (state) => {
  state.settingsStep = {};
};

const NEW_CUSTOMER_GROUP_LINK_DATA = (state, payload) => {
  state.newCustomerGroupLinkData = {
    ...state.newCustomerGroupLinkData,
    ...payload,
  };
};
const CLEAR_NEW_CUSTOMER_GROUP_LINK_DATA = (state) => {
  state.newCustomerGroupLinkData = {
    route: 'distributor-outlets',
  };
};

export default {
  CLEAR_OFFLINE,
  UPDATE_OFFLINE,
  SET_SINGLE_DISTRIBUTOR_VENUE_ACCOUNT_OWNER,
  CLEAR_OFFLINE_SELECTED_PRODUCTS,
  UPDATE_OFFLINE_PRODUCT,
  SET_OFFLINE_PRODUCTS,
  REMOVE_OFFLINE_SELECTED_PRODUCT,
  CLEAR_OFFLINE_PRODUCTS,
  ADD_OFFLINE_SELECTED_PRODUCT,
  INSERT_ME,
  UPDATE_DRAFT,
  CLEAR_DRAFT,
  UPDATE_DRAFT_ADMIN,
  INSERT_DISTRIBUTOR_VENUE_SINGLE,
  CLEAR_DISTRIBUTOR_VENUE_SINGLE,
  UPDATE_ONBOARDING_CONTACTS,
  CLEAR_ONBOARDING_CONTACTS,
  ADD_ACCEPTED_TOS,
  UPDATE_PUBLIC_ONBOARDING,
  CLEAR_PUBLIC_ONBOARDING,
  SET_LOGIN_PUBLIC_TOKEN,
  CLEAR_LOGIN_PUBLIC_TOKEN,
  SET_OFFLINE_ORDER_DISTRIBUTOR,
  SET_OFFLINE_ORDER_DATE,
  SET_OFFLINE_ORDER_RECEIPTS,
  CLEAR_OFFLINE_ORDER_RECEIPTS,
  SET_OFFLINE_ORDER_NEW_SUPPLIER,
  SET_PENDING_UPDATE,
  CLEAR_OFFLINE_ORDER_NEW_SUPPLIER,
  UPDATE_OFFLINE_PRODUCTS_NEW_SUPPLIER,
  CLEAR_SELECTED_SUPPLIER,
  UPDATE_NEW_ADDED_PRODUCTS,
  SET_ONBOARDING_LINK_DATA,
  UPDATE_PRICE_CHANGED_PRODUCTS,
  CLEAR_SEND_ONBOARDING_LINK_DATA,
  SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA,
  CLEAR_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA,
  UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT,
  SET_OFFLINE_ORDER_DELIVERY_FEE,
  SET_OFFLINE_ORDER_AMOUNT_ADJUSTMENT,
  SET_OFFLINE_ORDER_TAX_ADJUSTMENT,
  CLEAR_OFFLINE_ORDER_DELIVERY_FEE,
  CLEAR_OFFLINE_ORDER_AMOUNT_ADJUSTMENT,
  CLEAR_OFFLINE_ORDER_TAX_ADJUSTMENT,
  SET_SELECTED_INTEGRATION,
  UPDATE_SELECTED_INTEGRATION,
  CLEAR_SELECTED_INTEGRATION,
  UPDATE_SETTINGS_STEP,
  CLEAR_SETTINGS_STEP,
  NEW_CUSTOMER_GROUP_LINK_DATA,
  CLEAR_NEW_CUSTOMER_GROUP_LINK_DATA,
  UPDATE_CUSTOMER_TAGS,
};
