import uuid from 'uuid/v4';
import Product from '@/models/Product';

const NEW_DRAFT = (state, id) => {
  let product = Product.query()
    .with('specialTierPricings')
    .with('specialTierPricings.connectedDistributors')
    .find(id);

  if (!product) {
    product = new Product({
      id: uuid(),
    });
  }

  state.draft = product;
};

const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const UPDATE_INVENTORY_MANAGEMENT_DRAFT = (state, payload) => {
  state.inventoryManagement = {
    ...state.inventoryManagement,
    ...payload,
  };
};

const UPDATE_STOCKTAKE_DRAFT = (state, payload) => {
  state.stocktake = {
    ...state.stocktake,
    ...payload,
  };
};

const UPDATE_VENUE_STOCKTAKE_DRAFT = (state, payload) => {
  state.venueStocktake = {
    ...state.venueStocktake,
    ...payload,
  };
};

const UPDATE_VENUE_STOCKTAKE_LOCATION_USERS = (state, payload) => {
  const index = state.venueStocktake.locationStocktakes.findIndex(
    item => item.location.id === payload.location.id,
  );
  if (index !== -1) {
    state.venueStocktake.locationStocktakes[index].users = payload.data.users;
  }
  state.venueStocktake.location = payload.location;
};

const CLEAR_DRAFT = state => {
  state.draft = new Product();
  state.draftSupplier = { id: -1 };
  state.inventoryManagement = {
    warehouse: {},
    batchCode: '',
    expiryDate: null,
    items: [],
    params: {},
  };
  state.stocktake = {
    warehouse: {},
    selectedProducts: [],
    users: [],
    note: '',
  };
  state.venueStocktake = {
    location: {},
    locationStocktakes: [],
    selectedProducts: [],
    users: [],
    note: '',
  };
};

const NEW_DEFAULT_TIER_ITEM = state => {
  state.draft.defaultTierPricing.tiers.push({
    id: uuid(),
    discountType: 'price',
    minQuantity: null,
    value: null,
  });
};

const REMOVE_DEFAULT_TIER_ITEM = (state, id) => {
  const {
    draft: { defaultTierPricing },
  } = state;
  state.draft.defaultTierPricing.tiers = defaultTierPricing.tiers.filter(t => t.id !== id);
};

const UPDATE_DEFAULT_TIER = (state, payload) => {
  const {
    draft: { defaultTierPricing },
  } = state;
  state.draft.defaultTierPricing = {
    ...defaultTierPricing,
    ...payload,
  };
};

const NEW_SPECIAL_TIER_DRAFT = (state, payload) => {
  const tierDefaults = {
    id: uuid(),
    name: '',
    discountType: 'price',
    tiers: [
      {
        id: uuid(),
        discountType: 'price',
        minQuantity: null,
        value: null,
      },
    ],
    venues: [],
    connectedVenues: [],
  };

  state.specialTierDraft = {
    ...tierDefaults,
    ...JSON.parse(JSON.stringify(payload || {})),
  };
};

const REMOVE_SPECIAL_TIER = (state, id) => {
  const {
    draft: { specialTierPricings },
  } = state;
  state.draft.specialTierPricings = specialTierPricings.filter(t => t.id !== id);
};

const NEW_SPECIAL_TIER_ITEM = state => {
  state.specialTierDraft.tiers.push({
    id: uuid(),
    discountType: 'price',
    minQuantity: null,
    value: null,
  });
};

const REMOVE_SPECIAL_TIER_ITEM = (state, id) => {
  state.specialTierDraft.tiers = state.specialTierDraft.tiers.filter(t => t.id !== id);
};

const UPDATE_SPECIAL_TIER_ITEM = (state, payload) => {
  const { specialTierDraft } = state;

  state.specialTierDraft = {
    ...specialTierDraft,
    ...payload,
  };
};

const TOGGLE_SPECIAL_TIER_VENUE = (state, payload) => {
  const { specialTierDraft } = state;

  const venueIndex = specialTierDraft.venues.findIndex(v => v === payload.id);

  if (venueIndex === -1) {
    state.specialTierDraft = {
      ...specialTierDraft,
      venues: [...specialTierDraft.venues, payload.id],
      connectedVenues: [...specialTierDraft.connectedVenues, payload],
    };

    return;
  }

  state.specialTierDraft = {
    ...specialTierDraft,
    venues: specialTierDraft.venues.filter(v => v !== payload.id),
    connectedVenues: specialTierDraft.connectedVenues.filter(v => v.id !== payload.id),
  };
};

const APPLY_SPECIAL_TIER_CHANGES = state => {
  const { specialTierDraft } = state;
  const existingDraftIndex = state.draft.specialTierPricings.findIndex(
    t => t.id === specialTierDraft.id,
  );

  if (existingDraftIndex !== -1) {
    state.draft.specialTierPricings.splice(existingDraftIndex, 1, specialTierDraft);
    return;
  }

  state.draft.specialTierPricings.push(specialTierDraft);
};

const UPDATE_META = (state, { meta }) => {
  state.meta = {
    ...state.meta,
    ...meta,
  };
};

const REMOVE_PRODUCT_IMAGE = (state, { id }) => {
  const product = Product.query().with('category').find(id);

  if (!product) {
    return null;
  }

  state.draft = {
    ...state.draft,
    isDefaultImage: true,
    image: product.category.image,
  };

  return product.$update({
    isDefaultImage: true,
    image: product.category.image,
  });
};

function NEW_DISTRIBUTOR_PRODUCTS_REMOVE(state, id) {
  const { [id]: deletedKey, ...others } = state.draftSupplierProducts;
  state.draftSupplierProducts = others;
}

function NEW_DISTRIBUTOR_PRODUCTS_UPDATE(state, product) {
  state.draftSupplierProducts = {
    ...state.draftSupplierProducts,
    [product.id]: product,
  };
}

export default {
  REMOVE_PRODUCT_IMAGE,
  UPDATE_META,

  /** Tier mutations */
  NEW_DRAFT,
  UPDATE_DRAFT,
  CLEAR_DRAFT,
  NEW_DEFAULT_TIER_ITEM,
  REMOVE_DEFAULT_TIER_ITEM,
  UPDATE_DEFAULT_TIER,

  /** Special tier mutations */
  NEW_SPECIAL_TIER_DRAFT,
  REMOVE_SPECIAL_TIER,
  NEW_SPECIAL_TIER_ITEM,
  REMOVE_SPECIAL_TIER_ITEM,
  UPDATE_SPECIAL_TIER_ITEM,
  TOGGLE_SPECIAL_TIER_VENUE,
  APPLY_SPECIAL_TIER_CHANGES,

  NEW_DISTRIBUTOR_PRODUCTS_REMOVE,
  NEW_DISTRIBUTOR_PRODUCTS_UPDATE,
  UPDATE_INVENTORY_MANAGEMENT_DRAFT,
  UPDATE_STOCKTAKE_DRAFT,

  UPDATE_VENUE_STOCKTAKE_DRAFT,
  UPDATE_VENUE_STOCKTAKE_LOCATION_USERS,
};
