<script>
export default {
  name: 'InfoPanelCustomData',
  props: {
    infoRowList: {
      required: false,
      type: Array,
      default: () => [],
    },
    toShowEmptyLabels: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    infoRowListToShow() {
      return this.toShowEmptyLabels
        ? this.infoRowList
        : this.infoRowList
          .map(infoRow => ({
            ...infoRow,
            infoList: infoRow.infoList.filter(info => !!info.info),
          }))
          .filter(infoRow => infoRow.infoList.length);
    },
  },
};
</script>

<template>
  <div class="info-panel-custom-data">
    <div class="mb-16" v-for="(infoRow, i) in infoRowListToShow" :key="i">
      <div class="u-flex-row">
        <div
          class="info info--vertical mt-0 width-100"
          v-for="(infoObj, j) in infoRow.infoList"
          :key="'' + i + j"
        >
          <small class="info--bold mb-4">{{ infoObj.title }}</small>
          <span>{{ infoObj.info }}</span>
        </div>
      </div>
      <hr class="mt-16 mb-0" v-if="infoRow.hasDivider" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-panel-custom-data {
  margin-bottom: -16px;
}
</style>
