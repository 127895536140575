<template>
  <div class="upload-csv-wrapper">
    <input
        type="file"
        name="csv"
        ref="file"
        id="uploadcsv"
        accept="text/csv"
        @change="csvHandler"
        class="upload-csv-wrapper__input">
    <label
        v-if="file === null"
        for="uploadcsv"
        class="upload-csv-wrapper__button">
      <font-awesome-icon icon="upload"/>Choose CSV File</label>
    <div class="csv-preview" v-if="file">
      <div class="csv-preview__icon">
        <img src="@/assets/xls.png" alt="CSV Icon">
      </div>
      <strong class="csv-preview__title">{{ file.name }}</strong>
      <label for="uploadcsv" class="csv-preview__change">Change File</label>
    </div>
    <ez-loader :show="loader">Upload CSV in progress</ez-loader>
  </div>
</template>

<script>
import EzLoader from '@/components/ui/Loader';

export default {
  components: {
    EzLoader,
  },
  props: {
    submitFunction: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      file: null,
      loader: false,
    };
  },
  methods: {
    reset() {
      this.file = null;
      this.loader = false;
      this.$refs.file.value = '';
    },
    csvHandler() {
      // eslint-disable-next-line
      this.file = this.$refs.file.files[0];
      this.$emit('onFileAdded');
    },
    submit() {
      this.$emit('onUpload');
      this.loader = true;
      const formData = new FormData();
      formData.append('csv', this.file);
      this.submitFunction(formData)
        .then(({ data }) => {
          this.loader = false;
          this.$refs.file.value = '';
          this.$emit('onSuccess', { filename: this.file.name, ...data.data });
        })
        .catch((e) => {
          this.$emit('onError', e);
          this.reset();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-csv-wrapper {
  @extend %flex-center;
  justify-content: center;
  margin: 2.4em 0;
  height: 56px;
  border-radius: .25em;
  background-color: #F5F7FC;
  &__input {
    display: none;
  }
  &__button {
    @extend %base-button;
    @include button-color(
            $button-secondary-bg,
            $button-secondary-color,
            $button-secondary-hover-bg,
            $button-secondary-bg
    );
    svg {
      margin-right: 6px;
    }
    display: inline-block;
  }
}
.csv-preview {
  @extend %flex-center;
  flex: 1 0 100%;
  max-width: 100%;
  padding: 1em;
  box-shadow: 0 2px 8px 0 rgba(34,40,52,0.15);
  border-radius: .25em;
  background-color: #fff;
  text-align: left;
  &__icon {
    flex-shrink: 0;
    width: 36px;
    margin-right: 1em;
    img { max-width: 100%; }
  }
  &__title {
    flex: 1 1 auto;
    margin-right: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__change {
    flex-shrink: 0;
    margin-left: auto;
    color: #8790A3;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
