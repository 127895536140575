const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

function AUTHORIZE(state) {
  state.isAuthorized = true;
}

const CLEAR_STATE = (state) => {
  state.draft = {};
  state.selectedProducts = {};
  state.bulkActionProducts = {};
  state.connections = {};
  state.products = {};
  state.venues = {};
  state.contacts = {};
  state.isAuthorized = false;
};

const UPDATE_SELECTED_PRODUCTS = (state, payload) => {
  state.selectedProducts = {
    ...state.selectedProducts,
    ...payload,
  };
};

const UPDATE_BULK_ACTION_PRODUCTS = (state, payload) => {
  state.bulkActionProducts = {
    ...state.bulkActionProducts,
    ...payload,
  };
};

const CLEAR_BULK_ACTION_PRODUCTS = (state) => {
  state.bulkActionProducts = {};
};

const TOGGLE_SELECTED_PRODUCTS = (state, payload) => {
  Object.keys(state.selectedProducts).forEach((key) => {
    state.selectedProducts[key] = payload;
  });
};

const UPDATE_VENUE_SYNC = (state, payload) => {
  state.connections = {
    ...state.connections,
    ...payload,
  };
};

const SET_PRODUCTS = (state, payload) => {
  state.products = {
    ...state.products,
    ...payload,
  };
};

const SET_VENUES = (state, payload) => {
  state.venues = {
    ...state.venues,
    ...payload,
  };
};

const SET_CONTACTS = (state, payload) => {
  state.contacts = {
    ...state.contacts,
    ...payload,
  };
};

export default {
  UPDATE_DRAFT,
  CLEAR_STATE,
  UPDATE_SELECTED_PRODUCTS,
  TOGGLE_SELECTED_PRODUCTS,
  UPDATE_BULK_ACTION_PRODUCTS,
  CLEAR_BULK_ACTION_PRODUCTS,
  UPDATE_VENUE_SYNC,
  SET_PRODUCTS,
  SET_VENUES,
  SET_CONTACTS,
  AUTHORIZE,
};
