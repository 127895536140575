import Order from '@/models/Order';
import { groupListByDateFormat } from '@/util/utils';
import { ORDER_TYPE_QUOTE } from '@/views/platform/distributor/orders/new/overview/utils';

const getPendingOrdersCount = state => state.meta.actionsCount || 0;

const getGroupedOrders = () => {
  const ordersList = Order.query()
    .with('venue')
    .with('distributor')
    .orderBy('expectedAt', 'desc')
    .orderBy('id', 'desc')
    .get();

  return groupListByDateFormat({
    list: ordersList,
    dateKey: 'expectedAt',
  });
};

const getFutureOrders = () => Order.query().with('distributor').orderBy('orderedAt', 'desc').get();

const getDraftOrder = state => ({
  venueId: state.draft.venue.id,
  products: state.draft.products.map(p => ({
    id: p.id,
    quantity: p.quantity,
    price: p.price,
    marketPrice: p.isBonus ? false : p.marketPrice,
    ...(p.isBonus ? {} : { discountType: p.discountType }),
    ...(p.isBonus ? {} : { discountValue: p.discountValue }),
    internalId: p.internalId || null,
    bonusId: p.bonusId || null,
  })),
  message: state.draft.note?.message || '',
  messageDisplay: {
    invoice: state.draft.note?.invoice || false,
    deliveryOrder: state.draft.note?.deliveryOrder || false,
  },
  deliveryFee: state.draft.deliveryFee,
  venueOrderNumber: state.outletReferenceNumber,
  distributorType: state.draft.distributorType,
  driverId: state.draft.driverId,
  [state.draft.distributorType === ORDER_TYPE_QUOTE ? 'quoteEndDate' : 'requestedDeliveryAt']:
    state.draft.deliveryDate,
  orderDiscountAmount: state.draft.orderDiscountAmount,
});

function getDraftVenue(state) {
  return state.draft.venue;
}

function hasDraftProducts(state) {
  return state.draft.products.length > 0;
}

// new order products
const areAllSelected = state => {
  const selected = state.newOrder.selectedProducts;
  const unselected = state.newOrder.products;

  return selected.length === unselected.length;
};

const selectedOrderOrderedProducts = state => state?.singleOrder?.orderedProducts;

export default {
  getDraftOrder,
  getPendingOrdersCount,
  getGroupedOrders,
  getFutureOrders,
  getDraftVenue,
  hasDraftProducts,
  areAllSelected,
  selectedOrderOrderedProducts,
};
