import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let draft = null;

try {
  draft = JSON.parse(localStorage.getItem('creditNoteDraft') || '');
} catch (e) {
  // continue
}

const state = {
  draft: draft || {
    selectedOutlet: null,
    nonStockCredit: null,
    selectedProducts: [],
    returnToWarehouse: false,
    warehouse: {},
    note: '',
    fromOrder: false,
    order: null,
  },
  filters: {},
  meta: {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
