const dictionary = {
  en: {
    messages: {
      email: () => 'Please Enter a valid email address',
      required: () => 'This field is required',
    },
  },
};

export default dictionary;
