/* eslint-disable no-console */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'formdata-polyfill';
import '@/scss/main.scss';
import './util/filters';
import '@/icons';

window.addEventListener('unhandledrejection', (event) => {
  event.preventDefault();
  /**
   * Ignore NavigationDuplicated from vue router.
   * Since a recent change every call to push or replace that is handed the same
   * path as is currently active is rejecting with a NavigationDuplicated error.
   *
   * To be fixed in the future.
   * @see {@link https://github.com/vuejs/rfcs/blob/master/active-rfcs/0033-router-navigation-failures.md}
   */
  if (event.reason && event?.reason?.name !== 'NavigationDuplicated') {
    console.log(`Reason: ${event.reason}`);
    console.log(JSON.stringify(event));
  }
});
