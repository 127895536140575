import httpService from '@/api/http';

const getDefaultImage = state => type => (state.defaultImages || {})[type] || '';
const getDefaultImages = state => state.defaultImages;

const LOAD_IMAGES = (state, data) => {
  state.defaultImages = data;
};

const fetchDefaultImages = ({ commit }) =>
  httpService.get('/utils/default-images').then(({ data: { data } }) => {
    commit('LOAD_IMAGES', data);
  });

const state = {
  defaultImages: {},
};

export default {
  namespaced: true,
  state,
  actions: {
    fetchDefaultImages,
  },
  getters: {
    getDefaultImage,
    getDefaultImages,
  },
  mutations: {
    LOAD_IMAGES,
  },
};
