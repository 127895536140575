export default [
  {
    path: '/',
    name: 'platform-home',
    meta: {
      requireAuth: true,
      title: 'Home',
    },
  },
  {
    path: '/accounts',
    name: 'manage-accounts',
    redirect: { name: 'manage-accounts-premium' },
    component: () =>
      import(
        /* webpackChunkName: "common" */ '@/views/platform/user-settings/accounts/EzManageAccounts.vue'
      ),
    meta: {
      title: 'Manage Accounts',
      requireAuth: true,
    },
    children: [
      {
        path: '/accounts/premium',
        name: 'manage-accounts-premium',
        component: () =>
          import(
            /* webpackChunkName: "common" */ '@/views/platform/user-settings/accounts/PremiumAccounts.vue'
          ),
        meta: {
          title: 'Manage Accounts',
        },
      },
      {
        path: '/accounts/freemium',
        name: 'manage-accounts-freemium',
        component: () =>
          import(
            /* webpackChunkName: "common" */ '@/views/platform/user-settings/accounts/FreemiumAccounts.vue'
          ),
        meta: {
          title: 'Manage Accounts',
        },
      },
    ],
  },
  {
    path: '/accounts/distributor/new',
    name: 'manage-accounts-new-distributor',
    component: () =>
      import(
        /* webpackChunkName: "common" */ '@/views/common/distributors/new-distributor/NewDistributor.vue'
      ),
    meta: {
      title: 'Manage Accounts',
      requireAuth: true,
    },
  },
  {
    path: '/accounts/buyer-group/new',
    name: 'manage-accounts-new-outlet',
    component: () =>
      import(/* webpackChunkName: "common" */ '@/views/common/venues/new-venue/NewVenue.vue'),
    meta: {
      title: 'Manage Accounts',
      requireAuth: true,
    },
  },
  {
    path: '/set-password/:token',
    name: 'manager-set-password',
    component: () =>
      import(/* webpackChunkName: "platform" */ '@/views/platform/auth/SetPassword.vue'),
    meta: {
      requireAuth: false,
      title: 'Set password',
    },
  },
  {
    path: '/login',
    name: 'platform-login',
    component: () => import(/* webpackChunkName: "platform" */ '@/views/platform/auth/Login.vue'),
    meta: {
      requireAuth: false,
      title: 'Login',
    },
  },
  {
    path: '/forgot-password',
    name: 'platform-forgot-password',
    component: () =>
      import(/* webpackChunkName: "platform" */ '@/views/platform/auth/ForgotPassword.vue'),
    meta: {
      requireAuth: false,
      title: 'Forgot password',
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "platform" */ '@/views/platform/footer/Index.vue'),
    meta: {
      title: 'Legal',
    },
    redirect: { name: 'terms-and-conditions' },
    props: {
      isPublic: true,
    },
    children: [
      {
        path: '/legal/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () =>
          import(
            /* webpackChunkName: "platform" */ '@/views/platform/footer/TermsAndConditions.vue'
          ),
        meta: {
          title: 'Terms & Conditions',
        },
        props: {
          isPublic: true,
        },
      },
      {
        path: '/legal/privacy-policy',
        name: 'privacy-policy',
        component: () =>
          import(/* webpackChunkName: "platform" */ '@/views/platform/footer/PrivacyPolicy.vue'),
        meta: {
          title: 'Privacy Policy',
        },
        props: {
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '/features-list',
    name: 'features-list',
    meta: {
      title: 'Features List',
    },
    props: {
      isPublic: true,
    },
    component: () =>
      import(/* webpackChunkName: "venue" */ '@/views/platform/venue/FeaturesList.vue'),
  },
];
