import i18n from '@/i18n';

const SET_LOGGED_USER = (state, loggedUser) => {
  state.loggedUser = {
    ...loggedUser,
  };
};

const SET_DELIVERY_DATE = (state, deliveryDate) => {
  if (state.loggedUser?.distributor)
    state.loggedUser.distributor.defaultDeliveryDate = deliveryDate.defaultDeliveryDate;
};

const SET_CONTEXT = (state, context) => {
  if (context.context === 'venue')
    state.context = context.venues.find(v => v.id === state.contextId);
  else if (context.context === 'venue-group') state.context = context.venues;
};

const SWITCH_CONTEXT = (state, venue) => {
  const { loggedUser } = state;

  if (!loggedUser.venues || !loggedUser.venues.length) {
    throw new Error(`User ${i18n.t('global.venues').toLowerCase()} not found`);
  }

  if (!loggedUser.venues.some(v => v.id === venue.id) && !loggedUser.group.id === venue.id) {
    throw new Error('Unable to switch context');
  }
  state.contextId = venue.id;
  state.context = {
    ...state.context,
    ...venue,
  };
};

const UPDATE_DISTRIBUTOR_ACTIONS_COUNT = (state, newCount) => {
  state.loggedUser.distributor.actionsCount = newCount;
};

function SET_XERO_CONNECTION(state, status) {
  state.loggedUser.isXeroConnected = status;
}

export default {
  SET_LOGGED_USER,
  SET_DELIVERY_DATE,
  SET_CONTEXT,
  SWITCH_CONTEXT,
  UPDATE_DISTRIBUTOR_ACTIONS_COUNT,
  SET_XERO_CONNECTION,
};
