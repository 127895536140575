import bus from './bus';

import FlashMessage from './EzFlashMessage.vue';

function hide() {
  bus.$emit('hide');
}

function showSuccess({ title, message, ctas, delay }) {
  bus.$emit('show', {
    title, message, ctas, type: 'success', delay,
  });
}

function showFail({ title, message, ctas, delay }) {
  bus.$emit('show', {
    title, message, ctas, type: 'fail', delay,
  });
}

function showError({ title, message, ctas, delay }) {
  bus.$emit('show', {
    title, message, ctas, type: 'error', delay,
  });
}

function show({ title, message, ctas, delay, type = 'success' }) {
  bus.$emit('show', {
    title, message, ctas, type, delay,
  });
}

export { FlashMessage };
export default {
  show,
  hide,
  success: showSuccess,
  fail: showFail,
  error: showError,
};
