const UPDATE_DRAFT = (state, payload) => {
  state.draft = {
    ...state.draft,
    ...payload,
  };
};

const CLEAR_DRAFT = (state) => {
  state.draft = {};
};

export default {
  UPDATE_DRAFT,
  CLEAR_DRAFT,
};
