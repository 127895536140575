function getDraftVenue(state) {
  return state.draft.selectedOutlet;
}

function getDraftNonStockCredit(state) {
  return state.draft.nonStockCredit;
}

function getDraftProducts(state) {
  return state.draft.selectedProducts;
}

function getIsFromOrder(state) {
  return state.draft.fromOrder;
}

function getCreditNoteOrderId(state) {
  return state.draft.order?.id;
}

function getRemainingCreditAmount(state) {
  return state.draft.order?.remainingCreditAmount;
}

function getNonStockCredit(state) {
  return state.draft.nonStockCredit;
}

function getSelectedProductsTotalAmount(state) {
  const selectedProducts = getDraftProducts(state) || [];
  return +selectedProducts.reduce(
    (total, curr) => total + curr.price * curr.quantity,
    0,
  ).toFixed(2);
}

function getTotalCreditNoteAmountInvalid(state) {
  const productsTotal = getSelectedProductsTotalAmount(state);
  const remainingCreditAmount = getRemainingCreditAmount(state);
  const nonStockCredit = getNonStockCredit(state);

  return productsTotal + nonStockCredit > remainingCreditAmount;
}

function getFulfilmentWarehouse(state) {
  return state.draft.order?.fulfillmentWarehouse;
}

export default {
  getDraftVenue,
  getDraftNonStockCredit,
  getDraftProducts,
  getIsFromOrder,
  getCreditNoteOrderId,
  getRemainingCreditAmount,
  getNonStockCredit,
  getSelectedProductsTotalAmount,
  getTotalCreditNoteAmountInvalid,
  getFulfilmentWarehouse,
};
