import Vue from 'vue';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDesktop,
  faTruck,
  faChevronLeft,
  faChevronRight,
  faHistory,
  faTag,
  faStoreAlt,
  faSignOutAlt,
  faUpload,
  faTrashAlt,
  faEnvelope,
  faSearch,
  faTimes,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faShoppingCart,
  faPlus,
  faMinus,
  faMinusCircle,
  faCheck,
  faSyncAlt,
  faEllipsisH,
  faFileCsv,
  faFilePdf,
  faArrowLeft,
  faPaperPlane,
  faPen,
  faInfoCircle,
  faMoneyBillWave,
  faBalanceScale,
  faFileInvoiceDollar,
  faTrash,
  faExclamationCircle,
  faCheckCircle,
  faCartPlus,
  faComment,
  faUndo,
  faCircle,
  faQuestion,
  faEye,
  faBell,
  faListAlt,
  faCalendarAlt,
  faLink,
  faExchangeAlt,
  faBold,
  faItalic,
  faUnderline,
  faListUl,
  faListOl,
  faQuoteRight,
  faCode,
  faGlobeAsia,
  faUser,
  faPhoneAlt,
  faCoffee,
  faCommentDollar,
  faDownload,
  faTasks,
  faBriefcase,
  faPlane,
  faStoreAltSlash,
  faUserFriends,
  faSlidersH,
  faClock as faClockSolid,
  faSms,
  faSync,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock,
  faQuestionCircle,
  faEyeSlash,
  faCalendarAlt as faCalendarAltReg,
} from '@fortawesome/free-regular-svg-icons';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  faDesktop,
  faUser,
  faTruck,
  faChevronLeft,
  faChevronRight,
  faHistory,
  faTag,
  faStoreAlt,
  faSignOutAlt,
  faUpload,
  faTrashAlt,
  faEnvelope,
  faSms,
  faWhatsapp,
  faSearch,
  faTimes,
  faCheck,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faShoppingCart,
  faPlus,
  faMinus,
  faQuestionCircle,
  faMinusCircle,
  faCheckCircle,
  faSyncAlt,
  faEllipsisH,
  faFileCsv,
  faFilePdf,
  faArrowLeft,
  faPaperPlane,
  faPen,
  faInfoCircle,
  faMoneyBillWave,
  faBalanceScale,
  faFileInvoiceDollar,
  faTrash,
  faExclamationCircle,
  faComment,
  faUndo,
  faCartPlus,
  faCircle,
  faQuestion,
  faEye,
  faEyeSlash,
  faBell,
  faListAlt,
  faCalendarAlt,
  faLink,
  faExchangeAlt,
  faBold,
  faItalic,
  faUnderline,
  faListUl,
  faListOl,
  faQuoteRight,
  faCode,
  faGlobeAsia,
  faClock,
  faClockSolid,
  faCalendarAltReg,
  faPhoneAlt,
  faCoffee,
  faCommentDollar,
  faDownload,
  faTasks,
  faBriefcase,
  faPlane,
  faStoreAltSlash,
  faUserFriends,
  faSlidersH,
  faSync,
  faClipboardCheck,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
