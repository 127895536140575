/* eslint-disable no-restricted-syntax */
const permMap = {
  distributor_edit_product_pricing: 'editProductPricing',
  distributor_edit_product_info: 'editProductInfo',
  distributor_user_administration: 'userAdministration',
  distributor_create_delete_product: 'createDeleteProduct',
  distributor_see_orders: 'seeOrders',
  distributor_edit_order: 'editOrder',
  distributor_edit: 'editSettings',
  distributor_manage_venue: 'manageVenue',
  distributor_see_products: 'seeProducts',
  distributor_hide_products: 'hideProducts',
  distributor_see_reports: 'seeReports',
  distributor_see_crm: 'seeCRM',
  distributor_filter_account_owners: 'filterAccountOwners',
  distributor_see_all_accounts: 'seeAllAccounts',
  distributor_assign_account_owner: 'assignAccountOwner',
  distributor_account_activity_export: 'accountActivityExport',
  distributor_edit_inventory_item: 'editInventoryItem',
  distributor_can_be_account_owner: 'isAccountOwner',
  distributor_expenses_export: 'exportExpenses',
  distributor_create_todos: 'createTodos',
  distributor_see_cogs: 'seeCOGS',
  distributor_see_crm_for_all: 'seeCRMForAll',
  distributor_approve_credit_notes: 'approveCreditNote',
  distributor_venue_products_csv: 'exportVenueProducts',
  distributor_products_csv: 'exportProducts',
  distributor_venues_csv: 'exportVenues',
  distributor_conduct_stocktake: 'conductStocktake',

  // Venue
  venue_create_order: 'createOrder',
  venue_approve_order: 'approveOrder',
  venue_see_orders: 'seeOrders',
  venue_see_future_orders: 'seeFutureOrders',
  venue_set_purchase_limit: 'setPurchaseLimit',
  venue_can_have_purchase_limit: 'canHavePurchaseLimit',
  venue_user_administration: 'userAdministration',
  venue_resolve_dispute: 'resolveDispute',
  venue_order_notifications: 'orderNotifications',
  venue_edit: 'editSettings',
  venue_manage_distributor: 'manageDistributor',
  venue_manage_inventory: 'manageInventory',
  venue_conduct_stocktake: 'conductStocktake',
  venue_manage_product_groups: 'manageProductGroups',
  venue_assign_product_groups: 'assignProductGroups',
  venue_see_products: 'seeProducts',
  venue_see_overview: 'seeOverview',
  venue_see_reports: 'seeReports',
  venue_see_suppliers: 'seeSuppliers',
  venue_pending_invoices: 'seePendingInvoices',
  venue_manage_settings: 'manageSettings',
  venue_manage_accounting: 'manageAccounting',
  venue_export_accounting: 'exportAccounting',
  venue_manage_billing: 'manageBilling',
  premium: 'premium',
  lite: 'lite',
  free: 'free',
};

const adminPermMap = {
  // Admin
  admin_manage_platform: 'adminManagePlatform',
  admin_invoices_merge: 'adminMergeInvoices',
  admin_invoices_review: 'adminReviewInvoices',
  admin_invoices_train: 'adminTrainInvoices',
  // Assign also all distributor and venue permissions
  ...permMap,
};

const permKeys = Object.values(permMap);
const adminPermKeys = Object.values(adminPermMap);

/**
 * Do checks based on the condition. If a check fail throw a plugin error.
 * @param condition
 * @param msg
 */
const check = (condition, msg) => {
  if (condition) throw new Error(`[vue-permission] ${msg}`);
};

/**
 * Map old value based on map provided. New object key will be a value from map[old.key].
 * @param old
 * @param map
 * @returns {*}
 */
const remap = (old, map) => {
  if (!old) return {};
  if (!(typeof old === 'object' || typeof map === 'object')) return old;
  const newObj = {};
  for (const [key, value] of Object.entries(old)) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      newObj[map[key]] = value;
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
};

const is = (obj, type) => {
  const cls = Object.prototype.toString.call(obj).slice(8, -1);
  return obj !== undefined && obj !== null && cls.toLowerCase() === type.toLowerCase();
};

function isVenue(scope) {
  return ['venue', 'venue-group'].includes(scope);
}

function isDistributor(scope) {
  return scope === 'distributor';
}

export { permMap, permKeys, adminPermMap, adminPermKeys, check, remap, is, isVenue, isDistributor };
