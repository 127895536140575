<template>
  <ez-modal ref="modal" class="ez-info-modal">
    <div class="modal__inner">
      <div class="ez-info-modal__title">
        <h3 :data-cy="dataCyAttr('TEXT__TITLE')">
          <slot name="title"></slot>
        </h3>
        <div class="ez-info-modal__buttons">
          <button
            v-if="editable"
            @click="redirectToSingleOutlet"
            class="ez-info-modal__edit-button"
            :data-cy="dataCyAttr('BUTTON__EDIT')"
          >
            <font-awesome-icon icon="pen"/>
          </button>
          <button
            class="ez-info-modal__close-button"
            @click="close"
            :data-cy="dataCyAttr('BUTTON__CLOSE')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <!-- eslint-disable-next-line max-len -->
              <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
            </svg>
          </button>
        </div>
      </div>
      <info-panel v-if="data" :data="data" :data-cy="dataCyAttr('INFO_PANEL')" />
      <info-panel-custom-data v-if="infoRowList" :info-row-list="infoRowList" />
    </div>
  </ez-modal>
</template>

<script>
import { EzModal } from '@/components/ui/Modal/index';
import InfoPanel from '@/components/ui/InfoPanel';
import InfoPanelCustomData from '@/components/ui/InfoPanelCustomData/InfoPanelCustomData';

export default {
  components: {
    InfoPanelCustomData,
    EzModal,
    InfoPanel,
  },
  props: {
    data: {
      required: false,
      type: Object,
      default: () => null,
    },
    infoRowList: {
      required: false,
      type: Array,
      default: () => null,
    },
    editable: {
      required: false,
      type: Boolean,
      default: false,
    },
    venue: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    open() { this.$refs.modal.open(); },
    close() { this.$refs.modal.close(); },
    redirectToSingleOutlet() {
      this.close();
      this.$router.push({ name: 'distributor-outlets-single', params: { id: this.venue.id } });
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
.ez-info-modal {
  padding-top: 3rem;
  :deep() .modal__inner {
    max-width: 30rem;
    padding: 1.25rem 1.5rem;
    border-radius: .1875rem;
  }
  &__title {
    @extend %flex-center;
    margin-bottom: 1.25rem;
    h3 {
      @include font-size(20px);
      font-weight: 500;
      margin: 0;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    small {
      @extend %gray-label;
      font-weight: 500;
      margin-bottom: .5rem;
    }
    span {
      @include font-size(14px);
    }
  }
  &__contact {
    display: flex;
    border-bottom: 1px dashed #DEE1E4;
    padding-bottom: 1rem;
    + .ez-info-modal__contact {
      margin-top: 1rem;
    }
  }
  &__address {
    margin-top: 1rem;
  }
  &__buttons {
    margin-left: auto;
    display: flex;
    justify-content: center;
  }
  &__edit-button {
    cursor: pointer;
    color: $color-gray-6C;
    margin-right: 16px;
    padding-bottom: 3px;
  }
  &__close-button {
    cursor: pointer;
    svg {
      fill: $color-gray-6C;
    }
  }
}
</style>
