import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const context = {};
let contextId = -1;
let loggedUser = {};

try {
  loggedUser = JSON.parse(localStorage.getItem('loggedUser') || '');
  contextId = JSON.parse(localStorage.getItem('contextId') || -1);
} catch (e) {
  // continue
}

const state = {
  context,
  loggedUser,
  contextId,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
