import i18n from '@/i18n';

const QuickBooksSetup = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/setup/Index.vue'
  );
const QuickBooksSettings = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/Index.vue'
  );
const QuickBooksAccountInfo = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/Account.vue'
  );
const QuickBooksNotConnected = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/NotConnected.vue'
  );
const QuickBooksSuppliers = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/Suppliers.vue'
  );
const QuickBooksTaxMapping = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/TaxMapping.vue'
  );
const QuickBooksProducts = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/Products.vue'
  );
const QuickBooksProductGroups = () =>
  import(
    /* webpackChunkName: "venue-quickbooks-settings" */ '@/views/platform/venue/quick-books/settings/ProductGroups.vue'
  );

export default [
  // Setup Route
  {
    name: 'venue-quick-books-setup',
    path: '/buyer/quick-books/setup',
    component: QuickBooksSetup,
  },
  // Settings Routesamsun
  {
    path: '/buyers/:id(\\d+)/settings/integrations/quick-books',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: QuickBooksSettings,
    children: [
      {
        path: 'account-info',
        name: 'venue-quick-books-account',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksAccountInfo,
        meta: {
          title: `${i18n.t('quickBooks.name')} Account Info`,
        },
      },
      {
        path: 'suppliers',
        name: 'venue-quick-books-distributors',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksSuppliers,
        meta: {
          title: `${i18n.t('quickBooks.name')} ${i18n.t('global.distributors')}`,
        },
      },
      {
        path: 'tax',
        name: 'venue-quick-books-tax',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksTaxMapping,
        meta: {
          title: `${i18n.t('quickBooks.name')} Tax`,
        },
      },
      {
        path: 'products',
        name: 'venue-quick-books-products',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksProducts,
        meta: {
          title: `${i18n.t('quickBooks.name')} Products`,
        },
      },
      {
        path: 'product-groups',
        name: 'venue-quick-books-product-groups',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksProductGroups,
        meta: {
          title: `${i18n.t('quickBooks.name')} ${i18n.t('global.productGroups')}`,
        },
      },
      {
        path: '/',
        name: 'venue-quick-books-not-connected',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: QuickBooksNotConnected,
        meta: {
          title: `${i18n.t('quickBooks.name')} Not Connected`,
        },
      },
    ],
  },
];
