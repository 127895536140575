<template>
  <button
    @click="onClick"
    :class="[
      'button',
      `button--${type}`,
      `button--${size}`,
      { 'button--block': isFullWidth },
      { 'button-align--center': isLoading },
      customClass,
    ]"
    :disabled="disabled || isLoading"
    :type="formType"
    :data-cy="dataCy"
  >
    <template v-if="isLoading">
      <ez-spinner class="position-absolute" />
    </template>
    <span :class="{ 'opacity-0': isLoading }">
      <!-- @slot Default slot used to show content -->
      <slot></slot>
    </span>
  </button>
</template>

<script>
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
/**
 * Custom button, that support different styles.
 * @since 1.0.0
 */
export default {
  components: { EzSpinner },
  props: {
    /**
     * Type of button, this will change the class on the button.
     * Allowed values: `primary`, `secondary`, `link`, `red`.
     */
    type: {
      required: false,
      type: String,
      default: 'primary',
      validator: value =>
        [
          'primary',
          'secondary',
          'secondary-dark',
          'link',
          'rounded',
          'red',
          'green',
          'red-link',
          'inline-link',
          'yellow-link',
          'blue-link',
          'purple-link',
          'green-link',
        ].indexOf(value) !== -1,
    },
    /**
     * Controls if the button should submit parent form
     */
    formType: {
      required: false,
      type: String,
      default: 'button',
      validator: value => ['button', 'submit'].indexOf(value) !== -1,
    },
    /**
     * Size of the button, this will change the class on the button.
     * Allowed values: `small`, `medium`,  `large`.
     */
    size: {
      required: false,
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].indexOf(value) !== -1,
    },
    /**
     * If the button should be type `block` and the 100% `width`.
     */
    isFullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the button is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Adds a custom class to the button.
     */
    customClass: {
      required: false,
      type: String,
      default: null,
    },
    /**
     * Propagate Event.
     * Useful inside a table when a table has a click event.
     */
    stopPropagation: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * When the button is loading it will:
     * 1) show a spinner
     * 2) be disabled
     */
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    onClick(e) {
      /**
       * When button is clicked.
       * @event click
       * @type Event
       */
      if (this.stopPropagation) {
        e.stopPropagation();
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.button {
  @extend %base-button;
  text-decoration: none;

  @include button-type(
    'primary',
    $button-primary-bg,
    $button-primary-color,
    $button-primary-hover-bg,
    $button-primary-color
  ) {
    &:hover {
      background-color: $button-primary-hover-bg;
    }
    &:active {
      background-color: $button-primary-bg;
    }
    &:focus {
      background-color: $button-primary-hover-bg;
    }
  }
  @include button-type(
    'secondary',
    $button-secondary-bg,
    $button-secondary-color,
    $button-secondary-hover-bg,
    $button-secondary-bg
  );
  @include button-type('secondary-dark', #eceef5, #6c7995, $button-secondary-hover-bg, #f5f6fa);
  @include button-type(
    'rounded',
    $color-pastel-blue,
    $color-primary-blue,
    $color-blue-hover,
    $color-blue-hover
  ) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;

    span {
      @extend %flex-center;
      justify-content: center;

      svg {
        margin: 0;
      }
    }

    &:active {
      background-color: $color-primary-blue;
      color: $color-white;
    }
  }
  @include button-type('link', $button-link-bg, $button-link-color, none, none) {
    &:hover {
      color: $color-gray-55;
    }
  }

  @include button-type('inline-link', $button-link-bg, $button-link-color, none, none) {
    height: auto;
    &:hover {
      color: $color-gray-55;
    }
  }
  @include button-type(
    'red',
    $button-red-bg,
    $button-red-color,
    $button-red-hover-bg,
    $button-red-bg
  ) {
    .spinner {
      border-color: $button-red-bg;
      border-right-color: transparent;
    }
  }

  @include button-type(
    'green',
    $color-primary-green,
    $color-white,
    $button-green-hover-bg,
    $color-primary-green
  );

  @include button-type('red-link', $button-link-bg, $button-red-bg, none, none) {
    &:hover {
      color: $button-red-hover-bg;
    }
    &:active {
      color: $button-red-bg;
    }
  }

  @include button-type('blue-link', $button-link-bg, $button-primary-bg, none, none) {
    font-weight: 500;
    &:hover {
      color: $button-primary-hover-bg;
    }
    &:active {
      color: $button-primary-bg;
    }
  }

  @include button-type('purple-link', #ffffff, $color-primary-purple, none, none) {
    font-weight: 500;
    &:hover {
      background-color: darken(#fff, 2);
    }
    &:active {
      background-color: darken(#fff, 2);
    }
  }

  @include button-type('yellow-link', #ffffff, $color-primary-orange, none, none) {
    font-weight: 500;
    &:hover {
      background-color: darken(#fff, 2);
    }
    &:active {
      background-color: darken(#fff, 2);
    }
  }

  @include button-type('green-link', #fff, $button-green-bg, none, none) {
    font-weight: 500;
    &:hover {
      color: $button-green-hover-bg;
    }
    &:active {
      color: $button-green-bg;
    }
  }

  &--small {
    height: 32px;
    line-height: 32px;
  }

  &--large {
    @include font-size(16px);
  }

  &:disabled {
    box-shadow: none;
    background-color: $button-disabled-bg;
    color: #fff;
    cursor: not-allowed;
  }

  &--red:disabled {
    background-color: $button-red-disabled-bg;
  }

  &--block {
    display: block;
    width: 100%;
    max-width: 100% !important;
  }

  svg {
    margin-right: px-to-rem(8px);
  }

  &--link {
    line-height: 1;

    svg {
      margin-right: 0;
    }
  }
}

.button-align--center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
