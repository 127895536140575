import { SCOPE } from '@/util/constants';

const isVenue = state => [SCOPE.VENUE, SCOPE.VENUE_GROUP].includes(state?.loggedUser?.scope);
const isDistributor = state => state?.loggedUser?.scope === 'distributor';

function getDistributor(state) {
  return state.loggedUser.distributor || {};
}

function getVenues(state) {
  return state.loggedUser.venues || [];
}

function isDistributorXeroConnected(state, getters) {
  return getters.getDistributor.isXeroConnected || false;
}

function isDistributorQuickBooksConnected(state, getters) {
  return getters.getDistributor.quickBooks || false;
}

function isDistributorXeroTokenValid(state, getters) {
  return getters.getDistributor.isXeroTokenValid || false;
}

function isDistributorTradeGeckoConnected(state, getters) {
  return getters.getDistributor.isTradeGecko || false;
}

function isDistributorProvinoConnected(state, getters) {
  return !!(getters.getDistributor?.integrations?.provino ?? false);
}

function isDistributorHubDocConnected(state, getters) {
  return !!getters.getDistributor.hubdocEmail;
}

function isDistributorNetSuiteConnected(state, getters) {
  return !!(getters.getDistributor?.integrations?.netsuite ?? false);
}

function isDistributorNetSuiteReAuthNeeded(state, getters) {
  return !!(getters.getDistributor?.integrations?.netsuite?.reAuthNeeded ?? false);
}

function isDistributorNetSuiteConnectionExpired(state, getters) {
  return !!(getters.getDistributor?.integrations?.netsuite?.isExpired ?? false);
}

function isVenueXeroConnected(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).isXeroConnected;
}

function isVenueQuickBooksConnected(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).quickBooks;
}

function isVenueHubDocConnected(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).hubdocEmail;
}

function isVenueXeroTokenValid(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).isXeroTokenValid;
}

function isVenueQuickBooksTokenValid(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).quickBooks.isTokenValid;
}

function isDistributorQuickBooksTokenValid(state, getters) {
  return getters.getDistributor.quickBooks?.isTokenValid;
}

function isVenueQuickBooksReAuthorizationNeeded(state, getters) {
  const venues = getters.getVenues;
  return venueId => !!(venues.find(ven => ven.id === +venueId) || {}).quickBooks?.reAuthNeeded;
}

function isDistributorQuickBooksReAuthorizationNeeded(state, getters) {
  return getters.getDistributor.quickBooks?.reAuthNeeded;
}

function getType(state, getters) {
  if (getters.isVenue) return state.context.accountType;
  if (getters.isDistributor) return getters.getDistributor.accountType;
  return 'admin';
}

function getPermissions(state) {
  return state.loggedUser.permissions;
}

function getScope(state) {
  return state.loggedUser.scope;
}

function getDistributorId(state, getters) {
  return getters.getDistributor.id;
}

function getTax(state, getters) {
  return getters.getDistributor.tax;
}

function pendingOrderNotification(state, getters) {
  return getters.getDistributor.actionsCount;
}

function toDosNotification(state, getters) {
  return getters.getDistributor.dueTodosCount;
}

function venueInvitationsCount(state, getters) {
  return getters.getDistributor.invitationsCount;
}

function priceProposalsCount(state, getters) {
  return getters.getDistributor.priceProposalsCount;
}

export default {
  getTax,
  getDistributorId,
  getPermissions,
  getScope,
  isVenue,
  isDistributor,
  pendingOrderNotification,
  venueInvitationsCount,
  priceProposalsCount,
  getType,
  getDistributor,
  isDistributorXeroConnected,
  isDistributorQuickBooksConnected,
  isDistributorXeroTokenValid,
  isDistributorTradeGeckoConnected,
  isVenueXeroConnected,
  isVenueQuickBooksConnected,
  isVenueXeroTokenValid,
  isVenueQuickBooksTokenValid,
  isDistributorQuickBooksTokenValid,
  isVenueQuickBooksReAuthorizationNeeded,
  isDistributorQuickBooksReAuthorizationNeeded,
  getVenues,
  toDosNotification,
  isDistributorProvinoConnected,
  isDistributorHubDocConnected,
  isDistributorNetSuiteConnected,
  isDistributorNetSuiteReAuthNeeded,
  isDistributorNetSuiteConnectionExpired,
  isVenueHubDocConnected,
};
