<script>
/**
   * Confimation modal
   * @version 1.0.0
   * @since
   */

import { EzModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { mapGetters } from 'vuex';
import bus from './bus';

export default {
  components: {
    EzButton,
    EzModal,
  },
  data() {
    return {
      title: null,
      message: null,
      icon: 'exclamation',
      innerClasses: '',
      type: 'blue',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      promiseResolve: null,
      loadingKey: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
    buttonType() {
      const buttonMap = {
        blue: 'primary',
        red: 'red',
      };
      return buttonMap[this.type];
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    reset() {
      this.title = null;
      this.message = null;
      this.icon = 'exclamation';
      this.innerClasses = '';
      this.type = 'blue';
      this.promiseResolve = null;
      this.buttons = {
        cancel: 'Cancel',
        confirm: 'Confirm',
      };
    },
    open() { this.$refs.modal.open(); },
    cancelClick() {
      this.promiseResolve({ canceled: true });
      this.close();
    },
    confirmClick() { this.promiseResolve({ close: this.close }); },
    show({
      title,
      message,
      icon,
      type,
      resolve,
      buttons,
      loadingKey,
    }) {
      this.type = type;
      this.title = title;
      this.message = message;
      this.icon = icon;
      this.promiseResolve = resolve;

      this.buttons = { ...this.buttons, ...buttons };
      this.loadingKey = loadingKey;

      this.open();
    },
  },
  mounted() {
    bus.$on('show', this.show);
    bus.$on('hide', this.close);
  },
};
</script>
<template>
  <EzModal ref="modal" @close="reset">
    <div :class="`modal__inner ${innerClasses}`">
      <button class="modal__close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <!-- eslint-disable-next-line max-len -->
          <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
        </svg>
      </button>
      <div class="modal__title">
        <div :class="`modal__icon modal__icon--${type}`">
          <font-awesome-layers v-if="icon === 'question'">
            <font-awesome-icon icon="circle" />
            <font-awesome-icon icon="question" transform="shrink-6" :style="{ color: 'white' }" />
          </font-awesome-layers>
          <font-awesome-icon v-else icon="exclamation-circle"/>
        </div>
        <h2 v-if="title">{{ title }}</h2>
      </div>
      <div class="modal__text" v-if="message" v-html="message"/>
      <div class="modal__footer">
          <EzButton type="link" @click="cancelClick" :disabled="isLoading">
            {{ buttons.cancel }}
          </EzButton>
          <EzButton :type="buttonType" @click="confirmClick" :is-loading="isLoading">
            {{ buttons.confirm }}
          </EzButton>
      </div>
    </div>
  </EzModal>
</template>
<style lang="scss" scoped>
  .modal {
    &__inner {
      position: relative;
      padding: px-to-rem(24px);
    }

    &__close {
      @extend %button-reset;
      position: absolute;
      top: 25px;
      right: px-to-rem(24px);
      @include font-size(18px);
      cursor: pointer;

      svg {
        fill: $secondary-color;
      }
    }

    &__icon,
    &__title {
      @extend %flex-center;
    }

    &__title {
      margin-bottom: 1.5em;

      h2 {
        margin: 0;
        @include font-size(20px);
        font-weight: 500;
      }
    }

    &__text {
      line-height: 20px;
      @include font-size(14px);
    }

    &__icon {
      margin-right: px-to-rem(12px);
      @include font-size(16px);
      color: $secondary-color;

      &--blue {
        color: $color-primary-blue;
      }

      &--red {
        color: $color-primary-dark-red;
      }
    }

    &__footer {
      @extend %flex-center;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }
</style>
<style lang="scss">
  .modal {
    .button-primary,
    .button-red {
      width: 100%;
      max-width: 13.3em;
    }

    .button-link {
      margin-right: 1em;
    }
  }
</style>
