export const DISCOUNT_TYPE = {
  percent: 'percentage',
  amount: 'amount',
};

export const ORDER_TYPE_SALES_ORDER = 'sales_order';
export const ORDER_TYPE_QUOTE = 'quote';
export const ORDER_TYPE_BACKORDER = 'backorder';
export const ORDER_TYPE_DRAFT = 'draft';
export const ORDER_TYPES = [
  { id: ORDER_TYPE_SALES_ORDER, name: 'Sales Order' },
  { id: ORDER_TYPE_QUOTE, name: 'Quote' },
  { id: ORDER_TYPE_BACKORDER, name: 'Backorder' },
  { id: ORDER_TYPE_DRAFT, name: 'Draft' },
];

export const getPrice = product => {
  const { defaultMarketPrice, price, defaultPrice } = product;
  return defaultMarketPrice ? price ?? 0 : defaultPrice;
};

export const getDiscountValue = product => {
  const { discountType, discountValue, defaultPrice, defaultMarketPrice } = product;

  if (defaultMarketPrice) return 0;
  if (discountType === DISCOUNT_TYPE.amount) return discountValue;
  return (defaultPrice * (discountValue ?? 0)) / 100;
};

export const getDiscountedPrice = product => getPrice(product) - getDiscountValue(product);
