import { Model } from '@vuex-orm/core';

export default class Category extends Model {
  static entity = 'categories';

  static fields() {
    return {
      id: this.attr(null),
      image: this.attr(''),
      isDefaultImage: this.attr(false),
      name: this.attr(''),
      productCount: this.attr(0),
      parentId: this.attr(null),
      parent: this.belongsTo(Category, 'parentId'),
      children: this.hasMany(Category, 'parentId'),
    };
  }
}
