import i18n from '@/i18n';

const HubDocVenueSettings = () =>
  import(/* webpackChunkName: "venue" */ '@/views/platform/venue/hub-doc/Index.vue');
const HubDocVenueAccountInfo = () =>
  import(/* webpackChunkName: "venue" */ '@/views/platform/venue/hub-doc/AccountInfo.vue');

export default [
  {
    path: '/buyers/:id(\\d+)/settings/integrations/email-forwarding',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: HubDocVenueSettings,
    children: [
      {
        path: 'account-info',
        name: 'venue-hub-doc-account',
        props: ({ params }) => ({ venueId: Number(params.id) }),
        component: HubDocVenueAccountInfo,
        meta: {
          title: `${i18n.t('hubDoc.name')} Account Info`,
        },
      },
    ],
  },
];
