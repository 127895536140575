import httpService from '@/api/http';
import Group from '@/models/Group';
import i18n from '@/i18n';
import { LOADING_KEY, isAdmin } from '@/util/constants';

const fetchGroups = () => httpService.get('/admin/venue-groups', {
  headers: {
    loadingKey: 'get-venue-groups',
  },
})
  .then(({ data }) => Group.create({ data: data.data }));

const fetchGroup = (context, id) => {
  if (isAdmin() && !id) {
    const errMsg = `${i18n.t('global.venue')} id is required!`;
    // eslint-disable-next-line no-console
    console.error(errMsg);
    throw new Error(errMsg);
  }

  const route = isAdmin() ? `/admin/venue-groups/${id}` : '/venue-group';

  return httpService.get(route)
    .then(({ data }) => Group.insertOrUpdate({ data: data.data }));
};

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#bc727565-25eb-4927-a3a5-862e8cb5dfe9}
 * @param context
 * @param {{
 *  nextId?: string | number;
 *  nextValue?: string;
 *  limit?: number;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueGroups(
  context,
  { query, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_GROUPS } = {},
) {
  return httpService.get('/admin/venue-groups', {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dec97f7e-bc98-4713-adc6-0ba611ff4ab0}
 * @param context
* @param {string} loadingKey
*
* @return {Promise<AxiosResponse<any>>}
*/
function fetchPendingInvoicesVenueGroups(
  context,
  { loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_GROUPS } = {},
) {
  return httpService.get('/admin/pending-invoices/venue-groups', {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b9fc37ab-f1de-4805-bd32-82daeca7307c}
 * @param context
 * @param {string | number} groupId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueGroup(
  context,
  { groupId, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_GROUP } = {},
) {
  return httpService.get(`/admin/venue-groups/${groupId}`, {
    headers: { loadingKey },
  });
}

const removeImage = (context, id) => {
  if (isAdmin() && !id) {
    const errMsg = `${i18n.t('global.venue')} id is required!`;
    // eslint-disable-next-line no-console
    console.error(errMsg);
    throw new Error(errMsg);
  }

  const route = isAdmin() ? `/admin/venue-groups/${id}/logo` : '/venue-group/logo';

  return httpService.delete(route)
    .then(() => Group.update({
      data: {
        logo: null,
        isDefaultImage: true,
      },
      where: id,
    }));
};

const removeGroup = (context, id) => httpService.delete(`/admin/venue-groups/${id}`);

const sendGroupInvitation = (context, id) => httpService.put(`admin/venue-groups/${id}/invite`)
  .then(({ data }) => Group.update({ data: data.data }));

export default {
  fetchGroups,
  fetchGroup,
  removeGroup,
  removeImage,
  sendGroupInvitation,

  // ORM Refactor
  fetchVenueGroups,
  fetchPendingInvoicesVenueGroups,
  fetchVenueGroup,
};
