import actions from './actions';
import mutations from './mutations';

const state = {
  selectedProducts: {},
  bulkActionProducts: {},
  connections: {},
  products: {},
  venues: {},
  contacts: {},
  isAuthorized: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
