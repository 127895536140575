/**
 * InfoRow class represents one row in InfoPanelCustomData component
 * @param  {[Array]} infoList is list of elements: {title, info}
 * @param  {[Boolean]} hasDivider by default is false
 */
export default class InfoRow {
  constructor({ infoList, hasDivider }) {
    this.infoList = infoList;
    this.hasDivider = Boolean(hasDivider);
  }
}
