/**
 * This is an edge case that was introduced with financial users.
 * Financial venue users don't have permission to open overview tab or products tab.
 * Therefore, when the user is redirected on login to `venue-home`
 * he will enter an infinite loop.
 *
 * Because `venue-home` is the overview page and because it can't
 * initialize it (`initVenue`) it can't pull the permissions.
 * For this reason the user is stuck in between states.
 *
 * This function is a "quick workaround". It will check if the login user
 * is a venue user an if he is missing the `venue_see_overview` or `venue_see_products`
 * permission and it will return a route name based on that.
 *
 * @return {boolean|boolean}
 */
export default function () {
  const role = localStorage.getItem('role');
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  if (!permissions) {
    localStorage.clear();
    return false;
  }

  if (role === 'venue' && permissions.venue_see_overview) return false;
  if (role === 'venue' && permissions.venue_see_products) return 'venue-orders';
  if (role === 'venue') return 'venue-orders-history';

  return false;
}
