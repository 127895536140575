import i18n from '@/i18n';
import dayjs from 'dayjs';
import AccountDrawerAction from '@/views/platform/distributor/crm/AccountDrawerAction.model';

export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_SENT = 'sent';
export const ORDER_STATUS_RECEIVED = 'received';
export const ORDER_STATUS_PARTIALLY_ACCEPTED = 'partially_accepted';
export const ORDER_STATUS_ACCEPTED = 'accepted';
export const ORDER_STATUS_DECLINED = 'declined';
export const ORDER_STATUS_SHIPPED = 'out_for_delivery';
export const ORDER_STATUS_NEEDS_APPROVAL = 'needs_approval';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_COMPLETED_AFTER_DISPUTE = 'completed_after_dispute';
export const ORDER_STATUS_IN_DISPUTE = 'in_dispute';
export const ORDER_STATUS_NOT_MATCHING = 'not_matching';
export const ORDER_STATUS_ABANDONED = 'abandoned';
export const ORDER_STATUS_QUOTE = 'quote';
export const ORDER_STATUS_BACKORDER = 'backorder';
export const ORDER_STATUS_VENUE_DRAFT = 'draft_venue';
export const ORDER_STATUS_DISTRIBUTOR_DRAFT = 'draft_distributor';

export const CREDIT_NOTE_STATUS_DRAFT = 'draft';
export const CREDIT_NOTE_STATUS_APPROVED = 'approved';
export const CREDIT_NOTE_HISTORY_LOG = 'credit_note';

export const OUTLET_GROUP_PRIORITY_STANDARD = 'standard';
export const OUTLET_GROUP_PRIORITY_VIP = 'vip';
export const OUTLET_GROUP_PRIORITY_TRIALIST = 'trialist';
export const OUTLET_GROUP_PRIORITY_PRIORITY = 'priority';
export const OUTLET_GROUP_PRIORITY_LOW = 'low';
export const OUTLET_GROUP_PRIORITY_HIDDEN = 'hidden';

export const HISTORY_LOG_ACTION_ADD = 'addition';
export const HISTORY_LOG_ACTION_REMOVE = 'removal';
export const HISTORY_LOG_ACTION_EDIT = 'edit';
export const HISTORY_LOG_ACTION_STOCKTAKE = 'stocktake';

export const NEW_HISTORY_LOG_TYPE_ADD = 'addition';
export const NEW_HISTORY_LOG_TYPE_REMOVE = 'removal';
export const NEW_HISTORY_LOG_TYPE_TRANSFER = 'transfer';
export const NEW_HISTORY_LOG_TYPE_STOCKTAKE = 'stocktake';
export const NEW_HISTORY_LOG_TYPE_ORDER_ACCEPT = 'order_acceptance';
export const NEW_HISTORY_LOG_TYPE_ORDER_CANCEL = 'order_cancellation';
export const NEW_HISTORY_LOG_TYPE_CREDIT_NOTE_APPROVED = 'credit_note_approved';

export const FILE_TYPE_PDF = 'pdf';
export const FILE_TYPE_XLSX = 'xlsx';

export const ACCOUNT_STATUS_ACTIVE = 'active';
export const ACCOUNT_STATE_NEW = 'new';
export const ACCOUNT_STATUS_TRIAL_ENDING = 'trial_ending';
export const ACCOUNT_STATUS_TRIAL_EXPIRED = 'trial_expired';
export const ACCOUNT_STATUS_DEACTIVATED = 'deactivated';
export const ACCOUNT_STATUS_DRAFT = 'draft';
export const ACCOUNT_STATUS_INVITE_SENT = 'invite_sent';
export const ACCOUNT_CONNECTION_INVITATION = 'connection_invitation';

export const PRODUCT_STATE_ADDED = 'added';

export const QUANTITY_STATUS_OUT_OF_STOCK = 'out_of_stock';
export const QUANTITY_STATUS_NOT_TRACKING = 'not_tracking';

export const PRODUCT_VISIBILITY_VISIBLE = 'visible';
export const PRODUCT_VISIBILITY_HIDDEN = 'hidden';

export const PRODUCT_TRACKED = 'tracked';
export const PRODUCT_NOT_TRACKED = 'not_tracked';
export const PRODUCT_TRACKING_ASSIGN = 'assign';

export const CHECK_IN_EXPENSE = 'expense';
export const CHECK_IN_TEAM_ACTIVITY = 'team_activity';

export const ACCOUNT_TYPE_PREMIUM = 'premium';
export const ACCOUNT_TYPE_FREE = 'free';
export const ACCOUNT_TYPE_LITE = 'lite';

export const WAREHOUSE_PRIMARY_DEFAULT = 'default';

export const VENUE_USER_ROLE_FINANCE = 'venue_finance';
export const VENUE_USER_ROLE_ORDERER = 'venue_orderer';

export const PENDING_UPDATES_STATUS_ACTIVE = 'active';
export const PENDING_UPDATES_STATUS_REQUEST_PENDING = 'connection_pending';
export const PENDING_INVITE_SENT = 'invitation_sent';

export const TAX_CALCULATION_EXCLUSIVE = 'exclusive';
export const TAX_CALCULATION_INCLUSIVE = 'inclusive';
export const TAX_CALCULATION_EXEMPT = 'exempt';

export const DEFAULT_FRACTION_DIGITS = 2;

// Provino
export const DEFAULT_DELIVERY_METHOD = 'delivery';
export const PROVINO_UNAVAILABLE_TYPE = {
  not_existing: 'not_existing',
  not_enough_stock: 'not_enough_stock',
};

export const pendingUpdatesStatusLabels = {
  [PENDING_UPDATES_STATUS_ACTIVE]: 'Active',
  [PENDING_UPDATES_STATUS_REQUEST_PENDING]: 'Connection Request Pending',
  [PENDING_INVITE_SENT]: 'Connection Requested',
};

export const taxCalucationStatuLables = {
  [TAX_CALCULATION_EXCLUSIVE]: 'Exclusive',
  [TAX_CALCULATION_INCLUSIVE]: 'Inclusive',
  [TAX_CALCULATION_EXEMPT]: 'Exempt',
};

export const TARGET_ACCOUNT = 'target_account';
export const PENDING_ACCOUNT_OPENING = 'pending_account_opening';
export const ACCOUNT_OPEN_YET_TO_BUY = 'account_open';
export const NOT_ORDERING_ACCOUNT = 'not_ordering_account';
export const ORDERING_ACCOUNT = 'ordering_account';
export const NO_SPENDINGS = 'no_spendings';

export const CAN_BE_ACC_OWNER = 'distributor_can_be_account_owner';

export const PRIMARY_CONTACT_LABEL = 'primary';
export const PRIMARY_CONTACT_LABEL_DEFAULT = 'primary_default';

export const CURRENCY_NOT_MATCHING = 'currency-no-matching';

export const ExpenseType = {
  TRAVEL: 'travel',
  BUSINESS_DEVELOPMENT: 'business_development',
  OTHER: 'other',
};

export const ActivityType = {
  CHECK_IN: 'check_in',
  MEETING: 'meeting',
  CALL: 'call',
  TODO: 'todo',
};

export const EDITED_ORDER_PRODUCTS = 'product_update';
export const ADDED_ORDER_PRODUCTS = 'added';
export const REMOVED_ORDER_PRODUCTS = 'removed';

export const DELIVERY_FEE_UPDATE = 'delivery_fee_update';
export const DELIVERY_DATE_UPDATE = 'requested_delivery_date_update';

export const ACTIVITY_ORDER = 'order';

export const ExpenseTypeLabels = {
  [ExpenseType.TRAVEL]: 'Travel',
  [ExpenseType.BUSINESS_DEVELOPMENT]: 'Business Development',
  [ExpenseType.OTHER]: 'Other',
};

export const ActivityTypeLabels = {
  [ActivityType.CHECK_IN]: 'Check-In',
  [ActivityType.MEETING]: 'Meeting',
  [ActivityType.CALL]: 'Call',
  [ActivityType.TODO]: 'To-Do',
};

export const accountStatusLabels = {
  [TARGET_ACCOUNT]: 'Target Account',
  [PENDING_ACCOUNT_OPENING]: 'Pending Account Opening',
  [ACCOUNT_OPEN_YET_TO_BUY]: 'Account Open, Yet to Buy',
  [NOT_ORDERING_ACCOUNT]: 'Not Ordering Account',
  [ORDERING_ACCOUNT]: 'Ordering Account',
  [NO_SPENDINGS]: 'No Spendings',
};

export const statusLabels = {
  [ORDER_STATUS_QUOTE]: 'Quote',
  [ORDER_STATUS_BACKORDER]: 'Backorder',
  [ORDER_STATUS_PENDING]: 'Pending',
  [ORDER_STATUS_PARTIALLY_ACCEPTED]: 'P. Accepted',
  [ORDER_STATUS_ACCEPTED]: 'Accepted',
  [ORDER_STATUS_DECLINED]: 'Declined',
  [ORDER_STATUS_SHIPPED]: 'Scheduled',
  [ORDER_STATUS_NEEDS_APPROVAL]: 'Needs Approval',
  [ORDER_STATUS_VENUE_DRAFT]: 'Draft',
  [ORDER_STATUS_DISTRIBUTOR_DRAFT]: 'Draft',
  [ORDER_STATUS_COMPLETED]: 'Completed',
  [ORDER_STATUS_COMPLETED_AFTER_DISPUTE]: 'Completed',
  [ORDER_STATUS_IN_DISPUTE]: 'Incomplete',
  [ORDER_STATUS_ABANDONED]: 'Cancelled',
};

export const creditNoteStatusLabels = {
  [CREDIT_NOTE_STATUS_DRAFT]: 'Draft',
  [CREDIT_NOTE_STATUS_APPROVED]: 'Approved',
};

export const outletGroupPriorityStatusLabels = {
  [OUTLET_GROUP_PRIORITY_STANDARD]: 'Standard',
  [OUTLET_GROUP_PRIORITY_VIP]: 'VIP',
  [OUTLET_GROUP_PRIORITY_TRIALIST]: 'Trialist',
  [OUTLET_GROUP_PRIORITY_PRIORITY]: 'Priority',
};

export const INVOICE_PROCESSING_STATUS_PROCESSING = 'processing';
export const INVOICE_PROCESSING_STATUS_ACTION_NEEDED = 'action_needed';
export const INVOICE_PROCESSING_STATUS_DRAFT = 'draft';
export const INVOICE_PROCESSING_STATUS_PROCESSED = 'processed';

export const INVOICE_EXPORT_STATUS_READY = 'ready';
export const INVOICE_EXPORT_STATUS_ERROR = 'error';
export const INVOICE_EXPORT_STATUS_COMPLETED = 'completed';
export const INVOICE_EXPORT_STATUS_MAPPING = 'product_mapping_needed';

export const ADMIN_INVOICE_PROCESSING_STATUS_CLEARING_READY = 'clearing_ready';
export const ADMIN_INVOICE_PROCESSING_STATUS_IN_CLEARING = 'in_clearing';
export const ADMIN_INVOICE_PROCESSING_STATUS_REVIEW_READY = 'review_ready';
export const ADMIN_INVOICE_PROCESSING_STATUS_IN_REVIEW = 'in_review';
export const ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED = 'action_needed';
export const ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED = 'processed';

export const invoiceProcessingStatusLabels = {
  [INVOICE_PROCESSING_STATUS_PROCESSING]: 'Processing',
  [INVOICE_PROCESSING_STATUS_PROCESSED]: 'Completed',
  [INVOICE_PROCESSING_STATUS_ACTION_NEEDED]: 'Action Needed',
  [INVOICE_PROCESSING_STATUS_DRAFT]: 'Draft',
};

export const invoiceExportStatusLabels = {
  [INVOICE_EXPORT_STATUS_READY]: 'Ready',
  [INVOICE_EXPORT_STATUS_ERROR]: 'Error',
  [INVOICE_EXPORT_STATUS_COMPLETED]: 'Completed',
  [INVOICE_EXPORT_STATUS_MAPPING]: 'Mapping Needed',
};

export const adminInvoiceProcessingStatusLabels = {
  [ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED]: 'Action Needed',
  [ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED]: 'Completed',
  [ADMIN_INVOICE_PROCESSING_STATUS_CLEARING_READY]: 'Ready',
  [ADMIN_INVOICE_PROCESSING_STATUS_IN_CLEARING]: 'In Process',
  [ADMIN_INVOICE_PROCESSING_STATUS_REVIEW_READY]: 'Review Ready',
  [ADMIN_INVOICE_PROCESSING_STATUS_IN_REVIEW]: 'In Review',
};

/**
 * Because the labels and filter for labels has a different naming, see completed after dispute.
 * This is why we need a separate array for filter only.
 */
export const statusFilterLabelsSupplier = {
  [ORDER_STATUS_DISTRIBUTOR_DRAFT]: 'Draft',
  [ORDER_STATUS_QUOTE]: 'Quote',
  [ORDER_STATUS_BACKORDER]: 'Backorder',
  [ORDER_STATUS_PENDING]: 'Pending',
  [ORDER_STATUS_PARTIALLY_ACCEPTED]: 'Partially Accepted',
  [ORDER_STATUS_ACCEPTED]: 'Accepted',
  [ORDER_STATUS_DECLINED]: 'Declined',
  [ORDER_STATUS_SHIPPED]: 'Scheduled for Delivery',
  [ORDER_STATUS_NEEDS_APPROVAL]: 'Needs Approval',
  [ORDER_STATUS_COMPLETED]: 'Completed',
  [ORDER_STATUS_COMPLETED_AFTER_DISPUTE]: 'Completed after Action',
  [ORDER_STATUS_IN_DISPUTE]: 'Incomplete',
  [ORDER_STATUS_ABANDONED]: 'Cancelled',
};

export const statusFilterLabelsOutlet = {
  [ORDER_STATUS_VENUE_DRAFT]: 'Draft',
  [ORDER_STATUS_NEEDS_APPROVAL]: 'Needs Approval',
  [ORDER_STATUS_BACKORDER]: 'Backorder',
  [ORDER_STATUS_PENDING]: 'Sent',
  [ORDER_STATUS_RECEIVED]: 'Received',
  [ORDER_STATUS_PARTIALLY_ACCEPTED]: 'Partially Accepted',
  [ORDER_STATUS_ACCEPTED]: 'Accepted',
  [ORDER_STATUS_DECLINED]: 'Declined',
  [ORDER_STATUS_SHIPPED]: 'Scheduled for Delivery',
  [ORDER_STATUS_COMPLETED]: 'Completed',
  [ORDER_STATUS_COMPLETED_AFTER_DISPUTE]: 'Completed after Action',
  [ORDER_STATUS_IN_DISPUTE]: 'Incomplete',
  [ORDER_STATUS_ABANDONED]: 'Cancelled',
};

export const ORDER_TYPE_REGULAR = 'regular';
export const ORDER_TYPE_STANDING = 'standing';
export const ORDER_TYPE_DRAFT = 'draft';
export const ORDER_TYPE_SCHEDULED = 'scheduled';

/**
 * From which order status we can move into completed.
 * @type {*[]}
 */
export const COMPLETABLE_STATUS = [
  ORDER_STATUS_PENDING,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_BACKORDER,
];

/**
 * Which status can partially select orders.
 * @type {*[]}
 */
export const PARTIALLY_ACCEPTABLE = [
  ORDER_STATUS_IN_DISPUTE,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_NEEDS_APPROVAL,
  ORDER_STATUS_BACKORDER,
];

export const orderTypes = [
  {
    id: ORDER_TYPE_SCHEDULED,
    name: 'Regular',
    text: 'Creates an order that will be placed on a certain date.',
  },
  {
    id: ORDER_TYPE_STANDING,
    name: 'Standing',
    text: 'Creates a recurring order that will be repeated in certain time intervals.',
  },
];

export const venueSubscription = [
  {
    id: 'none',
    name: 'None',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
  {
    id: 'unlimited',
    name: 'Unlimited',
  },
];

export const venueAccountType = [
  {
    id: 'free',
    name: i18n.t('global.accountTypes.free'),
  },
  {
    id: 'free_trial',
    name: i18n.t('global.accountTypes.freeTrial'),
  },
  {
    id: 'lite',
    name: i18n.t('global.accountTypes.lite'),
  },
  {
    id: 'premium',
    name: i18n.t('global.accountTypes.premium'),
  },
];

export const orderingType = [
  {
    id: 'purchase',
    name: 'Purchase Order',
  },
  {
    id: 'simple',
    name: 'Simple Order',
  },
];

export const venuePriority = [
  {
    id: 'vip',
    name: 'VIP',
  },
  {
    id: 'priority',
    name: 'Priority',
  },
  {
    id: 'standard',
    name: 'Standard',
  },
  {
    id: 'trialist',
    name: 'Trialist',
  },
];

export const distributorAccountType = [
  {
    id: 'free',
    name: i18n.t('global.accountTypes.free'),
  },
  {
    id: 'premium',
    name: i18n.t('global.accountTypes.premium'),
  },
];

export const AccountDrawerType = {
  Target: 'target',
  Pending: 'pending',
  Opened: 'opened',
  NotOrdering: 'not-ordering',
  Ordering: 'ordering',
};

export const TagType = {
  Product: 'product',
  Customer: 'venue',
};

/**
 * Returns `true` if User `role` is `admin`.
 * @return {boolean}
 */
export const isAdmin = () => localStorage.getItem('role') === 'admin';

/**
 * Returns `true` if User `role` is `distributor`.
 * @return {boolean}
 */
export const isDistributor = () => localStorage.getItem('role') === 'distributor';

/**
 * Returns `true` if User `role` is `venue`.
 * @return {boolean}
 */
export const isVenue = () => localStorage.getItem('role') === 'venue';

export const SUPPLIER_ROLE = {
  ADMIN: {
    name: 'Admin',
    key: 'distributor_admin',
  },
  MANAGER: {
    name: 'Manager',
    key: 'distributor_manager',
  },
  FULFILLMENT: {
    name: 'Fulfillment',
    key: 'distributor_fulfillment',
  },
  DELIVERY: {
    name: 'Delivery',
    key: 'distributor_delivery',
  },
  SUBSCRIBER: {
    name: 'Subscriber',
    key: 'distributor_subscriber',
  },
};

export const SCOPE = {
  ADMIN: 'admin',
  DISTRIBUTOR: 'distributor',
  VENUE: 'venue',
  VENUE_GROUP: 'venue-group',
};

export const FILTER_AMOUNT = {
  id: 'amount',
  name: 'By Spent Amount',
  tableColName: 'Total Amount',
};

export const FILTER_COUNT = {
  id: 'count',
  name: 'By Number of Items Sold',
  tableColName: 'No. of Units Sold',
};

export const UNASSIGNED_ACCOUNT = {
  id: null,
  name: 'Unassigned',
};

export const ALL_ACCOUNT_REPS = {
  id: null,
  name: 'All Account Reps',
};

export const ALL_TYPES = {
  id: null,
  name: 'All Types',
};

export const TAX_OPTIONS = [
  {
    id: 1,
    name: 'GST Included',
    value: true,
  },
  {
    id: 2,
    name: 'No Tax',
    value: false,
  },
];

export const TAX_CALCULATION_OPTIONS = [
  {
    id: 1,
    name: 'Tax Exclusive',
    value: 'exclusive',
  },
  {
    id: 2,
    name: 'Tax Inclusive',
    value: 'inclusive',
  },
  {
    id: 3,
    name: 'Tax Exempt',
    value: 'exempt',
  },
];

class RadioOption {
  constructor({ label = '', desc = '', value, data = null }) {
    this.label = label;
    this.desc = desc;
    this.value = value;
    this.data = data;
  }
}

export const BILL_INDIVIDUALLY = new RadioOption({
  label: 'Individually',
  desc: 'Generate a bill to pay for each individual expense.',
  value: 'false',
});

export const BILL_COMBINED = new RadioOption({
  label: 'Combined (Monthly)',
  desc: 'Generate one bill to pay for each account representative from all of their expenses from the previous month.',
  value: 'true',
});

export const BILL_TYPE_OPTIONS = [BILL_INDIVIDUALLY, BILL_COMBINED];

export const sendOnboardingAction = new AccountDrawerAction({
  text: 'Send Onboarding Link',
  type: 'primary',
  event: 'sendOnboardingLink',
});

export const createOutletAction = new AccountDrawerAction({
  text: `Create ${i18n.t('global.venue')}`,
  type: 'secondary',
  event: 'createOutlet',
});

export const sendConnectionReqAction = new AccountDrawerAction({
  text: 'Send Connection Request',
  type: 'primary',
  event: 'sendConnectionReq',
});

export const addActivityAction = new AccountDrawerAction({
  text: 'Add Activity',
  type: 'secondary',
  event: 'addActivity',
});

export const addExpenseAction = new AccountDrawerAction({
  text: 'Add Expense',
  type: 'secondary',
  event: 'addExpense',
});

export const completeToDoAction = new AccountDrawerAction({
  text: 'Complete To-Do',
  type: 'secondary',
  event: 'completeToDo',
});

export const resendInviteAction = new AccountDrawerAction({
  event: 'resendInvite',
  text: 'Resend Invite',
  type: 'primary',
});

export const MANAGE_INVENTORY_ACTION = {
  ADD: 'add',
  TAKE: 'take',
  TRANSFER: 'transfer',
};

export const API_WAREHOUSE_NAME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const isSafari = !!window.safari;

const today = new Date();
/**
 * Default filter value for timepicker on Orders page.
 * @type {{name: string, from: number, to: number}}
 */
export const ORDER_DATE_FILTER_DEFAULT = {
  name: 'All Time',
  from: dayjs('1970-01-02'),
  to: dayjs(today).endOf('d'),
};

/**
 * Default filter value for timepicker on Orders page.
 * @type {{name: string, from: number, to: number}}
 */
export const VENUE_REPORTS_DATE_FILTER_DEFAULT = {
  name: 'All Time',
  from: dayjs('1970-01-02'),
  to: dayjs(today).endOf('d'),
};

/**
 * Default filter value for timepicker on Orders page.
 * @type {{name: string, from: number, to: number}}
 */
export const DISTRIBUTOR_ORDER_SCHEDULED_DATE_FILTER_DEFAULT = {
  name: 'All Time',
  from: dayjs('1970-01-02').valueOf(),
  to: dayjs(today).endOf('d').add(10, 'year').valueOf(),
};

export const OUTLET_ORDER_SCHEDULED_DATE_FILTER_DEFAULT = {
  name: 'All Time',
  from: dayjs('1970-01-02').valueOf(),
  to: dayjs(today).endOf('d').add(10, 'year').valueOf(),
};

export const CREDIT_NOTE_DATE_FILTER_DEFAULT = {
  name: 'This Month',
  from: dayjs().startOf('M').valueOf(),
  to: dayjs().endOf('M').valueOf(),
};

export const STOCKTAKE_DATE_FILTER_DEFAULT = {
  name: 'This Month',
  from: dayjs().startOf('M').valueOf(),
  to: dayjs().endOf('M').valueOf(),
};

export const OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT = {
  name: 'All Time',
  from: dayjs('1970-01-02').valueOf(),
  to: dayjs(today).endOf('d').add(10, 'year').valueOf(),
};

export const INVENTORY_HISTORY_DATE_FILTER_DEFAULT = {
  from: dayjs().startOf('M').toISOString(),
  to: dayjs().endOf('M').toISOString(),
};

export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const TIME_INPUT_FORMAT = 'HH:mm';

export const TIME_PLACEHOLDER = TIME_INPUT_FORMAT;
export const DATE_PLACEHOLDER = DATE_INPUT_FORMAT;

export const userModalTab1 = 'userModalTab1';
export const userModalTab2 = 'userModalTab2';
export const userModalTab3 = 'userModalTab3';

export const toDosMap = [
  {
    key: 'pastDue',
    callback: item => item.isPastDue,
  },
  {
    key: 'dueToday',
    callback: item => item.isDue,
  },
  {
    key: 'upcoming',
    callback: item => !item.isDue && !item.isPastDue && item.dueDate,
  },
  {
    key: 'noDueDates',
    callback: item => !item.dueDate,
  },
];

export const DELIVERY_PERIOD_DAY = 'day';
export const DELIVERY_PERIOD_BUSINESS_DAY = 'business_day';
export const DELIVERY_PERIOD_BUSINESS_DAY_SATURDAY = 'business_day_saturday';
export const DELIVERY_PERIODS = [
  {
    id: DELIVERY_PERIOD_DAY,
    name: 'Days',
  },
  {
    id: DELIVERY_PERIOD_BUSINESS_DAY,
    name: 'Business Day(s)',
  },
  {
    id: DELIVERY_PERIOD_BUSINESS_DAY_SATURDAY,
    name: 'Business Day(s) (incl. Saturday)',
  },
];

export const DELIVERY_PERIODS_MAPPED = {
  [DELIVERY_PERIOD_DAY]: 'days',
  [DELIVERY_PERIOD_BUSINESS_DAY]: 'business days',
  [DELIVERY_PERIOD_BUSINESS_DAY_SATURDAY]: 'business days including Saturday',
};

export const CaseType = {
  CAMEL_CASE: 'camelCase',
  KEBAB_CASE: 'kebab-case',
  SNAKE_CASE: 'snake_case',
};

export const V_PRICE_TYPE = {
  DEFAULT: '',
  SECONDARY: 'secondary',
};

export const INTEGRATION_STATUSES = {
  connected: 'connected',
  notConnected: 'not_connected',
  notEnabled: 'not_enabled',
  actionNeeded: 'action_needed',
  updateNeeded: 'update_needed',
};

export const ECOMMERCE_ACTIONS = {
  mapProducts: 'mapProducts',
  pushProducts: 'pushProducts',
  mapWarehouses: 'mapWarehouses',
};

export const CONNECTED_STATUSES = [
  INTEGRATION_STATUSES.connected,
  INTEGRATION_STATUSES.updateNeeded,
];

export const INTEGRATIONS_TYPES = {
  eCommerce: 'e-commerce',
  default: 'default',
};

export const SUPPLIER_PRODUCTS_ACTIONS = {
  CREATE_PRODUCT: 'create-product',
  ADD_INVENTORY: 'add-inventory',
  TRANSFER_INVENTORY: 'transfer-inventory',
  REMOVE_INVENTORY: 'remove-inventory',
  IMPORT_PRODUCTS: 'import-products',
  EXPORT_PRODUCTS: 'export-products',
};

export const SELECT_DESELECT_ALL = {
  selectedAll: 'selected_all',
  deselectedAll: 'deselected_all',
};

export const DiscountType = {
  PERCENTAGE: 'percentage',
  PRICE: 'price',
};

export const UNIT_TYPE_SINGLE = 'single';
export const UNIT_TYPE_FRACTIONAL = 'fractional';
export const UNIT_TYPE_PACK = 'pack';

export const UNIT_TYPES = [UNIT_TYPE_SINGLE, UNIT_TYPE_FRACTIONAL, UNIT_TYPE_PACK];

export const ADDITIONAL_ITEMS = {
  DELIVERY_FEE: 'delivery-fee',
  CREDIT_NOTE: 'credit-note',
  DISCOUNT: 'discount',
  PRICE_ADJUSTMENT: 'price-adjustment',
  TAX_ADJUSTMENT: 'tax-adjustment',
};

export const CREDIT_NOTES_EDIT_ACTIONS_EVENTS = {
  saveCreditEdit: 'saveCreditEdit',
  discardCreditEdit: 'discardCreditEdit',
};

export const CREDIT_NOTES_ACTIONS = Object.keys(CREDIT_NOTES_EDIT_ACTIONS_EVENTS).map(cn => ({
  key: cn,
}));

export const CREATE_CUSTOMER_KEY = 'create_customer';
export const SEND_ONBOARDING_LINK_KEY = 'send_onboarding_link';
export const INTEGRATION_WARNINGS_CTAS_PATHS = {
  [CREATE_CUSTOMER_KEY]: 'distributor-outlets-new',
  [SEND_ONBOARDING_LINK_KEY]: 'distributor-outlets-send-onboarding-link',
};

export const NUMBER_OF_TAGS_TO_DISPLAY = 20;
// If total number of tags is between 20 and 25, display all
export const TAGS_DISPLAY_TRESHOLD = 5;

export const LOADING_KEY = {
  JOKER: 'joker', // It is used to cover multiple actions that can't be united in Promise.all
  FETCH_PRODUCTS: 'show-products',
  ME: 'me',
  GET_USERS: 'get-users',
  GET_DISTRIBUTOR_ROLES: 'get-distributor-roles',
  GET_ADMIN_DISTRIBUTOR_ROLES: 'get-admin-distributor-roles',
  REMOVE_USER: 'remove-user',
  DISCONNECT_VENUE: 'disconnect-venue',
  CONNECT_DISTRIBUTOR_TO_VENUE: 'connect-distributor-to-venue',
  DELETE_DISTRIBUTOR_USER: 'delete-distributor-user',
  ADMIN_FETCH_CURRENCIES: 'admin-fetch-currencies',
  FETCH_CURRENCIES: 'fetch-currencies',
  FETCH_TO_DO_SINGLE: 'fetch-to-do-single',
  FETCH_TO_DO_ACCOUNT_REPS_OPTIONS: 'fetch-to-do-account-reps-options',
  FETCH_TO_DO_VENUES: 'fetch-to-do-venues',
  FETCH_TO_DO_LIST: 'fetch-to-do-list',
  DELETE_TO_DO: 'delete-to-do',
  CREATE_TO_DO: 'create-to-do',
  FETCH_MORE_ORDERS: 'fetch-more-orders',
  FETCH_ACTIVE_ACCOUNT_OWNERS: 'fetch-active-account-owners',
  FETCH_ACCOUNT_ACTIVITY_TOTAL_AMOUNT: 'fetch-account-activity-total-amount',
  CREATE_TARGET_ACCOUNT: 'create-target-account',
  FETCH_TARGET_ACCOUNT_LIST: 'fetch-target-account-list',
  FETCH_PENDING_ACCOUNT_LIST: 'fetch-pending-account-list',
  FETCH_OPENED_ACCOUNT_LIST: 'fetch-opened-account-list',
  FETCH_NOT_ORDERING_ACCOUNT_LIST: 'fetch-not-ordering-account-list',
  FETCH_ORDERING_ACCOUNT_LIST: 'fetch-ordering-account-list',
  FETCH_VENUE_USERS: 'fetch-venue-users',
  FETCH_VENUE_REPORT: 'fetch-venue-report',
  FETCH_PRODUCT_REPORT: 'fetch-product-report',
  FETCH_REPORT_PRODUCTS_SEARCH: 'fetch-report-products-search',
  FETCH_EXPENSE_TYPES_BY_CATEGORY: 'fetch-expense-types-by-category',
  FETCH_EXPENSE_USERS: 'fetch-expense-users',
  FETCH_EXPENSE_LIST: 'fetch-expense-list',
  DELETE_EXPENSE: 'delete-expense',
  FETCH_TIME_ZONES: 'fetch-time-zones',
  FETCH_CONNECTED_VENUES: 'fetch-connected-venues',
  FETCH_SINGLE_VENUE_REPORT: 'fetch-single-venue-report',
  FETCH_MOST_ORDERED_ITEMS: 'fetch-most-ordered-items',
  FETCH_SINGLE_PRODUCT_REPORT: 'fetch-single-product-report',
  FETCH_MOST_ORDERED_BY: 'fetch-most-ordered-by',
  FETCH_EXPENSE_SINGLE: 'fetch-expense-single',
  FETCH_PENDING_ACCOUNT_SINGLE: 'fetch-pending-account-single',
  FETCH_MONEY_SPENT_BY_ACCOUNT_REP: 'fetch-money-spent-by-account-rep',
  FETCH_VENUE: 'fetch-venue',
  FETCH_VENUE_LOCATIONS: 'fetch-venue-locations',
  ADD_VENUE_LOCATIONS: 'add-venue-locations',
  SWITCH_CONTEXT: 'switch-context',
  FETCH_ACCOUNTS: 'fetch-accounts',
  FETCH_MORE_ACCOUNTS: 'fetch-more-accounts',
  UPDATE_DEFAULT_ACCOUNT: 'update-default-account',
  LEAVE_ACCOUNT: 'leave-account',
  UPDATE_CONNECTION_OPTIONS: 'update-connection-options',
  FETCH_MOST_ORDERED_ITEMS_PER_VENUE: 'fetch-most-ordered-items-per-venue',
  FETCH_ORDER_HISTORY: 'fetch-order-history',
  FETCH_ORDER_HISTORY_TOTAL: 'fetch-order-history-total',
  DELETE_PENDING_ACCOUNT_SINGLE: 'delete-pending-account-single',
  FETCH_EXPENSE_TYPES: 'fetch-expense-types',
  FETCH_ACTIVITY_TYPES: 'fetch-activity-types',
  DISTRIBUTOR_FETCH_VENUE_FILTER_OPTIONS: 'distributor-fetch-venue-filter-options',
  DISTRIBUTOR_FETCH_VENUE: 'distributor-fetch-venue',
  FETCH_DISTRIBUTOR_VENUE: 'fetch-distributor-venue',
  DISTRIBUTOR_FETCH_TERMS_AND_CONDITIONS: 'distributor-fetch-terms-and-conditions',
  DISTRIBUTOR_UPDATE_TERMS_AND_CONDITIONS: 'distributor-update-terms-and-conditions',
  DISTRIBUTOR_FETCH_CONNECTION_REQUESTS: 'distributor-fetch-connection-requests',
  DISTRIBUTOR_ONBOARDING_PREMIUM_OUTLET: 'distributor-onboarding-premium-outlet',
  DISTRIBUTOR_APPROVE_PROPOSED_PRICE: 'distributor-approve-proposed-price',
  DISTRIBUTOR_DECLINE_PROPOSED_PRICE: 'distributor-decline-proposed-price',
  DISTRIBUTOR_ADD_PRODUCT_WIZARD_FORM: 'distributor-add-product-wizard-form',
  DISTRIBUTOR_GET_PRODUCTS: 'distributor-get-products',
  DISTRIBUTOR_GET_MORE_PRODUCTS: 'distributor-get-more-products',
  DISTRIBUTOR_UPDATE_XERO_PRODUCTS: 'distributor-update-xero-products',
  DISTRIBUTOR_GET_PRODUCT_GROUPS: 'distributor-get-product-groups',
  DISTRIBUTOR_GET_MORE_PRODUCT_GROUPS: 'distributor-get-more-product-groups',
  DISTRIBUTOR_UPDATE_XERO_PRODUCT_GROUPS: 'distributor-update-xero-product-groups',
  DISTRIBUTOR_GET_CUSTOMER_CATEGORIES: 'distributor-get-customer-categories',
  DISTRIBUTOR_GET_MORE_CUSTOMER_CATEGORIES: 'distributor-get-more-customer-categories',
  DISTRIBUTOR_UPDATE_XERO_CUSTOMER_CATEGORIES: 'distributor-update-xero-customer-categories',
  DISTRIBUTOR_FETCH_XERO_ACCOUNT_TYPES: 'distributor-fetch-xero-account-types',
  DISTRIBUTOR_GET_SYNCED_ITEMS: 'distributor-get-synced-items',
  INIT_DISTRIBUTOR: 'init-distributor',
  REMOVE_DISTRIBUTOR_PRODUCT: 'remove-distributor-product',
  DISTRIBUTOR_FETCH_SINGLE_PROPOSED_PRICING: 'distributor-fetch-single-proposed-pricing',
  DISTRIBUTOR_UNHIDE_ALL_VENUES_FOR_PRODUCT: 'distributor-unhide-all-venues-for-product',
  DISTRIBUTOR_HIDE_ALL_VENUES_FOR_PRODUCT: 'distributor-hide-all-venues-for-product',
  DISTRIBUTOR_UNHIDE_VENUE_FOR_PRODUCT: 'distributor-unhide-venue-for-product',
  DISTRIBUTOR_HIDE_VENUE_FOR_PRODUCT: 'distributor-hide-venue-for-product',
  DELETE_DISTRIBUTOR_SPECIAL_TIER_PRICING: 'delete-distributor-special-tier-pricing',
  DELETE_DISTRIBUTOR_DEFAULT_TIER_PRICING: 'delete-distributor-default-tier-pricing',
  DISTRIBUTOR_UPDATE_PRODUCT: 'distributor-update-product',
  MANAGE_INVENTORY: 'manage-inventory',
  FETCH_SUPPLIERS: 'show-suppliers',
  FETCH_SUPPLIERS_LOAD_MORE: 'show-suppliers-load-more',
  FETCH_ORDERS: 'show-orders',
  ADD_TO_CART: 'add-to-cart',
  BUTTON_ADD_TO_CART: 'button-add-to-cart',
  FETCH_PRODUCTS_FOR_VENUE: 'fetch-products-for-venue',
  FETCH_MORE_PRODUCTS_FOR_VENUE: 'fetch-more-products-for-venue',
  FETCH_ORDER: 'show-order',
  EXPORT_ORDER: 'export-order',
  APPROVE_ORDER: 'approve-order',
  RECEIVE_ORDER: 'receive-order',
  CREATE_FROM_DRAFT: 'create-from-draft',
  DECLINE_ORDER: 'decline-order',
  OPEN_DISPUTE: 'open-dispute',
  RESOLVE_DISPUTE: 'resolve-dispute',
  CANCEL_ORDER: 'cancel-order',
  ACCEPT_ORDER: 'accept-order',
  UPDATE_INTERVAL: 'update-interval',
  ABANDON_ORDER: 'abandon-order',
  VENUE_CONNECTION_REQUESTS: 'show-invitations',
  VENUE_PENDING_UPDATES: 'get-pending-updates',
  COMPLETE_ORDER: 'complete-order',
  XERO_CREATE_BILL: 'xero-create-bill',
  VENUE_FETCH_SUPPLIER_SINGLE: 'venue-fetch-supplier-single',
  VENUE_UPDATE_SUPPLIER_AVAILABILITY: 'venue-update-supplier-availability',
  VENUE_UPDATE_PRODUCT_AVAILABILITY: 'venue-update-product-availability',
  VENUE_HIDE_UNHIDE_PRODUCTS: 'hideUnhide-distributor-products',
  VENUE_APPROVE_OR_DECLINE_REQUEST: 'venue-approve-or-decline-request',
  DELETE_VENUE_FROM_VENUE_GROUP: 'delete-venue-from-venue-group',
  ADD_OR_UPDATE_PRODUCT: 'product-form',
  PROPOSE_PRICE: 'price-proposal',
  VENUE_CREATE_SUPPLIER: 'distributor-form',
  VENUE_EDIT_SUPPLIER: 'single-distributor-form',
  VENUE_DISCONNECT_SUPPLIER: 'venue-disconnect-distributor',
  VENUE_SUPPLIER_PRODUCTS: 'show-supplier-products',
  VENUE_SUPPLIER_PRODUCTS_MORE: 'show-more-supplier-products',
  VENUE_EXPORT_PRODUCTS: 'export-single-distributor-products',
  VENUE_GROUP_EDIT: 'venue-group-form',
  VENUE_GROUP_ADD_VENUE: 'venue-group-add-outlet',
  VENUE_GROUP_EDIT_VENUE: 'venue-group-edit-outlet',
  VENUE_CHANGE_PASSWORD: 'venue-change-password',
  VENUE_CHANGE_EMAIL: 'venue-change-email',
  VENUE_USER_SAVE_SETTINGS: 'venue-change-user-settings',
  VENUE_APPROVE_OR_DECLINE_CONNECTION: 'venue-approve-decline',
  VENUE_FETCH_DISTRIBUTORS_FILTER: 'venue-fetch-distributor-filter',
  VENUE_FETCH_CATEGORIES_FILTER: 'venue-fetch-categories-filter',
  FETCH_PRODUCT: 'fetch-product',
  DISTRIBUTOR_DOWNLOAD_PRODUCTS_CSV: 'distributor-download-products-csv',
  DISTRIBUTOR_FETCH_PROPOSED_PRICING: 'distributor-fetch-proposed-pricing',
  DISTRIBUTOR_UPDATE_PRODUCT_STOCK: 'distributor-update-product-stock',
  DISTRIBUTOR_FETCH_SINGLE_PRODUCT: 'distributor-fetch-single-product',
  DISTRIBUTOR_FETCH_PRODUCT_HISTORY_LOG: 'distributor-fetch-product-history-log',
  DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_HISTORY: 'distributor-fetch-product-inventory-history',
  DISTRIBUTOR_FETCH_MORE_INVENTORY_HISTORY: 'distributor-fetch-more-inventory-history',
  DISTRIBUTOR_FETCH_INVENTORY_HISTORY_WAREHOUSES: 'distributor-fetch-inventory-history-warehouses',
  DISTRIBUTOR_FETCH_INVENTORY_HISTORY_TYPES: 'distributor-fetch-inventory-history-types',
  DISTRIBUTOR_FETCH_ALL_VENUES: 'distributor-fetch-all-venues',
  DISTRIBUTOR_FETCH_ALL_VENUES_LOAD_MORE: 'distributor-fetch-all-venues-load-more',
  LOAD_MORE_PRODUCTS: 'load-more-products',
  DISTRIBUTOR_FETCH_AUTH_URL: 'distributor-fetch-auth-url',
  FETCH_DISTRIBUTOR_PRODUCTS: 'fetch-distributor-products',
  FETCH_DISTRIBUTOR_PRODUCT: 'fetch-distributor-product',
  FETCH_DISTRIBUTOR: 'fetch-distributor',
  FETCH_REPORT_VENUES_SEARCH: 'fetch-report-venues-search',
  DISTRIBUTOR_NEW_ORDER_FETCH_PRODUCT_PRICE: 'distributor-new-order-fetch-product-price',
  ADMIN_FETCH_UNITS: 'admin-fetch-units',
  EZ_AUTOCOMPLETE: 'ez-autocomplete',
  VENUE_ADD_SUPPLIER: 'venue-add-supplier',
  VENUE_SUPPLIER_ADD_USER: 'add-venue-supplier-user',
  SUPPLIER_SEND_INVITE: 'supplier-send-invite',
  EDIT_PRODUCT_DETAILS_FROM_ORDER: 'edit-product-details-from-order',
  DISTRIBUTOR_FETCH_PRODUCTS_FOR_VENUE: 'distributor-fetch-products-for-venue',
  DISTRIBUTOR_EXPORT_PRODUCTS_FOR_VENUE: 'distributor-export-products-for-venue',
  DISTRIBUTOR_VENUE_PRODUCTS_MORE: 'show-more-products-for-venue',
  DISTRIBUTOR_HIDE_UNHIDE_PRODUCTS_FOR_VENUE: 'hideUnhide-distributor-products-for-venue',
  LOG_OFFLINE_ORDER: 'log-offline-order-confirm',
  DISTRIBUTOR_ADD_INVOICE_TO_ORDER: 'distributor-add-invoice-to-order',
  DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS: 'fetch-venue-visible-products',
  DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE: 'fetch-venue-visible-products-load-more',
  DISTRIBUTOR_SEND_ONBOARDING_LINK: 'distributor-send-onboarding-link',
  DISTRIBUTOR_BULK_UPDATE_PRODUCTS_PRICES_FOR_VENUE: 'bulk-update-products-prices-for-venue',
  DISTRIBUTOR_ADD_PRODUCTS_TO_SINGLE_ORDER: 'distributor-add-products-to-single-order',
  DISTRIBUTOR_DELETE_PRODUCTS_FROM_SINGLE_ORDER: 'distributor-delete-products-from-single-order',
  VENUE_ADD_PRODUCTS_TO_SINGLE_ORDER: 'venue-add-products-to-single-order',
  VENUE_DELETE_PRODUCTS_FROM_SINGLE_ORDER: 'venue-delete-products-from-single-order',
  DISTRIBUTOR_EDIT_PUBLIC_SINGLE_ORDER: 'distributor-edit-public-single-order',
  VENUE_EDIT_PUBLIC_SINGLE_ORDER: 'venue-edit-public-single-order',
  FETCH_DISTRIBUTORS_FOR_VENUE: 'fetch-distributors-for-venue',
  DOWNLOAD_TOS: 'download-tos',
  FETCH_PROVINO_DATA: 'fetch-provino-data',
  DISCONNECT_FROM_PROVINO: 'disconnect-from-provino',
  SUPPLIER_UPDATE_XERO_OUTLETS: 'supplier-update-xero-outlets',
  VENUE_UPDATE_XERO_SUPPLIERS: 'venue-update-xero-suppliers',
  VENUE_UPDATE_XERO_PRODUCT_GROUPS: 'venue-update-xero-product-groups',
  VENUE_GET_PRODUCT_GROUPS: 'venue-get-product-groups',
  VENUE_GET_MORE_PRODUCT_GROUPS: 'venue-get-more-product-groups',
  VENUE_GET_XERO_PRODUCTS: 'venue-get-xero-products',
  VENUE_UPDATE_XERO_PRODUCTS: 'venue-update-xero-products',
  VENUE_GET_MORE_PRODUCTS: 'venue-get-more-products',
  VENUE_GET_QUICK_BOOKS_SUPPLIERS: 'venue-get-quick-books-distributors',
  VENUE_UPDATE_QUICK_BOOKS_SUPPLIERS: 'venue-update-quick-books-distributors',
  VENUE_GET_QUICK_BOOKS_PRODUCTS: 'venue-get-quick-books-products',
  VENUE_UPDATE_QUICK_BOOKS_PRODUCTS: 'venue-update-quick-books-products',
  VENUE_UPDATE_QUICK_BOOKS_PRODUCT_GROUPS: 'venue-update-quick-books-product-groups',
  DISTRIBUTOR_CREATE_ORDER: 'distributor-create-order',
  DISTRIBUTOR_CREATE_UNIT: 'distributor-create-unit',
  DISTRIBUTOR_DELETE_UNIT: 'distributor-delete-unit',
  DISTRIBUTOR_UPDATE_UNIT: 'distributor-update-unit',
  VENUE_CREATE_UNIT: 'venue-create-unit',
  VENUE_DELETE_UNIT: 'venue-delete-unit',
  VENUE_UPDATE_UNIT: 'venue-update-unit',
  GET_CSV_TEMPLATE: 'get-csv-template',
  SUPPLIER_GET_WAREHOUSES: 'supplier-get-warehouses',
  SUPPLIER_MAP_WAREHOUSES: 'supplier-map-warehouses',
  SUPPLIER_GET_PRODUCTS: 'supplier-get-products',
  SUPPLIER_MAP_PRODUCTS: 'supplier-map-products',
  SUPPLIER_PUSH_PRODUCTS: 'supplier-push-products',
  SUPPLIER_GET_CONFIG_WIZARD_URL: 'supplier-get-config-wizard-url',
  VENUE_GET_CONFIG_WIZARD_URL: 'venue-get-config-wizard-url',
  DISTRIBUTOR_FETCH_INTEGRATIONS: 'distributor-fetch-integrations',
  DISTRIBUTOR_ENABLE_INTEGRATIONS: 'distributor-enable-integrations',
  DISTRIBUTOR_CREATE_INTEGRATIONS: 'distributor-create-integrations',
  VENUE_ENABLE_INTEGRATIONS: 'venue-enable-integrations',
  VENUE_DISCONNECT_INTEGRATIONS: 'venue-disconnect-integrations',
  DISTRIBUTOR_DISCONNECT_INTEGRATIONS: 'distributor-disconnect-integrations',
  VENUE_GET_INTEGRATIONS: 'venue-get-integrations',
  VENUE_HIDE_UNHIDE_DISTRIBUTOR_PRODUCT: 'venue-hide-unhide-distributor-product',
  DISTRIBUTOR_HIDE_UNHIDE_VENUE_PRODUCT: 'distributor-hide-unhide-venue-product',
  SEARCH_FOR_VENUE_PRODUCTS: 'search-for-venue-products',
  SEARCH_FOR_DISTRIBUTOR_PRODUCTS: 'search-for-distributor-products',
  DISTRIBUTOR_FETCH_WAREHOUSES: 'distributor-fetch-warehouses',
  DISTRIBUTOR_CREATE_WAREHOUSE: 'distributor-create-warehouse',
  DISTRIBUTOR_UPDATE_WAREHOUSE: 'distributor-update-warehouse',
  DISTRIBUTOR_DELETE_WAREHOUSE: 'distributor-delete-warehouse',
  DISTRIBUTOR_FETCH_STOCKTAKES: 'distributor-fetch-stocktakes',
  DISTRIBUTOR_CONDUCT_STOCKTAKE: 'distributor-conduct-stocktake',
  DISTRIBUTOR_FETCH_MORE_STOCKTAKES: 'distributor-fetch-more-stocktakes',
  DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE: 'distributor-fetch-single-stocktake',
  DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE_ITEMS: 'distributor-fetch-single-stocktake-items',
  DISTRIBUTOR_FETCH_MORE_SINGLE_STOCKTAKE_ITEMS: 'distributor-fetch-more-single-stocktake-items',
  DISTRIBUTOR_EXPORT_STOCKTAKE: 'distributor-export-stocktake',
  DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW: 'distributor-fetch-product-inventory-overview',
  DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW:
    'distributor-fetch-more-product-inventory-overview',
  DISTRIBUTOR_UPDATE_INVENTORY_ITEM: 'distributor-update-inventory-item',
  DISTRIBUTOR_FETCH_INVENTORY_LOCATIONS: 'distributor-fetch-inventory-locations',
  DISTRIBUTOR_FETCH_MORE_INVENTORY_LOCATIONS: 'distributor-more-fetch-inventory-locations',
  DISTRIBUTOR_PRODUCTS_ADD_INVENTORY: 'distributor-products-add-inventory',
  DISTRIBUTOR_PRODUCTS_TRANSFER_INVENTORY: 'distributor-products-transfer-inventory',
  DISTRIBUTOR_PRODUCTS_REMOVE_INVENTORY: 'distributor-products-remove-inventory',
  DISTRIBUTOR_FETCH_PRODUCTS_ACTIONS: 'distributor-fetch-products-actions',
  DISTRIBUTOR_FETCH_ORDER_FULFILLMENT_ITEMS: 'distributor-fetch-order-fulfillment-items',
  DISTRIBUTOR_FETCH_ORDER_INVENTORY_ITEMS: 'distributor-fetch-order-inventory-items',
  DISTRIBUTOR_FETCH_MORE_ORDER_INVENTORY_ITEMS: 'distributor-fetch-more-order-inventory-items',
  DISTRIBUTOR_CONVERT_QUOTE: 'distributor-convert-quote',
  DISTRIBUTOR_FETCH_INVENTORY_STOCK: 'distributor-fetch-inventory-stock',
  DISTRIBUTOR_FETCH_INVENTORY_VALUATION: 'distributor-fetch-inventory-valuation',
  DISTRIBUTOR_CREATE_XERO_BILL: 'distributor-create-xero-bill',
  DISTRIBUTOR_FETCH_XERO_VENUES: 'distributor-fetch-xero-venues',
  DISTRIBUTOR_FETCH_MORE_XERO_VENUES: 'distributor-fetch-more-xero-venues',
  DISTRIBUTOR_FETCH_XERO_CONTACTS: 'distributor-fetch-xero-contats',
  DISTRIBUTOR_UPDATE_CONNECTIONS: 'distributor-update-connections',
  DISTRIBUTOR_FETCH_CONNECTED_VENUES: 'distributor-fetch-connected-venues',
  DISTRIBUTOR_FETCH_MORE_CONNECTED_VENUES: 'distributor-fetch-more-connected-venues',
  DISTRIBUTOR_DOWNLOAD_OUTLETS_CSV: 'distributor-download-outlets-csv',
  DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_VENUES: 'distributor-fetch-account-activity-venues',
  DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_BY_VENUE: 'distributor-fetch-account-activity-by-venue',
  DISTRIBUTOR_NETSUITE_GET_AUTHORIZATION_URL: 'distributor-netsuite-get-authorization-url',
  DISTRIBUTOR_NETSUITE_AUTHORIZE: 'distributor-netsuite-authorize',
  DISTRIBUTOR_NETSUITE_FETCH_OUTLETS: 'distributor-netsuite-fetch-outlets',
  DISTRIBUTOR_NETSUITE_FETCH_MORE_OUTLETS: 'distributor-netsuite-fetch-more-outlets',
  DISTRIBUTOR_NETSUITE_UPDATE_OUTLETS: 'distributor-netsuite-update-connections',
  DISTRIBUTOR_NETSUITE_FETCH_WAREHOUSES: 'distributor-netsuite-fetch-warehouses',
  DISTRIBUTOR_NETSUITE_FETCH_MORE_WAREHOUSES: 'distributor-netsuite-fetch-more-warehouses',
  DISTRIBUTOR_NETSUITE_UPDATE_WAREHOUSES: 'distributor-netsuite-update-warehouses',
  DISTRIBUTOR_NETSUITE_FETCH_CONFIG: 'distributor-netsuite-get-config',
  DISTRIBUTOR_NETSUITE_UPDATE_CONFIG: 'distributor-netsuite-update-config',
  DISTRIBUTOR_NETSUITE_FETCH_SUBSIDIARIES: 'distributor-netsuite-fetch-subsidiaries',
  DISTRIBUTOR_NETSUITE_FETCH_DELIVERY_ITEMS: 'distributor-netsuite-fetch-delivery-items',
  DISTRIBUTOR_NETSUITE_FETCH_CUSTOM_FORMS: 'distributor-netsuite-fetch-custom-forms',
  DISTRIBUTOR_NETSUITE_FETCH_PRICE_LEVELS: 'distributor-netsuite-fetch-price-levels',
  DISTRIBUTOR_NETSUITE_FETCH_EMPLOYEES: 'distributor-netsuite-fetch-employees',
  VENUE_REQUEST_MORE_INVOICES: 'venue-request-more-invoices',
  DISTRIBUTOR_FETCH_CUSTOMER_CATEGORIES: 'distributor-fetch-customer-categories',
  DISTRIBUTOR_CREATE_CUSTOMER_CATEGORY: 'distributor-create-customer-category',
  DISTRIBUTOR_UPDATE_CUSTOMER_CATEGORY: 'distributor-update-customer-category',
  DISTRIBUTOR_DELETE_CUSTOMER_CATEGORY: 'distributor-delete-customer-category',
  DISTRIBUTOR_FETCH_CREDIT_NOTES: 'distributor-fetch-credit-notes',
  DISTRIBUTOR_FETCH_MORE_CREDIT_NOTES: 'distributor-fetch-more-credit-notes',
  DISTRIBUTOR_FETCH_CREDIT_NOTES_TOTAL: 'distributor-fetch-credit-notes-total',
  DISTRIBUTOR_EXPORT_CREDIT_NOTES_PDF: 'distributor-export-credit-notes-pdf',
  DISTRIBUTOR_EXPORT_CREDIT_NOTES_CSV: 'distributor-export-credit-notes-csv',
  DISTRIBUTOR_FETCH_SINGLE_CREDIT_NOTE: 'distributor-fetch-single-credit-note',
  DISTRIBUTOR_FETCH_CREDIT_NOTE_PRODUCTS: 'distributor-fetch-credit-note-products',
  DISTRIBUTOR_FETCH_MORE_CREDIT_NOTE_PRODUCTS: 'distributor-fetch-more-credit-note-products',
  DISTRIBUTOR_CREATE_CREDIT_NOTE: 'distributor-create-credit-note',
  DISTRIBUTOR_UPDATE_CREDIT_NOTE: 'distributor-update-credit-note',
  DISTRIBUTOR_FETCH_CREDIT_NOTE_AMOUNTS: 'distributor-fetch-credit-note-amounts',
  DISTRIBUTOR_DELETE_CREDIT_NOTE: 'distributor-delete-credit-note',
  DISTRIBUTOR_EXPORT_CREDIT_NOTE: 'distributor-export-credit-note',
  VENUE_FETCH_OVERVIEW_STATS: 'venue-fetch-overview-stats',
  VENUE_FETCH_OVERVIEW_NEW_PRODUCTS: 'venue-fetch-overview-new-products',
  VENUE_FETCH_OVERVIEW_PRICE_CHANGES: 'venue-fetch-overview-price-changes',
  VENUE_FETCH_REPORTS_PRICE_CHANGES: 'venue-fetch-reports-price-changes',
  VENUE_FETCH_REPORTS_PRICE_CHANGES_TOTAL: 'venue-fetch-reports-price-changes-total',
  VENUE_DOWNLOAD_REPORTS_PRICE_CHANGES: 'venue-download-reports-price-changes',
  VENUE_FETCH_MORE_REPORTS_PRICE_CHANGES: 'venue-fetch-more-reports-price-changes',
  VENUE_FETCH_SINGLE_PRODUCT_REPORTS_PRICE_CHANGES:
    'venue-fetch-single-product-report-price-changes',
  VENUE_FETCH_SINGLE_PRODUCT_REPORTS_ORDERS: 'venue-fetch-single-product-report-orders',
  VENUE_FETCH_MORE_SINGLE_PRODUCT_REPORTS_ORDERS: 'venue-fetch-more-single-product-report-orders',

  VENUE_FETCH_ALL_SUPPLIERS_PRODUCTS: 'venue-fetch-all-suppliers-products',
  VENUE_BULK_UPDATE_SUPPLIERS_PRODUCTS: 'venue-bulk-update-suppliers-products',
  VENUE_BULK_UPDATE_SUPPLIER_PRODUCTS: 'venue-bulk-update-supplier-products',
  VENUE_FETCH_MORE_ALL_SUPPLIERS_PRODUCTS: 'venue-fetch-more-all-suppliers-products',
  VENUE_FETCH_SUPPLIER_PRODUCTS: 'venue-fetch-suppliers-products',
  VENUE_FETCH_MORE_SUPPLIER_PRODUCTS: 'venue-fetch-more-suppliers-products',
  VENUE_UPDATE_SUPPLIER_PRODUCT: 'venue-update-supplier-product',
  VENUE_FETCH_ALL_PRODUCTS_STATS: 'venue-fetch-all-suppliers-products-stats',
  VENUE_UPDATE_INVENTORY_PRODUCTS: 'venue-update-inventory-product',
  VENUE_FETCH_STOCKTAKES: 'venue-fetch-stocktakes',
  VENUE_FETCH_MORE_STOCKTAKES: 'venue-fetch-more-stocktakes',
  VENUE_FETCH_STOCKTAKE_USERS: 'venue-fetch-stocktake-users',
  VENUE_START_STOCKTAKE: 'venue-start-stocktake',
  VENUE_UPLOAD_STOCKTAKE_CSV: 'venue-upload-stockta-csv',

  FETCH_ADMIN_DISTRIBUTOR: 'fetch-admin-distributor',
  FETCH_ADMIN_DISTRIBUTOR_VENUES: 'fetch-admin-distributor-venues',
  FETCH_MORE_ADMIN_DISTRIBUTOR_VENUES: 'fetch-more-admin-distributor-venues',
  FETCH_ADMIN_DISTRIBUTOR_PRODUCTS: 'fetch-admin-distributor-products',
  FETCH_MORE_ADMIN_DISTRIBUTOR_PRODUCTS: 'fetch-more-admin-distributor-products',
  ADMIN_EXPORT_DISTRIBUTOR_PRODUCTS: 'admin-export-distributor-products',
  FETCH_ADMIN_DISTRIBUTOR_USERS: 'fetch-admin-distributor-users',
  FETCH_ADMIN_DISTRIBUTOR_USER: 'fetch-admin-distributor-user',
  FETCH_ADMIN_DISTRIBUTORS: 'fetch-admin-distributors',
  FETCH_MORE_ADMIN_DISTRIBUTORS: 'fetch-more-admin-distributors',
  FETCH_ADMIN_VENUE_GROUP: 'fetch-admin-venue-group',
  FETCH_ADMIN_VENUE_GROUP_USERS: 'fetch-admin-venue-group-users',
  FETCH_ADMIN_VENUE_GROUPS: 'fetch-admin-venue-groups',
  FETCH_MORE_ADMIN_VENUE_GROUPS: 'fetch-more-admin-venue-groups',
  FETCH_ADMIN_VENUES_FOR_GROUP: 'fetch-admin-venues-for-group',
  FETCH_MORE_ADMIN_VENUES_FOR_GROUP: 'fetch-more-admin-venues-for-group',
  FETCH_ADMIN_VENUE_CONNECTED_SUPPLIERS: 'fetch-admin-venue-connected-suppliers',
  FETCH_MORE_ADMIN_VENUE_CONNECTED_SUPPLIERS: 'fetch-more-admin-venue-connected-suppliers',
  ADMIN_CONNECT_VENUE_WITH_DISTRIBUTOR: 'admin-connect-venue-with-distributor',
  ADMIN_DISCONNECT_VENUE_FROM_DISTRIBUTOR: 'admin-disconnect-venue-from-distributor',
  ADMIN_CONNECT_DISTRIBUTOR_WITH_VENUE: 'admin-connect-distributor-with-venue',
  ADMIN_DISCONNECT_DISTRIBUTOR_FROM_VENUE: 'admin-disconnect-distributor-from-venue',
  FETCH_ADMIN_VENUE: 'fetch-admin-venue',
  FETCH_ADMIN_ORDER_HISTORY: 'fetch-admin-order-history',
  FETCH_ADMIN_ORDER_HISTORY_LOAD_MORE: 'fetch-admin-order-history-load-more',
  FETCH_ADMIN_PENDING_INVOICES: 'fetch-admin-pending-invoices',
  FETCH_ADMIN_PENDING_INVOICE: 'fetch-admin-pending-invoice',
  FETCH_ADMIN_PENDING_INVOICES_LOAD_MORE: 'fetch-admin-pending-invoices-load-more',
  FETCH_ADMIN_PENDING_INVOICE_MERGE: 'fetch-admin-pending-invoice-merge',
  FETCH_ADMIN_PENDING_INVOICE_MERGE_LOAD_MORE: 'fetch-admin-pending-invoices-merge-load-more',
  ADMIN_PENDING_INVOICE_MERGE: 'admin-pending-invoice-merge',
  ADMIN_UPDATE_CLEARING_NOTE: 'admin-update-clearing-note',
  ADMIN_COMPLETE_PENDING_INVOICE: 'admin-complete-pending-invoice',
  ADMIN_FETCH_NEXT_PENDING_INVOICE: 'admin-fetch-next-pending-invoice',
  RELEASE_ADMIN_PENDING_INVOICE: 'release-pending-invoice',
  FETCH_ADMIN_VENUES: 'fetch-admin-venues',
  VENUE_FETCH_DISTRIBUTOR_FILTER_OPTIONS: 'venue-fetch-distributor-filter-options',
  ADMIN_FETCH_VENUES_FOR_PRODUCT: 'admin-fetch-venues-for-product',
  ADMIN_UNHIDE_ALL_VENUES_FOR_PRODUCT: 'admin-unhide-all-venues-for-product',
  ADMIN_HIDE_ALL_VENUES_FOR_PRODUCT: 'admin-hide-all-venues-for-product',
  ADMIN_UNHIDE_VENUE_FOR_PRODUCT: 'admin-unhide-venue-for-product',
  ADMIN_HIDE_VENUE_FOR_PRODUCT: 'admin-hide-venue-for-product',
  FETCH_PRODUCT_GROUPS: 'fetch-product-groups',
  CREATE_PRODUCT_GROUP: 'create-product-group',
  UPDATE_PRODUCT_GROUP: 'update-product-group',
  DELETE_PRODUCT_GROUP: 'delete-product-group',
  DISTRIBUTOR_CREATE_TARGET_VENUE: 'distributor-create-target-venue',
  DISTRIBUTOR_FETCH_DRIVERS: 'distributor-fetch-drivers',
  DISTRIBUTOR_FETCH_TAGS: 'distributor-fetch-tags',
  FETCH_TAG_COLORS: 'fetch-tag-colors',
  DISTRIBUTOR_CREATE_TAG: 'distributor-create-tag',
  DISTRIBUTOR_UPDATE_TAG: 'distributor-update-tag',
  DISTRIBUTOR_DELETE_TAG: 'distributor-delete-tag',
  FETCH_PRODUCT_SUGGESTED_MATCHES: 'admin-fetch-product-suggested-matches',
  FETCH_DISTRIBUTOR_SUGGESTED_MATCHES: 'admin-fetch-distributor-suggested-matches',
  BULK_EXPORT_ORDERS: 'venue-bulk-export-orders',
};
