import httpService from '@/api/http';
import Distributor from '@/models/Distributor';
import Venue from '@/models/Venue';
import User from '@/models/User';
import { isAdmin, LOADING_KEY } from '@/util/constants';

const fetchVenues = () =>
  httpService.get('/admin/venues').then(({ data }) => Venue.insertOrUpdate({ data: data.data }));

const fetchVenuesForGroup = (context, groupId) => {
  const route = isAdmin() ? `/admin/venue-groups/${groupId}/venues` : 'venue-group/venues';

  return httpService
    .get(route, {
      headers: {
        loadingKey: 'get-venues',
      },
    })
    .then(({ data }) => Venue.create({ data: data.data.map(d => ({ ...d, groupId })) }));
};

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3010e2f9-42eb-4dd4-810f-b499fe1219c4}
 * @param context
 * @param {string | number} groupId
 * @param {{
 *  nextId?: string | number;
 *  nextValue?: string;
 *  limit?: number;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenuesForGroupAdmin(
  context,
  { groupId, query, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUES_FOR_GROUP } = {},
) {
  return httpService.get(`/admin/venue-groups/${groupId}/venues`, {
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#bbac1604-da65-4f54-9ede-c89ce5848ddd}
 * @param context
 * @param {string | number} groupId
 * @param {{
 *  nextId?: string | number;
 *  nextValue?: string;
 *  limit?: number;
 *  term?: string;
 * }} query
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenuesForVenueGroup(
  context,
  { groupId, query, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUES_FOR_GROUP } = {},
) {
  return httpService.get(`/venue-groups/${groupId}/venues`, {
    params: { ...query },
    headers: { loadingKey },
  });
}

const fetchAdminVenue = (context, id) =>
  Promise.all([
    httpService.get(`/admin/venues/${id}`),
    httpService.get(`/admin/venues/${id}/distributors`, {
      headers: {
        loadingKey: 'get-venue-distributors',
      },
    }),
  ]).then(([venueResult, distributorsResult]) => {
    Distributor.insertOrUpdate({
      data: distributorsResult.data.data.map(({ isConnected, ...distributor }) => distributor),
    });

    return Venue.insertOrUpdate({
      data: {
        ...venueResult.data.data,
        connectedDistributors: distributorsResult.data.data.filter(v => v.isConnected),
      },
    });
  });

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#453dee09-1a86-4b8c-96aa-50d139c3c694}
 * @param context
 * @param {string} venueId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminVenueNew(context, { venueId, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE } = {}) {
  return httpService.get(`/admin/venues/${venueId}`, { headers: { loadingKey } });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#05f07c37-d85e-41a7-8dac-25f6cc27a6a0}
 * @param context
 * @param {string} venueId
 * @param {string} groupId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueNew(context, { venueId, groupId, loadingKey = 'fetch-new-venue' } = {}) {
  return httpService.get(`/venue-groups/${groupId}/venues/${venueId}`, { headers: { loadingKey } });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#906a3201-080b-4554-b201-9cc7017e0ca8}
 * @param context
 * @param {string} venueId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueLocations(
  context,
  { venueId, loadingKey = LOADING_KEY.FETCH_VENUE_LOCATIONS } = {},
) {
  return httpService.get(`/venues/${venueId}/locations`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#05cc6182-89d3-42f9-90ac-1cb80e9e5561}
 * @param context
 * @param {string} venueId
 * @param {Object} data
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function addVenueLocation(
  context,
  { venueId, data, loadingKey = LOADING_KEY.ADD_VENUE_LOCATION } = {},
) {
  return httpService.post(`/venues/${venueId}/locations`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0ecc796b-2873-417b-a0f4-3ceb981b3822}
 * @param context
 * @param {string} venueId
 * @param {string} locationId
 * @param {Object} data
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function updateVenueLocation(
  context,
  { venueId, locationId, data, loadingKey = LOADING_KEY.ADD_VENUE_LOCATION } = {},
) {
  return httpService.put(`/venues/${venueId}/locations/${locationId}`, data, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b7aa3d6d-2b45-4f85-8127-b4143b78b465}
 * @param context
 * @param {string} venueId
 * @param {string} locationId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteVenueLocation(
  context,
  { venueId, locationId, loadingKey = LOADING_KEY.ADD_VENUE_LOCATION } = {},
) {
  return httpService.delete(`/venues/${venueId}/locations/${locationId}`, {
    headers: { loadingKey },
  });
}

const fetchPlatformVenue = (context, id) =>
  httpService.get(`/venues/${id}`).then(({ data }) => {
    Venue.insertOrUpdate({ data: data.data });
    return { data };
  });

const fetchDistributorVenue = async (
  { commit },
  { id, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_VENUE },
) => {
  const { data } = await httpService.get(`/distributor/venues/${id}`, { headers: { loadingKey } });
  Venue.insertOrUpdate({ data: data.data });
  commit('INSERT_DISTRIBUTOR_VENUE_SINGLE', data.data);
  return { data };
};

const fetchVenue = (context, id) => {
  if (isAdmin()) {
    return fetchAdminVenue(context, id);
  }
  if (context.rootGetters['entities/users/isVenue']) return fetchPlatformVenue(context, id);

  return fetchDistributorVenue(context, { id });
};

const fetchVenueUsers = (
  context,
  { groupId, venueId, loadingKey = LOADING_KEY.FETCH_VENUE_USERS },
) =>
  httpService
    .get(`/distributor/venues/${venueId}/users`, {
      headers: {
        loadingKey,
      },
    })
    .then(({ data }) => {
      const userData = Array.isArray(data.data)
        ? data.data.map(d => ({
            ...d,
            groupId,
            venueId,
          }))
        : data.data;

      return User.insertOrUpdate({ data: userData });
    });

const removeMyLogo = ({ commit, state }) =>
  httpService.delete('/venue/me/logo').then(() => {
    commit('INSERT_ME', { me: { ...state.me, logo: null, isDefaultImage: true } });
  });

const removeVenueLogo = (context, id) =>
  httpService
    .delete(`/admin/venues/${id}/logo`)
    .then(() => Venue.update({ where: id, data: { logo: null, isDefaultImage: true } }));

const removeVenue = (context, id) => httpService.delete(`/admin/venues/${id}`);

const disconnectVenue = (context, { id, loadingKey = LOADING_KEY.DISCONNECT_VENUE }) =>
  httpService.delete(`/distributor/venues/${id}`, {
    headers: {
      loadingKey,
    },
  });

const connectVenueWithDistributor = (
  context,
  { venueId, distributorId, loadingKey = LOADING_KEY.ADMIN_CONNECT_VENUE_WITH_DISTRIBUTOR },
) =>
  httpService
    .put(`/admin/venues/${venueId}/distributors/${distributorId}`, {}, { headers: { loadingKey } })
    .then(() => {
      const venue = Venue.find(venueId);

      return venue?.$update({
        distributorIds: [...venue.distributorIds, distributorId],
      });
    });

const disconnectVenueFromDistributor = (
  context,
  { venueId, distributorId, loadingKey = LOADING_KEY.ADMIN_DISCONNECT_VENUE_FROM_DISTRIBUTOR },
) =>
  httpService
    .delete(`/admin/venues/${venueId}/distributors/${distributorId}`, { headers: { loadingKey } })
    .then(() => {
      const venue = Venue.find(venueId);

      return venue?.$update({
        distributorIds: venue.distributorIds.filter(d => d !== distributorId),
      });
    });

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#772fa679-8674-48c4-afbf-6406ed556b6b}
 *
 * @param {number} id
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function distributorFetchVenue(context, { id, loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE }) {
  return httpService.get(`/distributor/venues/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9eb1e3cf-4f67-41d8-ab62-4429c86f5414}
 *
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchConnectionRequests({
  loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTION_REQUESTS,
}) {
  return httpService.get('/distributor/invitations', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f551dcfe-bfcb-46b0-b3a0-e8dce2f2f81f}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorUpdateConnectionStatus({ id, status }) {
  return httpService.patch(`distributor/invitations/${id}`, { status });
}

/**
 * @since 2.3.0
 * @see distributorUpdateConnectionStatus
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<*>>}
 */
function distributorApproveConnectionRequest(context, id) {
  return distributorUpdateConnectionStatus({
    id,
    status: 'approved',
  });
}

/**
 * @since 2.3.0
 * @see distributorUpdateConnectionStatus
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<*>>}
 */
function distributorDeclineConnectionRequest(context, id) {
  return distributorUpdateConnectionStatus({
    id,
    status: 'declined',
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a88aa26e-7f39-4fd8-88cb-dd136e99e46f}
 *
 * @param context
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchOnboardingContacts(context, { id }) {
  return httpService.get(`venues/${id}/users`);
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a88aa26e-7f39-4fd8-88cb-dd136e99e46f}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchOnboardingContactsFromPublicEntry(context, { venueId, distributorId }) {
  return httpService.get(`venues/${venueId}/distributors/${distributorId}/venue-contacts`);
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cc18b436-b4b0-45d4-9116-0348998b7bb0}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueApproveDeclineOnboarding(context, { venueId, distributorId, data }) {
  return httpService.patch(`venues/${venueId}/invitations/${distributorId}`, {
    ...data,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cc18b436-b4b0-45d4-9116-0348998b7bb0}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueApproveDeclineOnboardingFromPublicEntry(context, { venueId, distributorId, data }) {
  return httpService.patch(`venues/${venueId}/invitations/distributors/${distributorId}`, {
    ...data,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d4e2e35e-cbeb-46d9-b037-1d04bd346e0d}
 *
 * @param context
 * @param venueId
 * @param config
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAddVenueToGroup(context, { config, venueId }) {
  return httpService.put(
    `venue-group/venues/${venueId}`,
    {},
    {
      ...config,
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0c7d9067-cf4f-4784-9ecc-842262f7b6bd}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param userId
 * @param type
 * @return {Promise<AxiosResponse<any>>}
 */
function venueAssignUserToDistributor(context, { venueId, distributorId, userId, type }) {
  return httpService.put(`venues/${venueId}/users/${userId}/distributors/${distributorId}`, {
    type,
  });
}

// Public onboarding endpoints

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2d9340db-f106-4eb8-ae04-ac9dca5245f2}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPublicExistingOnboardingInfo(context, { config }) {
  return httpService.get('public/venue-onboarding', {
    ...config,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2d9340db-f106-4eb8-ae04-ac9dca5245f2}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPublicExistingOnboardingContacts(context, { distributorId, venueId, config }) {
  return httpService.get(`public/distributors/${distributorId}/venues/${venueId}/users`, {
    ...config,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#99db6867-092b-4878-81c9-650493d4257e}
 *
 * @param context
 * @param distributorId
 * @param config
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPublicExistingOnboardingTos(context, { distributorId, config }) {
  return httpService.get(`public/distributors/${distributorId}/terms-and-conditions`, {
    ...config,
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ff998066-7824-4cec-8c26-7e990c4533ca}
 *
 * @param context
 * @param distributorId
 * @param config
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchPublicExistingOnboardingTosPdf(context, { distributorId, config }) {
  return httpService.get(`public/distributors/${distributorId}/terms-and-conditions/pdf`, {
    ...config,
    responseType: 'arraybuffer',
  });
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2f63d200-c855-46c7-b248-70467f86b33b}
 *
 * @param context
 * @param distributorId
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOnboardingTos(context, { distributorId, venueId }) {
  return httpService.get(`venues/${venueId}/distributors/${distributorId}/terms-and-conditions`);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#18457db1-8406-45dd-b91c-9062c14c3ba2}
 *
 * @param context
 * @param distributorId
 * @param venueId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchOnboardingTosPdf(
  context,
  { distributorId, venueId, loadingKey = LOADING_KEY.DOWNLOAD_TOS },
) {
  return httpService.get(
    `venues/${venueId}/distributors/${distributorId}/terms-and-conditions/pdf`,
    {
      responseType: 'arraybuffer',
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f7f3d7e8-59cf-457c-9c6d-d9e66cbd0122}
 *
 * @param context
 * @param distributorId
 * @param venueId
 * @param config
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function connectPublicExistingOnboarding(context, { distributorId, venueId, config, data }) {
  return httpService.put(
    `public/distributors/${distributorId}/venues/${venueId}`,
    {
      ...data,
    },
    config,
  );
}

// End of public endpoints

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#02581e23-6c41-4bf0-aabc-88438043fc2e}
 *
 * @param {Object} data
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorOnboardingPremiumOutlet(
  context,
  { data, loadingKey = LOADING_KEY.DISTRIBUTOR_ONBOARDING_PREMIUM_OUTLET },
) {
  return httpService.post('/distributor/me/onboarding-requests', data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0e981ba1-df93-4324-9c08-1ac7d7bbc9f6}
 *
 * @param context
 * @param distributorId
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function outletDownloadProductsCSV(
  context,
  { distributorId, loadingKey = LOADING_KEY.VENUE_EXPORT_PRODUCTS },
) {
  return httpService.get(`venue/distributors/${distributorId}/export/products`, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 2.4.0
 *
 * @param context
 * @param distributorId
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function outletDownloadAllProductsCSV(context, { loadingKey = LOADING_KEY.VENUE_EXPORT_PRODUCTS }) {
  return httpService.get(`venue/distributors/export/products`, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.0.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1c696705-38ec-4cda-bd3c-7ae8364b02fe}
 *
 * @param context
 * @param venueId
 * @param query
 * @return {Promise<AxiosResponse<any>>}
 */
function outletGetPendingInvoices(
  context,
  { venueId, query, loadingKey = LOADING_KEY.VENUE_PENDING_UPDATES },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`venues/${venueId}/pending-invoices${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.1.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#90144df5-b6a8-4549-a4e3-068346ddddcc}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @return {Promise<AxiosResponse<any>>}
 */
function outletDeletePendingInvoice(context, { pendingId }) {
  return httpService.delete(`venue/pending-invoices/${pendingId}`);
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c33b150b-d028-4df7-8eb1-d40664612534}
 *
 * @param context
 * @param venueId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function createOfflineOrder(context, { venueId, data }) {
  return httpService.post(`venues/${venueId}/orders`, data);
}

/**
 * @since 3.5.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#3063e3cb-9fe3-40c1-bdf2-95e1366d2641}
 *
 * @param context
 * @param venueId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function createOfflineOrderMulti(context, { venueId, data }) {
  return httpService.post(`venues/${venueId}/orders/offline`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6fe25419-7bd6-4dac-b966-4145180bd4c1}
 *
 * @param distributorId
 * @param limit
 * @param term
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchVenueProductsForDistributor(context, { distributorId, limit, term }) {
  const isVenue = localStorage.getItem('role') === 'venue';
  const venueId = context.rootState.entities.users.contextId;

  if (isVenue && !venueId) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  return httpService.get(`/venues/${venueId}/distributors/${distributorId}/search/products`, {
    params: {
      limit,
      term,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#37c86f79-475f-401e-8e11-ad3453770fe5}
 *
 * This action gets all products (visible or not visible).
 *
 * @param context
 * @param {number} distributorId
 * @param {number} limit
 * @param {number} nextId
 * @param {unknown} nextValue
 * @param {string} term
 * @param {number} categoryId
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAllVenueProductsForDistributor(
  context,
  { distributorId, limit, term, categoryId, nextId, nextValue },
) {
  const isVenue = localStorage.getItem('role') === 'venue';
  const venueId = context.rootState.entities.users.contextId;

  if (isVenue && !venueId) {
    throw new Error(`${this.$t('global.venue')} context is required`);
  }

  return httpService.get(`/venue/distributors/${distributorId}/products`, {
    params: {
      term,
      categoryId,
      limit,
      nextId,
      nextValue,
    },
  });
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#99bc5410-fb94-478d-80a6-a5730acc052e}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function venueProductsBulkUpdate(context, { venueId, distributorId, data }) {
  return httpService.patch(`/venues/${venueId}/distributors/${distributorId}/products/bulk`, {
    products: data,
  });
}

/**
 * @since 3.3.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6f9050af-5315-4e79-8e3e-2dc73c2720b5}
 *
 * @param context
 * @param {number} venueId
 * @param {string} loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteVenueFromVenueGroup(
  context,
  { venueId, loadingKey = LOADING_KEY.DELETE_VENUE_FROM_VENUE_GROUP },
) {
  return httpService.delete(`/venue-group/venues/${venueId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ced5d6d5-19b7-451b-a894-7c24bef65ea4}
 *
 * @param context
 * @param venueId
 * @param query
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchPendingUpdates(context, { venueId, query }) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/admin/venues/${venueId}/pending-invoices${queryParam}`);
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5468a676-5b2c-411e-8e63-20e80f18ef7d}
 *
 * @param context
 * @param venueId
 * @param pendingId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminDeletePendingUpdates(context, { venueId, pendingId }) {
  return httpService.delete(`/admin/venues/${venueId}/pending-invoices/${pendingId}`);
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#dce2e86d-b4e6-4ab6-8d41-5aef3563f290}
 *
 * @param context
 * @param venueId
 * @param distributorId
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function adminProductsBulkUpdate(context, { venueId, distributorId, data }) {
  return httpService.patch(`/admin/distributors/${distributorId}/products/venues/${venueId}/bulk`, {
    products: data,
  });
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#79ef5193-208b-4d9f-be28-fe32f80272a6}
 *
 * @param context
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
function adminCreateOfflineOrder(context, { data }) {
  return httpService.post('admin/orders', data);
}

/**
 * @since 3.3.1
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#cfe4cb83-b930-4166-9e4a-7c2b0475fbf2}
 *
 * @param context
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminDeleteVenue(context, { venueId }) {
  return httpService.delete(`admin/venues/${venueId}`);
}

/**
 * @since 3.0.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#091a9d5d-30e7-445b-b706-c453de6e7674}
 *
 * @param context
 * @param {object} query
 * @param {string} venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function adminFetchConnectedSuppliers(
  context,
  { venueId, query = {}, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_CONNECTED_SUPPLIERS },
) {
  return httpService.get(`/admin/venues/${venueId}/distributors`, {
    params: { ...query },
    headers: { loadingKey },
  });
}

/**
 * @since 3.13.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e7fc5030-5b41-4d8e-9dd1-808fefc1ad77}
 *
 * @param context
 * @param venueId
 * @param distributors
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchDeliveryDate(context, { venueId, distributors }) {
  return httpService.get(`/venues/${venueId}/orders/delivery-date`, {
    params: {
      distributors,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#e25519be-6793-486b-a5d0-d85b90fd5be9}
 *
 * @param context
 * @param venueId
 * @param query
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchAvailableIntegrations(
  context,
  { venueId, query, loadingKey = LOADING_KEY.VENUE_GET_INTEGRATIONS },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/venues/${venueId}/tray/solutions${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#31435461-9d94-43fa-9671-8414308b4410}
 *
 * @param context
 * @param venueId
 * @param query
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchConnectedIntegrations(
  context,
  { venueId, query, loadingKey = LOADING_KEY.VENUE_GET_INTEGRATIONS },
) {
  const queryParam = query ? `?${query}` : '';
  return httpService.get(`/venues/${venueId}/tray/solution-instances${queryParam}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#54646035-3e53-4770-bfb8-376890faaa68}
 *
 * @param context
 * @param {String} solutionId
 * @param {String} venueId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueEnableConnectedIntegrations(
  context,
  { solutionId, venueId, loadingKey = LOADING_KEY.VENUE_ENABLE_INTEGRATIONS },
) {
  return httpService.put(
    `/venues/${venueId}/tray/solution-instances/${solutionId}/enable`,
    {},
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7bc69fa7-5ff1-4d6d-9694-a9d0e0049b1f}
 *
 * @param context
 * @param {String} solutionId
 * @param {String} venueId
 * @param loadingKey
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDisconnectIntegrations(
  context,
  { solutionId, venueId, loadingKey = LOADING_KEY.VENUE_DISCONNECT_INTEGRATIONS },
) {
  return httpService.delete(`/venues/${venueId}/tray/solution-instances/${solutionId}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#df79c62d-ba85-49ff-93bc-3db232e299cf}
 *
 * @param query
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function fetchAdminVenues(context, { query, loadingKey = LOADING_KEY.FETCH_ADMIN_VENUES } = {}) {
  return httpService.get('/admin/venues', {
    params: {
      ...query,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c0d7947c-6d3a-4d0e-91af-21fe944e82e7}
 *
 * @param context
 * @param {string} venueId
 * @param {Object} query { for: 'orders' | 'products'; term: string; }
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchDistributorFilterOptions(
  context,
  { query, loadingKey = LOADING_KEY.VENUE_FETCH_DISTRIBUTOR_FILTER_OPTIONS } = {},
) {
  return httpService.get(`/venue/filters/distributors`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#c0d7947c-6d3a-4d0e-91af-21fe944e82e7}
 *
 * @param query
 * @param loadingKey
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchFilterSuppliers(
  context,
  { query = undefined, loadingKey = LOADING_KEY.VENUE_FETCH_DISTRIBUTORS_FILTER } = {},
) {
  return httpService.get(`/venue/filters/distributors`, {
    params: {
      for: 'products',
      ...query,
    },
    headers: {
      loadingKey,
    },
  });
}

function fetchDistributorVenueNew(
  context,
  { id, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_VENUE } = {},
) {
  return httpService.get(`/distributor/venues/${id}`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#ff76f067-0cf6-4405-a6ed-163085b9d5b2}
 *
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchOverviewStatistics(
  context,
  { loadingKey = LOADING_KEY.VENUE_FETCH_OVERVIEW_STATS } = {},
) {
  return httpService.get('/venue/dashboard/stats', {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f44eeabe-ada0-4eaa-8c14-4627fb715e2a}
 *
 * @param limit
 * @param from
 * @param to
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchNewProducts(
  context,
  { limit, from, to, loadingKey = LOADING_KEY.VENUE_FETCH_OVERVIEW_NEW_PRODUCTS } = {},
) {
  return httpService.get('/venue/dashboard/products/new', {
    params: {
      limit,
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#92a4f75e-0bd0-41e9-bf81-1cff323b5b4c}
 *
 * @param limit
 * @param from
 * @param to
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchPriceChanges(
  context,
  { limit, from, to, loadingKey = LOADING_KEY.VENUE_FETCH_OVERVIEW_PRICE_CHANGES } = {},
) {
  return httpService.get('/venue/dashboard/products/price-changes', {
    params: {
      limit,
      from,
      to,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f965121b-a9eb-4044-9aa8-184cad3d7074}
 *
 * @param limit
 * @param from
 * @param to
 * @param term
 * @param nextId
 * @param nextValue
 * @param productGroupId
 * @param categoryId
 * @param distributorId
 * @param onlyWithChange
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchReportsPriceChanges(
  context,
  {
    limit,
    from,
    to,
    term,
    nextId,
    nextValue,
    productGroupId,
    categoryId,
    distributorId,
    onlyWithChange,
    type = 'invoice',
    loadingKey = LOADING_KEY.VENUE_FETCH_REPORTS_PRICE_CHANGES,
  } = {},
) {
  return httpService.get('/venue/reports/products/price-changes', {
    params: {
      limit,
      from,
      to,
      term,
      nextId,
      nextValue,
      productGroupId,
      categoryId,
      distributorId,
      onlyWithChange,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f965121b-a9eb-4044-9aa8-184cad3d7074}
 *
 * @param from
 * @param to
 * @param productGroupId
 * @param categoryId
 * @param distributorId
 * @param onlyWithChange
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchReportsPriceChangesTotal(
  context,
  {
    from,
    to,
    productGroupId,
    categoryId,
    distributorId,
    onlyWithChange,
    type = 'invoice',
    loadingKey = LOADING_KEY.VENUE_FETCH_REPORTS_PRICE_CHANGES_TOTAL,
  } = {},
) {
  return httpService.get('/venue/reports/total-amount', {
    params: {
      from,
      to,
      productGroupId,
      categoryId,
      distributorId,
      onlyWithChange,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d1bc3b50-82f3-4461-8eb2-48472e9d6f02}
 *
 * @param productId
 * @param from
 * @param to
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchSingleProductReportPriceChanges(
  context,
  {
    productId,
    from,
    to,
    type = 'invoice',
    loadingKey = LOADING_KEY.VENUE_FETCH_SINGLE_PRODUCT_REPORTS_PRICE_CHANGES,
  } = {},
) {
  return httpService.get(`/venue/reports/products/${productId}`, {
    params: {
      from,
      to,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b311b052-4942-42cb-ac93-feb6d67bef88}
 *
 * @param productId
 * @param from
 * @param to
 * @param limit
 * @param nextId
 * @param nextValue
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchSingleProductReportOrders(
  context,
  {
    productId,
    from,
    to,
    limit,
    nextId,
    nextValue,
    type = 'invoice',
    loadingKey = LOADING_KEY.VENUE_FETCH_SINGLE_PRODUCT_REPORTS_ORDERS,
  } = {},
) {
  return httpService.get(`/venue/reports/products/${productId}/orders`, {
    params: {
      from,
      to,
      limit,
      nextId,
      nextValue,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @since 3.16.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#72ba53ec-2f56-4356-9708-945e99c84470}
 *
 * @param limit
 * @param from
 * @param to
 * @param term
 * @param productGroupId
 * @param categoryId
 * @param distributorId
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueDownloadProductsReportCSV(
  context,
  {
    limit,
    from,
    to,
    term,
    productGroupId,
    categoryId,
    distributorId,
    type = 'invoice',
    loadingKey = LOADING_KEY.VENUE_DOWNLOAD_REPORTS_PRICE_CHANGES,
  } = {},
) {
  return httpService.get('/venue/reports/products/price-changes/export', {
    params: {
      limit,
      from,
      to,
      term,
      productGroupId,
      categoryId,
      distributorId,
      type,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#1420157b-4c67-40c6-83b2-9e7191b63372}
 *
 * @param context
 * @param query
 * @param venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchStocktakes(
  context,
  { venueId, query = {}, loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES } = {},
) {
  return httpService.get(`/venues/${venueId}/stocktakes`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#d0c56562-f49a-4e74-8df7-1a6071870574}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchStocktake(
  context,
  { venueId, stocktakeId, loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES } = {},
) {
  return httpService.get(`/venues/${venueId}/stocktakes/${stocktakeId}`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#234727fb-37cb-47fd-871a-b4478a0bc8a5}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchStocktakeItems(
  context,
  { venueId, stocktakeId, query = {}, loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES } = {},
) {
  return httpService.get(`/venues/${venueId}/stocktakes/${stocktakeId}/items`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f000aae4-8f30-491c-b702-83e7fa8ff0a5}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchStocktakeTotalAmount(
  context,
  { venueId, stocktakeId, query = {}, loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES } = {},
) {
  return httpService.get(`/venues/${venueId}/stocktakes/${stocktakeId}/valuation`, {
    headers: { loadingKey },
    params: { ...query },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0422661b-ae5e-4fe4-a442-8680ed0dc669}
 *
 * @param context
 * @param venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchStocktakeUsers(
  context,
  { venueId, loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKE_USERS } = {},
) {
  return httpService.get(`/venues/${venueId}/stocktakes/users`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#f7d8ef7b-e950-4e42-8c85-4d793c1fa990}
 *
 * @param context
 * @param venueId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueStartStocktake(
  context,
  { venueId, loadingKey = LOADING_KEY.VENUE_START_STOCKTAKE } = {},
) {
  return httpService.post(`/venues/${venueId}/stocktakes`, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#659625be-593b-45db-a5bf-d13c74f636cf}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueUpdateStocktake(
  context,
  { venueId, stocktakeId, body, loadingKey = LOADING_KEY.VENUE_START_STOCKTAKE } = {},
) {
  return httpService.put(`/venues/${venueId}/stocktakes/${stocktakeId}/locations`, body, {
    headers: { loadingKey },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#659625be-593b-45db-a5bf-d13c74f636cf}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param locationId
 * @param page
 * @param limit
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueFetchProductsByLocation(
  context,
  {
    venueId,
    stocktakeId,
    locationId,
    page,
    limit = 10,
    term,
    loadingKey = LOADING_KEY.VENUE_START_STOCKTAKE,
  } = {},
) {
  return httpService.get(
    `/venues/${venueId}/stocktakes/${stocktakeId}/locations/${locationId}/inventory-products`,
    {
      headers: { loadingKey },
      params: { page, limit, term },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#42420587-360e-421c-acb2-fa539e2092f7}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param locationId
 * @param body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueUpdateProductQuantity(
  context,
  { venueId, stocktakeId, locationId, body, loadingKey = LOADING_KEY.VENUE_START_STOCKTAKE } = {},
) {
  return httpService.put(
    `/venues/${venueId}/stocktakes/${stocktakeId}/locations/${locationId}/items`,
    body,
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#b26e8eb7-5f7b-417a-aa53-f246f6e8b2b1}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param locationId
 * @param body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueConductStocktake(
  context,
  { venueId, stocktakeId, locationId, body, loadingKey = LOADING_KEY.VENUE_START_STOCKTAKE } = {},
) {
  return httpService.put(
    `/venues/${venueId}/stocktakes/${stocktakeId}/locations/${locationId}/complete`,
    body,
    {
      headers: { loadingKey },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#9d9eb538-9b6d-49ab-ad65-5ec8f39571e9}
 *
 * @param context
 * @param venueId
 * @param stocktakeId
 * @param locationId
 * @param body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueImportStocktakeCSV(
  context,
  {
    venueId,
    stocktakeId,
    locationId,
    body,
    loadingKey = LOADING_KEY.VENUE_UPLOAD_STOCKTAKE_CSV,
  } = {},
) {
  return httpService.put(
    `/venues/${venueId}/stocktakes/${stocktakeId}/locations/${locationId}/import`,
    body,
    {
      headers: { loadingKey, 'Content-Type': 'multipart/form-data' },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#99cac2ea-32e2-4010-a50c-903135a07902}
 *
 * @param context
 * @param venueId
 * @param body
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function venueImportAllStockCSV(
  context,
  { venueId, body, loadingKey = LOADING_KEY.VENUE_UPLOAD_STOCKTAKE_CSV } = {},
) {
  return httpService.put(`/venues/${venueId}/inventory-products/import`, body, {
    headers: { loadingKey, 'Content-Type': 'multipart/form-data' },
  });
}

/**
 * @since 2.4.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#2a3b51c3-2e6d-4fd9-9ca4-ec51bb6e6765}
 * @param context
 * @param stocktakeId
 * @param venueId
 * @return {Promise<AxiosResponse<any>>}
 */
function venueExportSingleStocktake(
  context,
  { venueId, stocktakeId, loadingKey = LOADING_KEY.VENUE_EXPORT_PRODUCTS },
) {
  return httpService.get(`venues/${venueId}/stocktakes/${stocktakeId}/export`, {
    responseType: 'arraybuffer',
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#5b284388-7631-412d-ac9a-fa387c42d2c7}
 *
 * @param context
 * @param {number} venueId
 * @param {number} stocktakeId
 * @param {string} loadingKey
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function exportStocktakeToAccountingIntegration(
  context,
  { venueId, stocktakeId, loadingKey = LOADING_KEY.EXPORT_ORDER } = {},
) {
  return httpService.put(`/venues/${venueId}/stocktakes/${stocktakeId}/accounting`, {
    headers: { loadingKey },
  });
}

export default {
  fetchAllVenueProductsForDistributor,
  fetchVenueProductsForDistributor,
  fetchVenues,
  fetchVenuesForGroup,
  fetchVenuesForVenueGroup,
  fetchVenue,
  removeMyLogo,
  removeVenue,
  removeVenueLogo,
  disconnectVenue,
  fetchVenueUsers,
  fetchVenueLocations,
  addVenueLocation,
  updateVenueLocation,
  deleteVenueLocation,
  connectVenueWithDistributor,
  disconnectVenueFromDistributor,
  fetchPlatformVenue,

  // Refactored Methods
  distributorFetchVenue,
  distributorFetchConnectionRequests,
  distributorApproveConnectionRequest,
  distributorDeclineConnectionRequest,

  venueFetchOnboardingContacts,
  venueApproveDeclineOnboarding,
  venueAssignUserToDistributor,

  fetchPublicExistingOnboardingInfo,
  fetchPublicExistingOnboardingTos,
  fetchPublicExistingOnboardingTosPdf,
  fetchPublicExistingOnboardingContacts,
  connectPublicExistingOnboarding,
  distributorOnboardingPremiumOutlet,
  outletDownloadProductsCSV,
  outletDownloadAllProductsCSV,
  outletGetPendingInvoices,
  outletDeletePendingInvoice,
  createOfflineOrder,
  venueProductsBulkUpdate,
  adminFetchPendingUpdates,
  adminDeletePendingUpdates,
  adminProductsBulkUpdate,
  adminCreateOfflineOrder,
  deleteVenueFromVenueGroup,
  adminDeleteVenue,
  adminFetchConnectedSuppliers,
  fetchOnboardingTos,
  fetchOnboardingTosPdf,
  venueFetchOnboardingContactsFromPublicEntry,
  venueApproveDeclineOnboardingFromPublicEntry,
  venueAddVenueToGroup,
  createOfflineOrderMulti,
  venueFetchDeliveryDate,
  // Tray Integrations
  venueFetchAvailableIntegrations,
  venueFetchConnectedIntegrations,
  venueEnableConnectedIntegrations,
  venueDisconnectIntegrations,

  // ORM Refactor
  fetchVenuesForGroupAdmin,
  fetchAdminVenueNew,
  fetchVenueNew,
  fetchAdminVenues,
  venueFetchDistributorFilterOptions,

  venueFetchFilterSuppliers,

  venueFetchOverviewStatistics,
  venueFetchPriceChanges,
  venueFetchNewProducts,
  venueFetchReportsPriceChanges,
  venueFetchReportsPriceChangesTotal,
  venueDownloadProductsReportCSV,
  venueFetchSingleProductReportPriceChanges,
  venueFetchSingleProductReportOrders,

  fetchDistributorVenueNew,
  venueFetchStocktakes,
  venueFetchStocktakeUsers,
  venueStartStocktake,
  venueFetchStocktake,
  venueUpdateStocktake,
  venueFetchProductsByLocation,
  venueUpdateProductQuantity,
  venueConductStocktake,
  venueFetchStocktakeItems,
  venueFetchStocktakeTotalAmount,
  venueImportStocktakeCSV,
  venueImportAllStockCSV,
  venueExportSingleStocktake,
  exportStocktakeToAccountingIntegration,
};
