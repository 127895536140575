import { Model } from '@vuex-orm/core';
import User from '@/models/User';
import Venue from './Venue';

export default class Distributor extends Model {
  static entity = 'distributors';

  static fields() {
    return {
      id: this.attr(null),
      address: this.attr({
        city: '',
        country: '',
        street: '',
        zipCode: '',
      }),
      isConnected: this.attr(false),
      logo: this.attr(''),
      name: this.attr(''),
      customInfo: this.attr(''),
      primaryContactName: this.attr(''),
      primaryContactPhone: this.attr(''),
      secondaryContactName: this.attr(''),
      secondaryContactPhone: this.attr(''),
      productCount: this.attr(0),
      connectedVenuesCount: this.attr(null),
      email: this.attr(''),
      invitedAt: this.attr(null),
      isDefaultImage: this.attr(true),
      isTradeGecko: this.attr(false),
      registeredAt: this.number(null),
      smsNumber: this.attr(null),
      status: this.attr('inactive'),
      tgLastSyncedAt: this.attr(null),
      tgSyncing: this.attr(false),
      whatsAppNumber: this.attr(null),
      timezone: this.attr(null),
      workingHours: this.attr({
        from: '',
        to: '',
      }),
      venueIds: this.attr([]),
      connectedVenues: this.hasManyBy(Venue, 'venueIds'),
      users: this.hasMany(User, 'distributorId'),
      dormantPeriod: this.attr(0),
      minimumOrderAmount: this.attr(0),
      deliveryFee: this.attr(0),
      tax: this.attr(0),
      actionsCount: this.attr(0),
      invitationsCount: this.attr(0),
      priceProposalsCount: this.attr(0),
      accountType: this.attr(''),
      inventoryTracking: this.attr(false),
      inventorySettings: this.attr({
        tracking: false,
        valuation: '',
        displayLevel: '',
        displayLevelBelow: null,
        batchTracking: false,

        // Outlet settings
        displayStockNumbers: false,
        lowInventoryThreshold: 10,
        orderMoreThanAvailable: true,
        excludeExpired: false,
      }),
      primaryWarehouseName: this.attr(''),
      secondaryWarehouseName: this.attr(''),
      onboardingLink: this.attr(''),
      onboardingProductVisibility: this.attr(false),
      deletedAt: this.attr(''),
      currency: this.attr(null),
      currencyChangeEnabled: this.attr(false),
      primaryUserId: this.attr(null),
      defaultDriverId: this.attr(null),
      deliveryPeriodCount: this.attr(null),
      deliveryPeriod: this.attr(null),
      dateFormat: this.attr(''),
      timeFormat: this.attr(''),
      dateTimeFormat: this.attr(''),
      supportedDateFormats: this.attr([]),
      hasInvoiceNumbers: this.attr(false),
      showTaxInvoice: this.attr(false),
      deliveryDays: this.attr([]),
    };
  }
}
