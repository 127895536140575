import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let cart = {};
let info = {};

try {
  cart = JSON.parse(localStorage.getItem('cartItems') || '');
  info = JSON.parse(localStorage.getItem('cartInfo') || '');
} catch (e) {
  // continue
}

const state = {
  cart,
  info,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
