import i18n from '@/i18n';

const TraySettings = () => import(/* webpackChunkName: "distributor-tray-settings" */ '@/views/platform/venue/settings/venue/tray-integrations/Index.vue');
const TrayConnectOrEnable = () => import(/* webpackChunkName: "distributor-tray-settings" */ '@/views/platform/venue/settings/venue/tray-integrations/ConnectOrEnable.vue');


export default [
  // Tray Settings routes
  {
    path: '/buyers/:id(\\d+)/settings/integrations/tray',
    props: ({ params }) => ({ venueId: Number(params.id) }),
    component: TraySettings,
    children: [
      {
        path: '/',
        name: 'venue-tray-connect-or-enable',
        component: TrayConnectOrEnable,
        meta: {
          title: `${i18n.t('integrations.tray.nameAlias')} Account Info`,
        },
      },
    ],
  },
];
