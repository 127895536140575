import i18n from '@/i18n';

const NetSuiteSettings = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/netsuite/Index.vue');
const NetSuiteNotConnected = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/netsuite/NotConnected.vue');
const NetSuiteAccountInfo = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/netsuite/AccountInfo.vue');
const NetSuiteWarehouses = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/netsuite/Warehouses.vue');
const NetSuiteOutlets = () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/netsuite/Outlets.vue');

export default [
  {
    path: '/supplier/settings/integrations/netsuite',
    component: NetSuiteSettings,
    children: [
      {
        path: '/',
        name: 'distributor-netsuite-not-connected',
        component: NetSuiteNotConnected,
        meta: {
          title: `${i18n.t('netsuite.name')} Not Connected`,
        },
      },
      {
        path: 'account-info',
        name: 'distributor-netsuite-account-info',
        component: NetSuiteAccountInfo,
        meta: {
          title: `${i18n.t('netsuite.name')} Account Info`,
        },
      },
      {
        path: 'warehouses',
        name: 'distributor-netsuite-warehouses',
        component: NetSuiteWarehouses,
        meta: {
          title: `${i18n.t('netsuite.name')} Warehouses`,
        },
      },
      {
        path: 'outlets',
        name: 'distributor-netsuite-outlets',
        component: NetSuiteOutlets,
        meta: {
          title: `${i18n.t('netsuite.name')} ${i18n.t('global.venues')}`,
        },
      },
    ],
  },
];
