import i18n from '@/i18n';
import VuePermission from '@/mixins/permissions';

export default [
  {
    path: '/login',
    name: 'admin-login',
    meta: {
      role: 'admin',
      requireAuth: false,
      title: `${i18n.t('routes.global.login')}`,
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/auth/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'admin-forgot-password',
    meta: {
      role: 'admin',
      requireAuth: false,
      title: `${i18n.t('routes.global.forgotPassword')}`,
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/auth/ForgotPassword.vue'),
  },
  {
    path: '/set-password/:token',
    name: 'admin-set-password',
    meta: {
      role: 'admin',
      requireAuth: false,
      title: `${i18n.t('routes.global.setPassword')}`,
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/auth/SetPassword.vue'),
  },
  {
    path: '/buyer-groups/:groupId(\\d+)/venues/:id(\\d+)/pending-update/:pendingId(\\d+)',
    name: 'new-pending-update',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/admin/venues/pending-updates/new-supplier/Index'
      ),
    props: true,
    meta: {
      role: 'admin',
      requireAuth: true,
      title: `${i18n.t('routes.admin.pendingUpdates')}`,
    },
  },
  {
    path: '/',
    name: 'admin-home',
    redirect: { name: 'admin-order-history' },
    meta: {
      role: 'admin',
      requireAuth: true,
      title: `${i18n.t('routes.admin.home')}`,
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Dashboard.vue'),
    children: [
      {
        path: 'order-history/:id(\\d+)?',
        name: 'admin-order-history',
        props: ({ params }) => ({ orderId: Number(params.id) }),
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/orders/OrderHistory.vue'),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
          title: `${i18n.t('routes.admin.orderHistory')}`,
        },
      },
      {
        path: 'suppliers',
        name: 'admin-distributors',
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/distributors/Index.vue'),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
          title: `${i18n.t('routes.admin.distributors')}`,
        },
      },
      {
        path: 'suppliers/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/Show.vue'
          ),
        props: ({ params }) => ({ distributorId: Number(params.id) }),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
        children: [
          {
            path: '',
            name: 'admin-distributor-info',
            props: ({ params }) => ({ distributorId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/ShowInfo.vue'
              ),
            meta: {
              title: `${i18n.t('routes.admin.distributorInfo')}`,
            },
          },
          {
            path: 'buyers',
            name: 'admin-distributor-venues',
            props: ({ params }) => ({ distributorId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/ShowVenues.vue'
              ),
            meta: {
              title: `${i18n.t('routes.admin.distributorVenues')}`,
            },
          },
          {
            path: 'products',
            name: 'admin-distributor-products',
            props: ({ params }) => ({ distributorId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/ShowProducts.vue'
              ),
            meta: {
              title: `${i18n.t('routes.admin.distributorProducts')}`,
            },
          },
          {
            path: 'users',
            name: 'admin-distributor-users',
            props: ({ params }) => ({ distributorId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/ShowUsers.vue'
              ),
            meta: {
              title: `${i18n.t('routes.admin.distributorUsers')}`,
            },
          },
        ],
      },
      {
        path: 'suppliers/:id(\\d+)/products/:productId(\\d+)',
        props: ({ params }) => ({
          productId: Number(params.productId),
        }),
        component: () =>
          import(/* webpackChunkName: "common" */ '@/views/common/products/Show.vue'),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
        children: [
          {
            path: '/',
            name: 'admin-distributor-product-info',
            component: () =>
              import(/* webpackChunkName: "common" */ '@/views/common/products/ProductInfo.vue'),
            props: ({ params }) => ({
              distributorId: Number(params.id),
              productId: Number(params.productId),
            }),
            meta: {
              title: `${i18n.t('routes.admin.productInfo')}`,
            },
          },
          {
            path: 'pricing',
            name: 'admin-distributor-product-tiers',
            props: ({ params }) => ({ productId: Number(params.productId) }),
            component: () =>
              import(/* webpackChunkName: "common" */ '@/views/common/products/TierPricing.vue'),
          },
          {
            path: 'availability',
            name: 'admin-distributor-product-availability',
            props: ({ params }) => ({
              productId: Number(params.productId),
              distributorId: Number(params.id),
            }),
            component: () =>
              import(/* webpackChunkName: "common" */ '@/views/common/products/Availability.vue'),
          },
        ],
      },
      {
        path: 'buyer-groups',
        name: 'admin-venue-groups',
        component: () =>
          import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venue-groups/List.vue'),
        meta: {
          title: `${i18n.t('routes.admin.venueGroups')}`,
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
      },
      {
        path: 'buyer-groups/:groupId(\\d+)',
        props: ({ params }) => ({ groupId: Number(params.groupId) }),
        component: () =>
          import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venue-groups/Show.vue'),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
        children: [
          {
            path: '/',
            name: 'admin-venue-groups-info',
            props: ({ params }) => ({ groupId: Number(params.groupId) }),
            component: () =>
              import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venue-groups/Info.vue'),
            meta: {
              title: `${i18n.t('routes.admin.groupInfo')}`,
            },
          },
          {
            path: 'buyers',
            name: 'admin-venue-groups-venues',
            props: ({ params }) => ({ groupId: Number(params.groupId) }),
            component: () =>
              import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venues/List.vue'),
            meta: {
              title: `${i18n.t('routes.admin.venues')}`,
            },
          },
          {
            path: 'users',
            name: 'admin-venue-groups-users',
            props: ({ params }) => ({ groupId: Number(params.groupId) }),
            component: () =>
              import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venue-groups/Users.vue'),
            meta: {
              title: `${i18n.t('routes.admin.venueUsers')}`,
            },
          },
        ],
      },
      {
        path: 'buyer-groups/:groupId(\\d+)/venues/:id(\\d+)',
        props: ({ params }) => ({
          venueId: Number(params.id),
          groupId: Number(params.groupId),
        }),
        component: () =>
          import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venues/Show.vue'),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
        children: [
          {
            path: '',
            name: 'admin-venue-info',
            props: ({ params }) => ({
              venueId: Number(params.id),
              groupId: Number(params.groupId),
            }),
            component: () =>
              import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venues/ShowInfo.vue'),
            meta: {
              title: `${i18n.t('routes.admin.venueInfo')}`,
            },
          },
          {
            path: 'suppliers',
            name: 'admin-venue-distributors',
            props: ({ params }) => ({ venueId: Number(params.id) }),
            component: () =>
              import(
                /* webpackChunkName: "admin-venues" */ '@/views/admin/venues/ShowDistributors.vue'
              ),
            meta: {
              title: `${i18n.t('routes.admin.venueDistributors')}`,
            },
          },
          {
            path: 'pending-updates',
            name: 'admin-venue-pending-updates',
            props: ({ params }) => ({
              venueId: Number(params.id),
              groupId: Number(params.groupId),
            }),
            component: () =>
              import(
                /* webpackChunkName: "admin-venues" */ '@/views/admin/venues/pending-updates/index'
              ),
            meta: {
              title: `${i18n.t('routes.admin.venueDistributors')}`,
            },
          },
        ],
      },
      {
        path: 'categories',
        name: 'admin-categories',
        component: () =>
          import(
            /* webpackChunkName: "admin-categories" */ '@/views/admin/categories/Categories.vue'
          ),
        meta: {
          title: `${i18n.t('routes.admin.categories')}`,
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
      },
      {
        path: 'categories/:id(\\d+)+',
        name: 'admin-categories-single',
        props: ({ params }) => ({ parentId: Number(params.id) }),
        component: () =>
          import(
            /* webpackChunkName: "admin-categories" */ '@/views/admin/categories/SingleCategory.vue'
          ),
        meta: {
          ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
        },
      },
      {
        path: 'clearing-app',
        name: 'admin-clearing-app',
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/clearing-app/Index.vue'),
        redirect: { name: 'admin-clearing-app-processing' },
        meta: {
          title: `${i18n.t('routes.admin.clearingApp')}`,
          requireAuth: true,
        },
        children: [
          {
            path: 'processing',
            name: 'admin-clearing-app-processing',
            component: () =>
              import(/* webpackChunkName: "admin" */ '@/views/admin/clearing-app/Processing.vue'),
            meta: {
              title: `${i18n.t('routes.admin.clearingApp')}`,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/suppliers/new',
    name: 'admin-distributors-new',
    component: () =>
      import(
        /* webpackChunkName: "admin-distributors" */ '@/views/admin/distributors/new-distributor/NewDistributor.vue'
      ),
    meta: {
      title: `${i18n.t('routes.admin.createDistributor')}`,
      ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
    },
  },
  {
    path: '/clearing-app/processing/:id(\\d+)+',
    name: 'admin-clearing-app-processing-single',
    props: ({ params, query }) => ({
      invoiceId: Number(params.id),
      ...(query.status ? { status: String(query.status) } : {}),
    }),
    component: () =>
      import(
        /* webpackChunkName: "admin-categories" */ '@/views/admin/clearing-app/pending-invoice/NewPendingInvoice.vue'
      ),
    meta: {
      title: `${i18n.t('routes.admin.clearingApp')}`,
      requireAuth: true,
    },
  },
  {
    path: '/clearing-app/merge/:id(\\d+)+',
    name: 'admin-clearing-app-merge',
    props: ({ params }) => ({
      invoiceId: Number(params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "admin-categories" */ '@/views/admin/clearing-app/pending-invoice/PendingInvoiceMerge.vue'
      ),
    meta: {
      title: `${i18n.t('routes.admin.clearingApp')}`,
      requireAuth: true,
      ...VuePermission.routeRule('adminMergeInvoices', 'admin-clearing-app-processing-single'),
    },
  },
  {
    path: '/suppliers/:distributorId(\\d+)/products/new',
    name: 'admin-distributors-products-new',
    props: ({ params }) => ({ distributorId: Number(params.distributorId) }),
    component: () =>
      import(
        /* webpackChunkName: "admin-distributors" */ '@/views/common/products/new-product/NewProduct.vue'
      ),
    meta: {
      title: `${i18n.t('routes.admin.createDistributorProduct')}`,
      ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
    },
  },
  {
    path: '/buyer-groups/new',
    name: 'admin-venue-groups-new',
    component: () =>
      import(/* webpackChunkName: "admin-venues" */ '@/views/admin/venues/new-venue/NewVenue.vue'),
    meta: {
      title: `${i18n.t('routes.admin.createVenueGroup')}`,
      ...VuePermission.routeRule('adminManagePlatform', 'admin-clearing-app'),
    },
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "pagenotfound" */ '@/views/common/PageNotFound.vue'),
  },
];
