import httpService, { userRoute } from '@/api/http';
import Category from '@/models/Category';
import { isAdmin, LOADING_KEY } from '@/util/constants';

const fetchCategories = () => httpService.get(userRoute('/categories'))
  .then(({ data }) => Category.create({ data: data.data }));

const fetchCategoriesByVenueId = () => httpService.get(`${isAdmin() ? 'admin/' : ''}venue/categories`)
  .then(({ data }) => Category.create({ data: data.data }));

const fetchCategoryById = (context, categoryId) => httpService.get(userRoute(`/categories/${categoryId}`))
  .then(({ data }) => {
    Category.insertOrUpdate({ data: data.data });
    return data.data;
  });

const removeCategory = (context, id) => httpService.delete(`/admin/categories/${id}`)
  .then(() => Category.delete(id));

const removeCategoryImage = (context, category) => Category.update({
  data: {
    ...category,
    image: null,
    isDefaultImage: true,
  },
});

/**
 * @since 2.3.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0eaf7975-bcb4-4190-a57a-343691aa3bd4}
 *
 * @return {Promise<AxiosResponse<T>>}
 */
function venueFetchCategories() {
  return httpService.get(`/venue/categories?all=true`);
}


/**
 * @since 3.6.0
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#7b7cc8d5-29ec-4de0-940c-04bcdc1950a1}
 *
 * @param context
 * @return {Promise<AxiosResponse<any>>}
 */
function distributorFetchCategories() {
  return httpService.get('/distributor/categories');
}

/**
 * @since 3.8.2
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#0eaf7975-bcb4-4190-a57a-343691aa3bd4}
 *
 * @param context
 * @param loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
function venueFetchCategoriesNew(context, {
  loadingKey = LOADING_KEY.VENUE_FETCH_CATEGORIES_FILTER,
} = {}) {
  return httpService.get(`/venue/categories`, {
    headers: {
      loadingKey,
    },
  });
}


export default {
  fetchCategories,
  fetchCategoriesByVenueId,
  fetchCategoryById,
  removeCategory,
  removeCategoryImage,
  venueFetchCategories,
  distributorFetchCategories,

  // Refactored
  venueFetchCategoriesNew,
};
