import { Model } from '@vuex-orm/core';
import Distributor from '@/models/Distributor';
import Group from '@/models/Group';
import User from '@/models/User';

export default class Venue extends Model {
  static entity = 'venues';

  static fields() {
    return {
      id: this.attr(null),
      isConnected: this.boolean(false),
      logo: this.attr(''),
      name: this.attr(''),
      legalName: this.attr(''),
      companyRegistrationNumber: this.attr(''),
      customInfo: this.attr(''),
      status: this.attr(''),
      shippingAddress: this.attr({
        city: '',
        country: '',
        street: '',
        zipCode: '',
      }),
      billingAddress: this.attr({
        city: '',
        country: '',
        street: '',
        zipCode: '',
      }),
      accountOwner: this.attr({
        id: null,
        name: 'Unassigned',
        email: '',
        phone: null,
        status: '',
      }),
      connection: this.attr({
        paymentTerms: 'no_terms',
        tax: null,
        primaryWarehouseId: null,
      }),
      email: this.attr(''),
      invitedAt: this.attr(null),
      registeredAt: this.attr(null),
      isDefaultImage: this.attr(false),
      ownerEmail: this.attr(''),
      primaryContactName: this.attr(''),
      primaryContactPhone: this.attr(''),
      secondaryContactName: this.attr(''),
      secondaryContactPhone: this.attr(''),
      distributorIds: this.attr([]),
      dormantPeriod: this.attr(null),
      workingHours: this.attr({ from: '', to: '' }),
      instruction: this.attr(''),
      groupId: this.attr(null),
      group: this.belongsTo(Group, 'groupId'),
      connectedDistributors: this.hasManyBy(Distributor, 'distributorIds'),
      users: this.hasMany(User, 'venueId'),
      accountType: this.attr(''),
      editable: this.attr(false),
      isXeroConnected: this.attr(false),
      isXeroTokenValid: this.attr(false),
      timezone: this.attr(null),
      accountActivityStatus: this.attr(null),
      currency: this.attr(null),
      currencyChangeEnabled: this.attr(false),
      priceChangeNotificationPercent: this.attr(null),
      shouldUpdateReferencePrice: this.attr(false),
      differentCurrency: this.attr(false),
      primaryUserId: this.attr(null),
      provider: this.attr(null),
      dateFormat: this.attr(''),
      timeFormat: this.attr(''),
      dateTimeFormat: this.attr(''),
      tags: this.attr([]),
      orderingType: this.attr(''),
      invoiceEmail: this.attr(''),
    };
  }
}
