<template>
  <ez-modal ref="modal" v-on="$listeners">
    <div class="modal__image">
      <img :src="image" alt="Image inside a modal" />
    </div>
  </ez-modal>
</template>

<script>
import { EzModal } from '.';
/**
 * EzImageModal
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzModal,
  },
  data() {
    return {
      image: '',
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open(image) {
      this.image = image;
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal {
  @extend %flex-center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

.modal__image {
  max-height: 100%;
  max-width: 80%;
  text-align: center;

  img {
    max-height: 95vh;
    max-width: 80%;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(0,0,20,0.1);
  }
}
</style>
