/**
 * Calculate delivery fee based on distributor and the order total amount.
 *
 * @param distributor
 * @param totalAmount
 * @param checkTotal
 * @returns {number}
 */
export function calculateDeliveryFee(distributor, totalAmount, checkTotal = true) {
  const moa = distributor?.minimumOrderAmount ?? null;
  const df = distributor?.deliveryFee ?? 0;
  if (moa === null) return 0;
  if (moa === 0) return df;
  if (totalAmount === 0 && checkTotal) return 0;

  if (totalAmount < moa) return df;

  return 0;
}
